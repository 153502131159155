import React, { useReducer } from "react";
import axios from "axios";

import AuthContext from "./authContext";
import authReducer from "./authReducer";

import {
  AUTH_ERROR,
  CLEAR_ERRORS,
  LOAD_FAIL,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_LOADED,
} from "./types";
import { getErrorMessage } from "../../core/utils/helpers";

import Keywords from "../../core/utils/Keywords.json";
import { CHANGE_LANGUAGES } from "../shared/types";
import Logger, { ManageErrors } from "../../core/utils/Logger";
import LINKS from "../../config";

const AuthState = (props) => {
  const initialState = {
    keywords: Keywords,
    isAuth: false,
    currentUser: null,
    error: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState, () => {
    // if (localStorage.getItem('token')) initialState.isAuth = true;
    return initialState;
  });

  const changeKeywordsAuth = (keywords) => {
    dispatch({
      type: CHANGE_LANGUAGES,
      payload: keywords,
    });
  };

  //  Login User
  const login = (formData) => {
    try {
      return new Promise(async (resolve, reject) => {
        localStorage.removeItem("token");
        await axios
          .post(LINKS.auth, { ...formData, grant_type: "password" })

          //  await axios.post("/ms-users/users/login", formData)
          .then(async (response) => {
            if (response.data) {
              dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data,
              });
              resolve(response.data);
            }
            await loadUser();
          })
          .catch((error) => {
            // ManageErrors(error.response, state.keywords);
            // Logger("INVALID_CREDENTIALS", true, "error");
            dispatch({
              type: LOGIN_FAIL,
              payload: "Invalid Credentials",
            });
            reject({
              data: (error.response && error.response.data) || {},
              status: error.response && error.response.status,
            });
          });
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
        payload: getErrorMessage(err),
      });
    }
  };

  //  Load User
  const loadUser = (params = {}) => {
    try {
      return new Promise(async (resolve, reject) => {
        await axios
          .get(LINKS.apiUrlAPI + "/auth/current")
          .then((response) => {
            if (response.data) {
              dispatch({
                type: USER_LOADED,
                payload: response.data,
              });
              resolve(response.data);
            }
          })
          .catch((error) => {
            if (!params.noredirect) {
              ManageErrors(error.response, state.keywords);
            }
            dispatch({
              type: LOAD_FAIL,
              payload: "User loaded fail",
            });
            reject(error);
          });
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
        payload: getErrorMessage(err),
      });
    }
  };

  //  Logout
  const logout = () => {
    localStorage.removeItem("token");
    dispatch({
      type: LOGOUT,
    });
    window.location.href = LINKS.login();
  };

  //  Clear Errors
  const clearErrors = () => {
    dispatch({
      type: CLEAR_ERRORS,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        keywords: state.keywords,
        isAuth: state.isAuth,
        currentUser: state.currentUser,
        isLoading: state.isLoading,
        error: state.error,
        loadUser,
        login,
        logout,
        clearErrors,
        changeKeywordsAuth,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
