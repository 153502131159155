import React, { useContext, useEffect, useState } from "react";
import queryString from "query-string";
import { transformFormFromDbToJs } from "./creator/helpers";
import SharedContext from "../../contexts/shared/sharedContext";
import { useHistory, useParams } from "react-router-dom";
import { getArray, getJson, getJsonArray, getValueManager, getValueManagerArray, isEmpty, isTypeOf, updateObjectFromImage, updateValueManager } from "../../core/manager/ManagerUtils";
import TextInput from "../../core/common/inputs/TextInput";
import QSQuestionCreatorPreview from "./preview/FormPreview";
import { keys } from "@material-ui/core/styles/createBreakpoints";
import { userIsAffiliate, userIsInternal, userIsStartup } from "../../core/utils/security";
import Logger, { LoggerSuccess } from "../../core/utils/Logger";
import { validateForm } from "../../core/utils/form-validation";
import { fi } from "date-fns/locale";
import {Modal} from "antd";

export const FeedbackFill = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const { GetResource, getKeyword, profile, ImageSaver } = useContext(SharedContext);
    const [searchParams] = useState(
        queryString.parse(props.location.search, { ignoreQueryPrefix: true })
    );
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [preview, setPreview] = useState(null);
    const [formData, setFormData] = useState(null);
    const [page, setPage] = useState(0);

    const [form, setForm] = useState({});
    const [formPreview, setFormPreview] = useState({});
    const [images, setImages] = useState({});

    const [showError, setShowError] = useState(null);

    const [isLoading, setIsLoading] = useState({
        loading: false,
        size: 0,
        count: 0,
    });

    const [isModalOpen, setIsModalOpen] = useState(true);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        getBackendForm(id);
    }, []);

    const getBackendForm = (idParam) => {
        setLoading(true);
        GetResource(
            "CATALOG_MANAGEMENT",
            "GET_ID",
            { id: idParam },
            null
        ).then((res) => {
            setFormData(res.payload);
            const newForm = transformFormFromDbToJs(res.payload.form);
            setPreview(JSON.parse(JSON.stringify(newForm)));
            setLoading(false);
        }, err => {

        });

        if (searchParams.feedbackId) {

            GetResource(
                "FEEDBACK_MANAGEMENT",
                "GET_ID",
                { id: searchParams.feedbackId },
                null
            ).then((res) => {
                setForm({ name: res.payload.name })
                setFormPreview({ ...res.payload.responses, ...res.payload.responsesTable, ...res.payload.responsesArray, ...res.payload.responsesTableArray })
            }, err => {

            });
        }
    };

    const onChange = (v, n, indexes = null) => {
        const f = updateValueManager(form, n, v, indexes);
        setForm({ ...f });
    };

    const formValid = getValueManager(form, "name");

    const onChangePreview = (v, n, indexes = null) => {
        const f = updateValueManager(formPreview, n, v, indexes);
        setFormPreview({ ...f });
    };

    const onChangeImages = (
        files,
        name,
        multiple,
        indexes,
        usedFor = "FORM"
    ) => {
        images[name] = { files, name, multiple, indexes, usedFor };
        setImages({ ...images });
    };

    const uploadImages = (callback) => {
        ImageSaver(
            (params, images) => {
                updateObjectFromImage(formPreview, images);
                callback();
            },
            (params, status) => setIsLoading(status),
            {},
            images,
            form
        );
    };

    const isFormValid = () => {
        return !getValueManagerArray(formData, "form.cards").find(item => {

            const conditionalVisibility = getValueManager(item, "conditionalVisibility") && getJsonArray(getValueManager(item, "visibility"));
            if (getArray(conditionalVisibility).length != 0 && !validateForm(formPreview, conditionalVisibility)) {
                return false;
            }

            if (getValueManager(item, "required") && isEmpty(getValueManager(formPreview, getValueManager(item, "name")))) {
                return true;
            }

            if (getValueManager(item, "question.type") == "QUESTION_TYPE_TABLE") {
                let containsError = false;
                getValueManagerArray(formPreview, getValueManager(item, "name")).forEach(table => {
                    getValueManagerArray(item, "question.lines").forEach(line => {
                        const required = getJson(getValueManager(item, "secondNames"))[line.id + "_required"];
                        if (required && isEmpty(getValueManager(table, getJson(getValueManager(item, "secondNames"))[line.id] || line.id))) {
                            containsError = true;
                        }
                    })
                })
                if (containsError) {
                    return containsError;
                }
            }


            return false;

            // const validation = getValueManager(item, "hasValidation") && getJsonArray(getValueManager(item, "validation"));
            // if (getArray(validation).length == 0) {
            //     return false;
            // }
            // return !validateForm(formPreview, validation);
        })
    }

    const saveForm = () => {
        if (!isFormValid()) {
            setShowError(true);
            Logger("Validation errors");
            return;
        }
        const req = {
            ...form,
            id: searchParams.feedbackId || null,
            date: searchParams.date || null,
            catalog: { id: id },
            target: searchParams.target || (userIsAffiliate(profile) && "affiliate") || (userIsStartup(profile) && "startup") || "internal", feedbacks: []
        };
        if (req.target == "affiliate") {
            req.affiliate = { id: profile.affiliate.id };
        } else if (req.target == "startup") {
            req.startup = { id: profile.startup.id };
        } else {
            req.user = { id: profile.id };
        }
        uploadImages(() => {
            Object.keys(formPreview).forEach(key => {
                const value = formPreview[key];
                if (isTypeOf(value, "Array", false)) {
                    value.forEach((value2, index) => {
                        if (isTypeOf(value2, "Object", false)) {
                            Object.keys(value2).forEach(key2 => {
                                const value3 = value2[key2];
                                if (isTypeOf(value3, "Array", false)) {
                                    value3.forEach((val3, index2) => {
                                        req.feedbacks.push({
                                            name: key,
                                            secondName: key2,
                                            value: val3,
                                            inTable: true,
                                            inArray: true,
                                            line: index,
                                            row: index2
                                        })
                                    });
                                } else {
                                    req.feedbacks.push({
                                        name: key,
                                        secondName: key2,
                                        value: value3,
                                        inTable: true,
                                        line: index
                                    })
                                }
                            })
                        } else {
                            req.feedbacks.push({
                                name: key,
                                // secondName: key2,
                                value: value2,
                                inArray: true,
                                row: index
                            })
                        }
                    })
                } else {
                    req.feedbacks.push({
                        name: key,
                        value: value
                    })
                }
            });
            GetResource(
                "FEEDBACK_MANAGEMENT",
                "ADD",
                req,
                null
            ).then((res) => {
                LoggerSuccess(getKeyword("NOTIFICATION.WELL_DONE"));
                if (userIsInternal(profile)) {
                    history.push("/admin/feedback");
                } else {
                    history.push("/external/feedback");
                }
            }, err => {

            });
        })
    }

    return <div>
        {step == 0 ?
            <div className="row">
                <div className="col-12">
                    <TextInput
                        label="Nom de collecte"
                        name="name"
                        onChange={(v, n) => onChange(v, n)}
                        value={getValueManager(form, "name", null)}
                        required={true}
                    ></TextInput>
                </div>
                <div className="col-12">
                    <button
                        className="btn-1"
                        color="danger"
                        type="button"
                        disabled={!formValid}
                        onClick={(e) => setStep(1)}
                    >
                        {getKeyword("STD_VERB.NEXT")}
                    </button>
                </div>
            </div> : null}
        {step == 1 && !loading && preview ? <div className="row">
            <div className="col-12 qc-preview mgb-20">
                <Modal title="confirmation" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <p>Nous vous informons que votre questionnaire ne sera pas enregistré tant qu'il n'aura pas été sauvegardé.</p>
                </Modal>
                <QSQuestionCreatorPreview
                    version={id}
                    items={preview[page].items}
                    root={true}
                    indexes={[]}
                    parentIndex=""
                    onChange={onChangePreview}
                    onChangeImages={onChangeImages}
                    form={formPreview}
                    showError={showError}>
                </QSQuestionCreatorPreview>
            </div>
            <div className="col-12">
                <button
                    className="btn-1"
                    color="danger"
                    type="button"
                    onClick={(e) => saveForm()}
                >
                    {getKeyword("STD_VERB.SAVE")}
                </button>
            </div>
        </div> : null}
    </div>
}

export default FeedbackFill;
