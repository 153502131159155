import { getJsonArray, getValueManager, getValueManagerNumber } from "../../core/manager/ManagerUtils";

// const data = [
//   {
//     affiliate: "AFMA_CODE",
//     affiliateName: "AFMA",
//     startup: "AFMA_CODE",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: false,
//     year: 2021,
//     month: 11,
//     satisfaction: "value1",
//     date: "11/20",
//   },
//   {
//     affiliate: "AFMA_CODE",
//     affiliateName: "AFMA",
//     startup: "AFMA_CODE2",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: true,
//     year: 2022,
//     month: 11,
//     satisfaction: "value2",
//     date: "11/20",
//   },
//   {
//     affiliate: "AFMA_CODE",
//     affiliateName: "AFMA",
//     startup: "AFMA_CODE2",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: true,
//     year: 2022,
//     month: 11,
//     satisfaction: "value2",
//     date: "11/20",
//   },
//   {
//     affiliate: "AFMA_CODE",
//     affiliateName: "AFMA",
//     startup: "AFMA_CODE2",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: true,
//     year: 2021,
//     month: 11,
//     satisfaction: "value1",
//     date: "11/20",
//   },

//   {
//     affiliate: "AFMA_CODE",
//     affiliateName: "AFMA",
//     startup: "AFMA_CODE",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: true,
//     year: 2020,
//     month: 1,
//     satisfaction: "value1",
//     date: "11/20",
//   },
//   {
//     affiliate: "AFMA_CODE",
//     affiliateName: "AFMA",
//     startup: "AFMA_CODE",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: false,
//     year: 2020,
//     month: 11,
//     satisfaction: "value1",
//     date: "11/20",
//   },
//   {
//     affiliate: "AGMA_CODE",
//     affiliateName: "AGMA",
//     startup: "AGMA_STARTUP",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: true,
//     year: 2022,
//     month: 11,
//     satisfaction: "value3",
//     date: "11/20",
//   },
//   {
//     affiliate: "AGMA_CODE",
//     affiliateName: "AGMA",
//     startup: "AGMA_STARTUP",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: false,
//     year: 2022,
//     month: 11,
//     satisfaction: "value3",
//     date: "11/20",
//   },
//   {
//     affiliate: "AGMA_CODE",
//     affiliateName: "AGMA",
//     startup: "AGMA_STARTUP",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: false,
//     year: 2022,
//     month: 11,
//     satisfaction: "value4",
//     date: "11/20",
//   },
//   {
//     affiliate: "AGMA_CODE",
//     affiliateName: "AGMA",
//     startup: "AGMA_STARTUP",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: true,
//     year: 2020,
//     month: 4,
//     satisfaction: "value1",
//     date: "11/20",
//   },
//   {
//     affiliate: "AGMA_CODE",
//     affiliateName: "AGMA",
//     startup: "AGMA_STARTUP",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: true,
//     year: 2020,
//     month: 3,
//     satisfaction: "value1",
//     date: "11/20",
//   },
//   {
//     affiliate: "AGMA_CODE",
//     affiliateName: "AGMA",
//     startup: "AGMA_STARTUP",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: true,
//     year: 2020,
//     month: 2,
//     satisfaction: "value1",
//     date: "11/20",
//   },
//   {
//     affiliate: "AGMA_CODE",
//     affiliateName: "AGMA",
//     startup: "AGMA_STARTUP",
//     startupName: "STARTUP",
//     montant: 2.5,
//     signature: false,
//     year: 2020,
//     month: 1,
//     satisfaction: "value1",
//     date: "11/20",
//   },
// ];

// const goals = [
//   {
//     code: "AFMA_CODE",
//     goal: 10,
//   },
//   {
//     code: "AGMA_CODE",
//     goal: 20,
//   },
// ];

// const activities = {
//   AGMA_STARTUP: { activity: "activity agmaStartup", name: "Agma name" },
//   AFMA_CODE2: { activity: "activity afma code 2", name: "afma name 2" },
//   AFMA_CODE: { activity: "activity afma code ", name: "afma name " },
// };

const monthsNames = [
  "janv",
  "feb",
  "mars",
  "avr",
  "mai",
  "juin",
  "juil",
  "aout",
  "sept",
  "oct",
  "nov",
  "dec",
];
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const years = [
  new Date().getFullYear() - 2,
  new Date().getFullYear() - 1,
  new Date().getFullYear(),
];

const monthNow = new Date().getMonth() +1;

const barColors = ["#c23431", "#2f4553", "#619fa7"];

const formatDataForBarChart1 = (rawData, criteria, isSigned, affilates) => {
  const affiliates = [...new Set(rawData.map((data) => data.affiliateName))];
  console.log("affiliates",affiliates);
  const affiliateNames = affiliates.map(
    (affiliate_) =>
      getValueManager(affiliates, affiliate_ + ".name") || affiliate_,
  );

  console.log("affiliateNamesaffiliateNames",affiliateNames);
  let series = [];
  for (let i = 0; i < years.length; i++) {
    let serie = [];

    affiliates.forEach((affiliate) => {
      if (criteria === "montant") {
        serie.push(
          rawData
            .filter(
              (data) => data.affiliateName === affiliate && data.year === years[i]
            )
            .map((data) => data.montant)
            .reduce((a, b) => a + b, 0)
        );
      } else {
        serie.push(
          rawData.filter(
            (data) =>
              data.affiliateName === affiliate &&
              data.signature === isSigned &&
              data.year === years[i]
          ).length
        );
      }
    });

    series.push({ data: serie, formatter: "{c}" });
  }
 if(criteria === "signature") console.log("series of the last chart",series)
  return {
    xAxis: affiliateNames,
    series,
    legends: { data: [...years.map(y => ({ name: y + "" }))] }
  };
};

const formatDataForBarChart2 = (rawData, criteria, isSigned) => {
  let series = [];

  let serie = [];
  for (var i = 0; i < years.length; i++) {
    let total = 0;
    for (var j = 0; j < months.length; j++) {



      if (criteria === "montant") {
        total += rawData
          .filter((data) => data.month === months[j] && data.year === years[i])
          .map((data) => data.montant)
          .reduce((a, b) => a + b, 0);
      } else {
        total += rawData.filter(
          (data) => data.signature === isSigned && data.month === months[j] && data.year === years[i]
        ).length;
      }
      serie.push({
        value: total,
        itemStyle: { normal: { color: barColors[i] } },
      });
    }
  }


 console.log("all data series", monthNow);
  if (monthNow !==12){
    for (let i = 0; i < 12-monthNow; i++){
      serie[serie.length -1 -i].value=0;
    }
  }

  series.push({ data: serie, formatter: "{c}" });

  let xAxis = [];
  years.forEach((year) => {
    monthsNames.forEach((month) => {
      xAxis.push(month + year.toString().substring(2, 4));
    });
  });

  return {
    xAxis,
    series,
    // legends: { data: [...years.map(y => ({ name: y + "" }))] }

     legends: { data: [{ name: criteria === "montant" ? "Volume d'Affaires" : isSigned ? "Contrats Signés" : "Entrées en Relation" }] }
  };
};

const formatDataForBarChart3 = (rawData, isSigned) => {
  let series = [];
  let serie = [];

  years.forEach((year, i) => {
    const startups = [
      ...new Set(
          rawData.filter((data) => data.year === year).map((data) => data.startup)
      ),
    ].length;

    //
    // console.log('data with filter',startups);
    // let total = 0;
    // rawData.forEach((data) => {
    //   if (data.year === year) {
    //     total ++;
    //   }
    // });
    serie.push(startups);
  });

  series.push({ data: serie, formatter: "{c}", type:'bar' });

  // for (let i = 0; i < years.length; i++) {
  //
  //   let total = 0;
  //
  //   years.forEach((year, i) => {
  //     total += rawData.filter(
  //       (data) => data.signature === isSigned && data.year === year
  //     ).reduce((a, b) => a + b, 0);
  //     // serie.push();
  //     console.log("total inside", total);
  //
  //   });
  //   console.log("total global", total);
  //   serie.push({
  //       value: total,
  //       itemStyle: { normal: { color: barColors[i] } },
  //     });
  // }

  // series.push(serie);
  // years.forEach((year, i) => {
  //   let startups = [
  //     ...new Set(
  //         rawData.filter((data) => data.year === year).map((data) => data.year)
  //     ),
  //   ].length;
  //
  //   let totalSigned = rawData.filter(
  //       (data) => data.signature === true && data.year === year
  //   ).length;
  //   let totalInitiated = rawData.filter(
  //       (data) => data.signature === false && data.year === year
  //   ).length;
  //
  //   serie1.push({
  //     value: startups,
  //   });
  //   serie2.push({
  //     value: totalInitiated,
  //   });
  //   serie3.push({
  //     value: totalSigned,
  //   });
  // });
  // series.push({ data: serie, formatter: "{c}" });

  // option = {
  //   xAxis: {
  //     type: 'category',
  //     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  //   },
  //   yAxis: {
  //     type: 'value'
  //   },
  //   series: [
  //     {
  //       data: [120, 200, 150, 80, 70, 110, 130],
  //       type: 'bar'
  //     }
  //   ]
  // };
  console.log("seriesseriesseries",series)
  return {
    xAxis: {type: 'category', data : [(new Date()).getFullYear() - 2, (new Date()).getFullYear() - 1, (new Date()).getFullYear()]},
    series,
  };
};

const formatDataForBarChart4 = (rawData) => {
  let series = [];
  const serie1 = [];
  const serie2 = [];
  const serie3 = [];
  years.forEach((year, i) => {

    const startups = [
      ...new Set(
        rawData.filter((data) => data.year === year).map((data) => data.startup)
      ),
    ].length;
    let totalSigned = rawData.filter(
      (data) => data.signature === true && data.year === year
    ).length;
    let totalInitiated = rawData.filter(
      (data) => data.signature === false && data.year === year
    ).length;

    serie1.push({
      value: startups,
    });
    serie2.push({
      value: totalInitiated,
    });
    serie3.push({
      value: totalSigned,
    });
  });

  //
  console.log("serie1",serie1);
  console.log("serie2",serie2);
  console.log("serie3",serie3);

  const arr = ["Nombre de startups", "Entrées en Relation", "Contrats Signés"];

  series.push({ name:arr[0], data: serie1, formatter: "{c}" });
  series.push({ name:arr[1], data: serie2, formatter: "{c}" });
  series.push({ name:arr[2], data: serie3, formatter: "{c}" });
  //
  // console.log("seriesseriesseries",series)
  // (new Date()).getFullYear() - 2, (new Date()).getFullYear() - 1, (new Date()).getFullYear()

  return {
    xAxis: arr,
    series,
    legends: { data: [...years.map(y => ({ name: y + "" }))] }
  };
};

const formatDataForTable = (rawData, year, goals, affiliates) => {
  // const affiliates = [...new Set(rawData.map((data) => data.affiliate))];
  console.log("rawData", rawData);
  console.log("year", year);
    console.log("goals", goals);
    console.log("affiliates", affiliates);
  return goals.map(goal => {

    const affiliate = goal.name;
    const numberOfSignatures = rawData.filter(
      (data) =>
        data.affiliateName === affiliate &&
        data.signature === true &&
        data.year === year
    ).length || 0;

    const numberOfInitiations = rawData.filter(
      (data) =>
        data.affiliateName === affiliate &&
        data.signature === false &&
        data.year === year
    ).length || 0;

    return [
      getValueManager(affiliates, affiliate + ".name") || affiliate,
      getValueManagerNumber(goal, "goal"),
      numberOfSignatures,
      numberOfInitiations,
      (!numberOfSignatures && !numberOfInitiations ? 100 : Math.round(
        (numberOfSignatures /
          (numberOfInitiations === 0
            ? numberOfSignatures
            : numberOfInitiations)) *
        100
      )) + "%",
    ];
  })
  // return affiliates.map((affiliate) => {
  //   const numberOfSignatures = rawData.filter(
  //     (data) =>
  //       data.affiliate === affiliate &&
  //       data.signature === true &&
  //       data.year === year
  //   ).length;

  //   const numberOfInitiations = rawData.filter(
  //     (data) =>
  //       data.affiliate === affiliate &&
  //       data.signature === false &&
  //       data.year === year
  //   ).length;

  //   return [
  //     affiliate,
  //     getValueManagerNumber(goals.find((goal) => goal.code === affiliate), "goal"),
  //     numberOfSignatures,
  //     numberOfInitiations,
  //     Math.round(
  //       (numberOfSignatures /
  //         (numberOfInitiations === 0
  //           ? numberOfSignatures
  //           : numberOfInitiations)) *
  //       100
  //     ) + "%",
  //   ];
  // });
};

const formatDataForPieChart1 = (rawData, thisYear) => {

  const thisYearSignatures =rawData.filter(
      (data) => data.year === thisYear &&  data.startupName === data.startup
  );
  const previousYearSignatures = rawData.filter(
      (data) => data.year === thisYear && data.startupName !== data.startup
  );
  // remove duplicates from array of object
    const unique = [...new Set(thisYearSignatures.map(item => item.startupName))];
    const unique2 = [...new Set(previousYearSignatures.map(item => item.startupName))];

  return [
    { value: unique.length, name: "Nouvelle Startup" },
    { value: unique2.length, name: "Catalogue" },
  ];
};

const formatDataForPieChart2 = (rawData, year) => {
  const satisfactions = [...new Set(rawData.map((data) => data.satisfaction))];
  console.log("listSatisfaction",satisfactions);
  const AllSeriesSatisfactions = satisfactions.map((satisfaction) => ({
    value: rawData.filter(
      (data) => data.year === year && data.satisfaction === satisfaction
    ).length,
    name: satisfaction,
  }));

  console.log("AllSeriesSatisfactions",AllSeriesSatisfactions)
  return AllSeriesSatisfactions;
};

const getMostUsedStartups = (rawData, activities) => {
  const uniQueStartups = [...new Set(rawData.map((d) => d.startupName))];
  const sorted = uniQueStartups
    .map((startup) => ({
      startup,
      count: rawData.filter((d) => d.startup === startup).length,
    }))
    .sort((a, b) => b.count - a.count);
  if (sorted.length === 0) {
    return [];
  }
  let a = {};
  let startups = [];
  let tmp = sorted[0].count;
  console.log("HEHE", rawData, sorted, activities);

  sorted.forEach((element, i) => {
    if (element.count === tmp) {
      startups.push({
        name: getValueManager(activities, getValueManager(element, "startup") + ".name") || getValueManager(element, "startup"),
        activity: getJsonArray(getValueManager(activities, getValueManager(element, "startup") + ".activity")).reduce((a, b) => (a ? a + ", " : "") + b.value, "") || "",
      });
      a[element.count] = { count: element.count, startups };
    } else {
      startups = [];
      tmp = sorted[i].count;
      startups.push({
        name: getValueManager(activities, getValueManager(element, "startup") + ".name") || getValueManager(element, "startup"),
        activity: getJsonArray(getValueManager(activities, getValueManager(element, "startup") + ".activity")).reduce((a, b) => (a ? a + ", " : "") + b.value, "") || "",
      });
      a[element.count] = { count: element.count, startups };
    }
  });

  console.log(Object.values(a).sort((a, b) => b.count - a.count), sorted);
  return Object.values(a).sort((a, b) => b.count - a.count);
};

export {
  formatDataForBarChart1,
  formatDataForBarChart2,
  formatDataForBarChart3,
  formatDataForBarChart4,
  formatDataForTable,
  formatDataForPieChart1,
  formatDataForPieChart2,
  getMostUsedStartups,
};





