import React, { useContext, useEffect, useState } from "react";

import GlobalTable from "../../core/common/table/GlobalTable";
import GlobalFilters from "../../core/common/table/GlobalFilters";
import { USEFULL_VARIABLES } from "../../variables/shared";
import { updateValueManager } from "../../core/manager/ManagerUtils";
import { useHistory } from "react-router";
import ConfirmationPopupShared from "../../core/common/notifications/ConfirmationPopup";
import { replaceIndex } from "../../core/manager/ManagerUtils";
// import QSQuestionPreview from "./show/question-preview";
import QSQuestionMasterShow from "./show/question-master-show";
import SharedContext from "../../contexts/shared/sharedContext";
import ItemMenu from "../../core/common/table/ItemMenu";
import Logger, { LoggerSuccess } from "../../core/utils/Logger";
import QSFormPreviewSaved from "./show/feedback-preview-saved";

const TABLE = [
    {
        label: "COMMON.ID",
        value: "id",
        order: true,
        type: "CLICK",
        call: "show",

    },
    {
        label: "COMMON.CODE",
        value: "code",
    },
    {
        label: "COMMON.DESIGNATION",
        value: "name",
    },
    {
        label: "COMMON.DESCRIPTION",
        value: "description",
        truncate: true
    },
    // {
    //     label: "COMMON.STATUS",
    //     value: "enabled",
    //     type: "BOOLEAN_COLOR",
    //     class: "",
    //     data: {
    //         active: "Actif",
    //         noActive: "Inactif"
    //     }
    // },
    {
        label: "STD.ACTIONS",
        value: "actions",
        type: "RENDER",
        class: "table-actions",
        render: (props) => (
            <>
                <ItemMenu menus={[
                    // {
                    //     name: props.getKeyword("STD_VERB.VIEW_DETAILS"),
                    //     click: () => props.show(props.id)
                    // },
                    {
                        name: props.getKeyword("STD_VERB.EDIT"),
                        click: () => props.update(props.id)
                    },
                    // {
                    //     name: props.getKeyword("STD_VERB.DISABLE"),
                    //     click: () => props.disable(props.id),
                    //     hide: !props.item.enabled
                    // },
                    // {
                    //     name: props.getKeyword("STD_VERB.ENABLE"),
                    //     click: () => props.enable(props.id),
                    //     hide: props.item.enabled
                    // },
                    {
                        name: props.getKeyword("STD_VERB.DELETE"),
                        click: () => props.delete(props.id),
                    },
                    // {
                    //     name: props.getKeyword("STD_VERB.DUPLICATE"),
                    //     click: () => props.duplicate(props.id),
                    // },
                    {
                        name: props.getKeyword("STD_VERB.PREVIEW"),
                        click: () => props.preview(props.id),
                    }
                ]}>

                </ItemMenu>
            </>
        ),
    },
];

const FILTERS = [
    {
        label: "COMMON.DESIGNATION",
        type: "TEXT",
        value: "name",
        className: "col-md-6",
    },
];

const FILTER_INIT = {
    name: "",
    code: "",
    user: "",
    from: null,
};

const QSQuestionMaster = (props) => {
    const history = useHistory();
    const { GetResource, getKeyword } = useContext(SharedContext);
    const [data, setData] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        size: USEFULL_VARIABLES.paginationSize,
        order: null,
        direction: "DESC",
    });
    const [formFilter, setFormFilter] = useState(
        JSON.parse(JSON.stringify(FILTER_INIT))
    );
    const [modalState, setModalState] = useState({});

    const [deleteMessage, setDeleteMessage] = useState(null);

    const toggleModal = (state) => {
        modalState[state] = !modalState[state];
        setModalState({ ...modalState });
    };

    const [alert, setalert] = React.useState(false);
    const [form, setForm] = useState({
        code: "",
        name: "",
        description: "",
        type: null,
    });

    const [previewQuestion, setPreviewQuestion] = useState(null);

    const [isFilter, setIsFilter] = useState(false);

    useEffect(() => {
        paginate(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    }, []);

    const reload = () => {
        paginate(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    };

    const initForm = () => {
        setForm({
            code: "",
            name: "",
            description: "",
            permission: [],
        });
    };

    const beforeAdd = () => {
        history.push("/admin/creator-question?show=false");
    };

    const onAdd = () => {
        reload();
        setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        initForm();
    };

    const setAlert = (message) => {
        LoggerSuccess(message);
    };

    const onUpdate = () => {
        reload();
        setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        initForm();
    };

    const onChangeFilters = (v, n, indexes = null) => {
        const f = updateValueManager(formFilter, n, v, indexes);
        setFormFilter({ ...f });
    };

    const paginate = (page, size, order, direction) => {
        if (isFilter) {
            loadFilter(page, size, order, direction);
        } else {
            loadPaginate(page, size, order, direction);
        }
    };

    const filter = () => {
        pagination.page = 1;
        setIsFilter(true);
        loadFilter(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    };

    const loadPaginate = (page, size, order, direction) => {
        setLoaded(false);
        GetResource(
            "QUESTION_MASTER_MANAGEMENT",
            "GET",
            {
                page,
                size,
                order,
                direction,
            },
            null
        ).then((res) => {
            setData(res.payload);
            setPagination({ ...pagination, page, size, order, direction });
            setLoaded(true);
        });
    };

    const loadFilter = (page, size, order, direction) => {
        setLoaded(false);
        GetResource(
            "QUESTION_MASTER_MANAGEMENT",
            "FILTER",
            {
                ...formFilter,
                data: {
                    page,
                    size,
                    order,
                    direction,
                },
            },
            null
        ).then((res) => {
            setData(res.payload);
            setLoaded(true);
        });
    };

    const initializeFilter = () => {
        setFormFilter(JSON.parse(JSON.stringify(FILTER_INIT)));
        setIsFilter(false);
        loadPaginate(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    };

    const updateItem = (itemId) => {
        history.push("/admin/edit-question/" + itemId + "?show=false");
    };

    const duplicate = (itemId) => {
        GetResource("QUESTION_MASTER_MANAGEMENT", "GET_ID", { id: itemId }, null).then(
            (res) => {
                setForm({ ...res.payload, id: null, code: "" });
                toggleModal("formModal");
            }
        );
    };

    const showItem = (itemId) => {
        GetResource("QUESTION_MASTER_MANAGEMENT", "GET_ID", { id: itemId }, null).then(
            (res) => {
                setForm({ ...res.payload });
                toggleModal("showModal");
            }
        );
    };

    const deleteItem = (itemId) => {
        setDeleteMessage({
            message: getKeyword("NOTIFICATION.DELETE_TEXT"),
            id: itemId,
        });
    };

    const confirmDelete = () => {
        GetResource(
            "QUESTION_MASTER_MANAGEMENT",
            "DELETE",
            { id: deleteMessage.id },
            null
        ).then((res) => {
            setDeleteMessage(null);
            reload();
            setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        }).catch(err => {
            if (err && err.data && err.data.detail && err.data.detail.indexOf("constraint")) {
                Logger("Le formulaire ne peut pas être supprimée en raison de son utilisation dans un questionnaire");
            }
        });;
    };

    const enableItem = (id) => {
        GetResource("QUESTION_MASTER_MANAGEMENT", "ENABLE", { id, enabled: true }, null).then(
            (res) => {
                reload();
                setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
            }
        );
    };

    const disableItem = (id) => {
        GetResource("QUESTION_MASTER_MANAGEMENT", "DISABLE", { id, enabled: false }, null).then(
            (res) => {
                reload();
                setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
            }
        );
    };

    const preview = (itemId) => {
        setPreviewQuestion(itemId);
        toggleModal("previewModal");

    }

    return (
        <>
            {deleteMessage ? (
                <ConfirmationPopupShared
                    message={deleteMessage.message}
                    close={() => setDeleteMessage(null)}
                    confirm={confirmDelete}
                ></ConfirmationPopupShared>
            ) : null}
            <GlobalFilters
                filters={FILTERS}
                form={formFilter}
                onChange={onChangeFilters}
                filter={filter}
                initialize={initializeFilter}
            ></GlobalFilters>
            <div className="card-block">
                <div className="card-header">
                    <div >
                        <h3 className="mb-0">Créateur des formulaires</h3>
                    </div>
                    <div>
                        <button
                            className="btn-1"
                            id="tooltip969372949"
                            onClick={(e) => beforeAdd()}
                            size="lg"
                        >
                            <span className="btn-inner--icon mr-1">
                                <i className="fas fa-plus" />
                            </span>
                            <span className="btn-inner--text">{getKeyword("STD.NEW")}</span>
                        </button>
                        {modalState["showModal"] ? (
                            <QSQuestionMasterShow
                                modalName="showModal"
                                toggleModal={toggleModal}
                                showModal={modalState["showModal"]}
                                form={form}
                                update={updateItem}
                                enable={enableItem}
                                disable={disableItem}
                                duplicate={duplicate}
                                preview={preview}
                            ></QSQuestionMasterShow>
                        ) : null}
                        {modalState["previewModal"] ? (
                            <QSFormPreviewSaved
                                modalName="previewModal"
                                toggleModal={toggleModal}
                                showModal={modalState["previewModal"]}
                                id={previewQuestion}
                            ></QSFormPreviewSaved>
                        ) : null}
                    </div>
                </div>
                {isLoaded ? (
                    <GlobalTable
                        table={TABLE}
                        data={data.data}
                        count={data.count}
                        pagination={pagination}
                        paginate={paginate}
                        update={updateItem}
                        enable={enableItem}
                        disable={disableItem}
                        delete={deleteItem}
                        show={showItem}
                        duplicate={duplicate}
                        preview={preview}
                    ></GlobalTable>
                ) : null}
            </div>
        </>
    );
};

export default QSQuestionMaster;
