import FullInpurProvider from "../../../core/common/inputs/FullInputProvider";
import { getValueManagerArray } from "../../../core/manager/ManagerUtils";
import { getValueManager, getValueManagerNumber, getValueManagerNumberFloat } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { useDrag } from 'react-dnd'


const QSQuestionCreatorCardEdit = (props) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "CARD",
        item: { ...props.item, type: "CARD", question: props.question, index: props.index, isOld: true },
        collect: monitor => {
            return ({
                isDragging: monitor.isDragging(),
                type: "CARD"
            })
        },
        end: (draggedOitem) => {
            props.deleteHover();
        }
    }))

    return (
        <div
            ref={drag}
            className="qc-card-drag-edit"
            style={{
                opacity: isDragging ? 0.5 : 1,
                fontSize: 16,
                fontWeight: 'bold',
                cursor: 'move',
            }}
        >
            <div className="title">
                <div><i className="fas fa-grip-vertical"></i> {props.item.name}</div>
                <div ><i onClick={() => props.delete({ index: props.index })} className="fas fa-trash-alt qc-trash pointer"></i><i onClick={() => props.changeSettings({ index: props.index })} className="fas fa-cog pointer"></i></div>
            </div>
            <div className="elements">

                <FullInpurProvider update={true} label={props.question.name} form={props.form} name={props.item.name} onChange={props.onChange}
                    other={props.question.other} multiple={props.question.multiple}  item={props.item} 
                    api={{ apiLink: props.question.apiLink, apiMethod: props.question.apiMethod, apiBody: props.question.apiBody, apiLabel: props.question.apiLabel, apiSelect: props.question.apiSelect }}
                    onChangeImages={props.onChangeImages} type={props.question.type} required={props.item.required} description={props.question.description}
                    code={getValueManager(props.question, "response.id")} lines={getValueManagerArray(props.question, "lines")}></FullInpurProvider>
            </div>
        </div>
    )
}

export default QSQuestionCreatorCardEdit;