import Logs from "../../modules/logs/logs";
import NTNotification from "../../modules/notifications/Notifications";
import FeedbackFill from "../../modules/question/feedback-fill";
import QSFeedbackList from "../../modules/question/feedback-list";
import StartupForm from "../../modules/startup/forms/startup-form";
import Startup from "../../modules/startup/startup";
import ProfileForm from "../../modules/user/profile/profile";
import QSFeedbackListInternal from "../../modules/question/feedback-list-internal";
import QSFeedbackListInternalFiliale from "../../modules/question/feedback-list-internal-filiale";

export const ExternalRoutes = [
    {
        "name": "Enquêtes",
        "path": "/external/feedback",
        "component": QSFeedbackList,
        "icon": "fas fa-pencil-alt",
        "pTypes": ["USER_TYPE_STARTUP", "USER_TYPE_AFFILIATE"],
        "menu": "FEEDBACK",
    },
    {
        "name": "Startups",
        "path": "/external/startup",
        "menu": "STARTUP",
        "component": Startup,
        "icon": "fas fa-industry",
        "pTypes": ["USER_TYPE_AFFILIATE"],
    },
    {
        "name": "Fiche Startup",
        "path": "/external/feedback-internal",
        "component": QSFeedbackListInternalFiliale,
        "icon": "fas fa-folder-open",
        "menu": "FEEDBACK_ADMIN",
        "pTypes": ["USER_TYPE_AFFILIATE"],
        "pRoles": ["PFBG"]
    }
]

export const ExternalHeaderRoutes = [

]

export const MoreExternalRoutes = [
    {
        path: "/external/profile",
        name: "Profile",
        component: ProfileForm,
        "pTypes": ["USER_TYPE_STARTUP", "USER_TYPE_AFFILIATE"],
    },
    {
        path: "/external/notification",
        name: "Notifications",
        component: NTNotification,
        "menu": "NOTIFICATION",
        "pTypes": ["USER_TYPE_STARTUP", "USER_TYPE_AFFILIATE"],
    },
    {
        path: "/external/feedback/:id",
        name: "Feedback",
        component: FeedbackFill,
        "menu": "FEEDBACK",
        "pTypes": ["USER_TYPE_STARTUP", "USER_TYPE_AFFILIATE"],
    },
    {
        path: "/external/startup-show/:id",
        component: StartupForm,
        "menu": "STARTUP",
        "pTypes": ["USER_TYPE_AFFILIATE"],
    },
]
