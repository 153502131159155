import React , {useState,useEffect} from "react";
import Footer from "../../layouts/Layout/Footer";
import "./home.css"
import NavBar from "./NavBar";
import SlideHome from "./SlideHome";
import Counter from "./Counter";
import Presentation from "./Presentation";
import Services from "./Services";
import Contact from "./Contact";

const HomePage = (props) => {
    const [scrollTop, setScrollTop] = useState(0);

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            console.log(window.scrollY)
            console.log(window.offsetHeight)
            setScroll(window.scrollY > 80);
        };

        window.addEventListener("scroll", handleScroll,true);

        return () => {
            window.removeEventListener("scroll", handleScroll,true);
        };
    }, []);


    const scrollToTop = () => {
        console.log('scrollToTop')
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    return <div   >
        <NavBar scroll={scroll} scrollToTop={scrollToTop} />
        <SlideHome />
        <div style={{position:'relative'}}>
            <Counter />

        </div>
        <div id="presentation"></div>
        <Presentation />
        <div id="services"></div>
        <Services />
        <div id="contact"></div>
       <Contact />
        <Footer />
    </div>
        ;
};

export default HomePage;
