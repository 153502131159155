import AreaInput from "../../../../core/common/inputs/AreaInput";
import TextInput from "../../../../core/common/inputs/TextInput";
import { getValueManager } from "../../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "antd";
import SharedContext from "../../../../contexts/shared/sharedContext";
import { replaceIndex } from "../../../../core/manager/ManagerUtils";
import Logger from "../../../../core/utils/Logger";
import CodeInput from "../../../../core/common/inputs/CodeInput";

const TableTechniqueForm = (props) => {
  const { GetResource, getKeyword } = useContext(SharedContext);
  const form = props.form;
  const id = getValueManager(form, "id", null);

  const notify = (type) => {
    Logger(replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")]));
  };

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    props.updateForm({ ...f });
  };

  const addNewTable = (event) => {
    GetResource("TABLE_TECHNIQUE", "ADD_TABLE", form, null)
      .then((res) => {
        props.onAdd();
        props.toggleModal(props.modalName);
      })
      .catch((err) => {
        if (err && err.status == 409) {
          notify("danger");
        }
      });
  };

  const updateTable = (event) => {
    GetResource("TABLE_TECHNIQUE", "UPDATE_TABLE", form, null).then((res) => {
      props.onUpdate(props.id);
      props.toggleModal(props.modalName);
    });
  };

  const formValid =
    getValueManager(form, "code", null) &&
    getValueManager(form, "designation", null);

  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        style={{ maxWidth: "1000px", width: "100%" }}
        open={props.showModal}
        width={"1000px"}
        onCancel={() => props.toggleModal(props.modalName)}
      >
        <div className="modal-body p-0">
          <div className="bg-secondary  border-0 mb-0">
            <div className="bg-menu">
              <div className=" text-center ">
                <p className="font-weight-bold mb-0 text-white">{getKeyword("TABLE.TECHNICAL_TABLE")}</p>
              </div>
            </div>
            <div className="">
              <form role="form">
                <CodeInput
                  disabled={id ? true : false}
                  label={getKeyword("COMMON.CODE")}
                  name="code"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "code", null)}
                  required={true}
                ></CodeInput>
                <TextInput
                  label={getKeyword("COMMON.DESIGNATION")}
                  name="designation"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "designation", null)}
                  required={true}
                ></TextInput>
                <AreaInput
                  label={getKeyword("COMMON.DESCRIPTION")}
                  name="description"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "description", null)}
                ></AreaInput>
                <div className="text-center d-flex justify-content-between">
                  <button
                    className="my-4"
                    color="white"
                    type="button"
                    onClick={(e) => props.toggleModal(props.modalName)}
                  >
                    {getKeyword("STD_VERB.CANCEL")}
                  </button>
                  <button
                    className="btn-1"
                    color="danger"
                    type="button"
                    disabled={!formValid}
                    onClick={(e) => (id ? updateTable() : addNewTable())}
                  >
                    {id ? getKeyword("STD_VERB.EDIT") : getKeyword("STD_VERB.ADD")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default TableTechniqueForm;
