import React from 'react';
import ReactDOM from 'react-dom';
import './core/utils/Interceptor';
import App from './App';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(

    <React.StrictMode>
  
<App/>

</React.StrictMode>
, document.getElementById('root'));