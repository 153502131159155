import { getArray, getValueManager, getValueManagerNumberFloat, isEmpty } from "../manager/ManagerUtils";

export const validateForm = (form, validation) => {
    if (!validation || !form) {
        return true;
    }
    let expr = "";
    getArray(validation).forEach((v1, index) => {
        expr += "( ";
        getArray(v1).forEach((v2, index2) => {
            if (v2.valueToCompare == "value") {
                // if (isEmpty(getValueManager(form, v2.question)) || (v2.compareType != "F-X" && isEmpty(getValueManager(form, v2.valueToCompare)))) {
                //     return true;
                // }
                expr += " '" + getValueManager(form, v2.question) + "' " + v2.operator + " '" +
                    (v2.compareType == "F-X" ? getValueManager(v2, "value") : getValueManager(form, v2.valueToCompare)) + "'";
            }
            if (getArray(v1).length != index2 + 1) {
                expr += " && ";
            }
        });
        expr += " )";
        if (getArray(validation).length != index + 1) {
            expr += " || ";
        }
    });
    try {
        return !!eval(expr);
    } catch (err) {
        console.error(expr);
    }
    return true;
}

export const getFilterEval = (form, filter, item) => {
    var expr = filter;
    expr = expr.replaceAll("{{}}", '"' + item.value + '"');
    expr.match(/{{[^{]*}}/gmi).forEach(reg => {
        const val = getValueManager(form, reg.replaceAll("{{", "").replaceAll("}}", ""));
        expr = expr.replaceAll(reg, '"' + (val ? val : "") + '"');
    });
    try {
        return !!eval(expr);
    } catch (err) {
        console.error(expr);
    }
    return true;
}