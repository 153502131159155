import React, { useContext } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { replaceIndex } from "../../manager/ManagerUtils";
import TooltipProvider from "../outputs/TooltipProvider";

import "./Inputs.css";

export const TextInput = (props) => {
  const sharedContext = useContext(SharedContext);
  const { getKeyword } = sharedContext;

  const onChange = (event) => {
    if (props.onChange) {
      let val = event.target.value;
      if (props.length && props.length) {
        val = val.slice(0, props.length);
      }
      props.onChange(val, props.name);
    }
  };

  const iconClicked = () => {
    if(props.iconClicked){
      props.iconClicked();
    }
  }

  return (
    <div className="TextInput InputField">
      {!props.label ? null : (
        <label className="Ilabel">
          {replaceIndex(
            getKeyword(props.label),
            props.lableIndexesNames,
            props.lableIndexes
          )}&nbsp;
          {props.optText ? (
            <span>
              {props.optText !== true ? props.optText : "(Optionnel)"}
            </span>
          ) : (
            ""
          )}
          <span className="requiredLb">{props.required ? " *" : ""}</span>
          <TooltipProvider tooltip={props.description}></TooltipProvider>
        </label>
      )}
      <div className="input">
        {props.preview ?
          <p className="input-response">{props.value}</p>
          :
          <><input
            className="inp"
            type="text"
            value={props.value}
            name={"TextInput-" + props.name}
            onChange={onChange}
            placeholder={
              props.placeHolder
                ? replaceIndex(
                  getKeyword(props.placeHolder),
                  props.lableIndexesNames,
                  props.lableIndexes
                )
                : replaceIndex(
                  getKeyword(props.label),
                  props.lableIndexesNames,
                  props.lableIndexes
                )
            }
            disabled={props.disabled}
          ></input>
            <span className="icon" onClick={iconClicked}>
              {props.iconText ? (
                <i className={props.iconText}></i>
              ) : (
                props.iconString
              )}
            </span>
          </>}
      </div>
      {props.error ? (
        <p className="errorInput">
          <i className="fas fa-exclamation-triangle"></i> {props.error}
        </p>
      ) : null}
    </div>
  );
};

export default TextInput;
