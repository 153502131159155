import TextInput from "../../../core/common/inputs/TextInput";
import { getValueManager } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { updateObjectFromImage } from "../../../core/manager/ManagerUtils";
import FileInput from "../../../core/common/inputs/FileInput";
import queryString from "query-string";
import LINKS from "../../../config";
import PasswordInput from "../../../core/common/inputs/PasswordInput";
import { SETTING_SUCCESS } from "../../../contexts/shared/types";
import { validatePassword } from "../../../core/utils/helpers";
import { dateDateToString } from "../../../core/utils/helpers";
import DateInput from "../../../core/common/inputs/DateInput";
import { getValueManagerArray } from "../../../core/manager/ManagerUtils";
import { validateEmail } from "../../../core/utils/helpers";
import SharedContext from "../../../contexts/shared/sharedContext";
import Logger from "../../../core/utils/Logger";
import EmailSecondaryForm from "../utilisateur/form/EmailSecondaryForm";

const ProfileForm = (props) => {
  const { GetResource, ImageSaver, getCurrentProfil, getKeyword, settingData } =
    useContext(SharedContext);
  const history = useHistory();
  const { id } = useParams();
  const [searchParams] = useState(
    queryString.parse(props.location.search, { ignoreQueryPrefix: true })
  );
  const [operation, setOperation] = useState("");
  const [idAdd, setIdAdd] = useState(null);
  const [form, setForm] = useState({
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    image: null,
  });

  const [formPassword, setFormPassword] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordVerif: "",
  });

  const [showForm, setFormShow] = useState({});

  const notificationAlertRef = React.useRef(null);
  const [images, setImages] = useState({});
  const [isLoading, setIsLoading] = useState({
    loading: false,
    size: 0,
    count: 0,
  });

  useEffect(() => {
    getProfile(id);
    GetResource(
      "TABLE_TECHNIQUE",
      "GET_TABLE_BY_CODE",
      { value: "PWD" },
      SETTING_SUCCESS
    ).then((res) => { });
  }, []);

  const getProfile = (idParam) => {
    getCurrentProfil().then((res) => {
      setForm(res);
      setFormShow(JSON.parse(JSON.stringify(res)));
    });
  };

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    setForm({ ...f });
  };

  const onChangePassword = (v, n, indexes = null) => {
    const f = updateValueManager(formPassword, n, v, indexes);
    setFormPassword({ ...f });
  };

  const onChangeImages = (
    files,
    name,
    multiple,
    indexes,
    usedFor = "PROFILE"
  ) => {
    images[name] = { files, name, multiple, indexes, usedFor };
    setImages({ ...images });
  };

  const updateProfile = (event) => {
    uploadImages(() => {
      GetResource("USER_MANAGEMENT", "UPDATE_CURRENT", form, null).then(
        (res) => {
          setFormShow(JSON.parse(JSON.stringify(form)));
          setOperation("SHOW");
        }
      );
    });
  };

  const notify = (type, message) => {
    Logger(message);
  };

  const updatePassword = (event) => {
    uploadImages(() => {
      GetResource("USER_MANAGEMENT", "UPDATE_PASSWORD", formPassword, null)
        .then((res) => {
          setFormPassword({
            oldPassword: "",
            newPassword: "",
            newPasswordVerif: "",
          });
          setOperation("SHOW");
        })
        .catch((err) => {
          if ((err.status == 403 && err.data.message == "PASSWORD_ALREADY_USED") || (err.data && err.data.detail == "PASSWORD_ALREADY_USED")) {
            notify("danger", getKeyword("LOGIN.PASSWORD_USED"));
          } else {
            notify("danger", getKeyword("STD_ERROR.PASSWORD_INCORRECT"));
          }
        });
    });
  };

  const uploadImages = (callback) => {
    ImageSaver(
      (params, images) => {
        updateObjectFromImage(form, images);
        callback();
      },
      (params, status) => setIsLoading(status),
      {},
      images,
      form
    );
  };

  let resV = false;
  let html = null;
  if (getValueManager(formPassword, "newPassword", null)) {

    resV = validatePassword(settingData["PWD"], getValueManager(formPassword, "newPassword", null));
    if (resV && resV.error) {
      html = <ul>
        {resV.items.map(item => (
          <li style={{ color: item.valid ? "green" : "red" }}>{item.msg}</li>
        ))}
      </ul>
    }
  }

  const formValid =
    getValueManager(form, "firstName", null) &&
    getValueManager(form, "lastName", null);

  const formPasswordValid =
    getValueManager(formPassword, "oldPassword", null) &&
    getValueManager(formPassword, "newPassword", null) &&
    getValueManager(formPassword, "newPassword", null) ==
    getValueManager(formPassword, "newPasswordVerif", null) && !html;

  const finalId = id || idAdd;

  return (
    <div className="card-block">
      {(searchParams.show && !operation) ||
        (operation && operation == "SHOW") ? (
        <div className="mt--6"  >
          <div className="row">
            <div className="col">
              <div className="bg-secondary shadow border-0 w-75 m-auto">
                <div className="px-lg-5 py-lg-5">
                  <div className="row mgb-30">
                    {getValueManager(showForm, "image", null) ? (
                      <>
                        <div className="col-12 profile-image">
                          <img
                            src={
                              LINKS.imageURLPrivate +
                              getValueManager(showForm, "image", null)
                            }
                          ></img>
                        </div>
                        <br />
                        <br />
                        <br />
                      </>
                    ) : null}
                    {/* <div className="col-6">
                      <div className="row">
                        <strong className="col-6 h2 text-dark"> Identifiant :&nbsp;</strong>
                        <span className="col-6 text-dark">{getValueManager(showForm, "username", null)}</span>
                      </div>
                    </div> */}

                    <div className="col-6">
                      <div className="row">
                        <strong className="col-6 h2 text-dark">Adresse e-mail principale :&nbsp;</strong>
                        <span className="col-6 text-dark">
                          {getValueManager(showForm, "email", null)}
                        </span>
                      </div>
                    </div>


                    {getValueManager(
                      showForm,
                      "userType",
                      null
                    ) == "USER_TYPE_AFFILIATE" ?
                      <div className="col-6">
                        <div className="row">
                          <strong className="col-6 text-dark">Filiale :&nbsp;</strong>
                          <span className="col-6 text-dark"> {getValueManager(showForm, "affiliate.name", null)}</span>

                        </div></div> : null}
                    {getValueManager(
                      showForm,
                      "userType",
                      null
                    ) == "USER_TYPE_STARTUP" ?
                      <div className="col-6">
                        <div className="row">
                          <strong className="col-6 text-dark">Startup :&nbsp;</strong>
                          <span className="col-6 text-dark"> {getValueManager(showForm, "startup.name", null)}</span>

                        </div></div> : null}
                    <div className="col-6">
                      <div className="row">
                        <strong className="col-6 h2 text-dark"> Nom et Prénom :&nbsp;
                        </strong>
                        <span className="col-6 text-dark"> {getValueManager(showForm, "lastName", null)}&nbsp; {getValueManager(showForm, "firstName", null)}</span>

                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <strong className="col-6 h2 text-dark">  Date de naissance :&nbsp;</strong>
                        <span className="col-6 text-dark">
                          {getValueManager(showForm, "dateOfBirth") ? dateDateToString(
                            new Date(getValueManager(showForm, "dateOfBirth"))
                          ) : ""}
                        </span>
                      </div>
                    </div>
                    <div className="col-6">

                      <div className="row">
                        <strong className="col-6 h2 text-dark">Téléphone principal :&nbsp; </strong>
                        <span className="col-6 text-dark">
                          {getValueManager(showForm, "phone", null)}
                        </span>
                      </div>
                    </div>
                    <div className="col-6">

                      <div className="row">
                        <strong className="col-6 h2 text-dark">Téléphone secondaire :&nbsp; </strong>
                        <span className="col-6 text-dark">
                          {getValueManager(showForm, "secondaryPhoneNumber", null)}
                        </span>
                      </div>
                    </div>
                    <div className="col-6">

                      <div className="row">
                        <strong className="col-6 h2 text-dark">Adresse :&nbsp; </strong>
                        <span className="col-6 text-dark">
                          {getValueManager(showForm, "address", null)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between pt-5">
                    <button
                      className="btn-2"
                      color="info"
                      type="button"
                      onClick={(e) => setOperation("updatePassword")}
                    >
                      {getKeyword("PROFILE.EDIT_PASSOWRD")}
                    </button>
                    <button
                      className="btn-1"
                      color="danger"
                      type="button"
                      onClick={(e) => setOperation("update")}
                    >
                      {getKeyword("PROFILE.EDIT_PROFILE")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {operation == "updatePassword" ? (
            <div className="mt--6"  >
              <div className="row">
                <div className="col">
                  <div className="bg-secondary shadow border-0 w-75 m-auto">
                    <div className="px-lg-5 py-lg-5">
                      <form role="form">
                        <div className="row">
                          <div className="col-12">
                            <PasswordInput
                              label={getKeyword("PROFILE.OLD_PASSOWRD")}
                              name="oldPassword"
                              onChange={(v, n) => onChangePassword(v, n)}
                              value={getValueManager(
                                formPassword,
                                "oldPassword",
                                null
                              )}
                            ></PasswordInput>
                          </div>
                          <div className="col-12">
                            <PasswordInput
                              label={getKeyword("PROFILE.NEW_PASSWORD")}
                              name="newPassword"
                              onChange={(v, n) => onChangePassword(v, n)}
                              value={getValueManager(
                                formPassword,
                                "newPassword",
                                null
                              )}
                            ></PasswordInput>
                            {html ? (
                              <div className="invalid-feedback" style={{ display: "block" }}>
                                {html}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-12">
                            <PasswordInput
                              label={getKeyword("PROFILE.COMFIRM_PASSWORD")}
                              name="newPasswordVerif"
                              onChange={(v, n) => onChangePassword(v, n)}
                              value={getValueManager(
                                formPassword,
                                "newPasswordVerif",
                                null
                              )}
                              error={
                                getValueManager(
                                  formPassword,
                                  "newPasswordVerif",
                                  null
                                ) &&
                                  getValueManager(
                                    formPassword,
                                    "newPasswordVerif",
                                    null
                                  ) !=
                                  getValueManager(
                                    formPassword,
                                    "newPassword",
                                    null
                                  )
                                  ? getKeyword("STD_ERROR.PASSWORD_MISMATCH")
                                  : null
                              }
                            ></PasswordInput>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between pt-4">
                          <button
                            className=" "
                            color="white"
                            type="button"
                            onClick={(e) => setOperation("SHOW")}
                          >
                            {getKeyword("STD_VERB.CANCEL")}
                          </button>
                          <button
                            className="btn-1"
                            color="danger"
                            type="button"
                            disabled={!formPasswordValid}
                            onClick={(e) => updatePassword()}
                          >
                            {getKeyword("STD_VERB.EDIT")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          ) : (
            <div className="mt--6"  >
              <div className="row">
                <div className="col">
                  <div className="bg-secondary shadow border-0">
                    <div className="px-lg-5 py-lg-5">
                      <form role="form">
                        <div className="row">
                          {/* <div className="col-6">
                            <TextInput
                              disabled={true}
                              label="Identifiant"
                              name="username"
                              onChange={(v, n) => onChange(v, n)}
                              value={getValueManager(form, "username", null)}
                              required={true}
                            ></TextInput>
                          </div> */}
                          <div className="col-6">
                            <TextInput
                              disabled={true}
                              label="Adresse Email principal"
                              name="email"
                              onChange={(v, n) => onChange(v, n)}
                              value={getValueManager(form, "email", null)}
                              required={true}
                              error={getValueManager(form, "email", null) && !validateEmail(getValueManager(form, "email", null)) ? getKeyword("STD_ERROR.EMAIL") : null}
                            ></TextInput>
                          </div>
                          <div className="col-6">
                            <TextInput
                              label={getKeyword("USER_DATA.LASTNAME")}
                              name="lastName"
                              onChange={(v, n) => onChange(v, n)}
                              value={getValueManager(form, "lastName", null)}
                              required={true}
                            ></TextInput>
                          </div>
                          <div className="col-6">
                            <TextInput
                              label={getKeyword("USER_DATA.FIRSTNAME")}
                              name="firstName"
                              onChange={(v, n) => onChange(v, n)}
                              value={getValueManager(form, "firstName", null)}
                              required={true}
                            ></TextInput>
                          </div>

                          <div className="col-6">
                            <TextInput
                              label="Téléphone principal"
                              name="phone"
                              onChange={(v, n) => onChange(v, n)}
                              value={getValueManager(form, "phone", null)}
                            ></TextInput>
                          </div>
                          <div className="col-6">
                            <TextInput
                              label="Téléphone secondaire"
                              name="secondaryPhoneNumber"
                              onChange={(v, n) => onChange(v, n)}
                              value={getValueManager(form, "secondaryPhoneNumber", null)}
                            ></TextInput>
                          </div>
                          <div className="col-6">
                            <DateInput
                              label="Date de naissance"
                              name="dateOfBirth"
                              onChange={(v, n) => onChange(v, n)}
                              value={getValueManager(
                                form,
                                "dateOfBirth",
                                null
                              )}
                            ></DateInput>
                          </div>

                          <div className="col-6">
                            <TextInput
                              label="Adresse"
                              name="address"
                              onChange={(v, n) => onChange(v, n)}
                              value={getValueManager(form, "address", null)}
                            ></TextInput>
                          </div>
                          <div className="col-12">
                            <FileInput
                              label={getKeyword("USER_DATA.IMAGE")}
                              name="image"
                              onChange={(files) =>
                                onChangeImages(files, "image", false)
                              }
                              onChangeOld={(v, n) => onChange(v, n)}
                              value={getValueManager(form, "image", null)}
                              isImage={true}
                            ></FileInput>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between pt-5">
                          <button
                            className=""
                            color="white"
                            type="button"
                            onClick={(e) => setOperation("SHOW")}
                          >
                            {getKeyword("STD_VERB.CANCEL")}
                          </button>
                          <button
                            className="btn-1"
                            color="danger"
                            type="button"
                            disabled={!formValid}
                            onClick={(e) => updateProfile()}
                          >
                            {getKeyword("STD_VERB.EDIT")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default ProfileForm;
