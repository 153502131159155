import React, { useContext, useEffect, useState } from "react";
import { useDrag } from 'react-dnd'


const QSQuestionCreatorCardBlock = (props) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "BLOCK",
        item: { type: "BLOCK", index: 1 },
        collect: monitor => {
            return ({
                isDragging: monitor.isDragging(),
                type: "BLOCK"
            })
        },
        end: (draggedOitem) => {
            props.deleteHover();
        }
    }))

    return (
        <div
            ref={drag}
            className="qc-card-drag"
            style={{
                opacity: isDragging ? 0.5 : 1,
                fontSize: 16,
                fontWeight: 'bold',
                cursor: 'move',
            }}
        >
            <i className="fas fa-grip-vertical"></i> Section
        </div>
    )
}

export default QSQuestionCreatorCardBlock;