import { getArray, getValueManagerArray } from "../manager/ManagerUtils";


export const userIsInternal = (user) => {
    if (!user) {
        return false;
    }
    if ((user.userType == "USER_TYPE_SUPER_ADMIN" || user.userType == "USER_TYPE_INTERNAL")) {
        return true;
    }
    return false;
}

export const userIsAdmin = (user) => {
    if (!user) {
        return false;
    }
    if (user.userType == "USER_TYPE_SUPER_ADMIN") {
        return true;
    }
    return false;
}

export const userIsAffiliate = (user) => {
    if (!user) {
        return false;
    }
    if (user.userType && user.userType == "USER_TYPE_AFFILIATE") {
        return true;
    }
    return false;
}

export const userIsStartup = (user) => {
    if (!user) {
        return false;
    }
    if (user.userType && user.userType == "USER_TYPE_STARTUP") {
        return true;
    }
    return false;
}

export const userIsExternal = (user) => {
    if (!user) {
        return false;
    }
    if (user.userType && user.userType == "USER_TYPE_EXTERNAL") {
        return true;
    }
    return false;
}

export const userIsOfTypes = (user, types, def = true) => {
    if (!types || types.length == 0) {
        return def;
    }
    return types.find(type => userIsOfType(user, type));
}

export const userIsOfType = (user, type) => {
    if (!user) {
        return false;
    }

    return user.userType == type || (type == "USER_TYPE_SUPER_ADMIN" && user.authority == "USER_TYPE_SUPER_ADMIN")
        || (type == "ADMIN" && user.authority == "ADMIN");
}

export const userHasPermission = (user, permission) => {
    console.log("USEr", user, permission);
    if (!user) {
        return false;
    }
    return getValueManagerArray(user, "permissions").find(perm => perm == permission);
}

export const userHasOneOfPermissions = (user, permissions, def = true) => {
    if (!permissions || permissions.length == 0) {
        return def;
    }
    return permissions.find(permission => userHasPermission(user, permission));
}

export const hasAccessPermission = (user, forcedTypes, types, roles) => {
    if (!user) {
        return false;
    }
    if ((getArray(forcedTypes).length > 0 || getArray(types).length > 0 || getArray(roles).length > 0)) {
        if (userIsOfTypes(user, getArray(forcedTypes), false) ||
            (
                (getArray(types).length > 0 || getArray(roles).length > 0) &&
                userIsOfTypes(user, getArray(types), true) &&
                userHasOneOfPermissions(user, roles, true))) {
            return true
        } else {
            return false;
        }
    }
    return true;
}