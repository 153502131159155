import React, {useEffect, useState ,useRef} from "react";
import './styles/counter.css';
import {ASSETS_BASE} from "../../config";

const Counter = (props) => {

    const [wannaAnimate, setWannaAnimate] = useState(true);

    useEffect(() => {
        let nums = document.querySelectorAll('.counter');
        console.log(nums)
        nums.forEach((num) => {
            animate(num);
        });
        setWannaAnimate(false);
        // const handleScroll = () => {
        //     let nums = document.querySelectorAll('.counter');
        //     console.log(nums)
        //     nums.forEach((num) => {
        //         animate(num);
        //     });
        //     setWannaAnimate(false);
        // };

        // window.addEventListener('scroll', handleScroll);
        //
        // return () => {
        //     window.removeEventListener('scroll', handleScroll);
        // };
    }, []);

    const animate = (el) => {
        if (!wannaAnimate) {
            return;
        }

        let goal = el.getAttribute('data-goal');
        let count = setInterval(() => {
            el.textContent++;
            if (el.textContent === goal) {
                clearInterval(count);
            }
        }, 2700 / goal);
    };

    return <div className='counterBloc'>
        <div className="row charts mx-auto">
            <div className="col-md-3">
                <span className="counter" data-goal="300">0</span>
                <p>Contrats Signes</p>
            </div>
            <div className="col-md-3">
                <span className="counter" data-goal="500">0</span>
                <p>Mises en Relations Entre Start-ups et Participations</p>
            </div>

            <div className="col-md-3">
                <span className="counter" data-goal="12">0</span>
                <p>Participations Impliquées</p>
            </div>
            <div className="col-md-3">
                <span className="counter" data-goal="70">0</span>
                <p>Start-ups Accompagnées</p>
            </div>
        </div>
    </div>
        ;
};

export default Counter;
