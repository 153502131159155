import { getValueManager, getValueManagerNumber, getValueManagerNumberFloat } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { useDrag } from 'react-dnd'


const QSQuestionCreatorCardBlockEdit = (props) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "BLOCK",
        item: { ...props.item, type: "BLOCK", index: props.index, isOld: true },
        collect: monitor => {
            return ({
                isDragging: monitor.isDragging(),
                type: "BLOCK"
            })
        },
        end: (draggedOitem) => {
            props.deleteHover();
        }
    }))

    return (
        <div
            ref={drag}
            className="qc-card-drag-edit"
            style={{
                opacity: isDragging ? 0.5 : 1,
                fontSize: 16,
                fontWeight: 'bold',
                cursor: 'move',
            }}
        >
            <div className="title">
                <div><i className="fas fa-grip-vertical"></i> {props.item.title}</div>
                <div><i onClick={() => props.delete({ index: props.index })} className="fas fa-trash-alt qc-trash pointer"></i> <i onClick={() => props.changeSettings({ index: props.index })} className="fas fa-cog pointer"></i></div>
            </div>
        </div>
    )
}

export default QSQuestionCreatorCardBlockEdit;