
import { getValueManager } from "../../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../../contexts/shared/sharedContext";
import PasswordInput from "../../../../core/common/inputs/PasswordInput";
import { validatePassword } from "../../../../core/utils/helpers";
import { SETTING_SUCCESS } from "../../../../contexts/shared/types";
import Logger from "../../../../core/utils/Logger";
import { Modal } from "antd";

const PasswordFormM = (props) => {
  const { GetResource, getKeyword, settingData } = useContext(SharedContext);
  const [form, setForm] = useState({});
  const id = getValueManager(form, "id", null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    GetResource(
      "TABLE_TECHNIQUE",
      "GET_TABLE_BY_CODE",
      { value: "PWD" },
      SETTING_SUCCESS
    ).then((res) => { });
  }, []);

  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    Logger(message);
  };

  useEffect(() => { }, []);

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    setForm({ ...f });
  };

  const updatePassword = (event) => {
    GetResource("USER_MANAGEMENT", "UPDATE_USER_PASSWORD", { id: props.id, ...form }, null).then((res) => {
      if (props.toggleModalSuccess) {
        props.toggleModalSuccess(props.modalName);
      } else {
        props.toggleModal(props.modalName);
      }
    }).catch(err => {
      console.log("Errpr azebl", err)
      if ((err.status == 403 && err.data.message == "PASSWORD_ALREADY_USED") || (err.data && err.data.detail == "PASSWORD_ALREADY_USED")) {
        notify("danger", getKeyword("LOGIN.PASSWORD_USED"));
      }
    });
  };

  let resV = false;
  let html = null;
  if (getValueManager(form, "newPassword", null)) {

    resV = validatePassword(settingData["PWD"], getValueManager(form, "newPassword", null));
    if (resV && resV.error) {
      html = <ul>
        {resV.items.map(item => (
          <li style={{ color: item.valid ? "green" : "red" }}>{item.msg}</li>
        ))}
      </ul>
    }
  }

  const formValid =
    getValueManager(form, "newPassword", null) &&
    getValueManager(form, "newPassword", null) ==
    getValueManager(form, "newPasswordVerif", null) && !html;

  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        style={{ maxWidth: "1000px", width: "100%" }}
        open={props.showModal && !isLoading}
        width={"1000px"}
        onCancel={() => props.toggleModal(props.modalName)}
      >
        <div className="modal-body p-0">
          <div className="bg-secondary  border-0 mb-0">
            <div className="bg-menu">
              <div className="text-center ">
                <p className="font-weight-bold text-white mb-0">{getKeyword("USER.PROFILE")}</p>
              </div>
            </div>
            <div className="px-lg-5 py-lg-5">
              <form role="form">
                <div className="row">
                  <div className="col-12">
                    <PasswordInput
                      label={getKeyword("PROFILE.NEW_PASSWORD")}
                      name="newPassword"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(
                        form,
                        "newPassword",
                        null
                      )}
                    ></PasswordInput>
                    {html ? (
                      <div className="invalid-feedback" style={{ display: "block" }}>
                        {html}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-12">
                    <PasswordInput
                      label={getKeyword("PROFILE.COMFIRM_PASSWORD")}
                      name="newPasswordVerif"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(
                        form,
                        "newPasswordVerif",
                        null
                      )}
                      error={
                        getValueManager(
                          form,
                          "newPasswordVerif",
                          null
                        ) &&
                          getValueManager(
                            form,
                            "newPasswordVerif",
                            null
                          ) !=
                          getValueManager(
                            form,
                            "newPassword",
                            null
                          )
                          ? getKeyword("STD_ERROR.PASSWORD_MISMATCH")
                          : null
                      }
                    ></PasswordInput>
                  </div>
                </div>

                <div className="text-center d-flex justify-content-between">
                  <button
                    className="my-4"
                    color="white"
                    type="button"
                    onClick={(e) => props.toggleModal(props.modalName)}
                  >
                    {getKeyword("STD_VERB.CANCEL")}
                  </button>
                  <button
                    className="btn-1"
                    type="button"
                    disabled={!formValid}
                    onClick={(e) => (updatePassword())}
                  >
                    {getKeyword("STD_VERB.EDIT")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default PasswordFormM;
