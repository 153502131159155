import { getArray, getValueManager, getValueManagerArray } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import TechnicalTable from "../../../variables/technical-table";
import DateInput from "../../../core/common/inputs/DateInput";
import SelectInput from "../../../core/common/inputs/SelectInput";
import { dateToString, dateToStringJs } from "../../../core/utils/helpers";

export const CLCatalogFormTableType = (props) => {
  const { getKeyword } = useContext(SharedContext);
  const form = props.form;
  const [days, setDays] = useState({});

  useEffect(() => {
    const res = {};
    for (let month = 1; month <= 12; month++) {
      const DAYS = [];
      const lastDayOfMonth = new Date(new Date().getFullYear(), month, 0).getDate();
      for (let index = 1; index <= lastDayOfMonth; index++) {
        DAYS.push({ id: index + "" })
      }
      res[month] = DAYS;
    }
    setDays(res);
  }, [])

  const addLine = () => {
    let arr = getValueManagerArray(props.form, "forms", null);
    arr.push({
      name: "",
      type: "",
      response: null
    });
    props.onChange(arr, "forms");
  };

  const deleteFrom = (name, index) => {
    let arr = getValueManagerArray(props.form, name);
    if (!arr) {
      arr = [];
    }
    props.onChange(
      arr.filter((vv, i) => i != index),
      name
    );
  };

  const changeMonth = (v, n, indexes) => {
    const date = (new Date()).getFullYear() + "-" + v + "-" + 1;
    props.onChange(dateToStringJs(date), n, indexes);
    // setDays(getDays(n, indexes));
  }


  const changeDay = (v, n, indexes) => {
    const date = (new Date()).getFullYear() + "-" + (getMonth(n, indexes) || 1) + "-" + v;
    props.onChange(dateToStringJs(date), n, indexes);
  }

  const getMonth = (name, index) => {
    const date = getValueManager(form, name, [index]);
    if (!date) {
      return null;
    }
    return (new Date(date)).getMonth() + 1;
  }

  const getDay = (name, index) => {
    const date = getValueManager(form, name, [index]);
    if (!date) {
      return null;
    }
    return (new Date(date)).getDate();
  }

  const getDays = (name, index) => {
    const DAYS = [];
    const month = getMonth(name, index);
    if (month) {
      const lastDayOfMonth = new Date(new Date().getFullYear(), month, 0).getDate();
      for (let index = 1; index <= lastDayOfMonth; index++) {
        DAYS.push({ id: index + "" })
      }
    }
    return DAYS;
  }

  return (
    <div className="fair--create-area_inputs question-table-css">
      <label className="fair--create-area_labels question-table-css-head">
        Liste correspondance
      </label>
      {getValueManagerArray(props.form, "forms").map((et, index) => (
        <div className="question-table-block">
          <div className="row">
            <i
              className="fas fa-times question-table-block-close"
              onClick={() => deleteFrom("forms", index)}
            ></i>
            <div className="col-6">
              <SelectInput
                label="Mois de collecte"
                name="forms.?0.date"
                onChange={(v, n) => changeMonth(v, n, [index])}
                value={getMonth("forms.?0.date", [index])}
                required={true}
                data={{
                  items: [
                    {
                      id: "1"
                    },
                    {
                      id: "2"
                    },
                    {
                      id: "3"
                    },
                    {
                      id: "4"
                    },
                    {
                      id: "5"
                    },
                    {
                      id: "6"
                    },
                    {
                      id: "7"
                    },
                    {
                      id: "8"
                    },
                    {
                      id: "9"
                    },
                    {
                      id: "10"
                    },
                    {
                      id: "11"
                    },
                    {
                      id: "12"
                    }
                  ],
                  label: "id",
                  select: "id"
                }}
              ></SelectInput>
            </div>
            {getMonth("forms.?0.date", [index]) ?
              <div className="col-6">
                <SelectInput
                  label="Jour de collecte"
                  name="forms.?0.date"
                  onChange={(v, n) => changeDay(v, n, [index])}
                  value={getDay("forms.?0.date", [index])}
                  required={true}
                  data={{
                    items: getArray(days[getMonth("forms.?0.date", [index])]),
                    label: "id",
                    select: "id"
                  }}
                ></SelectInput>
              </div> : null}
          </div>
        </div>
      ))}

      <button className="add-group" type="button" onClick={addLine}>
        {"Ajouter"}
      </button>
    </div>
  );
};

export default CLCatalogFormTableType;
