
import React, {useContext, useEffect} from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { getValueManager } from "../../manager/ManagerUtils";

import "./Inputs.css";
import SelectInputResource from "./SelectInputResource";
import TextInput from "./TextInput";

export const SelectMultipleFull = (props) => {
  const sharedContext = useContext(SharedContext);
  const { getKeyword, keywords } = sharedContext;

  let data = props.dependencies
    ? props.dependencies[props.data.linkId]
      ? props.dependencies[props.data.linkId]
      : []
    : props.data.items;


  useEffect(() => {
    console.log("SelectMultipleFull props.data", props.data);
  }, [props.data]);

  const onChange = (value) => {
    if (props.onChange) {
      props.onChange(value, props.name);
    }
  };

  const addTo = (value) => {
    let res = [];
    if (props.value) {
      res = props.value;
    }
    res.push(value);
    console.log("HEHE", res);
    onChange(res);
  };

  const removeFrom = (value) => {
    let res = [];
    if (props.value) {
      res = props.value;
    }
    onChange(res.filter(f => f !== value));
  };

  return (
    <div className="SelectMultipleFull InputField">
      <div className="justify-content-between align-items-center">
        <label className="Ilabel">{getKeyword(props.label)} <span className="requiredLb">{props.required ? " *" : ""}</span></label>
        {props.search ?
          <>
            {props.search.type === "LIST" ? <div style={{minWidth: "250px"}}>
                  <SelectInputResource
              placeHolder={props.search.placeHolder}
              name={props.search.searchName || "search"}
              resource={props.search.resource}
              method={props.search.method}
              multiple={false}
              onChange={(v, n) => props.onChange ? props.search.onChange(v, n) : null}
              value={getValueManager(props.search.form, props.search.searchName || "search", null)}
              map={props.search.map}
            ></SelectInputResource></div> :
              <TextInput
                placeHolder={props.search.placeHolder}
                name={props.search.searchName || "search"}
                onChange={(v, n) => props.onChange ? props.search.onChange(v, n) : null}
                value={getValueManager(props.search.form, props.search.searchName || "search", null)}
              ></TextInput>}</>
          : null}

      </div>
      <div className="smf-div">
        <div className="smf-col">
          <h3>Non selectioné</h3>
          <ul>
            {data && data.filter(o => !props.value || !props.value.find(f =>
              (props.data.label ? o[props.data.select] : o) === f)).map((o, i) => (
                <li>
                  <button className="smf-checkbox" type="button" onClick={() => addTo(props.data.label ? o[props.data.select] : o)}>
                    <i className="fa fa-check" aria-hidden="true"></i>
                    {props.data.label ? o[props.data.label] : o}
                  </button>
                </li>
              ))}
          </ul>
        </div>
        <div className="smf-col active">
          <h3>Selectioné</h3>
          <ul>
            {data && data.filter(o => props.value && props.value.find(f =>
              (props.data.label ? o[props.data.select] : o) === f)).map((o, i) => (
                <li>
                  <button className="smf-checkbox" type="button" onClick={() => removeFrom(props.data.label ? o[props.data.select] : o)}>
                    <i className="fa fa-check" aria-hidden="true"></i>
                    {props.data.label ? o[props.data.label] : o}
                  </button>
                </li>))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SelectMultipleFull;
