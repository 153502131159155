import React, { useState, useEffect } from 'react';
import './styles/navbar.css';
import {ASSETS_BASE} from "../../config";

const NavBar = (props) => {
    const [isNavbarBackgroundDark, setNavbarBackgroundDark] = useState(false);

    const scrollInto = (id) => () => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    }

    return <div className={'parentHeader'}>
        <header  className={`header ${props.scroll ? 'background-dark' : ''}`}>
        <div className="container-fluid">
            <div className="row" >
                <nav className="navbar"  >
                    <div className="col-md-3">
                        <div className="navbar-logo">
                            <img onClick={props.scrollToTop} src={`${ASSETS_BASE}/home/images/logo.png`}  alt="Logo" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <ul className="navbar-list">
                            <li><a onClick={scrollInto('presentation')}  >Presentation</a></li>
                            <li><a onClick={scrollInto('services')}  >Service</a></li>
                            <li><a onClick={scrollInto('contact')}  >Contact</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <div className="navbar-profile">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM7.07031 18.28C7.50031 17.38 10.1203 16.5 12.0003 16.5C13.8803 16.5 16.5103 17.38 16.9303 18.28C15.5703 19.36 13.8603 20 12.0003 20C10.1403 20 8.43031 19.36 7.07031 18.28ZM18.36 16.83C16.93 15.09 13.46 14.5 12 14.5C10.54 14.5 7.07 15.09 5.64 16.83C4.62 15.49 4 13.82 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.82 19.38 15.49 18.36 16.83ZM12 6C10.06 6 8.5 7.56 8.5 9.5C8.5 11.44 10.06 13 12 13C13.94 13 15.5 11.44 15.5 9.5C15.5 7.56 13.94 6 12 6ZM12 11C11.17 11 10.5 10.33 10.5 9.5C10.5 8.67 11.17 8 12 8C12.83 8 13.5 8.67 13.5 9.5C13.5 10.33 12.83 11 12 11Z" fill="white"/>
                                <mask id="mask0_165_969" style={{maskType:"luminance"  }}>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM7.07031 18.28C7.50031 17.38 10.1203 16.5 12.0003 16.5C13.8803 16.5 16.5103 17.38 16.9303 18.28C15.5703 19.36 13.8603 20 12.0003 20C10.1403 20 8.43031 19.36 7.07031 18.28ZM18.36 16.83C16.93 15.09 13.46 14.5 12 14.5C10.54 14.5 7.07 15.09 5.64 16.83C4.62 15.49 4 13.82 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.82 19.38 15.49 18.36 16.83ZM12 6C10.06 6 8.5 7.56 8.5 9.5C8.5 11.44 10.06 13 12 13C13.94 13 15.5 11.44 15.5 9.5C15.5 7.56 13.94 6 12 6ZM12 11C11.17 11 10.5 10.33 10.5 9.5C10.5 8.67 11.17 8 12 8C12.83 8 13.5 8.67 13.5 9.5C13.5 10.33 12.83 11 12 11Z" fill="white"/>
                                </mask>
                                <g mask="url(#mask0_165_969)">
                                </g>
                            </svg>

                            <div className="username">
                                <a href='auth/lunch'   >
                                    <p style={{ color: 'white' }}  > <i className="fa fa-user-circle-o" aria-hidden="true"> Account </i> </p>
                                </a>
                            </div>
                            {/*<div className="language">*/}
                            {/*    <div className="dropdown">*/}
                            {/*        <a className=" dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">*/}

                            {/*        </a>*/}
                            {/*        <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton2">*/}
                            {/*            <li><a className="dropdown-item active " href="#">ENG</a></li>*/}
                            {/*            <li><a className="dropdown-item " href="#">Fr</a></li>*/}
                            {/*            <li><a className="dropdown-item" href="#">ESpagne</a></li>*/}
                            {/*        </ul>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </nav>
            </div>
        </div>

    </header>
    </div>
        ;
};

export default NavBar;
