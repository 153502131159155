import { getValueManager } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "antd";
import SharedContext from "../../../contexts/shared/sharedContext";
import { getValueManagerArray } from "../../../core/manager/ManagerUtils";
import { replaceIndex } from "../../../core/manager/ManagerUtils";
import Logger from "../../../core/utils/Logger";
import SelectInputResource from "../../../core/common/inputs/SelectInputResource";

const CLCatalogFormShare = (props) => {
  const { GetResource, getKeyword } = useContext(SharedContext);
  const form = props.form;
  const [formSearch, setFormSearch] = useState({ search: "" });
  const id = getValueManager(form, "id", null);
  const [isLoading, setIsLoading] = useState(false);

  const notify = (type) => {
    Logger(replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")]));
  };

  useEffect(() => { }, []);

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    props.updateForm({ ...f });
  };

  const onChangeSeacrh = (v, n, indexes = null) => {
    const f = updateValueManager(formSearch, n, v, indexes);
    setFormSearch({ ...f });
  };

  const updateTable = (event) => {
    let type = null;
    if (getValueManager(form, "type") == "PROCESS_COLLECT_STARTUP") {
      type = "UPDATE_STARTUP";
    } else if (getValueManager(form, "type") == "PROCESS_COLLECT_AFFILIATE") {
      type = "UPDATE_AFFILIATE";
    }
    if (type) {
      if (type == "UPDATE_AFFILIATE") {
        GetResource("CATALOG_MANAGEMENT", type, { id: form.id, affiliates: form.affiliates }, null).then((res) => {
          props.onUpdate(props.id);
          props.toggleModal(props.modalName);
        });
      } else {
        GetResource("CATALOG_MANAGEMENT", type, { id: form.id, startups: form.startups }, null).then((res) => {
          props.onUpdate(props.id);
          props.toggleModal(props.modalName);
        });
      }
    }
  };


  const formValid = true;

  console.log("Form", formSearch)

  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        style={{ maxWidth: "1000px", width: "100%" }}
        open={props.showModal && !isLoading}
        width={"1000px"}
        onCancel={() => props.toggleModal(props.modalName)}
      >
        <div className="modal-body p-0">
          <div className="bg-secondary  border-0 mb-0">
            {/* <div className="bg-menu">
              <div className="text-center">
                <p className="font-weight-bold text-white mb-0">{getKeyword("QUESTION.QUESTION")}</p>
              </div>
            </div> */}
            <div className="">
              <form role="form">
                <div className="row">
                  <div className="col-12">
                    {getValueManager(form, "type") == "PROCESS_COLLECT_AFFILIATE" ?
                      <SelectInputResource
                        label="Filiales"
                        name="affiliates"
                        resource="AFFILIATE_MANAGEMENT"
                        method="GET_ALL"
                        tableStyle={true}
                        multiple={true}
                        onChange={(v, n) =>
                          onChange(
                            v.map((m) => ({ id: m })),
                            n
                          )
                        }
                        value={getValueManagerArray(form, "affiliates", null).map(
                          (m) => m.id
                        )}
                        map={{
                          select: "id",
                          label: "name",
                        }}
                      ></SelectInputResource> : null}
                    {getValueManager(form, "type") == "PROCESS_COLLECT_STARTUP" ?
                      <SelectInputResource
                        label="Startups"
                        name="startups"
                        resource="STARTUP_MANAGEMENT"
                        method="GET_ALL"
                        tableStyle={true}
                        multiple={true}
                        filter={(item) => !getValueManager(formSearch, "search") || (getValueManager(item, "form.activity") && getValueManager(item, "form.activity").toLowerCase().indexOf(getValueManager(formSearch, "search").toLowerCase()) >= 0)}
                        onChange={(v, n) =>
                          onChange(
                            v.map((m) => ({ id: m })),
                            n
                          )
                        }
                        value={getValueManagerArray(form, "startups", null).map(
                          (m) => m.id
                        )}
                        map={{
                          select: "id",
                          label: "name",
                        }}
                        search={{
                          placeHolder: "Activité",
                          onChange: onChangeSeacrh,
                          form: formSearch,
                          name: "search",
                          type: "LIST",
                          resource: "STARTUP_MANAGEMENT",
                          method: "ACTIVITIES",
                          map: {
                            select: "value",
                            label: "label",
                          }
                        }}
                      ></SelectInputResource> : null}
                  </div>
                </div>
                <div className="text-center d-flex justify-content-between">
                  <button
                    className="my-4"
                    color="white"
                    type="button"
                    onClick={(e) => props.toggleModal(props.modalName)}
                  >
                    {getKeyword("STD_VERB.CANCEL")}
                  </button>
                  <button
                    className="btn-1"
                    color="danger"
                    type="button"
                    disabled={!formValid}
                    onClick={(e) => updateTable()}
                  >
                    {getKeyword("STD_VERB.SHARE")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default CLCatalogFormShare;
