import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../contexts/shared/sharedContext";
import { getHomeLink } from "../../routes/routes/HomeRoutes";

const Page404 = (props) => {

  const { profile, getCurrentProfil } = useContext(SharedContext);

  useEffect(() => {
    if (!profile) {
      getCurrentProfil().then((res) => { });
    }
  }, [])

  return (
    <>
      {/* <AuthHeader
      /> */}
      <div className=" form-login ">
        <h1 className="text-center text-white font-42">Page introuvable !</h1>
        <div className="text-center">
          <p className="text-white font-weight-bold pt-3">La page que vous recherchez est introuvable. <br />
            Elle pourrait avoir été supprimée, renommée, ou pourrait
            n’avoir jamais existé</p>

          <a href={getHomeLink(profile)} className="btn btn-danger  rounded-pill font-20"> Retoure à la page d’Accueille</a>
        </div>
      </div>
    </>
  );
};

export default Page404;
