/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect } from "react";
// core components
import { USEFULL_VARIABLES } from "../../../variables/shared";
import { getValueManager } from "../../../core/manager/ManagerUtils";
import { dateToTimeString } from "../../../core/utils/helpers";
import LINKS from "../../../config";
import { getValueManagerArray } from "../../../core/manager/ManagerUtils";
import ReferencielTableProvider from "../outputs/ReferencielTableProvider";
import { dateDateToString } from "../../../core/utils/helpers";
import TecTableProvider from "../outputs/TecTableProvider";
import { truncateTextAfter } from "../../../core/utils/helpers";
import SharedContext from "../../../contexts/shared/sharedContext";

const GlobalTable = (props) => {
  const page = props.pagination.page;
  const size = props.pagination.size || USEFULL_VARIABLES.paginationSize;
  const order = props.pagination.order;
  const direction = props.pagination.direction;
  const total = props.count;
  const { GetImageInfo, imageData, getKeyword, profile } = useContext(SharedContext);

  // const firstListRef = React.useRef(null);
  // React.useEffect(() => {
  //   new List(firstListRef.current, {
  //     valueNames: props.table.map((table) => table.name),
  //     listClass: "list",
  //   });
  // }, []);

  useEffect(() => {
    props.data.map((data) => {
      props.table.map((table) => {
        if (table.type === "FILE") {
          if (!imageData[getValueManager(data, table.value)]) {
            GetImageInfo(getValueManager(data, table.value)).then(() => { });
          }
        }
      });
    });
  }, [props.data]);

  const paginate = (e, newPage) => {
    e.preventDefault();
    if (props.paginate) {
      props.paginate(newPage, size, order, direction);
    }
  };

  const sort = (e, table) => {
    e.preventDefault();
    let newDirection = direction;
    let newOrder = table.orderName || table.value;
    if (newOrder === order) {
      newDirection = newDirection === "ASC" ? "DESC" : "ASC";
    }
    if (props.paginate) {
      props.paginate(page, size, newOrder, newDirection);
    }
  };

  const buildPagination = () => {
    var totalPages = Math.ceil(total / size);
    const res = [];
    res.push(
      <li className="pagination-item" key={"start"}>
        <button onClick={(e) => paginate(e, 1)}>
          &lt;
        </button>
      </li>
    );
    for (let i = 4; i > 0; i--) {
      let index = page - i;
      if (index > 0) {
        res.push(
          <li className="pagination-item" key={index}>
            <button onClick={(e) => paginate(e, index)}>
              {index}
            </button>
          </li>
        );
      }
    }
    res.push(
      <li className="pagination-item active" key={page}>
        <button onClick={(e) => paginate(e, page)}>
          {page}
        </button>
      </li>
    );
    for (let i = 1; i <= 4; i++) {
      let index = page + i;
      if (index <= totalPages) {
        res.push(
          <li className="pagination-item" key={index}>
            <button onClick={(e) => paginate(e, index)}>
              {index}
            </button>
          </li>
        );
      }
    }
    // ============= icone
    res.push(
      <li className="pagination-item" key={"last"}>
        <button onClick={(e) => paginate(e, totalPages)}>
          &gt;
        </button>
      </li>
    );
    return res;
  };

  const changeSize = (newSize) => {
    if (props.paginate) {
      props.paginate(page, newSize, order, direction);
    }
  };

  return (
    <>
      <div className="table-container">
        <table className="table-table">
          <thead className="thead-light">
            <tr>
              {props.table.map((table, i) => (
                <React.Fragment key={table.value + "-" + i}>
                  <th
                    key={table.value}
                    className="sort"
                    scope="col"
                    {...(table.order ? { "data-sort": table.value } : {})}
                    onClick={(e) => {
                      if (table.order && false) {
                        sort(e, table);
                      }
                    }}
                  >
                    {getKeyword(table.label)}
                  </th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody className="list">
            {props.data.map((data) => (
              <tr key={data.id}>
                {props.table.map((table) => (
                  <React.Fragment key={data.id + table.value}>
                    {!table.type || table.type === "TEXT" ? (
                      <td className="budget">
                        {table.multiple ? (
                          <>
                            {getValueManagerArray(table, "value").map((v, in2) => (
                              <span key={in2}>{getValueManager(data, v)} </span>
                            ))}
                          </>
                        ) : (
                          <>{table.truncate ? <>{truncateTextAfter(getValueManager(data, table.value), (table.truncateSize || 4))}</> : <>{getValueManager(data, table.value)}</>}</>
                        )}
                      </td>
                    ) : null}
                    {table.type === "CLICK" ? (
                      <td className={table.class}>
                        <div className="link" onClick={() => props[table.call](data.id)}>{getValueManager(data, table.value)}</div>
                      </td>
                    ) : null}
                    {table.type === "BOOLEAN" ? (
                      <td className={table.class}>
                        {getValueManager(data, table.value) ? table.data.active : table.data.notActive}
                      </td>
                    ) : null}
                    {table.type === "BOOLEAN_COLOR" ? (
                      <td className={table.class}>
                        <span className={" position-relative " + (getValueManager(data, table.value) ? "bg-status-active" : "bg-status-Expire")}>{getValueManager(data, table.value) ? table.data.active : table.data.notActive}</span>
                      </td>
                    ) : null}
                    {table.type === "FILE" ? (
                      <td className="budget">
                        {!getValueManager(data, table.value) ? null : (
                          <a
                            href={
                              LINKS.imageURLPrivate +
                              getValueManager(data, table.value)
                            }
                            target="_blank"
                          >
                            {(imageData[getValueManager(data, table.value)] &&
                              imageData[getValueManager(data, table.value)]
                                .text) ||
                              getValueManager(data, table.value)}
                          </a>
                        )}
                      </td>
                    ) : null}
                    {table.type === "IMAGE" ? (
                      <td className="budget">
                        {!getValueManager(data, table.value) ? <>
                          {table.default
                            ? <img
                              src={
                                LINKS.imageURLPrivate + table.default
                              }
                              style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                            />
                            : null}
                        </> : (
                          <img
                            src={
                              LINKS.imageURLPrivate +
                              getValueManager(data, table.value)
                            }
                            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                          />
                        )}
                      </td>
                    ) : null}
                    {table.type === "DATE" ? (
                      <td className="budget">
                        {getValueManager(data, table.value) ? dateToTimeString(
                          new Date(getValueManager(data, table.value))
                        ) : ""}
                      </td>
                    ) : null}
                    {table.type === "DATE_SIMPLE" ? (
                      <td className="budget">
                        {getValueManager(data, table.value) ? dateDateToString(
                          new Date(getValueManager(data, table.value))
                        ) : ""}
                      </td>
                    ) : null}
                    {table.type === "REF" ? (
                      <td>
                        {
                          <ReferencielTableProvider
                            code={getValueManager(data, table.value, null)}
                          />
                        }
                      </td>
                    ) : null}
                    {table.type === "TEC" ? (
                      <td>
                        {
                          <TecTableProvider
                            code={getValueManager(data, table.value, null)}
                          />
                        }
                      </td>
                    ) : null}

                    {table.type === "RENDER" ? (
                      <td className={table.class}>
                        {table.render({ ...props, id: data.id, item: data, getKeyword: getKeyword, profile: profile })}
                      </td>
                    ) : null}
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="py-4">
        <div className="table-pagination">
          <label>
            Afficher&nbsp;
            {
              <select
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm"
                value={size}
                onChange={(e) => changeSize(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }&nbsp;
            éléments.
          </label>
          <ul
            className="table-paginate"
          >
            {buildPagination()}
          </ul>
        </div>
      </div>
    </>
  );
};

export default GlobalTable;
