import React from "react";
import './footer.css';
import {ASSETS_BASE} from "../../config";

const Footer = (props) => {
    return <div>
        <footer>

            <section className="footerBloc">
                <div className="container">

                    <div className="row">
                        <div className="col-md-4">
                            <img className='imgFooter'  src={`${ASSETS_BASE}/home/images/logoF.svg`}  alt=""   />
                        </div>

                        <div className="col-md-2">
                            <h6 className=" fw-bold ">E-mail</h6>
                            <span><a href="mailto:ALMADA@MAROC.ma">ALMADA@MAROC.ma</a></span>
                        </div>

                        <div className="col-md-2">
                            <h6 className=" fw-bold">Téléphone</h6>
                            <span><a href="tel:+212520202020">+212 xxx xxx xxx</a></span>
                        </div>

                        <div className="col-md-2">
                            <h6 className=" fw-bold">Bureau</h6>
                            <span><a href="maps:">60, rue d’Alger20 070 – Casablanca – Maroc</a></span>
                        </div>

                        <div className="col-md-2">
                            <h6 className="fw-bold">Sociaux</h6>
                            <span>
               <a className="me-3 text-reset" href="#facebook"><img src={`${ASSETS_BASE}/home/images/Facebook.svg`}    alt=""/></a>
               <a className="me-3 text-reset" href="#twitter"><










                   img  src={`${ASSETS_BASE}/home/images/Twitter.svg`} alt="Twitter"/></a>
               <a className="me-3 text-reset" href="#instagram"><img src={`${ASSETS_BASE}/home/images/Instagram.svg`} alt=""/></a>
               <a className="me-3 text-reset" href="#linkedin"><img src={`${ASSETS_BASE}/home/images/in.svg`}  alt=""/></a>

        </span>
                        </div>
                    </div>
                    <hr />
                        <div className="row"  >
                            <div className="col-md-4">
                                <small>&copy;  Positive links by AL MADA FONDATION 2023</small>
                            </div>
                            <div className="col-md-6">
                                <small   >Politique de confidentialité de l'utilisateur  final</small>
                            </div>

                        </div>
                </div>
            </section>

        </footer>
    </div>
        ;
};

export default Footer;
