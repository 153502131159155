import React, { useContext } from "react";
import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import SharedContext from "../../../contexts/shared/sharedContext";

import "./Inputs.css";
import { getArray, getValueManager, replaceIndex } from "../../manager/ManagerUtils";
import CheckboxStyle from "./Checkbox";

export const CheckboxListInput = (props) => {
  const sharedContext = useContext(SharedContext);
  const { getKeyword } = sharedContext;
  const otherName = getKeyword("STD.OTHER_SELECT");
  const otherValue = "_OTHER_";

  let data = props.dependencies
    ? props.dependencies[props.data.linkId]
      ? props.dependencies[props.data.linkId]
      : []
    : props.data.items;

  if (props.filter) {
    data = data.filter(item => props.filter(item));
  }

  if (props.other && !props.multiple) {
    if (!props.data.label) {
      data = [...data, otherValue];
    } else {
      data = [...data, { [props.data.label]: otherName, [props.data.select]: otherValue }];
    }
  }

  const onChange = (value) => {
    console.log("CHAGE", value);
    if (props.onChange) {
      props.onChange(value, props.name);
    }
  };

  const valuesContainsOther = () => {
    if (!props.value) {
      return false;
    }
    return props.value == otherValue;
  }

  const onChangeOther = (event) => {
    if (props.onChange) {
      props.onChange(event.target.value, props.otherName);
    }
  }

  const valuesContains = (val) => {
    if (!props.value) {
      return false;
    }
    if (props.multiple) {
      return !!getArray(props.value).find(f => f == val);
    } else {
      return val == props.value;
    }
  }

  const addValue = (value) => {
    if (props.multiple) {
      let arr = getArray(props.value);
      if (!arr.find(f => f == value)) {
        arr.push(value);
      }
      props.onChange(arr, props.name);
    } else {
      props.onChange(value, props.name);
    }
  };

  const deleteValue = (val) => {
    if (props.multiple) {
      let arr = getArray(props.value);
      props.onChange(
        arr.filter((vv) => vv != val),
        props.name
      );
    } else {
      props.onChange(null, props.name);
    }
  };

  const getValuesToShow = () => {
    return <ul>
      {props.multiple ?
        <>
          {getArray(props.value).map(val => (
            <li>{getValueManager(data.find(f => f[props.data.select] == val), props.data.label) || val}</li>
          ))}
        </>
        :
        <li>{props.value == otherValue ? props.otherValue : (getValueManager(data.find(f => props.value && f[props.data.select] == props.value), props.data.label) || props.value)}</li>
      }
    </ul>
  }

  return (
    <div className="CheckboxListInput InputField">
      {!props.label ? null : (
        <label className="Ilabel">
          {replaceIndex(
            getKeyword(props.label),
            props.lableIndexesNames,
            props.lableIndexes
          )}&nbsp;
          {props.optText ? (
            <span>
              {props.optText !== true ? props.optText : "(Optionnel)"}
            </span>
          ) : (
            ""
          )}
          <span className="requiredLb">{props.required ? " *" : ""}</span>
        </label>
      )}
      <div className="input">
        {props.isLoading ? (
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <>
            {props.preview ?
              <p className="input-response">{getValuesToShow()}</p>
              :
              <div className="row">
                {data.map((o, i) => (
                  <div className="col-6" key={"CheckboxListInput" + props.name + i}>
                    <CheckboxStyle
                      key={props.data.label ? o[props.data.select] : o}
                      label={props.data.label
                        ? props.allowTraduction
                          ? getKeyword(o[props.data.label])
                          : o[props.data.label]
                        : props.allowTraduction
                          ? getKeyword(o)
                          : o}
                      name={props.data.label ? o[props.data.select] : o}
                      value={valuesContains(props.data.label ? o[props.data.select] : o)}
                      onChange={(v, n) => {
                        if (v) { addValue(props.data.label ? o[props.data.select] : o); } else {
                          deleteValue(props.data.label ? o[props.data.select] : o)
                        }
                      }}
                    >
                    </CheckboxStyle>
                  </div>
                ))}
              </div>
            }
          </>
        )}
      </div>
      {!props.preview && !props.multiple && props.other && valuesContainsOther() ?
        <div className="TextInput">
          <div className="input">
            <input
              className="inp"
              type="text"
              value={props.otherValue}
              name={"TextInput-" + props.otherName}
              onChange={onChangeOther}
              placeholder={getKeyword("STD.OTHER")}
            ></input>
          </div></div> : null}
      {props.error ? (
        <p className="errorInput">
          <i className="fas fa-exclamation-triangle"></i> {props.error}
        </p>
      ) : null}
    </div>
  );
};

export default CheckboxListInput;
