import React, { useContext, useEffect, useState } from "react";

import GlobalTable from "../../../core/common/table/GlobalTable";
import GlobalFilters from "../../../core/common/table/GlobalFilters";
import { USEFULL_VARIABLES } from "../../../variables/shared";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import { useHistory } from "react-router";
import RoleForm from "./form/role-form";
import ConfirmationPopupShared from "../../../core/common/notifications/ConfirmationPopup";
import { replaceIndex } from "../../../core/manager/ManagerUtils";
import SharedContext from "../../../contexts/shared/sharedContext";
import { LoggerSuccess } from "../../../core/utils/Logger";
import ItemMenu from "../../../core/common/table/ItemMenu";
import RoleShow from "./show/role-show";

const TABLE = [
  {
    label: "COMMON.ID",
    value: "id",
    order: true,
    type: "CLICK",
    call: "show"
  },
  {
    label: "COMMON.DESIGNATION",
    value: "name",
    order: true,
  },
  // {
  //   label: "COMMON.STATUS",
  //   value: "enabled",
  //   type: "BOOLEAN_COLOR",
  //   order: true,
  //   data: {
  //     active: "Actif",
  //     noActive: "Inactif"
  //   }
  // },
  {
    label: "STD.ACTIONS",
    value: "actions",
    type: "RENDER",
    class: "table-actions",
    render: (props) => (
      <>
        <ItemMenu menus={[
          {
            name: props.getKeyword("STD_VERB.VIEW_DETAILS"),
            click: () => props.show(props.id)
          },
          {
            name: props.getKeyword("STD_VERB.EDIT"),
            click: () => props.update(props.id)
          },
          // {
          //   name: props.getKeyword("STD_VERB.DISABLE"),
          //   click: () => props.disable(props.id),
          //   hide: !props.item.enabled
          // },
          // {
          //   name: props.getKeyword("STD_VERB.ENABLE"),
          //   click: () => props.enable(props.id),
          //   hide: props.item.enabled
          // },
          {
            name: props.getKeyword("STD_VERB.DELETE"),
            click: () => props.delete(props.id),
          }
        ]}>

        </ItemMenu>
      </>
    ),
  },
];

const FILTERS = [
  {
    label: "COMMON.ID",
    type: "ID",
    value: "id",
    className: "col-md-3",
  },
  {
    label: "COMMON.PERMISSION",
    value: "permission",
    className: "col-md-3",
    type: "RESOURCE",
    data: {
      resource: {
        resource: "PERMISSION",
        method: "GET",
        body: {},
      },
      items: [],
      select: "id",
      label: "description",
    },
  },
];


const FILTER_INIT = {
  user: "",
  code: "",
  permission: null,
  profile: null
};

const Role = (props) => {
  const history = useHistory();
  const { GetResource, getKeyword } = useContext(SharedContext);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    size: USEFULL_VARIABLES.paginationSize,
    order: null,
    direction: "DESC",
  });
  const [formFilter, setFormFilter] = useState(
    JSON.parse(JSON.stringify(FILTER_INIT))
  );
  const [modalState, setModalState] = useState({});

  const [deleteMessage, setDeleteMessage] = useState(null);

  const [disableMessage, setDisableMessage] = useState(null);

  const toggleModal = (state) => {
    modalState[state] = !modalState[state];
    setModalState({ ...modalState });
  };

  const [alert, setalert] = React.useState(false);
  const [form, setForm] = useState({
    code: "",
    name: "",
    description: "",
    permission: [],
  });

  const [isFilter, setIsFilter] = useState(false);

  useEffect(() => {
    paginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  }, []);

  const reload = () => {
    paginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const initForm = () => {
    setForm({
      code: "",
      name: "",
      description: "",
      permission: [],
    });
  };

  const beforeAdd = () => {
    initForm();
    toggleModal("formModal");
  };

  const onAdd = () => {
    reload();
    setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    initForm();
  };

  const setAlert = (message) => {
    LoggerSuccess(message);
  };

  const onUpdate = () => {
    reload();
    setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    initForm();
  };

  const onChangeFilters = (v, n, indexes = null) => {
    const f = updateValueManager(formFilter, n, v, indexes);
    setFormFilter({ ...f });
  };

  const paginate = (page, size, order, direction) => {
    if (isFilter) {
      loadFilter(page, size, order, direction);
    } else {
      loadPaginate(page, size, order, direction);
    }
  };

  const filter = () => {
    pagination.page = 1;
    setIsFilter(true);
    loadFilter(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const loadPaginate = (page, size, order, direction) => {
    setLoaded(false);
    GetResource(
      "ROLE_MANAGEMENT",
      "GET",
      {
        page,
        size,
        order,
        direction,
      },
      null
    ).then((res) => {
      setData(res.payload);
      setPagination({ ...pagination, page, size, order, direction });
      setLoaded(true);
    });
  };

  const loadFilter = (page, size, order, direction) => {
    setLoaded(false);
    GetResource(
      "ROLE_MANAGEMENT",
      "FILTER",
      {
        ...formFilter,
        data: {
          page,
          size,
          order,
          direction,
        },
      },
      null
    ).then((res) => {
      setData(res.payload);
      setLoaded(true);
    });
  };

  const initializeFilter = () => {
    setFormFilter(JSON.parse(JSON.stringify(FILTER_INIT)));
    setIsFilter(false);
    loadPaginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const updateItem = (itemId) => {
    GetResource("ROLE_MANAGEMENT", "GET_ID", { id: itemId }, null).then(
      (res) => {
        setForm({ ...res.payload });
        toggleModal("formModal");
      }
    );
  };


  const showItem = (itemId) => {
    GetResource("ROLE_MANAGEMENT", "GET_ID", { id: itemId }, null).then(
      (res) => {
        setForm({ ...res.payload });
        toggleModal("showModal");
      }
    );
  };

  const deleteItem = (itemId) => {
    GetResource("ROLE_MANAGEMENT", "DISABLE_CALC", { id: itemId, enabled: false }, null).then(
      (res) => {
        if (res.payload.count) {
          setDeleteMessage({
            message: replaceIndex(getKeyword("USER.ROLE_ATTACHED_DELETE"), ["nombre"], [res.payload.count]),
            id: itemId,
          });
        } else {
          setDeleteMessage({
            message: getKeyword("USER.ROLE_NONE_ATTACHED_DELETE"),
            id: itemId,
          });
        }
      }
    );
  };

  const confirmDelete = () => {
    GetResource(
      "ROLE_MANAGEMENT",
      "DELETE",
      { id: deleteMessage.id },
      null
    ).then((res) => {
      setDeleteMessage(null);
      reload();
      setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };

  const enableItem = (id) => {
    GetResource("ROLE_MANAGEMENT", "ACTIVATION", { id, enabled: true }, null).then(
      (res) => {
        reload();
        setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
      }
    );
  };

  const disableItem = (itemId) => {
    GetResource("ROLE_MANAGEMENT", "DISABLE_CALC", { id: itemId, enabled: false }, null).then(
      (res) => {
        if (res.payload.count) {
          setDisableMessage({
            message: replaceIndex(getKeyword("USER.ROLE_ATTACHED"), ["nombre"], [res.payload.count]),
            id: itemId,
          });
        } else {
          setDisableMessage({
            message: getKeyword("USER.ROLE_NONE_ATTACHED"),
            id: itemId,
          });
        }
      }
    );
  };

  const disableItemConfirm = () => {
    GetResource("ROLE_MANAGEMENT", "DESACTIVATION", { id: disableMessage.id, enabled: false }, null).then(
      (res) => {
        reload();
        setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        setDisableMessage(null);
      }
    );
  }

  return (
    <>
      {deleteMessage ? (
        <ConfirmationPopupShared
          message={deleteMessage.message}
          close={() => setDeleteMessage(null)}
          confirm={confirmDelete}
        ></ConfirmationPopupShared>
      ) : null}
      {disableMessage ? (
        <ConfirmationPopupShared
          message={disableMessage.message}
          close={() => setDisableMessage(null)}
          confirm={disableItemConfirm}
        ></ConfirmationPopupShared>
      ) : null}
      <div>
        <GlobalFilters
          filters={FILTERS}
          form={formFilter}
          onChange={onChangeFilters}
          filter={filter}
          initialize={initializeFilter}
        ></GlobalFilters>
        <div className="card-block">
          <div className="card-header">
            <div>
              <h3 className="mb-0">{getKeyword("USER.ROLES")}</h3>
            </div>
            <div>
              <button
                className="btn-1"
                id="tooltip969372949"
                onClick={(e) => beforeAdd()}
                size="lg"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">{getKeyword("STD.NEW")}</span>
              </button>
              {modalState["formModal"] ? (
                <RoleForm
                  modalName="formModal"
                  toggleModal={toggleModal}
                  showModal={modalState["formModal"]}
                  onAdd={onAdd}
                  form={form}
                  updateForm={(form) => setForm(form)}
                  onUpdate={onUpdate}
                ></RoleForm>
              ) : null}
              {modalState["showModal"] ? (
                <RoleShow
                  modalName="showModal"
                  toggleModal={toggleModal}
                  showModal={modalState["showModal"]}
                  form={form}
                  update={updateItem}
                  enable={enableItem}
                  disable={disableItem}
                ></RoleShow>
              ) : null}
            </div>
          </div>
          {isLoaded ? (
            <GlobalTable
              table={TABLE}
              data={data.data}
              count={data.count}
              pagination={pagination}
              paginate={paginate}
              update={updateItem}
              enable={enableItem}
              disable={disableItem}
              delete={deleteItem}
              show={showItem}
            ></GlobalTable>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Role;
