export const HOMEPAGE = "/admin/profile";
export const HOMEPAGE_EXTERNAL = "/external/feedback"

//Frontend base links, if html base is different than / = for prod or java based apps serving
//const apiUrl = process.env.APILINK || "http://localhost:8090";
 const apiUrl = process.env.APILINK || "";
const APISUFFIX = "/api";
export const URL_BASE = "";
export const URL_BASE_EXTERNAL = "";
export const ASSETS_BASE = "";

//Backend apis links
export const LINKS = {
  baseURL: apiUrl,
  apiUrlAPI: APISUFFIX,
  imageURLPrivate: apiUrl + APISUFFIX + "/files/",
  imageURLProduct: apiUrl + APISUFFIX + "/files/product-label/",
  auth: apiUrl + APISUFFIX + "/auth/login",
  login: (redirect) => `/auth/lunch?${redirect ? "redirect=" + redirect : ""}`,
};

export default LINKS;
