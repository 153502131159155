import DateInput from "../../../../core/common/inputs/DateInput";
import TextInput from "../../../../core/common/inputs/TextInput";
import { getValueManager, getValueManagerArray } from "../../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../../contexts/shared/sharedContext";
import { useHistory, useParams, useSearchParams } from "react-router";
import { updateObjectFromImage } from "../../../../core/manager/ManagerUtils";
import FileInput from "../../../../core/common/inputs/FileInput";
import queryString from "query-string";

import SelectInputResource from "../../../../core/common/inputs/SelectInputResource";
import EmailSecondaryForm from "./EmailSecondaryForm";
import { dateDateToString } from "../../../../core/utils/helpers";
import { dateStrToTimeString } from "../../../../core/utils/helpers";
import PasswordFormM from "./password-form";
import SelectInputTableTechnique from "../../../../core/common/inputs/SelectInputTableTechnique";
import TechnicalTable from "../../../../variables/technical-table";
import TecTableProvider from "../../../../core/common/outputs/TecTableProvider";
import { validateEmail } from "../../../../core/utils/helpers";
import Logger, { LoggerSuccess } from "../../../../core/utils/Logger";
import LINKS from "../../../../config";

const UtilisateurForm = (props) => {
    const { GetResource, ImageSaver, getKeyword } = useContext(SharedContext);
    const history = useHistory();
    const { id } = useParams();
    const [searchParams] = useState(
        queryString.parse(props.location.search, { ignoreQueryPrefix: true })
    );
    const [operation, setOperation] = useState("");
    const [idAdd, setIdAdd] = useState(null);
    const [tab, setTab] = useState(1);
    const [passwordId, setPasswordId] = useState(1);
    const [form, setForm] = useState({
        code: "",
        name: "",
        description: "",
        // profile: null,
        roles: [],
    });

    const [showForm, setFormShow] = useState({});

    const [images, setImages] = useState({});
    const [isLoading, setIsLoading] = useState({
        loading: false,
        size: 0,
        count: 0,
    });

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (id) {
            getUtilisateur(id);
        }
    }, []);

    const getUtilisateur = (idParam) => {
        GetResource(
            "USER_MANAGEMENT",
            "GET_ID",
            { id: idParam },
            null
        ).then((res) => {
            setForm(res.payload);
            setFormShow(JSON.parse(JSON.stringify(res.payload)));
        });
    };

    const onChange = (v, n, indexes = null) => {
        const f = updateValueManager(form, n, v, indexes);
        setForm({ ...f });
    };
    const onChangeUser = (v, n, indexes = null) => {

        onChange(v, n, indexes);
        // clear select roles

        onChange([], "roles");

    }
    const onChangeImages = (
        files,
        name,
        multiple,
        indexes,
        usedFor = "PROFILE"
    ) => {
        images[name] = { files, name, multiple, indexes, usedFor };
        setImages({ ...images });
    };

    const addNewTable = (event) => {
        setLoading(true);
        uploadImages(() => {
            GetResource("USER_MANAGEMENT", "ADD", form, null).then((res) => {

                setIdAdd(res.payload.id);
                getUtilisateur(res.payload.id);
                setFormShow(JSON.parse(JSON.stringify(form)));
                setOperation("SHOW");
            }).catch((err) => {
                setLoading(false);
                if (err && err.status == 409 && err.data && err.data.message == "ALREADY_EXISTS" && err.data.detail == "EMAIL") {
                    notify("danger", getKeyword("USER.EMAIL_USED"));
                } else if (err && err.status == 409 && err.data && err.data.message == "ALREADY_EXISTS" && err.data.detail == "USERNAME") {
                    notify("danger", getKeyword("USER.LOGIN_USED"));
                } else if (err && err.status == 403 && err.data && err.data.message == "FORBIDDEN" && err.data.detail == "USERS_LIMIT") {
                    notify("danger", getKeyword("USER.USERS_LIMIT"));
                }
            });
        });
    };

    const updateTable = (event) => {
        setLoading(true);
        uploadImages(() => {
            GetResource("USER_MANAGEMENT", "UPDATE", form, null).then((res) => {
                setFormShow(JSON.parse(JSON.stringify(form)));
                setOperation("SHOW");
            }).catch((err) => {
                setLoading(false);
            });
        });
    };

    const uploadImages = (callback) => {
        ImageSaver(
            (params, images) => {
                updateObjectFromImage(form, images);
                callback();
            },
            (params, status) => setIsLoading(status),
            {},
            images,
            form
        );
    };

    const formValid =
        // getValueManager(form, "dateOfBirth", null) &&
        // getValueManager(form, "profile", null) &&
        getValueManager(form, "email", null) &&
        validateEmail(getValueManager(form, "email", null)) &&
        // getValueManager(form, "phone", null) &&
        getValueManager(form, "firstName", null) &&
        getValueManager(form, "lastName", null) &&
        getValueManager(form, "userType", null) &&
        ((getValueManager(
            form,
            "userType",
            null
        ) == "USER_TYPE_AFFILIATE" && getValueManager(
            form,
            "affiliate",
            null
        )) || (getValueManager(
            form,
            "userType",
            null
        ) == "USER_TYPE_STARTUP" && getValueManager(
            form,
            "startup",
            null
        )) || (getValueManager(
            form,
            "userType",
            null
        ) != "USER_TYPE_STARTUP") && getValueManager(
            form,
            "userType",
            null
        ) != "USER_TYPE_AFFILIATE") && getValueManagerArray(form, "roles").length > 0;

    const finalId = id || idAdd;

    console.log(form);

    const makeMain = () => {
        getUtilisateur(id || idAdd);
    };

    const notify = (type, message) => {
        Logger(message);
    };


    const beforeonSend = () => {
        initForm();
        toggleModal("formModalTwo");
    };
    const onSend = () => {
        reload();
        successAlert("invitation est envoyer");
        initForm();
    };
    const toggleModal = (state) => {
        setModalState({ ...modalState, [state]: !modalState[state] });
    };

    const [modalState, setModalState] = useState({});
    const initForm = () => {
        setForm({
            code: "",
            designation: "",
            description: "",
        });
    };
    const successAlert = (message) => {
        LoggerSuccess(message);
    };

    const reload = () => {
        getUtilisateur(id);
    };

    const enableItem = (id) => {
        GetResource(
            "USER_MANAGEMENT",
            "ENABLE",
            { id, enabled: true },
            null
        ).then((res) => {
            reload();
        }).catch((err) => {
            if (err && err.status == 403 && err.data && err.data.message == "FORBIDDEN" && err.data.detail == "USERS_LIMIT") {
                notify("danger", getKeyword("USER.USERS_LIMIT"));
            }
        });
    };

    const disableItem = (id) => {
        GetResource(
            "USER_MANAGEMENT",
            "DISABLE",
            { id, enabled: false },
            null
        ).then((res) => {
            reload();
        });
    };

    const debloque = (id) => {
        GetResource(
            "USER_MANAGEMENT",
            "BLOCK",
            { id, enabled: false },
            null
        ).then((res) => {
            reload();
        });
    };

    const renitialise = (id) => {
        GetResource(
            "USER_MANAGEMENT",
            "RESET",
            { id, enabled: false },
            null
        ).then((res) => {
            reload();
            successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        });
    };

    const chnagePassword = (id, state) => {
        setPasswordId(id);
        setModalState({ ...modalState, [state]: !modalState[state] });
    }

    const chnagePasswordSuccess = (id, state) => {
        setPasswordId(id);
        setModalState({ ...modalState, [state]: !modalState[state] });
        successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    }


    return (
        <div className="row"><div className="col-12">
            <div className="body-content">
                {(searchParams.show && !operation) || (operation && operation == "SHOW") ? (
                    <div className="mgb-20">
                        <div className="mt--6"  >

                            <div className="my-6">
                                <div className=" d-flex justify-content-between">
                                    {getValueManager(showForm, "enabled", null) ?
                                        <div className=" pl-5 px-3">
                                            <button
                                                className="btn-3"
                                                color="danger"
                                                type="button"
                                                id="tooltip969372949"
                                                onClick={(e) => disableItem(getValueManager(showForm, "id", null))}
                                            >
                                                <i className="fa fa-ban mr-2" aria-hidden="true"></i>  Désactiver
                                            </button>
                                        </div>
                                        :
                                        <div className="pl-5 px-3">
                                            <button
                                                className="btn-2 btn btn-green"
                                                color=""
                                                type="button"
                                                id="tooltip969372949"
                                                onClick={(e) => enableItem(getValueManager(showForm, "id", null))}
                                            >
                                                <i className="fa fa-check mr-2" aria-hidden="true"></i>  Réactiver
                                            </button>
                                        </div>
                                    }
                                    {getValueManager(showForm, "blocked", null) ?
                                        <div className="px-3">
                                            <button
                                                className="btn-2 btn-lg"
                                                color="warning"
                                                type="button"
                                                id="tooltip969372949"
                                                onClick={(e) => debloque(getValueManager(showForm, "id", null))}
                                            >
                                                Débloquer
                                            </button>
                                        </div> : null}
                                    {/* <div className="px-3">
                                        <button
                                            className="btn-2 btn-lg"
                                            color="danger"
                                            type="button"
                                            id="tooltip969372949"
                                            onClick={(e) => chnagePassword(getValueManager(showForm, "id", null), "formModalTwo")}
                                        >

                                            <i className="fa fa-sliders mr-2" ></i>

                                            Changer mot de passe
                                        </button>
                                        {modalState["formModalTwo"] ? (
                                            <PasswordFormM
                                                modalName="formModalTwo"
                                                toggleModal={() => chnagePassword(null, "formModalTwo")}
                                                showModal={modalState["formModalTwo"]}
                                                id={passwordId}
                                            ></PasswordFormM>
                                        ) : null}
                                    </div> */}
                                    <div className="px-3">
                                        <button
                                            className="btn-2  btn-round btn-icon "
                                            color="info"
                                            id="tooltip969372948"
                                            onClick={(e) => renitialise(getValueManager(showForm, "id", null))}
                                        >
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fa fa-repeat" aria-hidden="true"></i>

                                            </span>
                                            <span className="btn-inner--text">Réinitialiser mot de passe </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>) : null}
                <>
                    {(searchParams.show && !operation) ||
                        (operation && operation == "SHOW") ? (

                        <div className="mt--6"  >
                            <div className="row">
                                <div className="col">
                                    <div className="bg-secondary shadow border-0">
                                        <div className="px-lg-5 py-lg-5">
                                            <div className="row">
                                                {getValueManager(showForm, "image", null) ? (
                                                    <>
                                                        <div className="col-12 card-profile-image">
                                                            <img
                                                                src={
                                                                    LINKS.imageURLPrivate +
                                                                    getValueManager(showForm, "image", null)
                                                                }
                                                            ></img>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                    </>
                                                ) : null}
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Adresse e-mail principal :</strong>&nbsp;
                                                        </p>
                                                        <a href="mailto: abc@example.com" className="w-50 text-gradient-lite font-weight-bold "> {getValueManager(showForm, "email", null)}</a>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Type utilisateur :</strong>&nbsp;
                                                        </p>
                                                        <span className="w-50 text-dark ">
                                                            {
                                                                <TecTableProvider
                                                                    code={getValueManager(
                                                                        showForm,
                                                                        "userType",
                                                                        null
                                                                    )}
                                                                />
                                                            }
                                                        </span>
                                                    </div>


                                                </div>
                                                {getValueManager(
                                                    form,
                                                    "userType",
                                                    null
                                                ) == "USER_TYPE_AFFILIATE" ? <div className="col-6"><p>
                                                    <strong>Filiale :</strong>&nbsp;
                                                    {getValueManager(showForm, "affiliate.name", null)}
                                                </p></div> : null}
                                                {getValueManager(
                                                    form,
                                                    "userType",
                                                    null
                                                ) == "USER_TYPE_STARTUP" ? <div className="col-6"><p>
                                                    <strong>Startup :</strong>&nbsp;
                                                    {getValueManager(showForm, "startup.name", null)}
                                                </p></div> : null}
                                                <div className="col-6">

                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Nom :</strong>&nbsp;
                                                        </p>
                                                        <span className="w-50 text-dark ">
                                                            {getValueManager(showForm, "lastName", null)}
                                                        </span>
                                                    </div>


                                                </div>
                                                <div className="col-6">


                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Prénom :</strong>&nbsp;
                                                        </p>
                                                        <span className="w-50 text-dark "> {getValueManager(showForm, "firstName", null)}</span>
                                                    </div>
                                                </div>

                                                <div className="col-6">


                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Adresse :</strong>&nbsp;
                                                        </p>
                                                        <span className="w-50 text-dark "> {getValueManager(showForm, "address", null)}</span>
                                                    </div>
                                                </div>

                                                <div className="col-6">

                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Date de naissance :</strong>&nbsp;
                                                        </p>
                                                        <span className="w-50 text-dark ">   {getValueManager(showForm, "dateOfBirth") ? dateDateToString(
                                                            new Date(getValueManager(showForm, "dateOfBirth"))
                                                        ) : ""}</span>
                                                    </div>
                                                </div>


                                                <div className="col-6">


                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Téléphone principal :</strong>&nbsp;
                                                        </p>
                                                        <a href="tel:0688122050" className="w-50 text-gradient-lite font-weight-600"> {getValueManager(showForm, "phone", null)}</a>
                                                    </div>
                                                </div>
                                                <div className="col-6">


                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Téléphone secondaire :</strong>&nbsp;
                                                        </p>
                                                        <a href="tel:0688122050" className="w-50 text-gradient-lite font-weight-600">  {getValueManager(showForm, "secondaryPhoneNumber", null)}</a>
                                                    </div>
                                                </div>

                                                <div className="col-6">


                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Date de création :</strong>&nbsp;
                                                        </p>
                                                        <span className="w-50 text-dark "> {getValueManager(showForm, "createdAt", null) ? dateStrToTimeString(getValueManager(showForm, "createdAt", null)) : null}</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">



                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Créé Par :</strong>&nbsp;
                                                        </p>
                                                        <span className="w-50 text-dark ">   {getValueManager(showForm, "invited") ? "Auto-inscription" : getValueManager(showForm, "createdByUser.firstName", null) + " " + getValueManager(showForm, "createdByUser.lastName", null)}</span>
                                                    </div>
                                                </div>

                                                <div className="col-12">



                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Rôles :</strong>&nbsp;
                                                        </p>
                                                        <span className="w-50 text-dark ">{getValueManagerArray(form, "roles", null).reduce((a, b) => (a ? a + ", " : "") + b.name, "")}</span>
                                                    </div>
                                                </div>

                                                {/* <div className="col-6">


                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Nom du Profil utilisateur :</strong>&nbsp;
                                                        </p>
                                                        <span className="w-50 text-dark ">
                                                            {getValueManager(form, "profile.name", null)}
                                                        </span>
                                                    </div>


                                                </div>
                                                <div className="col-6">

                                                    <div className="d-flex border-right">
                                                        <p className="w-50 font-weight-bold text-dark">
                                                            <strong>Code du Profil utilisateur :</strong>&nbsp;
                                                        </p>
                                                        <span className="w-50 text-dark ">
                                                            {getValueManager(form, "profile.code", null)}
                                                        </span>
                                                    </div>
                                                    <br />
                                                </div> */}

                                            </div>


                                        </div>

                                    </div>
                                    {/* <div>
                                        <div>
                                            <div className="col-12">
                                                <strong className="text-dark">Rôle :</strong>&nbsp;
                                                <table className="mttable mt-2">
                                                    <thead>
                                                        <tr>
                                                            <th>{getKeyword("COMMON.CODE")}</th>
                                                            <th>{getKeyword("COMMON.DESIGNATION")}</th>
                                                            <th>{getKeyword("COMMON.DESCRIPTION")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {getValueManagerArray(form, "roles", null).map(
                                                            (m) => <tr><td>{m.code}</td><td>{m.name}</td><td>{m.description}</td></tr>,
                                                        )}
                                                    </tbody>
                                                </table>
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div>
                                        <div className="d-flex justify-content-around">
                                            <button
                                                className="my-4"
                                                color="white"
                                                type="button"
                                                onClick={(e) => history.push("/admin/user-management")}
                                            >
                                                Liste des utilisateurs
                                            </button>
                                            <button
                                                className="btn-1"
                                                color="danger"
                                                type="button"
                                                onClick={(e) => setOperation("update")}
                                            >
                                                Modifier
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (

                        <div className="mt--6"  >
                            <div className="row">
                                <div className="col">
                                    <div className="bg-secondary shadow border-0">
                                        <div className="px-lg-5 py-lg-5">
                                            <div role="form">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <TextInput
                                                            // disabled={id ? true : false}
                                                            label="Adresse Email principal"
                                                            name="email"
                                                            onChange={(v, n) => onChange(v, n)}
                                                            value={getValueManager(form, "email", null)}
                                                            required={true}
                                                            error={getValueManager(form, "email", null) && !validateEmail(getValueManager(form, "email", null)) ? getKeyword("STD_ERROR.EMAIL") : null}
                                                        ></TextInput>
                                                    </div>
                                                    <div className={getValueManager(
                                                        form,
                                                        "userType",
                                                        null
                                                    ) === "USER_TYPE_EXTERNE" ? "col-6" : "col-6"}>
                                                        <SelectInputTableTechnique
                                                            required={true}
                                                            label="Type utilisateur"
                                                            name="userType"
                                                            code={TechnicalTable.USER_TYPE}
                                                            onChange={(v, n) => onChangeUser(v, n)}
                                                            value={getValueManager(
                                                                form,
                                                                "userType",
                                                                null
                                                            )}
                                                            filter={(v) => v.code === "USER_TYPE_INTERNAL" || v.code === "USER_TYPE_AFFILIATE" || v.code === "USER_TYPE_STARTUP"}
                                                        ></SelectInputTableTechnique>
                                                    </div>
                                                    {getValueManager(
                                                        form,
                                                        "userType",
                                                        null
                                                    ) === "USER_TYPE_AFFILIATE" ?
                                                        <div className="col-6">
                                                            <SelectInputResource
                                                                label="Filiale"
                                                                name="affiliate"
                                                                resource="AFFILIATE_MANAGEMENT"
                                                                method="GET_ALL"
                                                                multiple={false}
                                                                onChange={(v, n) =>
                                                                    onChange(v ? { id: v } : null,
                                                                        n
                                                                    )
                                                                }
                                                                value={getValueManager(form, "affiliate.id", null)}
                                                                map={{
                                                                    select: "id",
                                                                    label: "name",
                                                                }}

                                                                required={true}
                                                            ></SelectInputResource>
                                                        </div> : null}
                                                    {getValueManager(
                                                        form,
                                                        "userType",
                                                        null
                                                    ) === "USER_TYPE_STARTUP" ?
                                                        <div className="col-6">
                                                            <SelectInputResource
                                                                label="Startup"
                                                                name="startup"
                                                                resource="STARTUP_MANAGEMENT"
                                                                method="GET_ALL"
                                                                multiple={false}
                                                                onChange={(v, n) =>

                                                                    onChange(v ? { id: v } : null,
                                                                        n
                                                                    )
                                                                }
                                                                value={getValueManager(form, "startup.id", null)}
                                                                map={{
                                                                    select: "id",
                                                                    label: "name",
                                                                }}

                                                                required={true}
                                                            ></SelectInputResource>
                                                        </div> : null}
                                                    <div className="col-6">
                                                        <TextInput
                                                            label="Nom"
                                                            name="lastName"
                                                            onChange={(v, n) => onChange(v, n)}
                                                            value={getValueManager(form, "lastName", null)}
                                                            required={true}
                                                        ></TextInput>
                                                    </div>
                                                    <div className="col-6">
                                                        <TextInput
                                                            label="Prénom"
                                                            name="firstName"
                                                            onChange={(v, n) => onChange(v, n)}
                                                            value={getValueManager(form, "firstName", null)}
                                                            required={true}
                                                        ></TextInput>
                                                    </div>
                                                    <div className="col-6">
                                                        <DateInput
                                                            label="Date de naissance"
                                                            name="dateOfBirth"
                                                            onChange={(v, n) => onChange(v, n)}
                                                            value={getValueManager(
                                                                form,
                                                                "dateOfBirth",
                                                                null
                                                            )}
                                                        ></DateInput>
                                                    </div>

                                                    <div className="col-6">
                                                        <TextInput
                                                            label="Adresse"
                                                            name="address"
                                                            onChange={(v, n) => onChange(v, n)}
                                                            value={getValueManager(form, "address", null)}
                                                        ></TextInput>
                                                    </div>

                                                    <div className="col-6">
                                                        <TextInput
                                                            label="Téléphone principal"
                                                            name="phone"
                                                            onChange={(v, n) => onChange(v, n)}
                                                            value={getValueManager(form, "phone", null)}
                                                        // required={true}
                                                        ></TextInput>
                                                    </div>
                                                    <div className="col-6">
                                                        <TextInput
                                                            label="Téléphone secondaire"
                                                            name="secondaryPhoneNumber"
                                                            onChange={(v, n) => onChange(v, n)}
                                                            value={getValueManager(form, "secondaryPhoneNumber", null)}
                                                        ></TextInput>
                                                    </div>


                                                    {/* <div className="col-6">
                                                        <SelectInputResource
                                                            label="Profil utilisateur"
                                                            name="profile"
                                                            resource="PROFILE_MANAGEMENT"
                                                            method="GET_ALL"
                                                            // required={true}
                                                            multiple={false}
                                                            onChange={(v, n) =>
                                                                onChange({ id: v },
                                                                    n
                                                                )
                                                            }
                                                            value={getValueManager(form, "profile.id", null)}
                                                            map={{
                                                                select: "id",
                                                                label: "code",
                                                            }}
                                                            filter={(item) => item.type == getValueManager(form, "userType", null) && item.enabled}
                                                        ></SelectInputResource>
                                                    </div> */}
                                                    <div className="col-12">
                                                        <SelectInputResource
                                                            label="Rôles"
                                                            name="roles"
                                                            resource="ROLE_MANAGEMENT"
                                                            method="GET_ALL"
                                                            multiple={true}
                                                            onChange={(v, n) =>
                                                                onChange(
                                                                    v.map((m) => ({ id: m })),
                                                                    n
                                                                )
                                                            }
                                                            value={getValueManagerArray(form, "roles", null).map(
                                                                (m) => m.id
                                                            )}
                                                            map={{
                                                                select: "id",
                                                                label: "name",
                                                            }}
                                                            filter={(item) => item.type == getValueManager(form, "userType", null) && item.enabled}
                                                            required={true}
                                                        ></SelectInputResource>
                                                    </div>
                                                    <div className="col-12">
                                                        <FileInput
                                                            label="Photo"
                                                            name="image"
                                                            onChange={(files) => onChangeImages(files, "image", false)}
                                                            onChangeOld={(v, n) => onChange(v, n)}
                                                            value={getValueManager(form, "image", null)}
                                                        ></FileInput>
                                                    </div>
                                                </div>
                                                {/* <br />
                                                <hr />
                                                <br />
                                                <EmailSecondaryForm
                                                    label="Adresse email Secondaire"
                                                    name="emailAddresses"
                                                    onChange={(v, n, index) =>
                                                        onChange(
                                                            v,
                                                            n,
                                                            index
                                                        )
                                                    }
                                                    form={form}
                                                >
                                                </EmailSecondaryForm> */}
                                                <div className="d-flex justify-content-around pt-5">
                                                    <button
                                                        className="my-4"
                                                        color="white"
                                                        type="button"
                                                        onClick={(e) => {
                                                            finalId
                                                                ? setOperation("SHOW")
                                                                : history.push("/admin/user-management");
                                                        }}
                                                    >
                                                        Annuler
                                                    </button>
                                                    <button
                                                        className="btn-1"
                                                        color="danger"
                                                        type="button"
                                                        disabled={!formValid || loading}
                                                        onClick={(e) => (id ? updateTable() : addNewTable())}
                                                    >
                                                        <i className="fas fa-plus mr-2"></i> {id ? "Modifier" : "Ajouter"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            </div>
        </div>
        </div>
    );
};
export default UtilisateurForm;
