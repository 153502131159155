import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";

import "./Inputs.css";
import SelectInput from "./SelectInput";
import SelectMultipleFull from "./SelectMultipleFull";

export const SelectInputResource = (props) => {
  const { GetResource } = useContext(SharedContext);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const onChange = (value) => {
  //   if (props.onChange) {
  //     if (props.multiple) {
  //       props.onChange(
  //         value.map((value2) => value2.value),
  //         props.name
  //       );
  //     } else {
  //       props.onChange(value.value, props.name);
  //     }
  //   }
  // };

  const onChange = (val, name) => {
    if (props.onChange) {
      props.onChange(val, name);
    }
  };

  useEffect(() => {
    if (props.resource && props.method) {
      setIsLoading(true);
      GetResource(props.resource, props.method, {}, null)
        .then((res) => {
            // let data = res.payload.filter(item => item.enabled)
            let data = res.payload
          setItems(
              data.map((o) => ({
                ...o,
                value: props.map.label ? o[props.map.select] : o,
                label: props.map.label ? o[props.map.label] : o,
              }))

          );
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      // setItems([]);
    }
  }, [props.resource, props.method]);

  // const finalValues =
  //   (props.value &&
  //     props.multiple &&
  //     items.filter((i) => props.value.find((ii) => i.value == ii))) ||
  //   (props.value && !props.multiple && items.find((i) => i.value == props.value)) || null;

  let itemsF = items;
  console.log("ITEM", itemsF);
  if (props.filter) {
    itemsF = items.filter(item => props.filter(item));
  }

  return (
    <div className="">
      {props.isLoading ? (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <>
          {props.tableStyle ? <SelectMultipleFull
            placeholder={props.placeHolder}
            label={props.label}
            value={props.value}
            name={props.name}
            data={{
              items: itemsF,
              label: "label",
              select: "value"
            }}
            onChange={onChange}
            multiple={props.multiple}
            disabled={props.disabled}
            error={props.error}
            search={props.search}
          >
          </SelectMultipleFull> :
            <SelectInput
              placeHolder={props.placeHolder}
              label={props.label}
              value={props.value}
              name={props.name}
              data={{
                items: itemsF,
                label: "label",
                select: "value"
              }}
              onChange={onChange}
              multiple={props.multiple}
              disabled={props.disabled}
              other={props.other}
              error={props.error}
              otherName={props.otherName}
              otherValue={props.otherValue}
              preview={props.preview}
              required={props.required}
            >
            </SelectInput>}
        </>
      )}
    </div>
  );
};

export default SelectInputResource;
