import React, { useContext, useEffect, useState } from "react";
import { useDrag } from 'react-dnd'


const QSQuestionCreatorCardItem = (props) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "CARD",
        item: { type: "CARD", question: props.question },
        collect: monitor => {
            return ({
                isDragging: monitor.isDragging(),
                type: "CARD"
            })
        },
        end: (draggedOitem) => {
            props.deleteHover();
        }
    }), [props.question.id])


    return (
        <div>
            <div
                ref={drag}
                className="qc-card-drag qc-card-item mgb-15"
                style={{
                    opacity: isDragging ? 0.5 : 1,
                    fontSize: 16,
                    fontWeight: 'bold',
                    cursor: 'move',
                }}
            >
                <i className="fas fa-grip-vertical myicon"></i>
                <div className="items-card">
                    <p className="code">{props.question && props.question.id}</p>
                    <p className="name" title={props.question && props.question.name}>{props.question && props.question.name}</p></div>
            </div>
        </div>
    )
}

export default QSQuestionCreatorCardItem;