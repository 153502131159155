import React, { useContext, useEffect, useState } from "react";

import SharedContext from "../../../contexts/shared/sharedContext";
import GlobalTable from "../../../core/common/table/GlobalTable";
import GlobalFilters from "../../../core/common/table/GlobalFilters";
import { USEFULL_VARIABLES } from "../../../variables/shared";
import { updateValueManager } from "../../../core/manager/ManagerUtils";

import TableReponsesForm from "./form/table-reponses-form";
import { replaceIndex } from "../../../core/manager/ManagerUtils";
import ItemMenu from "../../../core/common/table/ItemMenu";
import { LoggerSuccess } from "../../../core/utils/Logger";
import ConfirmationPopupShared from "../../../core/common/notifications/ConfirmationPopup";

const TABLE = [
  {
    label: "COMMON.DESIGNATION",
    value: "designation",
    order: true,
  },
  {
    label: "COMMON.CREATED_AT2",
    value: "createdAt",
    type: "DATE",
    order: true,
  },
  {
    label: "STD.ACTIONS",
    value: "actions",
    type: "RENDER",
    class: "table-actions",
    render: (props) => (
      <>
        <ItemMenu menus={[
          {
            name: props.getKeyword("STD_VERB.VIEW_DETAILS"),
            type: "REDIRECT",
            link: "/admin/table-reponses-detail/" + props.id
          },
          {
            name: props.getKeyword("STD_VERB.EDIT"),
            click: () => props.update(props.id)
          },
          {
            name: props.getKeyword("STD_VERB.DELETE"),
            click: () => props.delete(props.id)
          },
        ]}>

        </ItemMenu>
      </>
    ),
  },
];

const FILTERS = [
  {
    label: "COMMON.ID",
    type: "ID",
    value: "id",
    className: "col-md-3",
  },
  {
    label: "COMMON.DESIGNATION",
    type: "TEXT",
    value: "name",
    className: "col-md-3",
  },
  {
    type: "DATE_INTERVAL",
    labelFrom: "COMMON.FROM",
    valueFrom: "from",
    labelTo: "COMMON.TO",
    valueTo: "to",
    className: "col-md-6",
  },
];

const FILTER_INIT = {
  name: "",
  code: "",
  to: null,
  from: null,
};

const TableReponses = (props) => {
  const { GetResource, getKeyword } = useContext(SharedContext);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    size: USEFULL_VARIABLES.paginationSize,
    order: null,
    direction: "DESC",
  });
  const [formFilter, setFormFilter] = useState(
    JSON.parse(JSON.stringify(FILTER_INIT))
  );
  const [modalState, setModalState] = useState({});

  const toggleModal = (state) => {
    setModalState({ ...modalState, [state]: !modalState[state] });
  };

  const [alert, setalert] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [form, setForm] = useState({
    code: "",
    designation: "",
    description: "",
  });

  const [isFilter, setIsFilter] = useState(false);

  const successAlert = (message) => {
    LoggerSuccess(message);
  };

  useEffect(() => {
    paginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  }, []);

  const reload = () => {
    paginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const initForm = () => {
    setForm({
      code: "",
      designation: "",
      description: "",
    });
  };

  const beforeAdd = () => {
    initForm();
    toggleModal("formModal");
  };

  const onAdd = () => {
    reload();
    successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    initForm();
  };

  const onUpdate = () => {
    reload();
    successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    initForm();
  };

  const onChangeFilters = (v, n, indexes = null) => {
    const f = updateValueManager(formFilter, n, v, indexes);
    setFormFilter({ ...f });
  };

  const paginate = (page, size, order, direction) => {
    if (isFilter) {
      loadFilter(page, size, order, direction);
    } else {
      loadPaginate(page, size, order, direction);
    }
  };

  const filter = () => {
    pagination.page = 1;
    setIsFilter(true);
    loadFilter(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const loadPaginate = (page, size, order, direction) => {
    setLoaded(false);
    GetResource(
      "TABLE_RESPONSES",
      "GET_TABLE",
      {
        page,
        size,
        order,
        direction,
      },
      null
    ).then((res) => {
      setData(res.payload);
      setPagination({ ...pagination, page, size, order, direction });
      setLoaded(true);
    });
  };

  const loadFilter = (page, size, order, direction) => {
    setLoaded(false);
    GetResource(
      "TABLE_RESPONSES",
      "FILTER_TABLE",
      {
        ...formFilter,
        data: {
          page,
          size,
          order,
          direction,
        },
      },
      null
    ).then((res) => {
      setData(res.payload);
      setLoaded(true);
    });
  };

  const initializeFilter = () => {
    setFormFilter(JSON.parse(JSON.stringify(FILTER_INIT)));
    setIsFilter(false);
    loadPaginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const updateItem = (id) => {
    GetResource("TABLE_RESPONSES", "GET_TABLE_BY_ID", { id }, null).then(
      (res) => {
        setForm(res.payload);
        toggleModal("formModal");
      }
    );
  };

  const enableItem = (id) => {
    GetResource(
      "TABLE_RESPONSES",
      "ENABLE_TABLE",
      { id, enabled: true },
      null
    ).then((res) => {
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };

  const disableItem = (id) => {
    GetResource(
      "TABLE_RESPONSES",
      "ENABLE_TABLE",
      { id, enabled: false },
      null
    ).then((res) => {
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };

  const deleteItem = (itemId) => {
    setDeleteMessage({
      message: getKeyword("NOTIFICATION.DELETE_TEXT"),
      id: itemId,
    });
  };

  const confirmDelete = () => {
    GetResource(
      "TABLE_RESPONSES",
      "DELETE_TABLE",
      { id: deleteMessage.id },
      null
    ).then((res) => {
      setDeleteMessage(null);
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  }


  return (
    <div>
      {deleteMessage ? (
        <ConfirmationPopupShared
          message={deleteMessage.message}
          close={() => setDeleteMessage(null)}
          confirm={confirmDelete}
        ></ConfirmationPopupShared>
      ) : null}
      <GlobalFilters
        filters={FILTERS}
        form={formFilter}
        onChange={onChangeFilters}
        filter={filter}
        initialize={initializeFilter}
      ></GlobalFilters>
      <div className="card-block">
        <div className="card-header">
          <div>
            <h3 className="mb-0">{getKeyword("TABLE.RESPONSES_TABLE")}</h3>
          </div>
          <div>
            <button
              className="btn-1"
              id="tooltip969372949"
              onClick={(e) => toggleModal("formModal")}
              size="lg"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-plus" />
              </span>
              <span className="btn-inner--text">{getKeyword("STD.NEW")}</span>
            </button>
            {modalState["formModal"] ? (
              <TableReponsesForm
                modalName="formModal"
                toggleModal={toggleModal}
                showModal={modalState["formModal"]}
                onAdd={onAdd}
                form={form}
                updateForm={(form) => setForm(form)}
                onUpdate={onUpdate}
              ></TableReponsesForm>
            ) : null}
          </div>
        </div>
        {isLoaded ? (
          <GlobalTable
            table={TABLE}
            data={data.data}
            count={data.count}
            pagination={pagination}
            paginate={paginate}
            update={updateItem}
            enable={enableItem}
            disable={disableItem}
            delete={deleteItem}
          ></GlobalTable>
        ) : null}
      </div>
    </div>
  );
};

export default TableReponses;
