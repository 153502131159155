import {
  CHANGE_LANGUAGES,
  CHANGE_MENU_NAME,
  EMPTY_VARIABLES,
  SEARCH_PEOPLE,
  GET_MESSAGES,
  GET_MESSAGE_LIST,
  PROFIL_LOAD_SUCCESS,
  REFERENCE_DATA_SUCCESS,
  IMAGE_DATA_SUCCESS,
  SETTING_SUCCESS,
  CHANGE_QUIZ,
} from "./types";

export default (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_MENU_NAME:
      return {
        ...state,
        menuName: payload,
      };
    case CHANGE_LANGUAGES:
      return {
        ...state,
        keywords: payload,
      };
    case EMPTY_VARIABLES:
      return {
        ...state,
        [payload.name]: payload.value,
      };
    case PROFIL_LOAD_SUCCESS:
      return {
        ...state,
        profile: payload,
      };
    case REFERENCE_DATA_SUCCESS:
      state.referenceData = {
        ...state.referenceData,
        [payload.payload["code"]]: payload.payload,
      };
    case SETTING_SUCCESS:
      state.settingData = {
        ...state.settingData,
        [payload.body["value"]]: payload.payload,
      };
    case CHANGE_QUIZ:
      return {
        ...state,
        quiz: payload,
      };
    case IMAGE_DATA_SUCCESS:
      state.imageData = {
        ...state.imageData,
        [payload.payload["fileName"]]: payload.payload,
      };
      return {
        ...state,
      };
    default:
      return state;
  }
};
