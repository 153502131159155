import React, { useReducer } from "react";
import {
  CHANGE_LANGUAGES,
  CHANGE_MENU_NAME,
  EMPTY_VARIABLES,
  IMAGE_DATA_SUCCESS,
  PROFIL_LOAD_SUCCESS,
} from "./types";
import sharedReducer from "./sharedReducer";
import SharedContext from "./sharedContext";
import axios from "axios";
import Logger, {
  ManageErrors,
  ManageInternalErrors,
} from "../../core/utils/Logger";
import Keywords from "../../core/utils/Keywords.json";
import { getValueManager } from "../../core/manager/ManagerUtils";
import LINKS from "../../config";

const SharedState = (props) => {
  const initialState = {
    menuName: null,
    keywords: Keywords,
    profile: null,
    referenceData: {},
    settingData: {},
    sapiTypeData: {},
    imageData: {},
    quiz: [
      <p className="myallblockitem">Badr</p>,
      <p className="myallblockitem">Badr2</p>,
      <p className="myallblockitem">Badr3</p>,
      <p className="myallblockitem">Badr4</p>,
      <p className="myallblockitem">Badr5</p>
    ]
  };

  const [state, dispatch] = useReducer(sharedReducer, initialState);

  const getKeyword = (name) => {
    let t = (
      (state.keywords && getValueManager(state.keywords, name, [])) ||
      name
    );
    if (!t) {
      return t;
    }
    if (t.constructor.name === "Object") {
      t = name;
    }
    return t;
  };

  const changeMenuName = (name) => {
    dispatch({
      type: CHANGE_MENU_NAME,
      payload: name,
    });
  };

  //  Globals
  const GetResource = (resource, method, body, type) => {
    try {
      const resourceBody = {
        resource,
        method,
        body: body ? JSON.stringify(body) : "{}",
      };
      return new Promise(async (resolve, reject) => {
        await axios
          .post(process.env.APILINK || "/api/api", resourceBody)
          .then((response) => {
            if (type) {
              dispatch({
                type: type,
                payload: { payload: response.data, data: resourceBody, body: body },
              });
            }
            resolve({ payload: response.data, data: resourceBody, body: body });
          })
          .catch((error) => {
            ManageErrors(error.response, state.keywords);
            reject({
              data: (error.response && error.response.data) || {},
              status: error.response && error.response.status,
            });
          });
      });
    } catch (err) {
      ManageInternalErrors(err, state.keywords);
    }
  };


  const saveToState = (data, type) => {
    dispatch({
      type,
      payload: data,
    });
  };

  const GlobalPost = (formData, link, type) => {
    try {
      return new Promise(async (resolve, reject) => {
        await axios
          .post(link.startsWith("http") ? link : LINKS.apiUrlAPI + link, formData)
          .then((response) => {
            if (type) {
              dispatch({
                type: type,
                payload: { payload: response.data, data: formData },
              });
            }
            resolve({ payload: response.data, data: formData });
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              // Logger(error.response.data.message);
            }
            reject(error);
          });
      });
    } catch (err) {
      ManageInternalErrors(err, state.keywords);
    }
  };

  const GlobalPut = (formData, link, type) => {
    try {
      return new Promise(async (resolve, reject) => {
        await axios
          .put(link.startsWith("http") ? link : LINKS.apiUrlAPI + link, formData)
          .then((response) => {
            if (type) {
              dispatch({
                type: type,
                payload: { payload: response.data, data: formData },
              });
            }
            resolve({ payload: response.data, data: formData });
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              // Logger(error.response.data.message);
            }
            reject(error);
          });
      });
    } catch (err) {
      ManageInternalErrors(err, state.keywords);
    }
  };

  const GlobalGet = (data, link, type) => {
    try {
      return new Promise(async (resolve, reject) => {
        await axios
          .get(link.startsWith("http") ? link : LINKS.apiUrlAPI + link)
          .then((response) => {
            if (type) {
              dispatch({
                type: type,
                payload: { payload: response.data, data },
              });
            }
            resolve({ payload: response.data, data });
          })
          .catch((reason) => {
            ManageErrors(reason.response, state.keywords);
            reject(reason);
          });
      });
    } catch (err) {
      ManageInternalErrors(err, state.keywords);
    }
  };

  const GetImageInfo = (fileName) => {
    try {
      return new Promise(async (resolve, reject) => {
        await axios
          .get(LINKS.imageURLPrivate + "data/" + fileName)
          .then((response) => {
            dispatch({
              type: IMAGE_DATA_SUCCESS,
              payload: { payload: response.data, data: fileName },
            });
            resolve({ payload: response.data, data: fileName });
          })
          .catch((reason) => {
            // ManageErrors(reason.response, state.keywords);
            reject(reason);
          });
      });
    } catch (err) {
      ManageInternalErrors(err, state.keywords);
    }
  };


  
  const GetImageByte = (fileName) => {
    try {
      return new Promise(async (resolve, reject) => {
        await axios
          .get(LINKS.imageURLPrivate + "byte/" + fileName)
          .then((response) => {
            resolve({ payload: response.data, data: fileName });
          })
          .catch((reason) => {
            // ManageErrors(reason.response, state.keywords);
            reject(reason);
          });
      });
    } catch (err) {
      ManageInternalErrors(err, state.keywords);
    }
  };

  const GlobalDelete = (data, link, type) => {
    try {
      return new Promise(async (resolve, reject) => {
        await axios
          .delete(link.startsWith("http") ? link : LINKS.apiUrlAPI + link)
          .then((response) => {
            if (type) {
              dispatch({
                type: type,
                payload: { payload: response.data, data },
              });
            }
            resolve({ payload: response.data, data });
          })
          .catch((reason) => {
            ManageErrors(reason.response, state.keywords);
            reject(reason);
          });
      });
    } catch (err) {
      ManageInternalErrors(err, state.keywords);
    }
  };

  // Get Current Profil
  const getCurrentProfil = () => {
    try {
      return new Promise(async (resolve, reject) => {
        await axios
          .get(`${LINKS.apiUrlAPI}/auth/current`)
          .then((response) => {
            if (response.data) {
              dispatch({
                type: PROFIL_LOAD_SUCCESS,
                payload: response.data,
              });
              resolve(response.data);
            }
          })
          .catch((error) => {
            ManageErrors(error.response, state.keywords);
            reject(error);
          });
      });
    } catch (error) { }
  };

  const updateCurrentProfil = (data) => {
    // Todo
    return GlobalPut(data, "", PROFIL_LOAD_SUCCESS);
  };

  const getKeywords = (lng) => {
    try {
      dispatch({
        type: CHANGE_LANGUAGES,
        payload: initialState.keywords,
      });
      // return new Promise(async (resolve, reject) => {
      //   await axios
      //     .get(LINKS.apiUrlAPI + MSTRANSLATE_BACKEND_LINKS.keywordsList(lng))
      //     .then((response) => {
      //       if (response.data) {
      //         dispatch({
      //           type: CHANGE_LANGUAGES,
      //           payload: response.data.keywords,
      //         });
      //         resolve(response.data.keywords);
      //       }
      //     })
      //     .catch((reason) => {
      //       ManageErrors(reason.response, state.keywords);
      //       reject(reason);
      //     });
      // });
    } catch (err) {
      //   dispatch({
      //     type: ADD_INTERNAL_USER_FAIL,
      //     payload: getErrorMessage(err),
      //   });
    }
  };

  const changeKeywords = (keywords) => {
    dispatch({
      type: CHANGE_LANGUAGES,
      payload: keywords,
    });
  };

  //empty variables
  const emptyVariables = (payload) => {
    dispatch({
      type: EMPTY_VARIABLES,
      payload,
    });
  };

  // Upload new File
  const uploadNewFile = (data) => {
    try {
      return new Promise(async (resolve, reject) => {
        await axios
          .post(`${LINKS.apiUrlAPI}/files/service`, data)
          .then((response) => {
            if (response.data) {
              // dispatch({
              //   type: UPLOAD_USER_SUCCESS,
              //   payload: response.data,
              // });
              resolve(response.data);
            }
          })
          .catch((error) => {
            ManageErrors(error.response, state.keywords);
            reject(error);
          });
      });
    } catch (error) { }
  };

  //Upload multiple images
  const ImageSaver = (callback, loadingCallback, params, images) => {
    let count = Object.keys(images).length;
    let index = 0;
    let size = 0;
    let countSize = 0;
    let imageLoading = { size: 0, count: 0 };
    if (count === 0) {
      callback(params, images);
    } else {
      Object.keys(images).forEach((k) => {
        let countInt = images[k].files.length;
        let indexInt = 0;
        const resInt = [];
        if (countInt === 0) {
          index++;
          images[k].result = resInt;
          if (index === count) {
            callback(params, images);
          }
        } else {
          images[k].files.forEach((file) => {
            size++;
            loadingCallback(params, {
              loading: true,
              size,
              count: imageLoading.count,
            });
            let data = new FormData();
            data.append("file", file);
            data.append(
              "model",
              JSON.stringify({
                usedFor: images[k].usedFor || "IMAGE",
              })
            );
            uploadNewFile(data)
              .then((res) => {
                resInt.push(res.fileName);
              })
              .finally(() => {
                countSize++;
                loadingCallback(params, {
                  loading: true,
                  size,
                  count: countSize,
                });
                indexInt++;
                if (indexInt === countInt) {
                  index++;
                  images[k].result = resInt;
                }
                if (index === count) {
                  loadingCallback({ loading: false }, params);
                  callback(params, images);
                }
              });
          });
        }
      });
    }
  };

  return (
    <SharedContext.Provider
      value={{
        keywords: state.keywords,
        profile: state.profile,
        referenceData: state.referenceData,
        imageData: state.imageData,
        settingData: state.settingData,
        quiz: state.quiz,
        menuName: state.menuName,
        changeMenuName,
        GlobalGet,
        GlobalDelete,
        GlobalPost,
        GlobalPut,
        changeKeywords,
        getKeywords,
        getCurrentProfil,
        emptyVariables,
        getKeyword,
        uploadNewFile,
        updateCurrentProfil,
        ImageSaver,
        GetResource,
        GetImageInfo,
        saveToState,
        GetImageByte
      }}
    >
      {props.children}
    </SharedContext.Provider>
  );
};

export default SharedState;
