import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import AuthContext from "../../contexts/auth/authContext";
import Logger from "../../core/utils/Logger";
import { URL_BASE } from "../../config";
import { HOMEPAGE } from "../../config";
import queryString from "query-string";
import SharedContext from "../../contexts/shared/sharedContext";
import { SETTING_SUCCESS } from "../../contexts/shared/types";
import { validatePassword } from "../../core/utils/helpers";
import PasswordInput from "../../core/common/inputs/PasswordInput.js";

const ResetConfirm = (props) => {
  const history = useHistory();
  const { GlobalPost, GetResource, settingData, getKeyword } = useContext(SharedContext);
  const authContext = useContext(AuthContext);
  const { isAuth, login } = authContext;
  const [isLoading, setIsLoading] = useState(false);
  const [tokenError, setTokenError] = React.useState(0);
  const [done, setDone] = React.useState(false);
  const [searchParams] = useState(
    queryString.parse(props.location.search, { ignoreQueryPrefix: true })
  );
  const [errors, setErrors] = useState({
    confirmPassword: null,
    password: null,
    response: null,
    forceValidation: false,
  });
  const [formData, setFormData] = useState({
    confirmPassword: "",
    password: "",
  });
  useEffect(() => {
    if (isAuth) {
      history.push(URL_BASE + HOMEPAGE);
      Logger("LOGGEDIN_SUCCESS", true, "success");
    } else {
      GetResource(
        "TABLE_TECHNIQUE",
        "GET_TABLE_BY_CODE",
        { value: "PWD" },
        SETTING_SUCCESS
      ).then((res) => { });
    }

    GlobalPost(
      { ...formData, id: searchParams.id, token: searchParams.token },
      "/auth/reset/check",
      null
    )
      .then(() => setIsLoading(false))
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status == 403) {
            setTokenError(1);
          } else if (err.response.status == 408) {
            setTokenError(2);
          }
        }
      });
  }, [history, isAuth]);

  const { password, confirmPassword } = formData;

  const updateFormData = (v, n) => {
    const newForm = {
      ...formData,
      [n]: v,
    };
    setFormData(newForm);
    // if (errors.forceValidation) {
    validate(newForm, false);
    // }
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();

    if (!validate(formData, true)) {
      return;
    }

    setIsLoading(true);

    GlobalPost(
      { ...formData, id: searchParams.id, token: searchParams.token },
      "/auth/reset/validate",
      null
    )
      .then(() => setIsLoading(false))
      .then(() => {
        setDone(true);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 403 && err.response.data.message == "USER_DISABLED") {
            setErrors({
              forceValidation: errors.forceValidation,
              response: getKeyword("LOGIN.DEASACTIVATED_USER"),
            });
          } else if ((err.response.status == 403 && err.response.data.message == "USER_BLOCKED") || (err.response.data && err.response.data.detail == "USER_BLOCKED")) {
            setErrors({
              forceValidation: errors.forceValidation,
              response: getKeyword("LOGIN.PASSWORD_USED"),
            });
          } else if ((err.response.status == 403 && err.response.data.message == "PASSWORD_ALREADY_USED") || (err.response.data && err.response.data.detail == "PASSWORD_ALREADY_USED")
            || (err.status == 403 && err.data.message == "PASSWORD_ALREADY_USED") || (err.data && err.data.detail == "PASSWORD_ALREADY_USED")) {
            setErrors({
              forceValidation: errors.forceValidation,
              response: getKeyword("LOGIN.PASSWORD_USED"),
            });
          } else if (err.response.status == 403) {
            setTokenError(1);
          } else if (err.response.status == 408) {
            setTokenError(2);
          } else {
            setErrors({
              forceValidation: errors.forceValidation,
              response: getKeyword("LOGIN.INVALID_TOKEN"),
            });
          }
        } else {
          setErrors({
            forceValidation: errors.forceValidation,
            response: getKeyword("LOGIN.INVALID_TOKEN"),
          });
        }
        setIsLoading(false);
      });
  };

  const validate = (newForm, forceValidation) => {
    if (newForm.password === "") {
      setErrors({
        forceValidation: forceValidation ? true : errors.forceValidation,
        confirmPassword: newForm.confirmPassword
          ? null
          : "Confirmation de mot de passe requis",
        password: newForm.password ? null : "Mot de passe requis",
      });
      return false;
    }

    if (
      settingData &&
      settingData["PWD"]
    ) {
      var resV = validatePassword(settingData["PWD"], newForm.password);
      console.log(settingData, resV);
      if (resV && resV.error) {
        var html = <ul>
          {resV.items.map(item => (
            <li style={{ color: item.valid ? "green" : "red" }}>{item.msg}</li>
          ))}
        </ul>
        setErrors({
          forceValidation: forceValidation ? true : errors.forceValidation,
          password:
            html,
        });
        return false;
      }

    }

    if (newForm.confirmPassword === "") {
      setErrors({
        forceValidation: forceValidation ? true : errors.forceValidation,
        confirmPassword: newForm.confirmPassword
          ? null
          : "Confirmation de mot de passe requis",
        password: newForm.password ? null : "Mot de passe requis",
      });
      return false;
    }

    if (newForm.confirmPassword != newForm.password) {
      setErrors({
        forceValidation: forceValidation ? true : errors.forceValidation,
        confirmPassword: "Les deux mot de passe ne sont pas identiques",
      });
      return false;
    }
    setErrors({
      forceValidation: forceValidation ? true : errors.forceValidation,
    });
    return true;
  };

  return (
    <>
      {isLoading ? null :
        <> {tokenError != 0 ?
          <div className="mt--8 pb-5 mb-3 form-login">
            <div className="text-center">
              <div className="card p-3"><h2> <i className="fas fa-check mr-3"></i>{tokenError == 1 ? getKeyword("LOGIN.INVALID_TOKEN") : getKeyword("LOGIN.INVALID_TOKEN_DATE")}</h2></div>
              <a className=" my-4 rounded-pill font-20 btn btn-danger " href="/auth/reset"> {getKeyword("LOGIN.FORGET_PASSWORD")} </a>
            </div> </div> :
          <div className="mt--8 pb-5 mb-3 form-login">
            {done ? (
              <div className="text-center">
                <div className="card p-3"><h2> <i className="fas fa-check mr-3"></i> Mot de passe réinitialisé avec succès</h2></div>
                <a className=" my-4 rounded-pill font-20 btn btn-danger " href="/auth/login"> Retour à la connexion </a>
              </div>
            ) : (
              <div className="bg-image">
                <div className="">
                  <div className="bg-secondary border-0 mb-0">
                    <div className="px-lg-5 py-lg-5">
                      <div className=" text-muted mb-4">
                        <h1 className="font-25 text-uppercase text-center">
                          Récupération  de mot de passe
                        </h1>
                        <h2 className="font-17 text-center">Veuillez compléter le formulaire suivant
                          pour changer  votre compte.</h2>
                      </div>
                      <form role="form" onSubmit={mySubmitHandler}>
                        <PasswordInput
                          label="Nouveau mot de passe"
                          placeholder=""
                          name="password"
                          onChange={updateFormData}
                          value={password}
                          error={errors.password}
                        />
                        <PasswordInput
                          label="Confirmer le nouveau mot de passe"
                          placeholder=""
                          name="confirmPassword"
                          onChange={updateFormData}
                          value={confirmPassword}
                          error={errors.confirmPassword}
                        />
                        {!errors.response ? null : (
                          <p className="text-danger mb-0 errorInput">{errors.response}</p>
                        )}
                        <div className="row justify-content-between align-items-center">
                          <div className="">
                            <a className="text-light" href="/auth/login">
                              <small className=" text_dark border-bottom border-dark">
                                Retour à la connexion
                              </small>
                            </a>
                          </div>
                          <div className="">
                            <button
                              className="my-4 rounded-pill font-20"
                              color="danger"
                              type="submit"
                            >
                              Réinitialiser
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        }</>
      }
    </>
  );
};

export default ResetConfirm;
