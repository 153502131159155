import SharedContext from "../../../contexts/shared/sharedContext";
import React, { useContext, useEffect, useState } from "react";
import SelectInput from "./SelectInput";

export const SelectInputTableTechnique = (props) => {
  const { GetResource } = useContext(SharedContext);
  const [items, setItems] = useState([]);

  const onChange = (val, name) => {
    if (props.onChange) {
      props.onChange(val, name);
    }
  };

  useEffect(() => {
    if (props.code) {
      GetResource(
        "TABLE_TECHNIQUE",
        "GET_ALL_LINE_CODE",
        { value: props.code },
        null
      ).then((res) => {
        if (props.sort) {
          setItems(res.payload.sort(props.sort));
        } else {
          setItems(res.payload);
        }

      });
    } else {
      setItems([]);
    }
  }, [props.code]);



  let itemsF = [...items];
  if (props.filter) {
    itemsF = items.filter(item => props.filter(item));
  }


  return (
    <div className="">
      {props.isLoading ? (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <SelectInput
          placeholder={props.placeHolder}
          label={props.label}
          name={props.name}
          value={props.value}
          data={{
            items: itemsF,
            label: "designation",
            select: "code"
          }}
          onChange={onChange}
          multiple={props.multiple}
          disabled={props.disabled}
          error={props.error}
          other={props.other}
          required={props.required}
          otherName={props.otherName}
          otherValue={props.otherValue}
          preview={props.preview}

        >
        </SelectInput>
      )}
    </div>
  );
};

export default SelectInputTableTechnique;
