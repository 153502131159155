import React, { useContext } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { replaceIndex } from "../../manager/ManagerUtils";
import { dateToString } from "../../utils/helpers";
import TooltipProvider from "../outputs/TooltipProvider";
import moment from "moment";
import "./Inputs.css";

export const DateInput = (props) => {
  const sharedContext = useContext(SharedContext);
  const { getKeyword } = sharedContext;

  const onChange = (event) => {
    if (props.onChange) {
      props.onChange(event.target.value, props.name);
    }
  };

  return (
    <div className="TextInput InputField">
      {!props.label ? null : (
        <label className="Ilabel">
          {replaceIndex(
            getKeyword(props.label),
            props.lableIndexesNames,
            props.lableIndexes
          )}&nbsp;
          {props.optText ? (
            <span>
              {props.optText !== true ? props.optText : "(Optionnel)"}
            </span>
          ) : (
            ""
          )}
          <span className="requiredLb">{props.required ? " *" : ""}</span>
          <TooltipProvider tooltip={props.description}></TooltipProvider>
        </label>
      )}
      <div className="input">
        {props.preview ? (
          <p className="input-response">{dateToString(props.value)}</p>
        ) : (
          <input
          className={`${props.format?"my-input-date":""}`}
            type="date"
            value={props.value}
            onChange={onChange}
            data-date={
              props.format
                ? moment(props.value? props.value : moment(), "YYYY-MM-DD").format(props.format)
                : props.value
            }
            placeholder={
              props.placeHolder
                ? getKeyword(props.placeHolder)
                : getKeyword(props.label)
            }
          ></input>
        )}
      </div>
    </div>
  );
};

export default DateInput;
