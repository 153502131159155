import { Menu, MenuItem } from '@material-ui/core';
import * as React from 'react';
import { Redirect, useHistory } from 'react-router-dom';

export const ItemMenu = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const redirect = (link) => {
        history.push(link);
    }

    return (
        <div>
            {props.menus && props.menus.filter(f => !f.hide).length > 0 ?
                <>
                    <button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {props.menus && props.menus.filter(f => !f.hide).map((m, index) =>
                            <MenuItem key={"menus" + index} onClick={() => { if(m.type == "REDIRECT"){redirect(m.link)} else { m.click(m.props); handleClose(); }}}>{m.name}</MenuItem>
                        )}
                    </Menu>
                </> : null}
        </div>
    );
}

export default ItemMenu;