import React, { useState } from "react";
import { getValueManager, updateValueManager } from "../../manager/ManagerUtils";
import "./Inputs.css";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";

export default function ConditionsInput(props) {
    const criterias = props.value;
    const operator_options = [
        { value: "==", label: "égale à" },
        { value: ">", label: "supérieur à" },
        { value: ">=", label: "supérieur ou égale à" },
        { value: "<", label: "inférieur à" },
        { value: "<=", label: "inférieur ou égale à" },
        { value: "!=", label: "différent de " },
    ];
    const compareType_options = [
        { value: "F-X", label: "fixe" },
        ...props.questions.map((q) => ({ value: q, label: `${q}` })),
    ];
    const valueToCompare_options = [
        // { value: "length", label: "longueur" },
        { value: "value", label: "valeur" },
    ];
    const onValueChange = (value, property, i, j) => {
        const newCriterias = updateValueManager(criterias, "?0.?1." + property, value, [i, j]);
        updateCriteria(newCriterias);
    };
    const updateCriteria = (newCriterias) => {
        props.onChange(newCriterias, props.name);
    }
    const addCondition = (i) => {
        const newCriterias = criterias.map((criteriasArray, j) =>
            i === j
                ? [
                    ...criteriasArray,
                    {
                    },
                ]
                : criteriasArray
        );
        updateCriteria(newCriterias);
    };

    return (
        <div>
            <h4>{props.title} </h4>
            {criterias.length === 0 && (
                <h3 className="text-center">merci d'ajouter des conditions ...</h3>
            )}
            {criterias.map((criteriasArray, i) => (
                <div>
                    <div className="criterea-block">
                        {criteriasArray.map((criteria, j) => (
                            <>
                                <div className="criterea">
                                    <div className=" d-flex justify-content-between  align-items-start">
                                        <div className=" col-2">
                                            <SelectInput
                                                name="question"
                                                value={getValueManager(props.value, "?0.?1.question", [i, j])}
                                                onChange={(value, name) =>
                                                    onValueChange(value, name, i, j)
                                                }
                                                data={{
                                                    items: props.questions.map((question) => ({
                                                        value: question,
                                                        label: question,
                                                    })),
                                                    label: "label",
                                                    select: "value"
                                                }}
                                            ></SelectInput>
                                        </div>
                                        <div className=" col-3">
                                            <SelectInput
                                                name="valueToCompare"
                                                value={getValueManager(props.value, "?0.?1.valueToCompare", [i, j])}
                                                onChange={(value, name) =>
                                                    onValueChange(value, name, i, j)
                                                }
                                                data={{
                                                    items: valueToCompare_options,
                                                    label: "label",
                                                    select: "value"
                                                }}
                                            ></SelectInput>
                                        </div>
                                        <div className=" col-3">
                                            <SelectInput
                                                name="operator"
                                                value={getValueManager(props.value, "?0.?1.operator", [i, j])}
                                                onChange={(value, name) =>
                                                    onValueChange(value, name, i, j)
                                                }
                                                data={{
                                                    items: operator_options,
                                                    label: "label",
                                                    select: "value"
                                                }}
                                            />
                                        </div>
                                        <div className=" col-3">
                                            <div className="d-flex flex-column">
                                                <SelectInput
                                                    data={{
                                                        items: compareType_options,
                                                        label: "label",
                                                        select: "value"
                                                    }}
                                                    onChange={(value, name) =>
                                                        onValueChange(value, name, i, j)
                                                    }
                                                    name="compareType"
                                                    value={getValueManager(props.value, "?0.?1.compareType", [i, j])}
                                                />

                                                <br />
                                                {getValueManager(props.value, "?0.?1.compareType", [i, j]) == "F-X" ?
                                                    <TextInput
                                                        type="text"
                                                        value={criteria.value}
                                                        name="value"
                                                        onChange={(value, n) =>
                                                            onValueChange(value, "value", i, j)
                                                        }
                                                    /> : null
                                                }
                                            </div>
                                        </div>
                                        <button
                                            className="delete"
                                            color="danger"
                                            type="button"
                                            size="sm"
                                            onClick={() =>
                                                updateCriteria([
                                                    ...criterias.slice(0, i),
                                                    ...criterias.slice(i + 1),
                                                ])
                                            }
                                        >
                                            supprimer
                                        </button>
                                    </div>
                                </div>
                                {j < criteriasArray.length - 1 && (
                                    <div className=" d-flex justify-content-center p-2">ET</div>
                                )}
                            </>
                        ))}
                        <div className=" d-flex justify-content-center  align-items-center">
                            <button
                                className="my-4"
                                color="primary"
                                type="button"
                                onClick={() => addCondition(i)}
                            >
                                Ajouter condition
                            </button>
                        </div>
                    </div>
                    {i < criterias.length - 1 && (
                        <div className=" d-flex justify-content-center p-2">OU</div>
                    )}
                </div>
            ))}
            <div className=" d-flex justify-content-center  align-items-center">
                <button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={() => {
                        updateCriteria([...criterias, [{}]]);
                    }}
                >
                    Ajouter bloc
                </button>
            </div>
        </div>
    );
}
