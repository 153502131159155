export const OprationTypes = {
  LIST: "LIST",
  SEARCH: "SEARCH",
  ADD: "ADD",
  EDIT: "EDIT",
  DELETE: "DELETE",
};

export const InputOprationTypes = {
  TEXT: "text",
  AREA: "area",
  SELECT: "select",
  DEPENDENT: "dependant",
  DEPENDENT_MULTIPLE: "dependant_multiple",
  EDITOR: "editor",
  ARRAY: "array",
  ARRAYTABLE: "arraytable",
  IMAGE: "image",
  FILES: "files",
  PASSWORD: "password",
  NUMBER: "number",
  BOOLEAN: "boolean",
  DATE: "Date",
  COLOR: "Color",
  MAP: "MAP",
  FLAG: "FLAG",
  LINK: "LINK",
  FUNCTION: "FUNCTION",
};

export const ManagerEntityActionsEnum = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  IS_USED: "IS_USED",
  SELECTED: "SELECTED",
  ENABLE: "ENABLE",
  DRAFT: "DRAFT",
  ACTIVATED: "ACTIVATED",
  REJECT: "REJECT",
  CUSTOM: "CUSTOM",
  DEFAULT: null,
};

export const ManagerEntityActionsImageEnum = {
  EDIT: "/imgs/icons/edit.png",
  DELETE: "/imgs/icons/delete.png",
  IS_USED: { icon: "fas fa-check", iconEmpty: "far fa-check-square" },
  SELECTED: { icon: "fas fa-check", iconEmpty: "far fa-check-square" },
  ENABLE: { icon: "fas fa-check", iconEmpty: "far fa-check-square" },
  CUSTOM: "CUSTOM",
  REJECT: "REJECT",
  DEFAULT: null,
};

export const OperationType = {
  EQUAL: "EQUAL",
  LIKE: "LIKE",
  GREATER: "GREATER",
  LESS: "LESS",
  LESSOREQUAL: "LESSOREQUAL",
  GREATEROREQUAL: "GREATEROREQUAL",
  BETWEEN: "BETWEEN",
  DEFAULT: "DEFAULT",
};

export const ManagerClassNames = {
  FLEX_300PX: "flex300px",
  FLEX_100: "flex100",
  FLEX_50: "flex50",
  FLEX_33: "flex33",
  FLEX_25: "flex25",
  FLEX_20: "flex20",
  FLEX_REST: "flexrest",
  FLEX_WRAP: "flexwrap",
  PADLR_15: "padlr15",
  FLEX_START: "flexstart",
  FLEX_SPACEBETWEEN: "flexspacebetween",
};

export const ValidationEnum = {
  REQUIRED: "required",
  PATTERN: "pattern",
  MIN_LENGTH: "minlength",
  MAX_LENGTH: "maxlength",
  UNIQUE: "unique",
};

export const ValidationErrorsEnum = {
  REQUIRED: "REQUIRED",
  PATTERN: "PATTERN",
  MIN_LENGTH: "MIN_LENGTH",
  MAX_LENGTH: "MAX_LENGTH",
  INVALID: "INVALID",
  UNIQUE: "UNIQUE",
};
