import { getValueManager } from "../manager/ManagerUtils";
import LINKS from "../../config";
import { toast } from "react-toastify";

export const LoggerSuccess = (key, KEYWORDS = null) => {
  return Logger(key, KEYWORDS, "success");
}

export const Logger = (key, KEYWORDS = null, type = "error") => {
  if (type === "success") {
    if (KEYWORDS) {
      toast.success(getValueManager(KEYWORDS, key) || "_" + key + "_", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000,
        style: {
          background: "#245248",
        }
      });
    } else {
      toast.success(key, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000
      });
    }
  } else if (type === "error") {
    if (KEYWORDS) {
      const mes = getValueManager(KEYWORDS, key) || "_" + key + "_";
      toast.error(mes, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000
      });
    } else {
      toast.error(key, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 10000
      });
    }
  }
};

export const ManageErrors = (error, KEYWORDS) => {
  // error.response.data.message
  console.log("error: ", error);
  if (error && (error.status === 401)) {
    Logger("LOGIN.LOGIN_REQUIRED", KEYWORDS);
    if (window.location.pathname.indexOf("login") === -1 && window.location.pathname.indexOf("auth") === -1) {
      window.location.href = LINKS.login(window.location.pathname);
    }
  }
      else if (error && error.status === 500) {
        // Logger("RECORD_ALREADY_EXISTS", KEYWORDS);
          toast.error(error.data.detail  );
      }
  else if (error && error.status === 409 && KEYWORDS) {
    Logger("RECORD_ALREADY_EXISTS", KEYWORDS);
  }
  // else if (error && error.data && error.data.message) {
  //  Logger(error.data.message, error.data.detail);
  // }
  // else {
  //   Logger("INTERNAL_ERROR_OCCURED", {
  //     INTERNAL_ERROR_OCCURED: "An error occured, please refresh the page",
  //   });
  // }
};

export const ManageInternalErrors = (error, KEYWORDS) => {
  // error.response.data.message
  Logger("INTERNAL_ERROR_OCCURED", KEYWORDS);
};

export default Logger;
