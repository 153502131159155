import React, { useContext } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { replaceIndex } from "../../manager/ManagerUtils";
import CheckboxStyle from "./Checkbox";
import CheckboxInput from "./CheckboxInput";

import "./Inputs.css";

export const GroupInput = (props) => {
  const onChange = (value) => {
    if (props.onChange) {
      props.onChange(value, props.name);
    }
  };

  const changeValue = (value, permission) => {
    let res = props.value;
    if (!res) {
      res = [];
    }
    if (value) {
      res.push(permission.id);
    } else {
      res = res.filter((v) => v != permission.id);
    }
    var res2 = [];
    res.forEach((r) => {
      if (!res2.find((rr) => (rr == r))) {
        res2.push(r);
      }
    });
    onChange(res2);
  };

  const values = {};

  props.items.forEach((g) =>
    g.items.forEach((item) => {
      let perm = props.value && props.value.find((p) => p == item.id);
      if (perm) {
        values[item.id] = true;
        return true;
      }
    })
  );
// =========== 

  return (
    <div className="GroupInput InputField">
      {!props.label ? null : (
        <label className="Ilabel">
          {replaceIndex(
            props.label,
            props.lableIndexesNames,
            props.lableIndexes
          )}&nbsp;
          <span className="requiredLb">{props.required ? " *" : ""}</span>
        </label>
      )}
      {props.items.map((g) => (
        <div className="card">
          <div className="mb-20">
            <p className="title">{g.group}</p>
            <div className="row">
              {g.items.map((m) => (
                <div className="col-6">
                  <CheckboxStyle
                    label={m.description || m.permission}
                    name={"GroupInput-" + props.name + "-" + m.id}
                    onChange={(e) => changeValue(e, m)}
                    value={values[m.id] ? true : false}
                  ></CheckboxStyle>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      {props.error ? (
        <p className="errorInput">
          <i className="fas fa-exclamation-triangle"></i> {props.error}
        </p>
      ) : null}
    </div>
  );
};

export default GroupInput;
