import React, { useContext, useEffect, useState } from "react";

import SharedContext from "../../contexts/shared/sharedContext";
import GlobalTable from "../../core/common/table/GlobalTable";
import GlobalFilters from "../../core/common/table/GlobalFilters";
import { USEFULL_VARIABLES } from "../../variables/shared";
import { getValueManager, updateValueManager } from "../../core/manager/ManagerUtils";
import { useHistory } from "react-router";
import ConfirmationPopupShared from "../../core/common/notifications/ConfirmationPopup";
import { replaceIndex } from "../../core/manager/ManagerUtils";
import CLCatalogShow from "./show/catalog-show";
import CLCatalogForm from "./form/catalog-form";
import { LoggerSuccess } from "../../core/utils/Logger";
import ItemMenu from "../../core/common/table/ItemMenu";
import CLCatalogFormShare from "./form/catalog-form-share";

const TABLE = [
    {
        label: "COMMON.ID",
        value: "id",
        order: true,
        type: "CLICK",
        call: "show",

    },
    {
        label: "COMMON.DESIGNATION",
        value: "name",
    },
    {
        label: "COMMON.DESCRIPTION",
        value: "description",
        truncate: true
    },
    {
        label: "Type réponse",
        value: "type",
        type: "TEC",
    }, {
        label: "Répétiton",
        value: "repeat",
        type: "TEC",
    },
    {
        label: "COMMON.STATUS",
        value: "enabled",
        type: "BOOLEAN_COLOR",
        class: "",
        data: {
            active: "Actif",
            notActive: "Inactif"
        }
    },
    {
        label: "STD.ACTIONS",
        value: "actions",
        type: "RENDER",
        class: "table-actions",
        render: (props) => (
            <>
                <ItemMenu menus={[
                    {
                        name: props.getKeyword("STD_VERB.VIEW_DETAILS"),
                        click: () => props.show(props.id, true)
                    },
                    {
                        name: props.getKeyword("STD_VERB.EDIT"),
                        click: () => props.update(props.id, false)
                    },
                    {
                        name: props.getKeyword("STD_VERB.DISABLE"),
                        click: () => props.disable(props.id),
                        hide: !props.item.enabled
                    },
                    {
                        name: props.getKeyword("STD_VERB.ENABLE"),
                        click: () => props.enable(props.id),
                        hide: props.item.enabled
                    },
                    {
                        name: props.getKeyword("STD_VERB.DELETE"),
                        click: () => props.delete(props.id)
                    },
                    {
                        name: props.getKeyword("STD_VERB.SHARE"),
                        click: () => props.share(props.id),
                        hide: getValueManager(props.item, "type") != "PROCESS_COLLECT_AFFILIATE" && getValueManager(props.item, "type") != "PROCESS_COLLECT_STARTUP"
                    }
                ]}>

                </ItemMenu>

            </>
        ),
    },
];

const FILTERS = [
    {
        label: "COMMON.ID",
        type: "ID",
        value: "id",
        className: "col-md-3",
    },
    {
        label: "COMMON.DESIGNATION",
        type: "TEXT",
        value: "name",
        className: "col-md-3",
    },
    {
        label: "Questionnaire",
        value: "formId",
        className: "col-md-3",
        type: "RESOURCE",
        data: {
            resource: {
                resource: "QUESTION_MASTER_MANAGEMENT",
                method: "GET_ALL",
                body: {},
            },
            items: [],
            select: "id",
            label: "name",
        },
    },
    {
        label: "COMMON.STATUS",
        type: "LIST",
        value: "enabled",
        className: "col-md-3",
        data: {
            items: [
                {
                    name: "Actif",
                    status: true
                },
                {
                    name: "Inactif",
                    status: false
                }
            ],
            label: "name",
            select: "status"
        }
    },
];

const FILTER_INIT = {
    name: "",
    code: "",
    user: "",
    from: null,
};

const CLCatalog = (props) => {
    const history = useHistory();
    const { GetResource, getKeyword } = useContext(SharedContext);
    const [data, setData] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        size: USEFULL_VARIABLES.paginationSize,
        order: null,
        direction: "DESC",
    });
    const [formFilter, setFormFilter] = useState(
        JSON.parse(JSON.stringify(FILTER_INIT))
    );
    const [modalState, setModalState] = useState({});

    const [deleteMessage, setDeleteMessage] = useState(null);

    const toggleModal = (state) => {
        modalState[state] = !modalState[state];
        setModalState({ ...modalState });
    };

    const [alert, setalert] = React.useState(false);
    const [form, setForm] = useState({
        code: "",
        name: "",
        description: "",
        type: null,
    });

    const [shareForm, setShareForm] = useState(null);

    const [isFilter, setIsFilter] = useState(false);

    useEffect(() => {
        paginate(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    }, []);

    const reload = () => {
        paginate(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    };

    const initForm = () => {
        setForm({
            code: "",
            name: "",
            description: "",
            permission: [],
        });
    };

    const beforeAdd = () => {
        initForm();
        toggleModal("formModal");
    };

    const onAdd = () => {
        reload();
        setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        initForm();
    };

    const setAlert = (message) => {
        LoggerSuccess(message);
    };

    const onUpdate = () => {
        reload();
        setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        initForm();
    };

    const onChangeFilters = (v, n, indexes = null) => {
        const f = updateValueManager(formFilter, n, v, indexes);
        setFormFilter({ ...f });
    };

    const paginate = (page, size, order, direction) => {
        if (isFilter) {
            loadFilter(page, size, order, direction);
        } else {
            loadPaginate(page, size, order, direction);
        }
    };

    const filter = () => {
        pagination.page = 1;
        setIsFilter(true);
        loadFilter(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    };

    const loadPaginate = (page, size, order, direction) => {
        setLoaded(false);
        GetResource(
            "CATALOG_MANAGEMENT",
            "GET",
            {
                page,
                size,
                order,
                direction,
            },
            null
        ).then((res) => {
            setData(res.payload);
            setPagination({ ...pagination, page, size, order, direction });
            setLoaded(true);
        });
    };

    const loadFilter = (page, size, order, direction) => {
        setLoaded(false);
        GetResource(
            "CATALOG_MANAGEMENT",
            "FILTER",
            {
                ...formFilter,
                data: {
                    page,
                    size,
                    order,
                    direction,
                },
            },
            null
        ).then((res) => {
            setData(res.payload);
            setLoaded(true);
        });
    };

    const initializeFilter = () => {
        setFormFilter(JSON.parse(JSON.stringify(FILTER_INIT)));
        setIsFilter(false);
        loadPaginate(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    };

    const updateItem = (itemId) => {
        GetResource("CATALOG_MANAGEMENT", "GET_ID", { id: itemId }, null).then(
            (res) => {
                setForm({ ...res.payload });
                toggleModal("formModal");
            }
        );
    };

    const shareItem = (itemId) => {
        console.log("shareItem", itemId)
        GetResource("CATALOG_MANAGEMENT", "GET_ID", { id: itemId }, null).then(
            (res) => {
                setShareForm({ ...res.payload });
                toggleModal("shareModal");
            }
        );
    };

    const showItem = (itemId) => {
        GetResource("CATALOG_MANAGEMENT", "GET_ID", { id: itemId }, null).then(
            (res) => {
                setForm({ ...res.payload });
                toggleModal("showModal");
            }
        );
    };

    const deleteItem = (itemId) => {
        setDeleteMessage({
            message: getKeyword("NOTIFICATION.DELETE_TEXT"),
            id: itemId,
        });
    };

    const confirmDelete = () => {
        GetResource(
            "CATALOG_MANAGEMENT",
            "DELETE",
            { id: deleteMessage.id },
            null
        ).then((res) => {
            setDeleteMessage(null);
            reload();
            setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        });
    };

    const enableItem = (id) => {
        GetResource("CATALOG_MANAGEMENT", "ENABLE", { id, enabled: true }, null).then(
            (res) => {
                reload();
                setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
            }
        );
    };

    const disableItem = (id) => {
        GetResource("CATALOG_MANAGEMENT", "DISABLE", { id, enabled: false }, null).then(
            (res) => {
                reload();
                setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
            }
        );
    };


    return (
        <>
            {deleteMessage ? (
                <ConfirmationPopupShared
                    message={deleteMessage.message}
                    close={() => setDeleteMessage(null)}
                    confirm={confirmDelete}
                ></ConfirmationPopupShared>
            ) : null}
            <div>
                <GlobalFilters
                    filters={FILTERS}
                    form={formFilter}
                    onChange={onChangeFilters}
                    filter={filter}
                    initialize={initializeFilter}
                ></GlobalFilters>
                <div className="card-block">
                    <div className="card-header">
                        <div>
                            <h3 className="mb-0">Questionnaires</h3>
                        </div>
                        <div>
                            <button
                                className="btn-1"
                                id="tooltip969372949"
                                onClick={(e) => beforeAdd()}
                                size="lg"
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-plus" />
                                </span>
                                <span className="btn-inner--text">{getKeyword("STD.NEW")}</span>
                            </button>
                            {modalState["formModal"] ? (
                                <CLCatalogForm
                                    modalName="formModal"
                                    toggleModal={toggleModal}
                                    showModal={modalState["formModal"]}
                                    onAdd={onAdd}
                                    form={form}
                                    updateForm={(form) => setForm(form)}
                                    onUpdate={onUpdate}
                                ></CLCatalogForm>
                            ) : null}
                            {modalState["showModal"] ? (
                                <CLCatalogShow
                                    modalName="showModal"
                                    toggleModal={toggleModal}
                                    showModal={modalState["showModal"]}
                                    form={form}
                                    update={updateItem}
                                    enable={enableItem}
                                    disable={disableItem}
                                ></CLCatalogShow>
                            ) : null}
                            {modalState["shareModal"] ? (
                                <CLCatalogFormShare
                                    modalName="shareModal"
                                    toggleModal={toggleModal}
                                    showModal={modalState["shareModal"]}
                                    onAdd={onAdd}
                                    form={shareForm}
                                    updateForm={(form) => setShareForm(form)}
                                    onUpdate={onUpdate}
                                ></CLCatalogFormShare>
                            ) : null}
                        </div>
                    </div>
                    {isLoaded ? (
                        <GlobalTable
                            table={TABLE}
                            data={data.data}
                            count={data.count}
                            pagination={pagination}
                            paginate={paginate}
                            update={updateItem}
                            enable={enableItem}
                            disable={disableItem}
                            delete={deleteItem}
                            show={showItem}
                            share={shareItem}
                        ></GlobalTable>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default CLCatalog;
