import React, { useContext } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import TooltipProvider from "../outputs/TooltipProvider";

import "./Inputs.css";

export const NumberInput = (props) => {
  const sharedContext = useContext(SharedContext);
  const { getKeyword } = sharedContext;

  const onChange = (event) => {
    if (props.onChange) {
      var newVal = event.target.value;
      if (props.isInt) {
        if (newVal.endsWith(".")) {
          newVal = newVal.replace(/[^0-9]/gmi, "");
        }
      } else {
        newVal = newVal.replace(/[^0-9\.]/gmi, "");
      }
      let val = newVal;
      if (!val && val !== "0" && val !== 0) {
        props.onChange(null, props.name);
      } else {
        let add = "";
        let value = val;
        if (props.isInt) {
          value = Math.floor(parseFloat(val));
          if (props.isPositive) {
            value = Math.abs(value);
          }
        } else {

        }
        props.onChange(value + "", props.name);
      }

    }
  };

  return (
    <div className="TextInput InputField">
      {props.label ?
        <label className="Ilabel">
          {getKeyword(props.label)}&nbsp;
          {props.optText ? (
            <span>{props.optText !== true ? props.optText : "(Optionnel)"}</span>
          ) : (
            ""
          )}
          <span className="requiredLb">{props.required ? " *" : ""}</span>
          <TooltipProvider tooltip={props.description}></TooltipProvider>
        </label> : null}
      <div className="input">
        {props.preview ?
          <p className="input-response">{props.value}</p>
          :
          <input
            type="text"
            value={props.value}
            onChange={onChange}
            placeholder={
              props.placeHolder
                ? getKeyword(props.placeHolder)
                : getKeyword(props.label)
            }
          ></input>}
      </div>
      {props.subText ? (
        <p className="subText">{props.subText ? props.subText : ""}</p>
      ) : (
        ""
      )}
      {props.error ? (
        <p className="errorInput">
          <i className="fas fa-exclamation-triangle"></i> {props.error}
        </p>
      ) : null}
    </div>
  );
};

export default NumberInput;
