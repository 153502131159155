import React, { useContext } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import TooltipProvider from "../outputs/TooltipProvider";

export const CheckboxStyle = (props) => {
  const sharedContext = useContext(SharedContext);
  const { getKeyword } = sharedContext;

  const onChange = (event) => {
    if (props.onChange) {
      props.onChange((props.always && true) || event.target.checked, props.name);
    }
  };

  return (
    <div className={`CheckboxStyle InputField ${props.classes ? props.classes : ""}`}>
      <label className="ft">
        <input
          type="checkbox"
          onChange={onChange}
          checked={props.value}
        ></input>
        <p className="check">
          <span className="rd"></span>
          <span className="text">
            {props.value ? getKeyword("STD.YES") : getKeyword("STD.NO")}
          </span>
        </p>
        {props.label ? (
          <label className="Ilabel">
            {getKeyword(props.label)}  <span className="requiredLb">{props.required ? " *" : ""}</span>
            <TooltipProvider tooltip={props.description}></TooltipProvider>
          </label>
        ) : null}
      </label>
    </div>
  );
};

export default CheckboxStyle;
