import React, { useContext, useEffect, useState } from "react";

import SharedContext from "../../contexts/shared/sharedContext";
import GlobalTable from "../../core/common/table/GlobalTable";
import { USEFULL_VARIABLES } from "../../variables/shared";
import { updateValueManager } from "../../core/manager/ManagerUtils";
import { getValueManager } from "../../core/manager/ManagerUtils";
import AffiliateContactForm from "./forms/affiliate-contact-form";
import ConfirmationPopupShared from "../../core/common/notifications/ConfirmationPopup";
import { LoggerSuccess } from "../../core/utils/Logger";
import ItemMenu from "../../core/common/table/ItemMenu";
import TextInput from "../../core/common/inputs/TextInput";

const TABLE = [
  {
    label: "Nom",
    value: "lastName",
    order: true,
  },
  {
    label: "Prénom",
    value: "firstName",
    order: true,
  },
  {
    label: "Email",
    value: "email",
    order: true,
  },
  {
    label: "Téléphone",
    value: "phone",
  },
  {
    label: "COMMON.STATUS",
    value: "enabled",
    type: "BOOLEAN_COLOR",
    order: true,
    data: {
      active: "Actif",
      active: "Inactif"
    }
  },
  {
    label: "Créé à",
    value: "createdAt",
    type: "DATE",
    order: true,
  },
  {
    label: "Actions",
    value: "actions",
    type: "RENDER",
    class: "table-actions",
    render: (props) => (
      <>
        <ItemMenu menus={[
          {
            name: props.getKeyword("STD_VERB.EDIT"),
            click: () => props.update(props.id)
          },
          // {
          //   name: "Rendre principal",
          //   click: () => props.makeMain(props.id),
          // },
          {
            name: props.getKeyword("STD_VERB.DELETE"),
            click: () => props.delete(props.id)
          }
        ]}>
        </ItemMenu>
      </>
    ),
  },
];

const FILTERS = [
  {
    label: "Name",
    type: "TEXT",
    value: "name",
    className: "col-md-3",
  },
  {
    label: "Code",
    type: "TEXT",
    value: "code",
    className: "col-md-3",
  },
  {
    type: "DATE_INTERVAL",
    labelFrom: "De",
    valueFrom: "from",
    labelTo: "A",
    valueTo: "to",
    className: "col-md-6",
  },
];

const FILTER_INIT = {
  search: null,
};

const AffiliateContact = (props) => {
  const { GetResource, getKeyword } = useContext(SharedContext);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const { id } = props;
  const [pagination, setPagination] = useState({
    page: 1,
    size: USEFULL_VARIABLES.paginationSize,
    order: null,
    direction: "DESC",
  });
  const [formFilter, setFormFilter] = useState(
    JSON.parse(JSON.stringify(FILTER_INIT))
  );
  const [isFilter, setIsFilter] = useState(false);
  const [modalState, setModalState] = useState({});
  const [deleteMessage, setDeleteMessage] = useState(null);

  const [form, setForm] = useState({
    affiliate: { id },
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    type: null,
  });

  const setalert = (message) => {
    LoggerSuccess(message);
  };

  const toggleModal = (state) => {
    setModalState({ ...modalState, [state]: !modalState[state] });
  };

  useEffect(() => {
    if (props.id) {
      paginate(
        pagination.page,
        pagination.size,
        pagination.order,
        pagination.direction
      );
    }
  }, [props.id]);

  const reload = () => {
    paginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const initForm = () => {
    setForm({
      affiliate: { id },
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      type: null,
    });
  };

  const beforeAdd = () => {
    initForm();
    toggleModal("formModal");
  };

  const onAdd = () => {
    reload();
    setalert(getKeyword("NOTIFICATION.WELL_DONE"));
    initForm();
  };

  const onUpdate = () => {
    reload();
    setalert(getKeyword("NOTIFICATION.WELL_DONE"));
    initForm();
  };

  const paginate = (page, size, order, direction) => {
    if (isFilter) {
      loadFilter(page, size, order, direction);
    } else {
      loadPaginate(page, size, order, direction);
    }
    setLoaded(false);
  };

  const loadPaginate = (page, size, order, direction) => {
    GetResource(
      "AFFILIATE_CONTACT",
      "GET_BY_AFFILIATE",
      {
        id,
        data: { ...pagination, page, size, order, direction },
      },
      null
    ).then((res) => {
      setData(res.payload);
      setPagination({ ...pagination, page, size, order, direction });
      setLoaded(true);
    }).catch((res) => {
      setLoaded(true);
    });;
  }

  const loadFilter = (page, size, order, direction) => {
    setLoaded(false);
    GetResource(
      "AFFILIATE_CONTACT",
      "FILTER",
      {
        ...formFilter,
        id: id,
        data: {
          page,
          size,
          order,
          direction,
        },
      },
      null
    )
      .then((res) => {
        setData(res.payload);
        setLoaded(true);
      })
      .catch((res) => {
        setLoaded(true);
      });
  };

  const onChangeFilters = (v, n, indexes = null) => {
    const f = updateValueManager(formFilter, "search", v, null);
    setFormFilter({ ...f });
    if (v) {
      filter();
    } else {
      setIsFilter(false);
      loadPaginate(
        pagination.page,
        pagination.size,
        pagination.order,
        pagination.direction
      );
    }
  };

  const filter = () => {
    pagination.page = 1;
    setIsFilter(true);
    loadFilter(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const initializeFilter = () => {
    setFormFilter(JSON.parse(JSON.stringify(FILTER_INIT)));
  };

  const updateItem = (itemId) => {
    GetResource("AFFILIATE_CONTACT", "GET_BY_ID", { id: itemId }, null).then(
      (res) => {
        setForm({ ...res.payload, affiliate: { id } });
        toggleModal("formModal");
      }
    );
  };

  const deleteItem = (itemId) => {
    setDeleteMessage({
      message: getKeyword("NOTIFICATION.DELETE_TEXT"),
      id: itemId,
    });
  };

  const makeMain = (itemId) => {
    GetResource("AFFILIATE_CONTACT", "MAKE_MAIN", { id: itemId }, null).then(
      (res) => {
        setDeleteMessage(null);
        reload();
        setalert(getKeyword("NOTIFICATION.WELL_DONE"));
        props.makeMain();
      }
    );
  };

  const confirmDelete = () => {
    GetResource(
      "AFFILIATE_CONTACT",
      "DELETE",
      { id: deleteMessage.id },
      null
    ).then((res) => {
      setDeleteMessage(null);
      reload();
      setalert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };

  return (
    <>

      {deleteMessage ? (
        <ConfirmationPopupShared
          message={deleteMessage.message}
          close={() => setDeleteMessage(null)}
          confirm={confirmDelete}
        ></ConfirmationPopupShared>
      ) : null}
      <div className=""  >
        <div className="card-block">
          <div className="card-header">
            <div>
              <h3 className="mb-0">Contacts secondaires</h3>
            </div>
            <div className="">
              {!id ? null : (
                <div className="d-flex justify-content-around ignore">
                  <TextInput
                    name="search"
                    placeHolder="Recherche"
                    onChange={onChangeFilters}
                    value={getValueManager(formFilter, "search")}
                  />
                  <button
                    className="btn-1 mgl-15"
                    id="tooltip969372949"
                    onClick={(e) => beforeAdd()}
                    size="lg"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-plus" />
                    </span>
                    <span className="btn-inner--text">{getKeyword("STD.NEW")}</span>
                  </button>
                  {modalState["formModal"] ? (
                    <AffiliateContactForm
                      modalName="formModal"
                      toggleModal={toggleModal}
                      showModal={modalState["formModal"]}
                      onAdd={onAdd}
                      form={form}
                      updateForm={(form) => setForm(form)}
                      onUpdate={onUpdate}
                    ></AffiliateContactForm>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          {isLoaded && id ? (
            <GlobalTable className="bg-info"
              table={TABLE}
              data={data.data}
              count={data.count}
              pagination={pagination}
              paginate={paginate}
              update={updateItem}
              delete={deleteItem}
              makeMain={makeMain}
            ></GlobalTable>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AffiliateContact;
