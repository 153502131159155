import { getValueManager } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { getValueManagerArray } from "../../../core/manager/ManagerUtils";
import { dateStrToTimeString } from "../../../core/utils/helpers";
import { Modal } from "antd";
import SharedContext from "../../../contexts/shared/sharedContext";


const QSQuestionMasterShow = (props) => {
    const { GetResource, getKeyword } = useContext(SharedContext);
    const form = props.form;
    const id = getValueManager(form, "id", null);
    const [isLoading, setIsLoading] = useState(false);

    const notificationAlertRef = React.useRef(null);
    const notify = (type) => {
        // let options = {
        //     place: "tc",
        //     message: (
        //         <div className="alert-text">
        //             <span className="alert-title" data-notify="title">
        //                 &nbsp;
        //                 {replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")])}
        //             </span>
        //         </div>
        //     ),
        //     type: type,
        //     icon: "ni ni-bell-55",
        //     autoDismiss: 7,
        // };
        // notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const onChange = (v, n, indexes = null) => {
        const f = updateValueManager(form, n, v, indexes);
        props.updateForm({ ...f });
    };

    const formValid =
        getValueManager(form, "code", null) && getValueManager(form, "name", null) && getValueManager(form, "type", null);

    return (
        <div>
            {/* <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
            </div> */}
            <Modal
                className="modal-dialog-centered"
                size="lg"
                style={{ maxWidth: "1000px", width: "100%" }}
                open={props.showModal && !isLoading}
                width={"1000px"}
                onCancel={() => props.toggleModal(props.modalName)}
            >
                <div className="modal-body p-0">
                    <div className="bg-secondary shadow border-0">
                        <div className="bg-menu">
                            <div className="text-center">
                                <p className="bold text-white mb-0"> {getKeyword("QUESTION.QUESTION")}</p>
                            </div>

                        </div>
                        <div className="px-lg-5 py-lg-1">
                            <div className="text-center d-flex justify-content-between border-bottom  mgb-30">
                                <button
                                    className="btn-1"
                                    color="white"
                                    type="button"
                                    onClick={(e) => { props.toggleModal(props.modalName); props.update(getValueManager(form, "id", null)); }}
                                >
                                    <i className="fa fa-pencil-square-o mr-2"></i> {getKeyword("STD_VERB.EDIT")}
                                </button>
                                {/* <button
                                    className="btn-1"
                                    color="success"
                                    type="button"
                                    onClick={(e) => { props.toggleModal(props.modalName); props.duplicate(getValueManager(form, "id", null)); }}
                                >
                                    <i className="fa fa-clone mr-2"></i> {getKeyword("STD_VERB.DUPLICATE")}
                                </button> */}
                                <button
                                    className="btn-1"
                                    color="info"
                                    type="button"
                                    onClick={(e) => { props.toggleModal(props.modalName); props.preview(getValueManager(form, "id", null)); }}
                                >
                                    <i className="fas fa-eye mr-2"></i>  {getKeyword("STD_VERB.PREVIEW")}
                                </button>
                            </div>
                            <div className="row">
                                <div className="col-md-6 border-right">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.CODE")}:</span > <span className="text-dark">{getValueManager(form, "code", null)}</span></p>
                                </div>
                                <div className="col-md-6">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.DESIGNATION")}:</span > <span className="text-dark">{getValueManager(form, "name", null)}</span></p>

                                </div>
                                <div className="col-md-6 border-right">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.STATUS")}:</span > <span className="text-dark">{getValueManager(form, "enabled", null) ? getKeyword("COMMON.ENABLED") : getKeyword("COMMON.DISABLED")}</span></p>
                                </div>
                                <div className="col-md-6">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.CREATED_AT")}:</span > <span className="text-dark"> {getValueManager(form, "createdAt", null) ? dateStrToTimeString(getValueManager(form, "createdAt", null)) : null}</span></p>
                                </div>
                                <div className="col-md-6 border-right">

                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.CREATED_BY")}:</span > <span className="text-dark">{getValueManager(form, "createdByUser.lastName", null) + " " + getValueManager(form, "createdByUser.firstName", null)}</span></p>

                                </div>
                                <div className="col-md-6">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.UPDATED_AT")}:</span > <span className="text-dark"> {getValueManager(form, "updatedAt", null) ? dateStrToTimeString(getValueManager(form, "updatedAt", null)) : null}</span></p>
                                </div>
                                <div className="col-md-6 border-right">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.UPDATED_BY")}:</span > <span className="text-dark">{getValueManager(form, "updatedByUser.lastName", null) + " " + getValueManager(form, "updatedByUser.firstName", null)}</span></p>
                                </div>
                                <div className="col-md-12">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.DESCRIPTION")}:</span > <span className="text-dark">{getValueManager(form, "description", null)}</span></p>
                                </div>
                                {/* <div className="col-md-12">
                                    <strong className="text-dark">Historique :</strong>&nbsp;
                                    <table className="mttable mt-2">
                                        <thead>
                                            <tr>
                                                <th>{getKeyword("COMMON.VERSION")}</th>
                                                <th>{getKeyword("COMMON.DESIGNATION")}</th>
                                                <th>{getKeyword("COMMON.DESCRIPTION")}</th>
                                                <th>{getKeyword("COMMON.UPDATED_AT")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getValueManagerArray(form, "histories", null).map(
                                                (m) => <tr><td>{m.version}</td><td>{m.name}</td><td>{m.description}</td>
                                                    <td>{getValueManager(m, "createdAt", null) ? dateStrToTimeString(getValueManager(m, "createdAt", null)) : null}</td></tr>,
                                            )}
                                        </tbody>
                                    </table>
                                    <br />
                                    <br />
                                </div> */}
                            </div>
                            <div className="text-center d-flex justify-content-between">
                                <button
                                    className="my-4"
                                    color="white"
                                    type="button"
                                    onClick={(e) => props.toggleModal(props.modalName)}
                                >
                                    {getKeyword("STD_VERB.CLOSE")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    );
};
export default QSQuestionMasterShow;
