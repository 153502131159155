import axios from 'axios';
import LINKS from '../../config';

axios.defaults.baseURL = LINKS.baseURL;
axios.defaults.withCredentials = false;

// Add a request interceptor
axios.interceptors.request.use((config) => {
    // Do something before request is sent
    const access_token = localStorage.getItem('token');
    if (access_token) {
        config.headers.common['Authorization'] = `${access_token}`;
        // config.withCredentials = false;
    } else {
        delete config.headers.common['Authorization'];
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
