import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import TextInput from "../../../core/common/inputs/TextInput";
import { getArray, updateValueManager } from "../../../core/manager/ManagerUtils";

export const StartupListInput = (props) => {
  const { getKeyword } = useContext(SharedContext);

  const addEmails = () => {
    let arr = getArray(props.value);
    arr.push({
      value: "",
    });
    props.onChange(arr, props.name);
  };

  const deleteFrom = (name, index) => {
    let arr = getArray(props.value);
    if (!arr) {
      arr = [];
    }
    props.onChange(
      arr.filter((vv, i) => i != index),
      name
    );
  };

  const onChange = (v, n, indexes) => {
    const f = updateValueManager(getArray(props.value), n, v, indexes);
    props.onChange(f, props.name);
  }

  return (
    <div className="fair--create-area_inputs">
      <div className="fair--create-area_cards">
        <label className="fair--create-area_labels font-weight-bold">
          {props.label} <span className="requiredLb"> *</span>
        </label>
        <div className="fair--create-area_content OnlyBig row">
          {getArray(props.value).map((et, index) => (
            <div className="bloc-group col-md-6" key={et.id + "key" + index}>
              <i
                className="fas fa-times bloc-group-close"
                onClick={() => deleteFrom(props.name, index)}
                style={{ fontSize: "20px", zIndex: 10 }}
              ></i>
              <div className="mgb-20">
                <TextInput
                  name={"?0.value"}
                  placeHolder={props.label}
                  onChange={(v, n) => onChange(v, n, [index])}
                  value={et.value}
                  required={props.required}
                  error={props.error}
                  code={props.code}
                ></TextInput>
              </div>

            </div>
          ))}

        </div>
        <button className="add-group col-md-4 bg-danger" type="button" onClick={addEmails}>
          <i class="fas fa-plus mr-2"></i>  {"Ajouter"}
        </button>
      </div>
    </div>
  );
};

export default StartupListInput;
