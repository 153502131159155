import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { getValueManagerArray, getValueManagerNumber } from "../../../core/manager/ManagerUtils";
import { getValueManager } from "../../../core/manager/ManagerUtils";
import DateBetweenInput from "../inputs/DateBetweenInput";
import NumberInput from "../inputs/NumberInput";
import SelectInput from "../inputs/SelectInput";
import TextInput from "../inputs/TextInput";

import './Tables.css';

export const GlobalFilters = (props) => {
    const { GetResource, getKeyword } = useContext(SharedContext);
    const [dependencies, setDependencies] = useState({});
    const [showbutton, setshowbutton] = useState(getValueManagerArray(props, "morefilters").length > 0);
    const [hiddenbutton, sethiddenbutton] = useState(getValueManagerArray(props, "morefilters").length !== 0);
    const [filters, setFilters] = useState(props.filters);

    useEffect(() => {
        let index = 0;
        let size = [...getValueManagerArray(props, "filters"), ...getValueManagerArray(props, "morefilters")].filter(
            (filter) => filter.type === "RESOURCE"
        ).length;
        const output = {};

        [...getValueManagerArray(props, "filters"), ...getValueManagerArray(props, "morefilters")].map((filter) => {
            if (filter.type === "RESOURCE") {
                let add = filter.send ? filter.send(props.form) : {};
                GetResource(
                    filter.data.resource.resource,
                    filter.data.resource.method,
                    { ...add, ...filter.data.resource.body },
                    null
                )
                    .then((res) => {
                        index++;
                        output[filter.value] = res.payload;
                        if (index == size) {
                            setDependencies(output);
                        }
                    })
                    .catch(() => {
                        index++;
                        if (index == size) {
                            setDependencies(output);
                        }
                    });
            }
        });
    }, [props.form]);

    const handleShowMoreFilters = () => {
        setFilters([...getValueManagerArray(props, "filters"), ...getValueManagerArray(props, "morefilters")])
        setshowbutton(false)


    }


    const handlehiddenMoreFilters = () => {
        setFilters([...props.filters])
        setshowbutton(true)
        // sethiddenbutton(false)
    }





    return (
        <div className="table-filter">
            <div className="row justify-content-center table-filter-fields">
                {filters.map((filter, i) => (
                    <div key={filter.value + "-" + i} className={filter.className}>
                        {!filter.type || filter.type === "TEXT" ? (
                            <TextInput
                                label={getKeyword(filter.label)}
                                name={filter.value}
                                onChange={(v, n) => {
                                    props.onChange(v, n);
                                }}
                                value={getValueManager(props.form, filter.value, null)}
                            ></TextInput>
                        ) : null}
                        {filter.type === "ID" ? (
                            <NumberInput
                                label={getKeyword(filter.label)}
                                name={filter.value}
                                onChange={(v, n) => {
                                    props.onChange(v, n);
                                }}
                                value={getValueManagerNumber(props.form, filter.value, null, null)}
                                isInt={true}
                                isPositive={true}
                            ></NumberInput>
                        ) : null}
                        {filter.type === "LIST" ? (
                            <SelectInput
                                label={getKeyword(filter.label)}
                                name={filter.value}
                                onChange={(v, n) => props.onChange(v, n)}
                                value={getValueManager(props.form, filter.value, null)}
                                data={filter.data}
                            ></SelectInput>
                        ) : null}
                        {filter.type === "RESOURCE" ? (
                            <SelectInput
                                label={getKeyword(filter.label)}
                                name={filter.value}
                                onChange={(v, n) => props.onChange(v, n)}
                                value={getValueManager(props.form, filter.value, null)}
                                data={{
                                    ...filter.data,
                                    items: getValueManagerArray(dependencies, filter.value),
                                }}
                                filter={filter.filter}
                            ></SelectInput>
                        ) : null}
                        {filter.type === "DATE_INTERVAL" ? (
                            <DateBetweenInput
                                labelFrom={getKeyword(filter.labelFrom)}
                                nameFrom={filter.valueFrom}
                                valueFrom={getValueManager(props.form, filter.valueFrom, null)}
                                labelTo={getKeyword(filter.labelTo)}
                                nameTo={filter.valueTo}
                                valueTo={getValueManager(props.form, filter.valueTo, null)}
                                onChange={(v, n) => props.onChange(v, n)}
                            ></DateBetweenInput>
                        ) : null}
                    </div>
                ))}
            </div>
            {hiddenbutton ?
                <div className="d-flex justify-content-center">
                    {showbutton &&
                        <button className="d-flex align-items-center my-3"
                            color="white"
                            size="lg"
                            type="button"
                            onClick={() => handleShowMoreFilters()}
                        >

                            {/* <i className="ni ni-bold-down"></i> */}
                            Filter avance
                            <i className="ni ni-bold-down ml-2 pt-1"></i>

                        </button>

                    }
                    {!showbutton &&
                        <button
                            color="white"
                            size="lg"
                            type="button"
                            className="btn-inner--text d-flex align-items-center my-3"
                            onClick={() => handlehiddenMoreFilters()}
                        >
                            {/* <i className="ni ni-bold-up"></i> */}
                            Cacher les filtres
                            <i className="ni ni-bold-up ml-2 pt-1"></i>
                        </button>

                    }
                </div>
                : null}
            <div className="d-flex justify-content-center">
                <button
                    className="btn-1"
                    size="lg"
                    type="button"
                    onClick={(e) => (props.filter ? props.filter() : null)}
                >
                    {getKeyword("STD_VERB.FILTER")}
                </button>

                <button
                    className="text-dark"
                    color="secondary"
                    type="button"
                    onClick={(e) => (props.initialize ? props.initialize() : null)}
                >
                    {getKeyword("STD_VERB.RENITIALIZE_FILTERS")}
                </button>
            </div>
        </div>
    );
};
export default GlobalFilters;
