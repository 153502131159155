import AreaInput from "../../../core/common/inputs/AreaInput";
import TextInput from "../../../core/common/inputs/TextInput";
import { getValueManager, getValueManagerNumber } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "antd";
import SharedContext from "../../../contexts/shared/sharedContext";
import { getValueManagerArray } from "../../../core/manager/ManagerUtils";
import { replaceIndex } from "../../../core/manager/ManagerUtils";
import CatalogFormTableType from "./catalog-form-table";
import Logger from "../../../core/utils/Logger";
import TechnicalTable from "../../../variables/technical-table";
import SelectInputTableTechnique from "../../../core/common/inputs/SelectInputTableTechnique";
import DateInput from "../../../core/common/inputs/DateInput";
import SelectInputResource from "../../../core/common/inputs/SelectInputResource";
import CheckboxStyle from "../../../core/common/inputs/Checkbox";
import NumberInput from "../../../core/common/inputs/NumberInput";

const CLCatalogForm = (props) => {
  const { GetResource, getKeyword } = useContext(SharedContext);
  const form = props.form;
  const id = getValueManager(form, "id", null);
  const [isLoading, setIsLoading] = useState(false);

  const notify = (type) => {
    Logger(replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")]));
  };

  useEffect(() => { }, []);

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    props.updateForm({ ...f });
  };

  const addNewTable = (event) => {
    GetResource("CATALOG_MANAGEMENT", "ADD", form, null)
      .then((res) => {
        props.onAdd();
        props.toggleModal(props.modalName);
      })
      .catch((err) => {
        if (err && err.status == 409) {
          notify("danger");
        }
      });
  };

  const updateTable = (event) => {
    GetResource("CATALOG_MANAGEMENT", "UPDATE", form, null).then((res) => {
      props.onUpdate(props.id);
      props.toggleModal(props.modalName);
    });
  };


  console.log("FORM", form);
  const formValid =
    getValueManager(form, "name", null) &&
    getValueManager(form, "form", null) &&
    getValueManager(form, "type", null) && getValueManager(form, "repeat", null) &&
    (!(getValueManager(form, "name", null)) || !getValueManagerArray(form, "forms").find(f => !getValueManager(f, "date", null)))
    && getValueManager(form, "dateStart", null);

  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        style={{ maxWidth: "1000px", width: "100%" }}
        open={props.showModal && !isLoading}
        width={"1000px"}
        onCancel={() => props.toggleModal(props.modalName)}
      >
        <div className="modal-body p-0">
          <div className="bg-secondary  border-0 mb-0">
            <div className="bg-menu">
              <div className="text-center">
                <p className="font-weight-bold text-white mb-0">Questionnaire</p>
              </div>
            </div>
            <div className="">
              <form role="form">
                <div className="row">
                  <div className="col-12">
                    <TextInput
                      label={getKeyword("COMMON.DESIGNATION")}
                      name="name"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "name", null)}
                      required={true}
                    ></TextInput>
                  </div>
                </div>
                <div className="col-12">
                  <SelectInputResource
                    label="Questionnaire"
                    name="form"
                    resource="QUESTION_MASTER_MANAGEMENT"
                    method="GET_ALL"
                    required={true}
                    multiple={false}
                    onChange={(v, n) =>
                      v ? onChange({ id: v },
                        n
                      ) : onChange(null,
                        n
                      )
                    }
                    value={getValueManager(form, "form.id")}
                    map={{
                      select: "id",
                      label: "name",
                    }}
                  ></SelectInputResource>
                </div>
                <div className="col-12">
                  <SelectInputTableTechnique
                    label="Types de collecte"
                    name="type"
                    onChange={(v, n) => onChange(v, n)}
                    value={getValueManager(form, "type")}
                    code={TechnicalTable.PROCESS_COLLECT_TYPE}
                    filter={(e) => e.value != "TABLE"}
                    required={true}
                  ></SelectInputTableTechnique>
                </div>
                <div className="col-12">
                  <SelectInputTableTechnique
                    label="Types de répétition"
                    name="repeat"
                    onChange={(v, n) => onChange(v, n)}
                    value={getValueManager(form, "repeat")}
                    code={TechnicalTable.PROCESS_COLLECT_REPEAT}
                    filter={(e) => e.value != "TABLE"}
                    required={true}
                  ></SelectInputTableTechnique>
                </div>
                {getValueManager(form, "repeat") == "PROCESS_COLLECT_REPEAT_FIXED" ?
                  <div className="col-12">
                    <CheckboxStyle
                      label="Limité pour un seul remplissage par collect?"
                      name="unlimited"
                      onChange={(v, n) => onChange(!v, n)}
                      value={!getValueManager(form, "unlimited", null)}
                    ></CheckboxStyle>
                  </div>
                  : null}
                {getValueManager(form, "repeat") == "PROCESS_COLLECT_REPEAT_FIXED" ?
                  <div className="col-12">
                    <CheckboxStyle
                      label="Limité pour cette année seulement?"
                      name="once"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "once", null)}
                    ></CheckboxStyle>
                  </div>
                  : null}
                <div className="col-12">
                  <CheckboxStyle
                    label="Cloturé ?"
                    name="closing"
                    onChange={(v, n) => onChange(v, n)}
                    value={getValueManager(form, "closing", null)}
                  ></CheckboxStyle>
                </div>
                {getValueManager(form, "closing") ?
                  <div className="col-12">
                    <NumberInput
                      label="Cloturé apres ?"
                      name="closingDays"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManagerNumber(form, "closingDays", null)}
                      isInt={true}
                      isPositive={true}
                    ></NumberInput>
                  </div>
                  : null}
                {getValueManager(form, "repeat") ?
                  <div className="col-12">
                    <DateInput
                      label={getValueManager(form, "repeat") == "PROCESS_COLLECT_REPEAT_FIXED" ? "Date de début" : "Date de réception"}
                      name="dateStart"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "dateStart", null)}
                      required={true}
                    ></DateInput>
                  </div> : null}
                {getValueManager(form, "repeat") == "PROCESS_COLLECT_REPEAT_FIXED" ?
                  <CatalogFormTableType onChange={onChange} form={form}></CatalogFormTableType> : null}
                <div className="col-12">
                  <AreaInput
                    label={getKeyword("COMMON.DESCRIPTION")}
                    name="description"
                    onChange={(v, n) => onChange(v, n)}
                    value={getValueManager(form, "description", null)}
                  ></AreaInput>
                </div>
                <div className="text-center d-flex justify-content-between">
                  <button
                    className="my-4"
                    color="white"
                    type="button"
                    onClick={(e) => props.toggleModal(props.modalName)}
                  >
                    {getKeyword("STD_VERB.CANCEL")}
                  </button>
                  <button
                    className="btn-1"
                    color="danger"
                    type="button"
                    disabled={!formValid}
                    onClick={(e) => (id ? updateTable() : addNewTable())}
                  >
                    {id ? getKeyword("STD_VERB.EDIT") : getKeyword("STD_VERB.ADD")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default CLCatalogForm;
