import React, { useContext } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { getValueManager, getValueManagerArray, updateValueManager } from "../../../core/manager/ManagerUtils";
import { Modal } from "antd";
import TextInput from "../../../core/common/inputs/TextInput";
import SelectInputResource from "../../../core/common/inputs/SelectInputResource";
import SelectInputTableTechnique from "../../../core/common/inputs/SelectInputTableTechnique";
import TechnicalTable from "../../../variables/technical-table";
import { validateEmail } from "../../../core/utils/helpers";


const Invitation = (props) => {
    const { GetResource, getKeyword } = useContext(SharedContext);
    const form = props.form;
    const id = getValueManager(form, "id", null);

    const onChange = (v, n, indexes = null) => {
        const f = updateValueManager(form, n, v, indexes);
        props.updateForm({ ...f });
    };

    const sendInvitation = (event) => {
        GetResource("USER_MANAGEMENT", "INVITE", form, null).then((res) => {
            props.onSend();
            props.toggleModal(props.modalName);
        });
    };

    const formValid =
        getValueManager(form, "profile", null) &&
        getValueManager(form, "email", null) &&
        validateEmail(getValueManager(form, "email", null)) &&
        getValueManager(form, "userType", null) &&
        ((getValueManager(
            form,
            "userType",
            null
        ) == "USER_TYPE_AFFILIATE" && getValueManager(
            form,
            "affiliate",
            null
        )) || getValueManager(
            form,
            "userType",
            null
        ) != "USER_TYPE_AFFILIATE");

    return (
        <div>
            <Modal
                className="modal-dialog-centered"
                size="lg"
                style={{ maxWidth: "1000px", width: "100%" }}
                open={props.showModal}
                width={"1000px"}
                onCancel={() => props.toggleModal(props.modalName)}
            >
                <div className="modal-body p-0">
                    <div className="bg-secondary  border-0 mb-0">
                        <div className="bg-menu text-white">
                            <div className="text-center">
                                <strong>Création de compte</strong>

                            </div>

                        </div>
                        <div className="px-lg-4 py-lg-4">
                            <form role="form">

                                <div className="row">
                                    <div md="12">
                                        <TextInput
                                            label="Adresse email principale"
                                            name="email"
                                            onChange={(v, n) => onChange(v, n)}
                                            value={getValueManager(form, "email", null)}
                                            required={true}
                                            error={getValueManager(form, "email", null) && !validateEmail(getValueManager(form, "email", null)) ? getKeyword("STD_ERROR.EMAIL") : null}
                                        ></TextInput>
                                    </div>
                                    <div md="12">
                                        <SelectInputTableTechnique
                                            required={true}
                                            label="Type utilisateur"
                                            name="userType"
                                            code={TechnicalTable.USER_TYPE}
                                            onChange={(v, n) => onChange(v, n)}
                                            value={getValueManager(
                                                form,
                                                "userType",
                                                null
                                            )}
                                        ></SelectInputTableTechnique>
                                    </div>
                                    {getValueManager(
                                        form,
                                        "userType",
                                        null
                                    ) == "USER_TYPE_EXTERNE" ?
                                        <div md="12">
                                            <SelectInputResource
                                                disabled={id ? true : false}
                                                label="Filiale"
                                                name="affiliate"
                                                resource="AFFILIATE_MANAGEMENT"
                                                method="GET_ALL"
                                                multiple={false}
                                                onChange={(v, n) =>
                                                    onChange({ id: v },
                                                        n
                                                    )
                                                }
                                                value={getValueManager(form, "affiliate.id", null)}
                                                map={{
                                                    select: "id",
                                                    label: "code",
                                                }}
                                            ></SelectInputResource>
                                        </div> : null}
                                    <div md="12">
                                        <SelectInputResource
                                            label="Profil utilisateur "
                                            name="profile"
                                            resource="PROFILE_MANAGEMENT"
                                            method="GET_ALL"
                                            required={true}
                                            multiple={false}
                                            onChange={(v, n) =>
                                                onChange({ id: v },
                                                    n
                                                )
                                            }
                                            value={getValueManager(form, "profile.id", null)}
                                            map={{
                                                select: "id",
                                                label: "code",
                                            }}
                                        ></SelectInputResource>
                                    </div>
                                    <div md="12">
                                        <SelectInputResource
                                            label="Rôles"
                                            name="roles"
                                            resource="ROLE_MANAGEMENT"
                                            method="GET_ALL"
                                            multiple={true}
                                            onChange={(v, n) =>
                                                onChange(
                                                    v.map((m) => ({ id: m })),
                                                    n
                                                )
                                            }
                                            value={getValueManagerArray(form, "roles", null).map(
                                                (m) => m.id
                                            )}
                                            map={{
                                                select: "id",
                                                label: "name",
                                            }}
                                        ></SelectInputResource>
                                    </div>

                                </div>

                                <div className="d-flex justify-content-between">
                                    <button
                                        className="my-4"
                                        color="white"
                                        type="button"
                                        onClick={(e) => props.toggleModal(props.modalName)}
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        className="my-4"
                                        color="primary"
                                        type="button"
                                        onClick={(e) => (sendInvitation())}
                                        disabled={!formValid}
                                    >
                                        {"Envoyer une invitation"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Invitation;
