import React from "react";
import './styles/slideHome.css';
import {ASSETS_BASE} from "../../config";
import Counter from "./Counter";

const SlideHome = (props) => {
    return <div>
        <div className="slider_top">
            <div className="layer"></div>
            {/*<div className="slider">*/}
            {/*    <img src={`${ASSETS_BASE}/home/images/Picture1 1.png`}  className="img-fluid" alt="" />*/}
            {/*</div>*/}
            <div className="contenue-slider">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                            <div className="reseau-follow">
                                <ul>
                                    <li>follow</li>
                                    <li><a href=""><i className="fab fa-instagram"></i></a></li>
                                    <li><a href=""><i className="fab fa-twitter-square"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="positive-link">
                                <div className="titre-slider">
                                    <h1 style={{ color: 'white', textAlign: 'center' }}>Positive <span
                                        style={{ color: 'aqua' }}>Link</span></h1>
                                </div>
                                <div className="logo-slider">
                                    <img src={`${ASSETS_BASE}/home/images/logo.png`}  alt="logoCenter"
                                         style={{ textAlign: 'center' }} />
                                </div>
                                <br />
                                    <div className="paragraphe-link">
                                        <p>Un programme pour la promotion d’un écosystème dynamique de start-ups
                                            innovantes au Maroc </p>
                                    </div>

                                    <div className="scroll-down">
                                        <p>scroll down  </p> <i className="fal fa-long-arrow-down fa-pulse"
                                                              ></i>
                                    </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

    </div>
        ;
};

export default SlideHome;
