import React, { useContext, useEffect, useState } from "react";
import { getValueManager, getValueManagerArray } from "../../core/manager/ManagerUtils";

const app = {};

const posList = [
    'left',
    'right',
    'top',
    'bottom',
    'inside',
    'insideTop',
    'insideLeft',
    'insideRight',
    'insideBottom',
    'insideTopLeft',
    'insideTopRight',
    'insideBottomLeft',
    'insideBottomRight'
];
app.configParameters = {
    rotate: {
        min: -90,
        max: 90
    },
    align: {
        options: {
            left: 'left',
            center: 'center',
            right: 'right'
        }
    },
    verticalAlign: {
        options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom'
        }
    },
    position: {
        options: posList.reduce(function (map, pos) {
            map[pos] = pos;
            return map;
        }, {})
    },
    distance: {
        min: 0,
        max: 100
    }
};
app.config = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
    onChange: function () {
        // const labelOption = {
        //     rotate: app.config.rotate,
        //     align: app.config.align,
        //     verticalAlign: app.config.verticalAlign,
        //     position: app.config.position,
        //     distance: app.config.distance
        // };
        // // myChart.setOption({
        // //     series: [
        // //         {
        // //             label: labelOption
        // //         },
        // //         {
        // //             label: labelOption
        // //         },
        // //         {
        // //             label: labelOption
        // //         },
        // //         {
        // //             label: labelOption
        // //         }
        // //     ]
        // // });
    }
};
const getSeries = (data) => {
    const dataSeries  = getValueManagerArray(data, "series") || [];
    const newData = [];
    const legends = ['products']
    getValueManagerArray(data, "legends.data").forEach((sr, index) => {
        legends.push(sr.name)
    })
    newData.push(legends);
    if (dataSeries.length > 0) {
        dataSeries.forEach((sr, index) => {
           let newDataSeries = [];
              newDataSeries.push(sr.name);
            getValueManagerArray(sr, "data").forEach((d, index) => {
                newDataSeries.push(d.value);
            })
            newData.push(newDataSeries);
        })
    }
    console.log("newData",newData);
    return newData;
}

app.config = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
    onChange: function () {
        const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
        };

    }
};

const labelOption = {
    show: true,
    position: app.config.position,
    distance: app.config.distance,
    align: app.config.align,
    verticalAlign: app.config.verticalAlign,
    rotate: app.config.rotate,
    formatter: '{c}  {name|{a}}',
    fontSize: 16,
    rich: {
        name: {}
    }
}

const option = (data) => {
    console.log("data from fucntion0,0",data);
    let arrayData = getSeries(data);
    arrayData.shift();
    // return   {
    //     legend: {},
    //     tooltip: {},
    //     barGap: 0,
    //     dataset: {
    //         dimensions: getSeries(data)[0],
    //         source:  arrayData
    //     },
    //     // dataset: {
    //     //     dimensions: ['product', '2015', '2016', '2017'],
    //     //     source: [
    //     //         { product: 'Matcha Latte', 2015: 43.3, 2016: 85.8, 2017: 93.7 },
    //     //         { product: 'Milk Tea', 2015: 83.1, 2016: 73.4, 2017: 55.1 },
    //     //         { product: 'Cheese Cocoa', 2015: 86.4, 2016: 65.2, 2017: 82.5 },
    //     //         { product: 'Walnut Brownie', 2015: 72.4, 2016: 53.9, 2017: 39.1 }
    //     //     ]
    //     // },
    //     xAxis: { type: 'category' },
    //     yAxis: {},
    //     // Declare several bar series, each will be mapped
    //     // to a column of dataset.source by default.
    //     series: [{ type: 'bar'  }, { type: 'bar' }, { type: 'bar'}]
    // }
    return {

        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: getValueManagerArray(data, "legends.data").map(i => i.name),
            show: false
            // show: !getValueManager(data, "legends.hide")
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ['line', 'bar', 'stack'] },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        xAxis: [
            {
                type: 'category',
                // axisTick: { show: true },
                data: getValueManagerArray(data, "xAxis")
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        dataset: {
            dimensions: getSeries(data)[0],
            source:  arrayData
        },
        // series :  [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
        // series : getValueManagerArray(data, "series").map((sr, index) => ({
        //     type: 'bar'
        // // }))
        series: getValueManagerArray(data, "series").map((sr, index) => ({
            // name: getValueManager(data, "legends.data.?0.name", [index]),
            type: 'bar',
            barGap: 0,
            label: {
                show: true,
                position: app.config.position,
                distance: app.config.distance,
                align: app.config.align,
                verticalAlign: app.config.verticalAlign,
                rotate: app.config.rotate,
                // formatter: '{c}  {name|{a}}',
                // formatter: getValueManager(sr, "formatter") || '{c}  {name|{a}}',
                fontSize: 16,
                rich: {
                    name: {}
                }
            },
            itemStyle: {
                color: getValueManager(sr, "color") || null
            },
            emphasis: {
                focus: 'series'
            },
            // data: getValueManagerArray(sr, "data")
         }))
    }
};


export const CHART_BAR_MULTIPLE1 = option;
