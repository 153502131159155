import React, { useContext, useEffect, useState } from "react";

import GlobalTable from "../../core/common/table/GlobalTable";
import GlobalFilters from "../../core/common/table/GlobalFilters";
import { USEFULL_VARIABLES } from "../../variables/shared";
import { getValueManager, updateValueManager } from "../../core/manager/ManagerUtils";
import { useHistory } from "react-router";
import ConfirmationPopupShared from "../../core/common/notifications/ConfirmationPopup";
import QSQuestionShow from "./show/question-show";
import QSQuestionForm from "./form/question-form";
import QSQuestionPreview from "./show/question-preview";
import SharedContext from "../../contexts/shared/sharedContext";
import { LoggerSuccess } from "../../core/utils/Logger";
import ItemMenu from "../../core/common/table/ItemMenu";
import GlobalTableChild from "../../core/common/table/GlobalTableChild";
import QSFeedbackPreview from "./show/feedback-preview";
import { dateToStringJs } from "../../core/utils/helpers";
import { userHasPermission, userIsAdmin, userIsInternal } from "../../core/utils/security";

const TABLE = [
    {
        label: "COMMON.DESIGNATION",
        value: "name",
    }, {
        label: "Répétiton",
        value: "repeat",
        type: "TEC",
    }, {
        label: "Date de réception",
        value: "date",
        type: "DATE_SIMPLE",
    },
    {
        label: "Nombre de collectes",
        value: "collectNumber",
    }, {
        label: "Nom de collecte",
        value: "collectName",
    },
    {
        label: "Date d'import",
        value: "importDate",
        type: "DATE_SIMPLE"
    },
    {
        label: "STD.ACTIONS",
        value: "actions",
        type: "RENDER",
        class: "table-actions",
        render: (props) => (
            <>
                <ItemMenu menus={[
                    {
                        name: props.getKeyword("STD_VERB.ADD"),
                        click: () => props.addFeedback(props.item),
                        hide: !getValueManager(props.item, "allowAdd") || !(userHasPermission(props.profile, "PFBU") || userIsAdmin(props.profile))
                    },
                    {
                        name: props.getKeyword("STD_VERB.EDIT"),
                        click: () => props.updateFeedback(props.item),
                        hide: !getValueManager(props.item, "allowUpdate") || !(userHasPermission(props.profile, "PFBU") || userIsAdmin(props.profile))
                    },
                    {
                        name: props.getKeyword("STD_VERB.PREVIEW"),
                        click: () => props.preview(props.item),
                        hide: !getValueManager(props.item, "preview") || !(userHasPermission(props.profile, "PFBU") || userHasPermission(props.profile, "PFBV") || userIsAdmin(props.profile))
                    },
                    // {
                    //     name: props.getKeyword("STD_VERB.DUPLICATE"),
                    //     click: () => props.duplicate(props.id),
                    //     hide: !getValueManager(props.item, "data")
                    // }
                ]}>

                </ItemMenu>
            </>
        ),
    },
];

const FILTERS = [
    {
        label: "Code question",
        type: "TEXT",
        value: "code",
        className: "col-md-3",
    },
    {
        label: "COMMON.DESIGNATION",
        type: "TEXT",
        value: "name",
        className: "col-md-3",
    },
    {
        label: "COMMON.STATUS",
        type: "LIST",
        value: "enabled",
        className: "col-md-3",
        data: {
            items: [
                {
                    name: "Actif",
                    status: true
                },
                {
                    name: "Inactif",
                    status: false
                }
            ],
            label: "name",
            select: "status"
        }
    },
];

const FILTER_INIT = {
    name: "",
    code: "",
    user: "",
    from: null,
};

const QSFeedbackList = (props) => {
    const history = useHistory();
    const { GetResource, getKeyword, profile } = useContext(SharedContext);
    const [data, setData] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        size: USEFULL_VARIABLES.paginationSize,
        order: null,
        direction: "DESC",
    });
    const [formFilter, setFormFilter] = useState(
        JSON.parse(JSON.stringify(FILTER_INIT))
    );
    const [modalState, setModalState] = useState({});

    const [deleteMessage, setDeleteMessage] = useState(null);

    const [previewItem, setPreviewItem] = useState(null);

    const toggleModal = (state) => {
        modalState[state] = !modalState[state];
        setModalState({ ...modalState });
    };

    const [alert, setalert] = React.useState(false);
    const [form, setForm] = useState({
        code: "",
        name: "",
        description: "",
        type: null,
    });

    const [previewQuestion, setPreviewQuestion] = useState(null);

    const [isFilter, setIsFilter] = useState(false);

    useEffect(() => {
        paginate(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    }, []);

    const reload = () => {
        paginate(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    };

    const initForm = () => {
        setForm({
            code: "",
            name: "",
            description: "",
            permission: [],
        });
    };

    const beforeAdd = () => {
        initForm();
        toggleModal("formModal");
    };

    const onAdd = () => {
        reload();
        setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        initForm();
    };

    const setAlert = (message) => {
        LoggerSuccess(message);
    };

    const onUpdate = () => {
        reload();
        setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        initForm();
    };

    const onChangeFilters = (v, n, indexes = null) => {
        const f = updateValueManager(formFilter, n, v, indexes);
        setFormFilter({ ...f });
    };

    const paginate = (page, size, order, direction) => {
        if (isFilter) {
            loadFilter(page, size, order, direction);
        } else {
            loadPaginate(page, size, order, direction);
        }
    };

    const filter = () => {
        pagination.page = 1;
        setIsFilter(true);
        loadFilter(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    };

    const loadPaginate = (page, size, order, direction) => {
        setLoaded(false);
        GetResource(
            "FEEDBACK_MANAGEMENT",
            "GET",
            {
                page,
                size,
                order,
                direction,
            },
            null
        ).then((res) => {
            setData(res.payload);
            setPagination({ ...pagination, page, size, order, direction });
            setLoaded(true);
        });
    };

    const loadFilter = (page, size, order, direction) => {
        setLoaded(false);
        GetResource(
            "FEEDBACK_MANAGEMENT",
            "FILTER",
            {
                ...formFilter,
                data: {
                    page,
                    size,
                    order,
                    direction,
                },
            },
            null
        ).then((res) => {
            setData(res.payload);
            setLoaded(true);
        });
    };

    const initializeFilter = () => {
        setFormFilter(JSON.parse(JSON.stringify(FILTER_INIT)));
        setIsFilter(false);
        loadPaginate(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    };

    const updateItem = (itemId) => {
        GetResource("FEEDBACK_MANAGEMENT", "GET_ID", { id: itemId }, null).then(
            (res) => {
                setForm({ ...res.payload });
                toggleModal("formModal");
            }
        );
    };

    const duplicate = (itemId) => {
        GetResource("FEEDBACK_MANAGEMENT", "GET_ID", { id: itemId }, null).then(
            (res) => {
                setForm({ ...res.payload, id: null, code: "" });
                toggleModal("formModal");
            }
        );
    };

    const showItem = (itemId) => {
        GetResource("FEEDBACK_MANAGEMENT", "GET_ID", { id: itemId }, null).then(
            (res) => {
                setForm({ ...res.payload });
                toggleModal("showModal");
            }
        );
    };

    const deleteItem = (itemId) => {
        setDeleteMessage({
            message: getKeyword("NOTIFICATION.DELETE_TEXT"),
            id: itemId,
        });
    };

    const confirmDelete = () => {
        GetResource(
            "FEEDBACK_MANAGEMENT",
            "DELETE",
            { id: deleteMessage.id },
            null
        ).then((res) => {
            setDeleteMessage(null);
            reload();
            setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        });
    };

    const enableItem = (id) => {
        GetResource("FEEDBACK_MANAGEMENT", "ENABLE", { id, enabled: true }, null).then(
            (res) => {
                reload();
                setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
            }
        );
    };

    const disableItem = (id) => {
        GetResource("FEEDBACK_MANAGEMENT", "DISABLE", { id, enabled: false }, null).then(
            (res) => {
                reload();
                setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
            }
        );
    };

    const preview = (item) => {
        setPreviewQuestion({ id: item.id, feedbackId: item.feedbackId });
        toggleModal("previewModal");
    }

    const addFeedback = (item) => {
        console.log("USERTYE", profile, userIsInternal(profile));
        if (!userIsInternal(profile)) {
            history.push("/external/feedback/" + item.id + "?"
                + (item.year ? "year=" + item.year : "") + "&"
                + (item.month ? "month=" + item.month : "") + "&"
                + (item.day ? "day=" + item.day : "") + "&"
                + (item.date ? "date=" + dateToStringJs(item.date) : ""));
        } else {
            history.push("/admin/feedback/" + item.id + "?"
                + (item.year ? "year=" + item.year : "") + "&"
                + (item.month ? "month=" + item.month : "") + "&"
                + (item.day ? "day=" + item.day : "") + "&"
                + (item.date ? "date=" + dateToStringJs(item.date) : ""));
        }
    }

    const updateFeedback = (item) => {
        if (userIsInternal(profile)) {
            history.push("/admin/feedback/" + item.id + "?feedbackId=" + item.feedbackId + "&"
                + (item.year ? "year=" + item.year : "") + "&"
                + (item.month ? "month=" + item.month : "") + "&"
                + (item.day ? "day=" + item.day : "") + "&"
                + (item.date ? "date=" + dateToStringJs(item.date) : ""));
        } else {
            history.push("/external/feedback/" + item.id + "?feedbackId=" + item.feedbackId + "&"
                + (item.year ? "year=" + item.year : "") + "&"
                + (item.month ? "month=" + item.month : "") + "&"
                + (item.day ? "day=" + item.day : "") + "&"
                + (item.date ? "date=" + dateToStringJs(item.date) : ""));
        }
    }

    return (
        <>
            {deleteMessage ? (
                <ConfirmationPopupShared
                    message={deleteMessage.message}
                    close={() => setDeleteMessage(null)}
                    confirm={confirmDelete}
                ></ConfirmationPopupShared>
            ) : null}
            {/* <ReactBSAlertShared
                alert={alert}
                hideAlert={setAlert}
            ></ReactBSAlertShared> */}
            {/* <GlobalFilters
                filters={FILTERS}
                form={formFilter}
                onChange={onChangeFilters}
                filter={filter}
                initialize={initializeFilter}
            ></GlobalFilters> */}
            <div className="card-block">
                <div className="card-header">
                    <div xs="6">
                        <h3 className="mb-0">Mes enquêtes</h3>
                    </div>
                    <div>
                        {/* <button
                            className="btn-1"
                            id="tooltip969372949"
                            onClick={(e) => beforeAdd()}
                            size="lg"
                        >
                            <span className="btn-inner--icon mr-1">
                                <i className="fas fa-plus" />
                            </span>
                            <span className="btn-inner--text">{getKeyword("STD.NEW")}</span>
                        </button> */}
                    </div>
                    {modalState["previewModal"] && previewQuestion && previewQuestion.id ? (
                        <QSFeedbackPreview
                            modalName="previewModal"
                            toggleModal={toggleModal}
                            showModal={modalState["previewModal"]}
                            preview={previewQuestion}
                        ></QSFeedbackPreview>
                    ) : null}
                </div>
                {isLoaded ? (
                    <GlobalTableChild
                        table={TABLE}
                        data={data}
                        count={data.length}
                        pagination={pagination}
                        paginate={paginate}
                        update={updateItem}
                        enable={enableItem}
                        disable={disableItem}
                        delete={deleteItem}
                        show={showItem}
                        duplicate={duplicate}
                        preview={preview}
                        childName="feedbacks"
                        hidePagination={true}
                        addFeedback={addFeedback}
                        updateFeedback={updateFeedback}
                    ></GlobalTableChild>
                ) : null}
            </div>
        </>
    );
};

export default QSFeedbackList;
