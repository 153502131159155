import Logger from "./Logger";

export const copyLink = (keywords) => {
  var el = document.createElement("textarea");
  // Set value (string to be copied)
  el.value = window.location.href;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(el);
  //Logging
  Logger("TEXT_COPIED", keywords, "success");
};

export const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const validateRegex = (regex, value) => {
  var re = new RegExp(regex);
  return re.test(value);
};

export const emptyStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("idUser");
};

export const getErrorMessage = (err) => {
  return !(err.response && err.response.data.msg)
    ? "Something went wrong please check your internet connection and make sure form is filled correctly"
    : err.response.data.msg;
};

export const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
};

export const dateToTimeString = (date) => {
  if (!date) {
    return "";
  }
  return `${getDateOf2(date.getDate())}/${getDateOf2(
    date.getMonth() + 1
  )}/${date.getFullYear()} ${getDateOf2(date.getHours())}:${getDateOf2(
    date.getMinutes()
  )}:${getDateOf2(date.getSeconds())}`;
};
export const dateStrToTimeString = (date) => {
  if (!date) { return "" };
  return dateToTimeString(new Date(date));
}

export const dateDateToString = (date) => {
  if (!date) {
    return null;
  }
  return `${getDateOf2(date.getDate())}/${getDateOf2(
    date.getMonth() + 1
  )}/${date.getFullYear()}`;
};

export const dateToString = (dateStr) => {
  if (!dateStr) {
    return "";
  }
  const date = new Date(dateStr);
  return `${getDateOf2(date.getDate())}/${getDateOf2(
    date.getMonth() + 1
  )}/${date.getFullYear()}`;
};

export const getDateOf2 = (val) => {
  if (val < 10) {
    return "0" + val;
  }
  return val;
};

export const dateToStringJs = (dateStr) => {
  if (!dateStr) {
    return "";
  }
  const date = new Date(dateStr);
  return `${date.getFullYear()}-${getDateOf2(date.getMonth() + 1)}-${getDateOf2(
    date.getDate()
  )}`;
};


export const validatePassword = (table, password) => {
  if (table == null || table.data == null) {
    return { error: false };
  }
  let withError = false;
  let res = {
    error: false,
    items: []
  }
  const PWD_MAX_LENGTH = table.data.find(l => l.code == "PWD_MAX_LENGTH");
  if (PWD_MAX_LENGTH) {
    const PWD_MAX_LENGTH_NUM = parseInt(PWD_MAX_LENGTH.value);
    const obj = {
      msg: "Longueur maximum du mot de pass doit être: " + PWD_MAX_LENGTH_NUM,
      valid: true
    }
    if (PWD_MAX_LENGTH_NUM && PWD_MAX_LENGTH_NUM < password.length) {
      withError = true;
      obj.valid = false;
    }
    res.items.push(obj);
  }
  const PWD_MIN_LENGTH = table.data.find(l => l.code == "PWD_MIN_LENGTH");
  if (PWD_MIN_LENGTH) {
    const PWD_MIN_LENGTH_NUM = parseInt(PWD_MIN_LENGTH.value);
    const obj = {
      msg: "Longueur minimum du mot de pass doit être: " + PWD_MIN_LENGTH_NUM,
      valid: true
    }
    if (PWD_MIN_LENGTH_NUM && PWD_MIN_LENGTH_NUM > password.length) {
      withError = true;
      obj.valid = false;
    }
    res.items.push(obj);
  }
  const PWD_MIN_LOWER_LENGTH = table.data.find(l => l.code == "PWD_MIN_LOWER_LENGTH");
  if (PWD_MIN_LOWER_LENGTH) {
    const PWD_MIN_LOWER_LENGTH_NUM = parseInt(PWD_MIN_LOWER_LENGTH.value);
    const obj = {
      msg: "Nombre minimum des caractères en miniscule doit être: " + PWD_MIN_LOWER_LENGTH_NUM,
      valid: true
    }
    if (PWD_MIN_LOWER_LENGTH_NUM && !isPasswordValid(password, "[a-z]", PWD_MIN_LOWER_LENGTH_NUM)) {
      withError = true;
      obj.valid = false;
    }
    res.items.push(obj);
  }
  const PWD_MIN_UPPER_LENGTH = table.data.find(l => l.code == "PWD_MIN_UPPER_LENGTH");
  if (PWD_MIN_UPPER_LENGTH) {
    const PWD_MIN_UPPER_LENGTH_NUM = parseInt(PWD_MIN_UPPER_LENGTH.value);
    const obj = {
      msg: "Nombre minimum des caractères en majuscule doit être: " + PWD_MIN_UPPER_LENGTH_NUM,
      valid: true
    }
    if (PWD_MIN_UPPER_LENGTH_NUM && !isPasswordValid(password, "[A-Z]", PWD_MIN_UPPER_LENGTH_NUM)) {
      withError = true;
      obj.valid = false;
    }
    res.items.push(obj);
  }
  const PWD_MIN_NUMBER_LENGTH = table.data.find(l => l.code == "PWD_MIN_NUMBER_LENGTH");
  if (PWD_MIN_NUMBER_LENGTH) {
    const PWD_MIN_NUMBER_LENGTH_NUM = parseInt(PWD_MIN_NUMBER_LENGTH.value);
    const obj = {
      msg: "Nombre minimum de chiffre doit être: " + PWD_MIN_NUMBER_LENGTH_NUM,
      valid: true
    }
    if (PWD_MIN_NUMBER_LENGTH_NUM && !isPasswordValid(password, "[0-9]", PWD_MIN_NUMBER_LENGTH_NUM)) {
      withError = true;
      obj.valid = false;
    }
    res.items.push(obj);
  }
  const PWD_MIN_SPECIAL_LENGTH = table.data.find(l => l.code == "PWD_MIN_SPECIAL_LENGTH");
  if (PWD_MIN_SPECIAL_LENGTH) {
    const PWD_MIN_SPECIAL_LENGTH_NUM = parseInt(PWD_MIN_SPECIAL_LENGTH.value);
    const obj = {
      msg: "Nombre minimum de caractères spéciaux doit être: " + PWD_MIN_SPECIAL_LENGTH_NUM,
      valid: true
    }
    if (PWD_MIN_SPECIAL_LENGTH_NUM && !isPasswordValid(password, "[@_\\\\\\./\\[\\]\\(\\)-]", PWD_MIN_SPECIAL_LENGTH_NUM)) {
      withError = true;
      obj.valid = false;
    }
    res.items.push(obj);
  }
  res.error = withError;
  return res;
}

const isPasswordValid = (pass, regExp, countTotal) => {
  if (pass.length < countTotal) {
    return false;
  }
  // var reg = [/[a-z]/,/[A-Z]/,/[0-9]/,/[(!@#$%&]/]
  var reg = new RegExp(regExp)
  var count = 0;
  for (var i = 0; i < pass.length; i++) {
    if (reg.test(pass[i])) {
      count++;
    }
    if (count >= countTotal) {
      return true;
    }
  }
  return false;
}

export const truncateTextAfter = (str, length) => {
  if (!str) {
    return "";
  }
  const strs = str.replace(/\n/g, " ").split(" ");
  if (strs.length <= length) {
    return str;
  }
  let res = "";
  for (let index = 0; index < strs.length && index < length; index++) {
    res += strs[index] + " ";
  }
  res += "...";
  return res;
}


export const validatePhoneNumber = (phone, def = false) => {
  if (!phone) {
    return def;
  }
  var phoneReg = /^\+?([0-9]{1,3})\)?[-. ]?([0-9]{1,4})[-. ]?([0-9]{1,10})$/;
  if (phone.match(phoneReg)) {
    return true;
  }
  else {
    return false;
  }
}

export const getRandom = (length) => {
  let result = "";
  let characters =
    "0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}