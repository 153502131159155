import React, { useContext ,useEffect } from "react";
import { getValueManager, getValueManagerArray, updateValueManager } from "../../../../core/manager/ManagerUtils";
import { Modal } from "antd";
import SelectInputResource from "../../../../core/common/inputs/SelectInputResource";
import SharedContext from "../../../../contexts/shared/sharedContext";
import "./EmailSecondaryForm.css";

const InvitationRoles = (props) => {
    const { GetResource, getKeyword } = useContext(SharedContext);
    const form = props.form;

    const onChange = (v, n, indexes = null) => {
        const f = updateValueManager(form, n, v, indexes);
        props.updateForm({ ...f });
    };

    const sendInvitationRoles = (event) => {
        props.onSend();
    };

    useEffect(() => {
        return () => {
            console.log("props",props)
        };
    }, [props]);


    const formValid =
        getValueManagerArray(form, "roles", null).length > 0 ;

    return (
        <div>
            <Modal
                className="modal-dialog-centered"
                size="lg"
                style={{ maxWidth: "1000px", width: "100%" }}
                open={props.showModal}
                width={"1000px"}
                onCancel={() => props.toggleModal(props.modalName)}
            >
                <div className="modal-body p-0">
                    <div className="bg-secondary  border-0 mb-0">
                        <div className="bg-menu text-white">
                            <div className="text-center">
                                <strong>Acceptation de compte</strong>
                            </div>
                        </div>
                        <div className="">
                           <div className="row">
                               <div className="col-md-12">
                                 <div className="blockDetail">
                                     nom de la {
                                     getValueManager(form, "userType", null) === "USER_TYPE_STARTUP" ? "start-up" : "filiale"
                                 } choisi :  &nbsp;  <span>{getValueManager(form, "name", null)}</span>
                                 </div>
                               </div>
                               {/*<div className="col-md-8">*/}
                               {/*    {*/}
                               {/*        getValueManager(form, "userType", null) === "USER_TYPE_STARTUP" ?*/}
                               {/*            <SelectInputResource*/}
                               {/*                label="List Startup Existante"*/}
                               {/*                name="startup"*/}
                               {/*                resource="STARTUP_MANAGEMENT"*/}
                               {/*                method="GET_ALL_ENABLED"*/}
                               {/*                multiple={false}*/}
                               {/*                onChange={(v, n) =>*/}

                               {/*                    onChange(v ? { id: v } : null,*/}
                               {/*                        n*/}
                               {/*                    )*/}
                               {/*                }*/}
                               {/*                value={getValueManager(form, "startup.id", null)}*/}
                               {/*                filter={getValueManager(form, "enabled") === true}*/}
                               {/*                map={{*/}
                               {/*                    select: "id",*/}
                               {/*                    label: "name",*/}
                               {/*                }}*/}
                               {/*                required={true}*/}
                               {/*            ></SelectInputResource>*/}
                               {/*     :*/}
                               {/*            <SelectInputResource*/}
                               {/*                label="List Filiale Existante"*/}
                               {/*                name="affiliate"*/}
                               {/*                resource="AFFILIATE_MANAGEMENT"*/}
                               {/*                method="GET_ALL_ENABLED"*/}
                               {/*                multiple={false}*/}
                               {/*                onChange={(v, n) =>*/}
                               {/*                    onChange(v ? { id: v } : null,*/}
                               {/*                        n*/}
                               {/*                    )*/}
                               {/*                }*/}
                               {/*                value={getValueManager(form, "affiliate.id", null)}*/}

                               {/*                map={{*/}
                               {/*                    select: "id",*/}
                               {/*                    label: "name",*/}
                               {/*                }}*/}
                               {/*                required={true}*/}
                               {/*            >*/}
                               {/*            </SelectInputResource>*/}

                               {/*}*/}
                               {/*</div>*/}
                           </div>

                            <form role="form">
                                {/* filter={getValueManager(form, "enabled") === true}*/}
                                <div className="">

                                    {
                                        getValueManager(form, "userType", null) === "USER_TYPE_STARTUP" ?
                                            <SelectInputResource
                                                label="List Startup Existante"
                                                name="startupId"
                                                resource="STARTUP_MANAGEMENT"
                                                method="GET_ALL"
                                                multiple={false}
                                                onChange={(v, n) =>
                                                    onChange(v ?  v  : null, n )
                                                }
                                                value={getValueManager(form, "idName", null)}
                                                map={{
                                                    select: "id",
                                                    label: "name",
                                                }}
                                                required={true}
                                            ></SelectInputResource>
                                            :
                                            <SelectInputResource
                                                label="List Filiale Existante"
                                                name="affiliateId"
                                                resource="AFFILIATE_MANAGEMENT"
                                                method="GET_ALL"
                                                multiple={false}
                                                onChange={(v, n) =>
                                                    onChange(v ?  v  : null, n )
                                                }
                                                value={getValueManager(form, "idName", null)}
                                                map={{
                                                    select: "id",
                                                    label: "name",
                                                }}
                                                required={true}
                                            ></SelectInputResource>
                                    }
                                </div>
                                <div className="">
                                    <div className="">
                                        <SelectInputResource
                                            label="Rôles"
                                            name="roles"
                                            resource="ROLE_MANAGEMENT"
                                            method="GET_ALL"
                                            multiple={true}
                                            onChange={(v, n) =>
                                                onChange(
                                                    v.map((m) => ({ id: m })),
                                                    n
                                                )
                                            }
                                            value={getValueManagerArray(form, "roles", null).map(
                                                (m) => m.id
                                            )}
                                            map={{
                                                select: "id",
                                                label: "name",
                                            }}
                                            filter={(item) => item.type === getValueManager(form, "userType", null) && item.enabled}
                                            required={true}
                                        ></SelectInputResource>
                                    </div>

                                </div>

                                <div className="d-flex justify-content-between">
                                    <button
                                        className="my-4"
                                        color="white"
                                        type="button"
                                        onClick={(e) => props.toggleModal(props.modalName)}
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        className="btn-1"
                                        color="primary"
                                        type="button"
                                        onClick={(e) => (sendInvitationRoles())}
                                        disabled={!formValid}
                                    >
                                        {"Accepter l'invitation"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default InvitationRoles;
