import { getValueManager, updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { Modal } from "antd";
import QSQuestionCreatorPreview from "../preview/FormPreview";
import { transformFormFromDbToJs } from "../creator/helpers";


const QSFormPreviewSaved = (props) => {
    const { GetResource, getKeyword } = useContext(SharedContext);
    const id = props.id;
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);

    const [preview, setPreview] = useState(null);

    const [form, setForm] = useState({});
    const [formPreview, setFormPreview] = useState({});

    const [images, setImages] = useState({});


    useEffect(() => {
        setIsLoading(true);

        GetResource(
            "QUESTION_MASTER_MANAGEMENT",
            "GET_ID",
            { id: id },
            null
        ).then((res) => {
            setForm(res.payload);
            const newForm = transformFormFromDbToJs(res.payload);
            setPreview(JSON.parse(JSON.stringify(newForm)));
            setIsLoading(false);
        }, err => {

        });
    }, []);

    const onChange = (v, n, indexes = null) => {
        const f = updateValueManager(formPreview, n, v, indexes);
        setFormPreview({ ...f });
    };

    const onChangeImages = (
        files,
        name,
        multiple,
        indexes,
        usedFor = "FORM"
    ) => {
        images[name] = { files, name, multiple, indexes, usedFor };
        setImages({ ...images });
    };



    return (
        <div>
            <Modal
                className="modal-dialog-centered"
                size="lg"
                style={{ maxWidth: "1000px", width: "100%" }}
                open={props.showModal && !isLoading}
                width={"1000px"}
                onCancel={() => props.toggleModal(props.modalName)}
            >
                <div className="modal-body p-0">
                    <div className="bg-secondary  border-0 mb-0">
                        <div className="bg-menu">
                            <div className="text-center">
                                <p className="font-weight-bold text-white mb-0">{form && form.name}</p>
                            </div>
                        </div>
                        <div className="">
                            <div className="row">
                                <div className="col-md-12 qc-preview">
                                    {props.isLoading || !preview || !preview[page] ? null :
                                        <QSQuestionCreatorPreview
                                            version={0}
                                            items={preview[page].items}
                                            root={true}
                                            indexes={[]}
                                            parentIndex=""
                                            onChange={onChange}
                                            onChangeImages={onChangeImages}
                                            form={formPreview}
                                        >
                                        </QSQuestionCreatorPreview>
                                    }
                                </div>
                            </div>
                            <div className="text-center d-flex justify-content-between">
                                <button
                                    className="btn-1"
                                    color="white"
                                    type="button"
                                    onClick={(e) => props.toggleModal(props.modalName)}
                                >
                                    {getKeyword("STD_VERB.CLOSE")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    );
};
export default QSFormPreviewSaved;
