import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import GlobalTable from "../../../core/common/table/GlobalTable";
import GlobalFilters from "../../../core/common/table/GlobalFilters";
import { USEFULL_VARIABLES } from "../../../variables/shared";
import { getValueManager, getValueManagerArray, updateValueManager } from "../../../core/manager/ManagerUtils";
import { useHistory } from "react-router";
import Invitation from "./invitation";
import ReferentielTable from "../../../variables/referentiel-table";
import ConfirmationPopupShared from "../../../core/common/notifications/ConfirmationPopup";
import PasswordFormM from "./form/password-form";
import ItemMenu from "../../../core/common/table/ItemMenu";
import Logger, { LoggerSuccess } from "../../../core/utils/Logger";
import InvitationRoles from "./form/invitation-roles";

const TABLE = [
    {
        label: "Image",
        value: "image",
        type: "IMAGE"
    },
    {
        label: "Email",
        value: "email",
        order: true,
        // type: "CLICK",
        // call: "show"
    },
    {
        label: "Nom",
        value: "lastName",
        order: true,
    },
    {
        label: "Prénom",
        value: "firstName",
        order: true,
    },
    {
        label: "Type utilisateur",
        value: "userType",
        type: "TEC",
    },
    {
        label: "Filiale",
        value: "affiliateName",
    },
    {
        label: "Startup",
        value: "startupName",
    },
    {
        label: "Actions",
        value: "actions",
        type: "RENDER",
        class: "table-actions",
        render: (props) => (
            <>
                <ItemMenu menus={[
                    {
                        name: "Accepter",
                        click: () => props.enable(props.item),
                    },
                    {
                        name: "Refuser",
                        click: () => props.disable(props.id),
                    },

                ]}>

                </ItemMenu>
            </>
        ),
    },
];

const FILTERS = [
    {
        label: "Email",
        type: "TEXT",
        value: "email",
        className: "col-md-3",
    },
    // {
    //     label: "Code Rôle",
    //     type: "RESOURCE",
    //     value: "role",
    //     className: "col-md-3",
    //     data: {
    //         resource: {
    //             resource: "ROLE_MANAGEMENT",
    //             method: "GET_ALL",
    //             body: {},
    //         },
    //         items: [],
    //         select: "id",
    //         label: "code",
    //     },
    // },
    {
        label: "Type utilisateur",
        type: "RESOURCE",
        value: "userType",
        className: "col-md-3",
        data: {
            resource: {
                resource: "TABLE_TECHNIQUE",
                method: "GET_ALL_LINE_CODE",
                body: { value: ReferentielTable.USER_TYPE },
            },
            items: [],
            select: "code",
            label: "designation",
        },
        filter: (item) => item.code === "USER_TYPE_INTERNAL" || item.code === "USER_TYPE_AFFILIATE" || item.code === "USER_TYPE_STARTUP"
    },
    {
        label: "Filiale",
        type: "RESOURCE",
        value: "affiliate",
        className: "col-md-3",
        data: {
            resource: {
                resource: "AFFILIATE_MANAGEMENT",
                method: "GET_ALL",
                body: {},
            },
            items: [],
            select: "id",
            label: "name",
        },
    },
    {
        label: "Startup",
        type: "RESOURCE",
        value: "startup",
        className: "col-md-3",
        data: {
            resource: {
                resource: "STARTUP_MANAGEMENT",
                method: "GET_ALL",
                body: {},
            },
            items: [],
            select: "id",
            label: "name",
        },
    },

    {
        label: "Nom",
        type: "TEXT",
        value: "lastName",
        className: "col-md-3",
    },
    {
        label: "Prénom",
        type: "TEXT",
        value: "firstName",
        className: "col-md-3",
    },

];

const FILTER_INIT = {
    username: "",

    codeProfile: null,
    codeRole: null,
    userType: null,
    affilie: "",
    lastName: "",
    firstName: "",
    email: "",
    to: null,
    from: null,
};

const UtilisateursInvite = (props) => {
    const history = useHistory();
    const { GetResource, getKeyword } = useContext(SharedContext);
    const [data, setData] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        size: USEFULL_VARIABLES.paginationSize,
        order: "createdAt",
        direction: "DESC",
    });
    const [selected, setSelected] = useState(null);
    const [formFilter, setFormFilter] = useState(
        JSON.parse(JSON.stringify(FILTER_INIT))
    );
    const [modalState, setModalState] = useState({});

    const [deleteMessage, setDeleteMessage] = useState(null);

    const toggleModal = (state) => {
        setModalState({ ...modalState, [state]: !modalState[state] });
    };

    const [alert, setalert] = React.useState(false);
    const notificationAlertRef = React.useRef(null);
    const [form, setForm] = useState(null);

    const [isFilter, setIsFilter] = useState(false);
    const [passwordId, setPasswordId] = useState(1);

    const successAlert = (message) => {
        LoggerSuccess(message);
    };

    const notify = (message) => {
        Logger(message);
    };

    useEffect(() => {
        paginate(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    }, []);

    const reload = () => {
        paginate(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    };

    const initForm = () => {
        setForm({
            code: "",
            designation: "",
            description: "",
        });
    };

    const beforeAdd = () => {
        initForm();
        toggleModal("formModal");
    };



    const onAdd = () => {
        reload();
        // validation();
        successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        initForm();
    };

    const onUpdate = () => {
        reload();
        successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        initForm();
    };

    const onChangeFilters = (v, n, indexes = null) => {
        const f = updateValueManager(formFilter, n, v, indexes);
        setFormFilter({ ...f });
    };

    const paginate = (page, size, order, direction) => {
        if (isFilter) {
            loadFilter(page, size, order, direction);
        } else {
            loadPaginate(page, size, order, direction);
        }
    };

    const filter = () => {
        pagination.page = 1;
        setIsFilter(true);
        loadFilter(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    };

    const loadPaginate = (page, size, order, direction) => {
        setLoaded(false);
        GetResource(
            "USER_INVITE_MANAGEMENT",
            "GET",
            {
                page,
                size,
                order,
                direction,
            },
            null
        ).then((res) => {
            setData(res.payload);
            setPagination({ ...pagination, page, size, order, direction });
            setLoaded(true);
        });
    };



    const loadFilter = (page, size, order, direction) => {
        setLoaded(false);
        GetResource(
            "USER_INVITE_MANAGEMENT",
            "FILTER",
            {
                ...formFilter,
                data: {
                    page,
                    size,
                    order,
                    direction,
                },
            },
            null
        ).then((res) => {
            setData(res.payload);
            setLoaded(true);
        });
    };

    const initializeFilter = () => {
        setFormFilter(JSON.parse(JSON.stringify(FILTER_INIT)));
        setIsFilter(false);
        loadPaginate(
            pagination.page,
            pagination.size,
            pagination.order,
            pagination.direction
        );
    };

    const enableItem = (item) => {
        console.log("ITEMmmmmmmmmmmmmmmmmmmmmmmmmmmm", item);
        setForm({
            id: getValueManager(item,"id"),
            userType: getValueManager(item, "userType") ,
            name: getValueManager(item, "userType") === "USER_TYPE_STARTUP" ? getValueManager(item, "startupNameV1") : getValueManager(item, "affiliateNameV1"),
            IdName: getValueManager(item, "userType") === "USER_TYPE_STARTUP" ? getValueManager(item, "startupId") : getValueManager(item, "affiliateId")
        });
        toggleModal("formModal");
    };

    const accpetEnableItem = () => {
        toggleModal("formModal");
        GetResource(
            "USER_INVITE_MANAGEMENT",
            "DECISION",
            { ...form, enabled: true },
            null
        ).then((res) => {
            reload();
            successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
            setForm(null);
        }).catch((err) => {
            if (err && err.status === 409 && err.data && err.data.message === "ALREADY_EXISTS" && err.data.detail === "EMAIL") {
                notify(getKeyword("USER.EMAIL_USED"));
            } else if (err && err.status === 409 && err.data && err.data.message === "ALREADY_EXISTS" && err.data.detail === "USERNAME") {
                notify(getKeyword("USER.LOGIN_USED"));
            } else if (err && err.status === 403 && err.data && err.data.message === "FORBIDDEN" && err.data.detail === "USERS_LIMIT") {
                notify(getKeyword("USER.USERS_LIMIT"));
            }
            setForm(null);
        });
    }


    const disableItem = (id) => {
        GetResource(
            "USER_INVITE_MANAGEMENT",
            "DECISION",
            { id, enabled: false },
            null
        ).then((res) => {
            reload();
            successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
        });
    };

    console.log("FORM INVIT", form);



    return (
        <>
            {alert}
            <div>
                <div className="card-block">
                    <div className="card-header">
                        <div>
                            <h3 className="mb-0">Inscriptions</h3>
                        </div>
                        <div>
                        </div>
                    </div>
                    {modalState["formModal"] && form ? (
                        <InvitationRoles
                            modalName="formModal"
                            toggleModal={(state) => { toggleModal(state); setForm(null); }}
                            showModal={modalState["formModal"]}
                            onSend={accpetEnableItem}
                            form={form}
                            updateForm={(form) => setForm(form)}
                        ></InvitationRoles>
                    ) : null}
                    {isLoaded ? (
                        <GlobalTable
                            table={TABLE}
                            data={data.data}
                            count={data.count}
                            pagination={pagination}
                            paginate={paginate}
                            enable={enableItem}
                            disable={disableItem}
                        ></GlobalTable>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default UtilisateursInvite;
