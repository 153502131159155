import { getValueManagerArray } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { useDrag, useDrop } from 'react-dnd'
import QSQuestionCreatorCardBlockEdit from "./CardBlockEdit";
import QSQuestionCreatorCardEdit from "./CardEdit";



const QSQuestionCreatorBlock = (props) => {
    // const [data, setData] = useState(null);
    // const [finalShow, setFinalShow] = useState([]);
    // useEffect(() => {
    //     setFinalShow([...props.items])
    // }, [props.items])
    const [{ isOver, isOverCurrent }, drop] = useDrop(({
        accept: ["CARD", "BLOCK"],
        drop: function (item, monitor) {
            if (monitor.isOver() && monitor.isOver({ shallow: true })) {
                const itemPosition = monitor.getClientOffset();
                const finalPosition = getPosition(itemPosition);
                props.setItems(item, props.indexes, finalPosition);
            }
        },
        hover: (item, monitor) => {
            if (monitor.isOver() && monitor.isOver({ shallow: true })) {
                // if (data == null || (finalPosition != data.finalPosition)) {
                const itemPosition = monitor.getClientOffset();
                const finalPosition = getPosition(itemPosition);
                props.changePreview(item, props.indexes, finalPosition);
                // props.changePreview(item, finalPosition);
                // }
            }
        },
        collect: monitor => {
            return ({
                isOver: !!monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
            })
        },
    }), [props.items, props.form])

    const getPosition = (itemPosition) => {
        let docs = document.getElementsByClassName("myallblockitem" + props.parentIndex);
        if (!docs) {
            docs = [];
        }
        const positions = [];
        for (let index = 0; index < docs.length; index++) {
            const dc = docs[index];
            if (dc) {
                positions.push(dc.getBoundingClientRect())
            }

        }
        let finalPosition = 0;
        let done = false;
        positions.forEach((ps, i) => {
            if (!done) {
                if (ps.y + ps.height / 2 < itemPosition.y) {
                    finalPosition = i + 1;
                } else {
                    done = true;
                }
            }
        })
        return finalPosition;
    }


    const getRender = (myItems) => {
        let core = [];
        myItems.forEach((item, index) => {
            core.push(<div key={("myallblockitem" + props.parentIndex) + "-" + index + "-" + props.version}>
                {item.type == "CARD" ? <div className={("myallblockitem" + props.parentIndex)}>
                    <QSQuestionCreatorCardEdit deleteHover={props.deleteHover} question={item.question} item={item} index={[...props.indexes, index]}
                        onChange={props.onChange} onChangeImages={props.onChangeImages} form={props.form}
                        changeSettings={props.changeSettings} delete={props.delete}></QSQuestionCreatorCardEdit></div> : null}
                {item.type == "BLOCK" ? <div className={("myallblockitem" + props.parentIndex)}><QSQuestionCreatorBlock
                    root={false} items={item.items} item={item} setItems={props.setItems}
                    changePreview={props.changePreview}
                    preview={props.preview}
                    parentIndex={(props.parentIndex ? props.parentIndex + "-" : "") + index} indexes={[...props.indexes, index]} deleteHover={props.deleteHover}
                    changeSettings={props.changeSettings} delete={props.delete} onChange={props.onChange} onChangeImages={props.onChangeImages} form={props.form} /></div> : null}

            </div>)
        })
        return core;
    }
    return (
        <div
            // id="myallblock"
            className={(props.root ? "qc-root " : "qc-block ") + (isOver && isOverCurrent ? "active" : "")}
            id={"data-index-" + props.parentIndex}
            ref={drop}
        >
            {/* {getRender(props.items)} */}
            {props.root ? null : <QSQuestionCreatorCardBlockEdit deleteHover={props.deleteHover} item={props.item} index={[...props.indexes]}
                onChange={props.onChange} onChangeImages={props.onChangeImages} form={props.form}
                changeSettings={props.changeSettings} delete={props.delete}></QSQuestionCreatorCardBlockEdit>}
            {props.root ? (<div className="qc-core-js">{getRender(props.items)}</div>) : <div className="qc-core qc-core-js">{getRender(props.items)}
            </div>}
        </div>
    )
}

export default QSQuestionCreatorBlock;