import React from "react";
import "./App.css";
import Routes from "./routes/Routes";
import SharedState from "./contexts/shared/SharedState";
import AuthState from "./contexts/auth/AuthState";
import { ToastContainer } from "react-toastify";
import 'antd/dist/antd.css';

function App() {
  return (
    <AuthState>
      <SharedState>
        <ToastContainer hideProgressBar={true} autoClose={2000} />
        <Routes />
      </SharedState>
    </AuthState>
  );
}

export default App;
