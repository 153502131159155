import React, { useContext } from "react";
import { replaceIndex } from "../../manager/ManagerUtils";
import TooltipProvider from "../outputs/TooltipProvider";

import "./Inputs.css";

export const AreaInput = (props) => {
  const onChange = (event) => {
    if (props.onChange) {
      props.onChange(event.target.value, props.name);
    }
  };

  return (
    <div className="AreaInput InputField">
      {!props.label ? null : (
        <label className="Ilabel">
          {replaceIndex(
            props.label,
            props.lableIndexesNames,
            props.lableIndexes
          )}&nbsp;
          <span className="requiredLb">{props.required ? " *" : ""}</span>
          {props.optText ? <span>(Optionnel)</span> : ""}
          <TooltipProvider tooltip={props.description}></TooltipProvider>
        </label>
      )}
      <div className="input">
        {props.preview ?
          <p className="input-response">{props.value}</p>
          :
          <textarea
            type="textarea"
            value={props.value}
            name={"AreaInput-" + props.name}
            onChange={onChange}
            placeholder={
              props.placeHolder
                ? replaceIndex(
                  props.placeHolder,
                  props.lableIndexesNames,
                  props.lableIndexes
                )
                : replaceIndex(
                  props.label,
                  props.lableIndexesNames,
                  props.lableIndexes
                )
            }
          ></textarea>}
      </div>
      {props.error ? (
        <p className="errorInput">
          <i className="fas fa-exclamation-triangle"></i> {props.error}
        </p>
      ) : null}
    </div>
  );
};

export default AreaInput;
