import React, {useContext, useEffect, useState} from "react";
import classnames from "classnames";
import {useHistory} from "react-router";
import SharedContext from "../../contexts/shared/sharedContext";
import {replaceIndex} from "../../core/manager/ManagerUtils";
import AuthContext from "../../contexts/auth/authContext";
import TextInput from "../../core/common/inputs/TextInput";
import PasswordInput from "../../core/common/inputs/PasswordInput";

import "./Auth.css";
import "./login.css";
import {getHomeLink} from "../../routes/routes/HomeRoutes";


const Login = (props) => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const {settingData, GetResource, getKeyword, getCurrentProfil} = useContext(SharedContext);
    const {isAuth, login} = authContext;
    const [isLoading, setIsLoading] = useState(false);
    const [focusedEmail, setfocusedEmail] = React.useState(false);
    const [focusedPassword, setfocusedPassword] = React.useState(false);
    const [hidePassword, setHidePassword] = React.useState(true);
    const [showStartFrame, setShowStartFrame] = React.useState(true);
    const [loginType, setLoginType] = React.useState("external");
    const [loginTxt, setLoginTxt] = React.useState("external");

    const [errors, setErrors] = useState({
        username: null,
        password: null,
        response: null,
        forceValidation: false,
    });
    const [formData, setFormData] = useState({
        username: "",
        password: "",
    });

    useEffect(() => {
        console.log("props", props);
        console.log("props", props.location.state?.type);
        switch (props.location.state?.type) {
            case "filiale":
                setLoginType("filiale");
                setLoginTxt("en tant que filiale");
                break;
            case "startup":
                setLoginType("startup");
                setLoginTxt("en tant que startup");
                break;
            case "interne":
                setLoginType("interne");
                setLoginTxt("en tant que fondation");
                break;
            default:
                setLoginType("guest");
                setLoginTxt("");
                break;

        }
    }, [props]);


    useEffect(() => {
        if (isAuth) {
            redirectUser();
        } else {
        }
    }, [history, isAuth]);

    const {username, password} = formData;

    const updateFormData = (val, name) => {
        const newForm = {
            ...formData,
            [name]: val,
        };
        setFormData(newForm);
        if (errors.forceValidation) {
            validate(newForm, false);
        }
    };

    const redirectUser = () => {
        let url = new URLSearchParams(props.location.search).get("redirect");
        if (url && url.indexOf("auth") < 0) {
            history.push(url);
        } else {
            getCurrentProfil().then(profile => {
                history.push(getHomeLink(profile));
            })
        }
    }

    const mySubmitHandler = (event) => {
        event.preventDefault();

        if (!validate(formData, true)) {
            return;
        }

        setIsLoading(true);

        login({...formData})
            .then(() => setIsLoading(false))
            .then(() => {
                // Logger("LOGGEDIN_SUCCESS", true, "success");
                redirectUser();
            })
            .catch((err) => {
                if (err.status == 403 && err.data.message == "USER_DISABLED") {
                    setErrors({
                        forceValidation: errors.forceValidation,
                        response: getKeyword("LOGIN.DEASACTIVATED_USER"),
                    });
                } else if ((err.status == 403 && err.data.message == "USER_BLOCKED") || (err.data && err.data.detail == "USER_BLOCKED")) {
                    setErrors({
                        forceValidation: errors.forceValidation,
                        response: getKeyword("LOGIN.BLOCKED_USER"),
                    });
                } else if (err.status == 403 && err.data.message == "PASSWORD_EXPIRED") {
                    history.push("/auth/expired-password?username=" + formData.username);
                } else {
                    setErrors({
                        forceValidation: errors.forceValidation,
                        response: getKeyword("LOGIN.INVLALID_LOGIN"),
                    });
                }
            });
    };

    const validate = (newForm, forceValidation) => {
        if (newForm.username === "" || newForm.password === "") {
            setErrors({
                forceValidation: forceValidation ? true : errors.forceValidation,
                username: newForm.username ? null : replaceIndex(getKeyword("STD_ERROR.REQUIRED_FILED"), ["0"], [getKeyword("USER_DATA.USERNAME")]),
                password: newForm.password ? null : replaceIndex(getKeyword("STD_ERROR.REQUIRED_FILED"), ["0"], [getKeyword("USER_DATA.PASSWORD")]),
            });
            return false;
        }

        setErrors({
            forceValidation: forceValidation ? true : errors.forceValidation,
        });
        return true;
    };

    function showHidePassword() {
        setHidePassword(!hidePassword);
    }
    const navigateTo = (path) => {
        // history.push("/auth/"+path+"?type=" + loginType)
        history.push({
            pathname: '/auth/'+path,
            state: {type : loginType},
        })
    }
    return (

       <div className="parentBlock">

                   <div className="login">
                       <section className="vh-100">
                           <div className="container justify-content-center">
                               <div className="row justify-content-center align-items-center vh-100">
                                   <div className="col-md-12 col-sm-12 content">
                                       <form  onSubmit={mySubmitHandler}>
                                           <h3 className="fw-normal mb-3 pb-3 text">S'identifier  {loginTxt} </h3>
                                           <div className="form-group mb-4 input1">
                                               {/*<input type="email" id="form2Example18"*/}
                                               {/*       className="form-control inpu"/>*/}
                                               <TextInput
                                                   label={getKeyword("LOGIN.IDENTIFIER")}
                                                   name="username"
                                                   onChange={updateFormData}
                                                   placeholder=""
                                                   value={username}
                                                   error={errors.username}
                                               />

                                           </div>

                                           <div className="form-group mb-4 input1">
                                               <PasswordInput
                                                   label={getKeyword("LOGIN.PASSWORD")}
                                                   placeholder=""
                                                   type="password"
                                                   name="password"
                                                   onChange={updateFormData}
                                                   value={password}
                                                   error={errors.password}
                                               />

                                           </div>
                                           {!errors.response ? null : (
                                               <p className="text-danger mb-0">{errors.response}</p>
                                           )}
                                           <div className="d-grid gap-2">
                                               <button className="btn btn-success">Connexion</button>
                                           </div>

                                           <div className="text-center mt-3">
                                               <p className="text_3">Vous avez déjà un compte ? <a onClick={()=> navigateTo('invite')} className="link">Inscription</a>
                                               </p>
                                           </div>
                                           <div className=" mt-3">
                                                <p className="text_3">  <a  href="/auth/reset"  className="link">{getKeyword("LOGIN.FORGET_PASSWORD")}</a>
                                               </p>
                                           </div>
                                           <div className="blockTxt mt-3">
                                               <a href="/auth/lunch" className="launchClick" >&larr; retour</a>
                                           </div>
                                       </form>
                                   </div>
                               </div>
                           </div>
                       </section>


                       {/*<div className='loginForm'>*/}
                       {/*    <div className=" pt-5 mb-3 form-login">*/}
                       {/*        <div className=" bg-image">*/}
                       {/*            <div lg="6" md="7">*/}
                       {/*                <div className="bg-secondary border-0 mb-0">*/}
                       {/*                    <div className="px-lg-5 py-lg-2">*/}
                       {/*                        <div className=" text-muted mb-4">*/}
                       {/*                            <h1 className="font-25 text-uppercase">*/}
                       {/*                                Bienvenu*/}
                       {/*                            </h1>*/}
                       {/*                            <h2 className="font-30">Se connecter</h2>*/}
                       {/*                        </div>*/}
                       {/*                        <form role="form" onSubmit={mySubmitHandler}>*/}
                       {/*                            <div*/}
                       {/*                                className={classnames("mb-4", {*/}
                       {/*                                    focused: focusedEmail,*/}
                       {/*                                })}*/}
                       {/*                            >*/}
                       {/*                                <div className="input-group-merge input-group-alternative">*/}
                       {/*                                    <TextInput*/}
                       {/*                                        label={getKeyword("LOGIN.IDENTIFIER")}*/}
                       {/*                                        name="username"*/}
                       {/*                                        onChange={updateFormData}*/}
                       {/*                                        placeholder=""*/}
                       {/*                                        value={username}*/}
                       {/*                                        error={errors.username}*/}
                       {/*                                    />*/}
                       {/*                                </div>*/}
                       {/*                            </div>*/}
                       {/*                            <div*/}
                       {/*                                className={classnames({*/}
                       {/*                                    focused: focusedPassword,*/}
                       {/*                                })}*/}
                       {/*                            >*/}
                       {/*                                <PasswordInput*/}
                       {/*                                    label={getKeyword("LOGIN.PASSWORD")}*/}
                       {/*                                    placeholder=""*/}
                       {/*                                    type="password"*/}
                       {/*                                    name="password"*/}
                       {/*                                    onChange={updateFormData}*/}
                       {/*                                    value={password}*/}
                       {/*                                    error={errors.password}*/}
                       {/*                                />*/}
                       {/*                            </div>*/}
                       {/*                            {!errors.response ? null : (*/}
                       {/*                                <p className="text-danger mb-0">{errors.response}</p>*/}
                       {/*                            )}*/}
                       {/*                            <div className="justify-content-between align-items-center">*/}
                       {/*                                <div className="">*/}
                       {/*                                    <a href="/auth/reset">*/}
                       {/*                                        <small className=" text_dark border-bottom border-dark">*/}
                       {/*                                            {getKeyword("LOGIN.FORGET_PASSWORD")}*/}
                       {/*                                        </small>*/}
                       {/*                                    </a>*/}
                       {/*                                </div>*/}
                       {/*                                <div className="">*/}
                       {/*                                    <button*/}
                       {/*                                        className="btn-1"*/}
                       {/*                                        type="submit"*/}
                       {/*                                    >*/}
                       {/*                                        Se connecter*/}
                       {/*                                    </button>*/}
                       {/*                                </div>*/}
                       {/*                            </div>*/}
                       {/*                        </form>*/}
                       {/*                    </div>*/}
                       {/*                </div>*/}
                       {/*                <div className="">*/}
                       {/*                    <div className="text-right" xs="6"></div>*/}
                       {/*                </div>*/}
                       {/*            </div>*/}
                       {/*        </div>*/}
                       {/*    </div>*/}
                       {/*</div>*/}
                   </div>



       </div>


)

};

export default Login;
