import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import AuthContext from "../../contexts/auth/authContext";
import Logger from "../../core/utils/Logger";
import { URL_BASE } from "../../config";
import { HOMEPAGE } from "../../config";
import { validateEmail } from "../../core/utils/helpers";
import SharedContext from "../../contexts/shared/sharedContext";
import TextInput from "../../core/common/inputs/TextInput.js";

const ResetPassword = (props) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const { GlobalPost, getKeyword } = useContext(SharedContext);

  const { isAuth, login } = authContext;
  const [isLoading, setIsLoading] = useState(false);
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [errors, setErrors] = useState({
    username: null,
    forceValidation: false,
  });
  const [formData, setFormData] = useState({
    username: "",
  });

  useEffect(() => {
    if (isAuth) {
      history.push(URL_BASE + HOMEPAGE);
      Logger("LOGGEDIN_SUCCESS", true, "success");
    }
  }, [history, isAuth]);

  const { username, password } = formData;

  const updateFormData = (value, name) => {
    const newForm = {
      ...formData,
      [name]: value,
    };
    setFormData(newForm);
    if (errors.forceValidation) {
      validate(newForm, false);
    }
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();

    validate(formData, true);

    setIsLoading(true);

    GlobalPost({ ...formData }, "/auth/reset/send", null)
      .then(() => setIsLoading(false))
      .then(() => {
        setDone(true);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 403 && err.response.data.message == "USER_DISABLED") {
            setErrors({
              forceValidation: errors.forceValidation,
              response: getKeyword("LOGIN.DEASACTIVATED_USER"),
            });
          } else if ((err.response.status == 403 && err.response.data.message == "USER_BLOCKED") || (err.response.data && err.response.data.detail == "USER_BLOCKED")) {
            setErrors({
              forceValidation: errors.forceValidation,
              response: getKeyword("LOGIN.BLOCKED_USER"),
            });
          } else {
            setErrors({
              forceValidation: errors.forceValidation,
              response: getKeyword("LOGIN.NO_ACCOUNT_ATTACHED"),
            });
          }
        } else {
          setErrors({
            forceValidation: errors.forceValidation,
            response: getKeyword("LOGIN.NO_ACCOUNT_ATTACHED"),
          });
        }
      });
  };

  const validate = (newForm, forceValidation) => {
    if (!newForm.username) {
      setErrors({
        forceValidation: forceValidation ? true : errors.forceValidation,
        username: newForm.username ? null : "Username requis",
      });
      return false;
    }

    if (!validateEmail(newForm.username)) {
      setErrors({
        forceValidation: forceValidation ? true : errors.forceValidation,
        username: "Invalid email",
      });
      return false;
    }
    setErrors({
      forceValidation: forceValidation ? true : errors.forceValidation,
    });
    return true;
  };

  return (
    <>
      <div className=" pt-5 mb-3 form-login password-oublie">
        {done ? (
          <div className=" align-items-center flex-column ">
            <div className="card card-body ">
              <h2><i className="fas fa-check mr-2"></i> Demande envoyée avec succès</h2>
            </div>
            <a href="/auth/login" className="btn btn-danger rounded-pill">Retour à la connexion</a>
          </div>
        ) : (
          <div className=" bg-image pt-lg-5">
            <div lg="6" md="7 " className="">
              <div className="bg-secondary border-0 mb-0">
                <div className="px-lg-5 py-lg-4">
                  <div className=" text-muted mb-4">
                    <h1 className="font-30 text-uppercase mb-4">
                      MOT DE PASSE OUBLIÉ ?
                    </h1>
                    <h2 className="font-17">Veuillez saisir votre adresse e-mail 
                      pour récupérer votre compte.</h2>
                  </div>
                  <form role="form" onSubmit={mySubmitHandler}>
                    <div
                    >

                      <div className="input-group-merge input-group-alternative">
                        <TextInput
                          label={getKeyword("LOGIN.IDENTIFIER")}
                          name="username"
                          onChange={updateFormData}
                          value={username}
                          errors={errors.username}
                        />
                      </div>
                    </div>
                    {!errors.response ? null : (
                      <p className="text-danger mb-0">{errors.response}</p>
                    )}
                    <div className="row justify-content-between align-items-center">
                      <div xs=" col-md-6 12 ">
                        <a   href="/auth/login">
                          <small className=" text_dark border-bottom border-dark">
                            Retour à la connexion
                          </small>
                        </a>
                      </div>
                      <div xs=" col-md-6 12 text-center text-lg-right">
                        <button
                          className="btn-1"
                          type="submit"
                        >
                          Envoyer
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="">
                <div className="text-right" xs="6"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ResetPassword;
