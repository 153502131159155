import TextInput from "../../../core/common/inputs/TextInput";
import { getValueManager } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";

import { Modal } from "antd";

const QSQuestionCreatorCardBlockSetting = (props) => {
  const { GetResource, getKeyword } = useContext(SharedContext);
  const [form, setForm] = useState(JSON.parse(JSON.stringify(props.item.item)));
  const oldName = props.item.item.name;
  const id = getValueManager(form, "id", null);
  const [isLoading, setIsLoading] = useState(false);

  const notify = (type) => {
    // let options = {
    //   place: "tc",
    //   message: (
    //     <div className="alert-text">
    //       <span className="alert-title" data-notify="title">
    //         &nbsp;
    //         {replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")])}
    //       </span>
    //     </div>
    //   ),
    //   type: type,
    //   icon: "ni ni-bell-55",
    //   autoDismiss: 7,
    // };
    // notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => { }, []);

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    setForm({ ...f });
  };

  const updateTable = (event) => {
    props.updateSettings(form);
    props.toggleModal(props.modalName, null);
  };

  const formValid = getValueManager(form, "title", null)
    ;

  return (
    <div>
      {/* <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div> */}
      <Modal
        className="modal-dialog-centered"
        size="lg"
        style={{ maxWidth: "1000px", width: "100%" }}
        open={props.showModal && !isLoading}
        width={"1000px"}
        onCancel={() => props.toggleModal(props.modalName)}
      >
        <div className="modal-body p-0">
          <div className="bg-secondary  border-0 mb-0">
            <div className="bg-menu">
              <div className="text-center">
                <p className="font-weight-bold text-white mb-0">Paramètres</p>
              </div>
            </div>
            <div className="">
              <form role="form">
                <div className="row">
                  <div className="col-md-12">
                    <TextInput
                      label={"Titre"}
                      name="title"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "title", null)}
                      required={true}
                    ></TextInput>
                  </div>
                </div>

                <div className="text-center d-flex justify-content-between">
                  <button
                    className="my-4"
                    color="white"
                    type="button"
                    onClick={(e) => props.toggleModal(props.modalName)}
                  >
                    {getKeyword("STD_VERB.CANCEL")}
                  </button>
                  <button
                    className="my-4"
                    color="danger"
                    type="button"
                    disabled={!formValid}
                    onClick={(e) => updateTable()}
                  >
                    {getKeyword("STD_VERB.EDIT")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default QSQuestionCreatorCardBlockSetting;
