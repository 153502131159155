import React, { useState, useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Page404 from "../../modules/pages/404";
import { ExternalHeaderRoutes, ExternalRoutes, MoreExternalRoutes } from "../../routes/routes/ExternalRoutes";
import AdminHeader from "../Header/AdminHeader";

import './Layout.css';
import { LayoutProvider } from "./LayoutHelpers";

const ExternalLayout = (props) => {
    return <div>
        <AdminHeader routes={ExternalRoutes} topRoutes={ExternalHeaderRoutes}></AdminHeader>
        <div className="layout-style">
            <Switch>
                {ExternalRoutes.map(route => <Route
                    key={route.name}
                    exact
                    path={route.path}
                    render={(props) => <LayoutProvider props={props} menu={route.menu} component={route.component}></LayoutProvider>}
                />)}
                {MoreExternalRoutes.map(route => <Route
                    key={route.name}
                    exact
                    path={route.path}
                    render={(props) => <LayoutProvider props={props} menu={route.menu} component={route.component}></LayoutProvider>}
                />)}
                {ExternalHeaderRoutes.filter(f => !f.children || f.children.length === 0).map(route => <Route
                    key={route.name}
                    exact
                    path={route.path}
                    render={(props) => <LayoutProvider props={props} menu={route.menu} component={route.component}></LayoutProvider>}
                />)}
                {ExternalHeaderRoutes.map(routeR => routeR.children.map(route => <Route
                    key={route.name}
                    exact
                    path={route.path}
                    render={(props) => <LayoutProvider props={props} menu={route.menu} component={route.component}></LayoutProvider>}
                />))}
                <Route path="**" component={Page404} />
            </Switch>
        </div>
    </div>
        ;
};

export default ExternalLayout;
