import { ValidationEnum } from "./ManagerEnum";

export const getValueManager = (obj, name, indexes = [], isImage = false) => {
  if (!obj || !name) {
    return "";
  }
  let res = "";
  let currentObject = obj;
  const index = name.split(".");
  index.forEach((v, i) => {
    if (currentObject) {
      if (i === index.length - 1) {
        res = getObjFromArrayOrObject(currentObject, v, indexes, isImage);
      } else {
        currentObject = getObjFromArrayOrObject(
          currentObject,
          v,
          indexes,
          isImage
        );
      }
    } else {
      currentObject = null;
    }
  });
  return res;
};

export const updateValueManager = (
  obj,
  name,
  value,
  indexes = [],
  isImage = false
) => {
  if (!obj || !name) {
    return obj;
  }
  let currentObject = obj;
  const index = name.split(".");
  index.forEach((v, i) => {
    if (currentObject) {
      if (i === index.length - 1) {
        currentObject[v] = value;
      } else {
        // currentObject = currentObject[v];
        currentObject = getObjFromArrayOrObject(
          currentObject,
          v,
          indexes,
          isImage
        );
      }
    } else {
      currentObject = null;
    }
  });
  return obj;
};

const getObjFromArrayOrObject = (val, name, indexes, isImage) => {
  if (!name.startsWith("?")) {
    return val[name];
  } else {
    const newName = name.replace("?", "");
    const i = parseInt(newName);
    if (isImage) {
      if (val && val.length > i) {
        return val[i];
      }
    } else {
      if (indexes && indexes.length > i) {
        return val[indexes[i]];
      }
    }
  }
  return null;
};

export const validate = (obj, v) => {
  if (v.validation === ValidationEnum.REQUIRED) {
    return getValueManager(obj, v.name);
  }
  return true;
};

export const replaceIndex = (str, indexes, values) => {
  if (!str || !indexes || !values) {
    return str;
  }
  indexes.forEach((i, ii) => {
    str = str.replace(`$${i}$`, values[ii]);
  });
  return str;
};

export const updateObjectFromImage = (form, images) => {
  Object.keys(images).forEach((k) => {
    updateValueManager(
      form,
      k,
      images[k].result.length > 0
        ? images[k].multiple
          ? [...getValueManagerArray(form, k), ...images[k].result]
          : images[k].result[0]
        : images[k].multiple
          ? [...getValueManagerArray(form, k)]
          : null,
      [],
      true
    );
  });
  return form;
};

export const getTranslation = (
  obj,
  name,
  lng = null,
  indexes = [],
  isImage = false
) => {
  if (lng) {
    return (
      getValueManager(obj, "translation." + lng + "." + name, indexes, isImage) ||
      getValueManager(obj, name, indexes, isImage)
    );
  }
  return getValueManager(obj, name, indexes, isImage);
};

export const replaceValuesInJson = (obj, name, val) => {
  return { ...obj, [name]: val };
};

export const getValueManagerNumberFloat = (obj, name, indexes) => {
  let val = getValueManager(obj, name, indexes, false);
  val = val ? parseFloat(val) : 0;
  return val ? val : 0;
};

export const getValueManagerNumberFloatInput = (obj, name, indexes) => {
  let val = getValueManager(obj, name, indexes, false);
  let toAdd = "";
  if ((val + "").endsWith(".")) {
    toAdd = ".";
  }
  val = val ? parseFloat(val) : 0;
  return val ? val + toAdd : 0 + toAdd;
};

export const getValueManagerNumber = (obj, name, indexes, defaultValue = 0) => {
  let val = getValueManager(obj, name, indexes, false);
  val = val || val === "0" || val === 0 ? parseInt(val) : defaultValue;
  return val || val === 0 ? val : defaultValue;
};

export const getValueManagerArray = (obj, name, indexes) => {
  let arr = getValueManager(obj, name, indexes, false);
  return !arr || arr.constructor.name !== "Array" ? [] : arr;
};


export const isTypeOf = (obj, type, defaultVal = false) => {
  if (!obj) {
    return defaultVal;
  }
  return obj.constructor.name === type;
}

export const objDiff = (obj1, obj2, oldKey = "") => {
  const diffs = [];
  Object.keys(obj1).forEach(key => {
    const val = obj1[key];
    const fullKey = oldKey + key;
    const val2 = getValueManager(obj2, fullKey);
    if (isTypeOf(val, "Object")) {
      objDiff(val, obj2, fullKey + ".").forEach(kk => diffs.push(kk));
    } else if (isTypeOf(val, "Array") && !arrayEquals(val, val2)) {
      diffs.push(fullKey);
    } else {
      if (val != val2) {
        diffs.push(fullKey);
      }
    }
  })
  return diffs;
}

export const arrayEquals = (a, b) => {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val == b[index]);
}


export const arrayContains = (arr, val) => {
  if (!arr || !isTypeOf(arr, "Array")) {
    return false;
  }
  return !!arr.find(f => f == val);
}


export const getArray = (arr) => {
  if (arr && isTypeOf(arr, "Array")) {
    return arr;
  }
  return [];
}

export const getObject = (obj) => {
  if (obj && isTypeOf(obj, "Object")) {
    return obj;
  }
  return {};
}

export const getJsonArray = (json) => {
  if (!json) {
    return [];
  }
  try {
    return getArray(JSON.parse(json));
  } catch (err) { }
  return [];
}

export const getJson = (json) => {
  if (!json) {
    return {};
  }
  try {
    return getObject(JSON.parse(json));
  } catch (err) { }
  return {};
}

export const parseJsonArray = (json) => {
  if (!json) {
    return null;
  }
  try {
    return JSON.stringify(getArray(json));
  } catch (err) { }
  return null;
}

export const parseJsonObject = (json) => {
  if (!json) {
    return null;
  }
  try {
    return JSON.stringify(getObject(json));
  } catch (err) { }
  return null;
}

export const isEmpty = (val) => {
  return (!val && val !== "0" && val !== 0 && val !== false && val !== "false") || (isTypeOf(val, "Array") && getArray(val).length == 0);
}

export const getArrayFromVal = (val) => {
  if (isTypeOf(val, "Array")) {
    return val;
  }
  return [val];
}

export const isArrayOfOrderBigger = (arr1, arr2) => {
  const arrT1 = getArrayFromVal(arr1);
  const arrT2 = getArrayFromVal(arr2);
  let decision = false;
  let theDecision = null;
  arrT1.forEach((v, i) => {
    if (!decision) {
      if (arrT2.length <= i) {
        decision = true;
        theDecision = true;
      }
      if (v > arrT2[i]) {
        decision = true;
        theDecision = true;
      } else if (v < arrT2[i]) {
        decision = true;
        theDecision = false;
      }
    }
  })
  if (decision) {
    return theDecision;
  }
  return true;
}
