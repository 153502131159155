import { dateToString } from "../../../core/utils/helpers";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";

import { REFERENCE_DATA_SUCCESS } from "../../../contexts/shared/types";

export const ReferencielTableProvider = (props) => {
  const sharedContext = useContext(SharedContext);
  const { GetResource, referenceData } = sharedContext;
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (props.code && !referenceData[props.code]) {
      GetResource(
        "TABLE_REFERENTIEL",
        "GET_LINE_BY_CODE",
        { value: props.code },
        REFERENCE_DATA_SUCCESS
      ).then((res) => {});
    } else {
      setItems([]);
    }
  }, [props.code]);

  const onChange = (value) => {
    if (props.onChange) {
      props.onChange(dateToString(value), props.name);
    }
  };

  const val =
    props.code &&
    referenceData[props.code] &&
    referenceData[props.code].designation;

  return (
    <>{val}</>
  );
};

export default ReferencielTableProvider;
