import React, { useContext } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import TooltipProvider from "../outputs/TooltipProvider";

import "./Inputs.css";

export const CheckboxInput = (props) => {
  const { getKeyword } = useContext(SharedContext);
  const onChange = (event) => {
    if (props.onChange) {
      props.onChange(
        (props.always && true) || event.target.checked,
        props.name
      );
    }
  };

  return (
    <div className={`group ${props.classes ? props.classes : ""}`}>
      <label className="ft">
        <input
          type="checkbox"
          onChange={onChange}
          checked={props.value}
        ></input>
        <p className="check">
          <span className="rd"></span>
          <span className="text">
            {props.value ? getKeyword("STD.YES") : getKeyword("STD.NO")}
          </span>
        </p>
        {props.label ? (
          <label className="Ilabel">
            {getKeyword(props.label)}  <span className="requiredLb">{props.required ? " *" : ""}</span>
            <TooltipProvider tooltip={props.description}></TooltipProvider>
          </label>
        ) : null}
      </label>
      {props.error ? (
        <p className="errorInput">
          <i className="fas fa-exclamation-triangle"></i> {props.error}
        </p>
      ) : null}
    </div>
  );
};

export default CheckboxInput;
