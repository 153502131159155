import { getArray } from "../../core/manager/ManagerUtils";
import { userHasOneOfPermissions, userIsOfTypes } from "../../core/utils/security";

export const HomeRoutes = [{
    "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
    // "pTypes": ["USER_TYPE_INTERNAL"],
    // "pRoles": ["PFLV"],
    "path": "/admin/user-management"
},
{
    "pTypes": ["USER_TYPE_INTERNAL"],
    // "pRoles": ["PFLV", "PSTV", "PATV", "PCTV", "PRPV", "PPRV", "PQSV", "PTBV", "PFBV", "PFBU", "PFBG"],
    "path": "/admin/profile"
},
{
    "pTypes": ["USER_TYPE_INTERNAL", "USER_TYPE_SUPER_ADMIN"],
    "path": "/admin/profile"
},
{
    "pTypes": ["USER_TYPE_STARTUP", "USER_TYPE_AFFILIATE"],
    "path": "/external/feedback"
},
]

export const getHomeLink = (profile) => {
    if (profile) {
        const homeLink = HomeRoutes.find(permission => {
            return (userIsOfTypes(profile, getArray(permission.pForcedTypes), false) ||
                (
                    (getArray(permission.pTypes).length > 0 || getArray(permission.pRoles).length > 0) &&
                    userIsOfTypes(profile, getArray(permission.pTypes), true) &&
                    userHasOneOfPermissions(profile, permission.pRoles, true)))

        });
        if (homeLink) {
            return homeLink.path;
        }
    }
    return "/no-permission";

}