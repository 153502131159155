import AreaInput from "../../../core/common/inputs/AreaInput";
import TextInput from "../../../core/common/inputs/TextInput";
import { getValueManager } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { getValueManagerArray } from "../../../core/manager/ManagerUtils";
import SelectInputResource from "../../../core/common/inputs/SelectInputResource";
import { replaceIndex } from "../../../core/manager/ManagerUtils";
import ReferentielTable from "../../../variables/referentiel-table";
import SelectInputTableTechnique from "../../../core/common/inputs/SelectInputTableTechnique";
import QuestionFormTableType from "./question-form-table";
import SharedContext from "../../../contexts/shared/sharedContext";
import { Modal } from "antd";
import Logger from "../../../core/utils/Logger";
import CodeInput from "../../../core/common/inputs/CodeInput";
import CheckboxStyle from "../../../core/common/inputs/Checkbox";
import TechnicalTable from "../../../variables/technical-table";

const QSQuestionForm = (props) => {
  const { GetResource, getKeyword } = useContext(SharedContext);
  const form = props.form;
  const id = getValueManager(form, "id", null);
  const [isLoading, setIsLoading] = useState(false);

  const notify = (type) => {
    Logger(replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")]));
  };

  useEffect(() => { }, []);

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    props.updateForm({ ...f });
  };

  const addNewTable = (event) => {
    GetResource("QUESTION_MANAGEMENT", "ADD", form, null)
      .then((res) => {
        props.onAdd(res.payload.id);
        props.toggleModal(props.modalName);
      })
      .catch((err) => {
        if (err && err.status == 409) {
          notify("danger");
        }
      });
  };

  const updateTable = (event) => {
    GetResource("QUESTION_MANAGEMENT", "UPDATE", form, null).then((res) => {
      props.onUpdate(res.payload.id);
      props.toggleModal(props.modalName);
    });
  };


  const formValid =
    getValueManager(form, "name", null) && getValueManager(form, "type", null) && (
      !(getValueManager(form, "type", null) == "QUESTION_TYPE_CHOICE" ||
        getValueManager(form, "type", null) == "QUESTION_TYPE_CHOICE_MULTIPLE" ||
        getValueManager(form, "type", null) == "QUESTION_TYPE_RADIO" ||
        getValueManager(form, "type", null) == "QUESTION_TYPE_RADIO_MULTIPLE") || getValueManager(form, "response", null)
    ) &&
    (getValueManager(form, "type", null) != "QUESTION_TYPE_TABLE"
      || !getValueManagerArray(form, "lines").find(f => !getValueManager(f, "name", null) || !getValueManager(f, "type", null)
        || !(
          !(getValueManager(f, "type", null) == "QUESTION_TYPE_CHOICE" ||
            getValueManager(f, "type", null) == "QUESTION_TYPE_CHOICE_MULTIPLE" ||
            getValueManager(f, "type", null) == "QUESTION_TYPE_RADIO" ||
            getValueManager(f, "type", null) == "QUESTION_TYPE_RADIO_MULTIPLE") || getValueManager(f, "response", null)
        )))
    && (getValueManager(form, "type", null) != "QUESTION_TYPE_API" || (getValueManager(form, "apiMethod", null) && getValueManager(form, "apiLink", null)
      && getValueManager(form, "apiSelect", null)));

  return (
    <div>
      {/* <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div> */}
      <Modal
        className="modal-dialog-centered"
        size="lg"
        style={{ maxWidth: "1000px", width: "100%" }}
        open={props.showModal && !isLoading}
        width={"1000px"}
        onCancel={() => props.toggleModal(props.modalName)}
      >
        <div className="modal-body p-0">
          <div className="bg-secondary  border-0 mb-0">
            <div className="bg-menu">
              <div className="text-center">
                <p className="font-weight-bold text-white mb-0">{getKeyword("QUESTION.QUESTION")}</p>
              </div>
            </div>
            <dov className="">
              <form role="form">
                <div className="row">
                  <div className="col-md-6">
                    <TextInput
                      label={getKeyword("COMMON.DESIGNATION")}
                      name="name"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "name", null)}
                      required={true}
                    ></TextInput>
                  </div>
                </div>
                <div className="col-md-12">
                  <SelectInputTableTechnique
                    label="Type"
                    name="type"
                    onChange={(v, n) => onChange(v, n)}
                    value={getValueManager(form, "type", null)}
                    code={ReferentielTable.QUESTION_TYPE}
                    required={true}
                    sort={(a, b) => a.designation == b.designation ? 0 : a.designation > b.designation ? -1 : 1}
                  ></SelectInputTableTechnique>
                </div>


                {getValueManager(form, "type", null) == "QUESTION_TYPE_FILE"
                  || getValueManager(form, "type", null) == "QUESTION_TYPE_IMAGE"
                  || getValueManager(form, "type", null) == "QUESTION_TYPE_API" ?
                  <div className="col-md-12">
                    <CheckboxStyle
                      label={"Multiple?"}
                      name="multiple"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "multiple", null)}
                    ></CheckboxStyle>
                  </div> : null}

                {getValueManager(form, "type", null) == "QUESTION_TYPE_TABLE" ?
                  <div className="col-md-12"><QuestionFormTableType onChange={onChange} form={form}></QuestionFormTableType></div> : null}
                {getValueManager(form, "type", null) == "QUESTION_TYPE_CHOICE" ||
                  getValueManager(form, "type", null) == "QUESTION_TYPE_CHOICE_MULTIPLE" ||
                  getValueManager(form, "type", null) == "QUESTION_TYPE_RADIO" ||
                  getValueManager(form, "type", null) == "QUESTION_TYPE_RADIO_MULTIPLE"
                  ?
                  <>
                    <div className="col-md-12"><SelectInputResource
                      label="Réponse"
                      name="response"
                      resource="TABLE_RESPONSES"
                      method="GET_ALL"
                      required={true}
                      multiple={false}
                      onChange={(v, n) =>
                        v ? onChange({ id: v },
                          n
                        ) : onChange(null,
                          n
                        )
                      }
                      value={getValueManager(form, "response.id", null)}
                      map={{
                        select: "id",
                        label: "designation",
                      }}
                    ></SelectInputResource></div>
                  </>
                  : null}
                {getValueManager(form, "type", null) == "QUESTION_TYPE_CHOICE" ||
                  getValueManager(form, "type", null) == "QUESTION_TYPE_RADIO" ||
                  (getValueManager(form, "type", null) == "QUESTION_TYPE_API" && !getValueManager(form, "multiple", null))
                  ?
                  <div className="col-md-12">
                    <CheckboxStyle
                      label={"Autoriser ajout d'autre valeur?"}
                      name="other"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "other", null)}
                    ></CheckboxStyle>
                  </div> : null}

                {getValueManager(form, "type", null) == "QUESTION_TYPE_API" ? <>
                  <div className="col-md-12">
                    <TextInput
                      label={"Lien"}
                      name="apiLink"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "apiLink", null)}
                      required={true}
                    ></TextInput>
                  </div>
                  <div className="col-md-12">
                    <SelectInputTableTechnique
                      label="Méthode"
                      name="apiMethod"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "apiMethod", null)}
                      code={TechnicalTable.API_METHOD}
                      required={true}
                    ></SelectInputTableTechnique>
                  </div>
                  <div className="col-md-12">
                    <AreaInput
                      label="Body"
                      name="apiBody"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "apiBody", null)}
                    ></AreaInput>
                  </div>
                  <div className="col-md-12">
                    <TextInput
                      label={"Attribut à afficher"}
                      name="apiLabel"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "apiLabel", null)}
                      required={true}
                    ></TextInput>
                  </div>
                  <div className="col-md-12">
                    <TextInput
                      label={"Attribut à sélectionner"}
                      name="apiSelect"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "apiSelect", null)}
                      required={true}
                    ></TextInput>
                  </div>
                </> : null}

                <div className="col-md-12">
                  <AreaInput
                    label={getKeyword("COMMON.DESCRIPTION")}
                    name="description"
                    onChange={(v, n) => onChange(v, n)}
                    value={getValueManager(form, "description", null)}
                  ></AreaInput>
                </div>

                <div className="text-center d-flex justify-content-between">
                  <button
                    className="my-4"
                    color="white"
                    type="button"
                    onClick={(e) => props.toggleModal(props.modalName)}
                  >
                    {getKeyword("STD_VERB.CANCEL")}
                  </button>
                  <button
                    className="btn-1"
                    color="danger"
                    type="button"
                    disabled={!formValid}
                    onClick={(e) => (id ? updateTable() : addNewTable())}
                  >
                    {id ? getKeyword("STD_VERB.EDIT") : getKeyword("STD_VERB.ADD")}
                  </button>
                </div>
              </form>
            </dov>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default QSQuestionForm;
