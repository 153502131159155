import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { API_TYPE_SUCCESS } from "../../../contexts/shared/types";

import "./Inputs.css";
import SelectInput from "./SelectInput";
import SelectMultipleFull from "./SelectMultipleFull";

export const SelectInputApi = (props) => {
  const { GlobalGet, GlobalPost } = useContext(SharedContext);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const onChange = (value) => {
  //   if (props.onChange) {
  //     if (props.multiple) {
  //       props.onChange(
  //         value.map((value2) => value2.value),
  //         props.name
  //       );
  //     } else {
  //       props.onChange(value.value, props.name);
  //     }
  //   }
  // };

  const onChange = (val, name) => {
    if (props.onChange) {
      props.onChange(val, name);
    }
  };

  useEffect(() => {
    if (props.api) {
      try {
        if (props.api.apiMethod == "API_METHOD_GET") {
          setIsLoading(true);
          GlobalGet(!props.api.apiBody ? null : JSON.parse(props.api.apiBody), props.api.apiLink, null)
            .then((res) => {
              setItems(
                res.payload.map((o) => ({
                  ...o,
                  value: props.api.apiLabel ? o[props.api.apiSelect] : o,
                  label: props.api.apiLabel ? o[props.api.apiLabel] : o,
                }))
              );
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        } else if (props.api.apiMethod == "API_METHOD_POST") {
          setIsLoading(true);
          GlobalPost(!props.api.apiBody ? null : JSON.parse(props.api.apiBody), props.api.apiLink, null)
            .then((res) => {
              setItems(
                res.payload.map((o) => ({
                  ...o,
                  value: props.api.apiLabel ? o[props.api.apiSelect] : o,
                  label: props.api.apiLabel ? o[props.api.apiLabel] : o,
                }))
              );
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        }
      } catch (exceptionVar) {
        console.log("API ERROR", exceptionVar);
      }

    } else {
      // setItems([]);
    }
  }, [props.api && props.api.apiLink, props.api && props.api.apiMethod]);

  // const finalValues =
  //   (props.value &&
  //     props.multiple &&
  //     items.filter((i) => props.value.find((ii) => i.value == ii))) ||
  //   (props.value && !props.multiple && items.find((i) => i.value == props.value)) || null;

  let itemsF = items;
  if (props.filter) {
    itemsF = items.filter(item => props.filter(item));
  }

  return (
    <div className="">
      {props.isLoading ? (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <>
          {props.tableStyle ? <SelectMultipleFull
            placeholder={props.placeHolder}
            label={props.label}
            value={props.value}
            name={props.name}
            data={{
              items: itemsF,
              label: "label",
              select: "value"
            }}
            onChange={onChange}
            multiple={props.multiple}
            disabled={props.disabled}
            error={props.error}
          >
          </SelectMultipleFull> :
            <SelectInput
              placeholder={props.placeHolder}
              label={props.label}
              value={props.value}
              name={props.name}
              data={{
                items: itemsF,
                label: "label",
                select: "value"
              }}
              onChange={onChange}
              multiple={props.multiple}
              disabled={props.disabled}
              other={props.other}
              error={props.error}
              otherName={props.otherName}
              otherValue={props.otherValue}
              preview={props.preview}
              required={props.required}
            >
            </SelectInput>}
        </>
      )}
    </div>
  );
};

export default SelectInputApi;
