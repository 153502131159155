import { getValueManager } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { getValueManagerArray } from "../../../core/manager/ManagerUtils";
import { replaceIndex } from "../../../core/manager/ManagerUtils";
import FullInpurProvider from "../../../core/common/inputs/FullInputProvider";
import { Modal } from "antd";


const QSQuestionPreview = (props) => {
    const { GetResource, getKeyword } = useContext(SharedContext);
    const id = getValueManager(props.form, "id", null);
    const [isLoading, setIsLoading] = useState(false);

    const [form, setForm] = useState({});
    const [images, setImages] = useState({});

    const notificationAlertRef = React.useRef(null);
    const notify = (type) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        &nbsp;
                        {replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")])}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const onChange = (v, n, indexes = null) => {
        const f = updateValueManager(form, n, v, indexes);
        setForm({ ...f });
    };

    const onChangeImages = (
        files,
        name,
        multiple,
        indexes,
        usedFor = "QUESTION"
    ) => {
        images[name] = { files, name, multiple, indexes, usedFor };
        setImages({ ...images });
    };

    const formValid =
        getValueManager(form, "code", null) && getValueManager(form, "name", null) && getValueManager(form, "type", null);

    return (
        <div>
            {/* <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
            </div> */}
            <Modal
                className="modal-dialog-centered"
                size="lg"
                style={{ maxWidth: "1000px", width: "100%" }}
                open={props.showModal && !isLoading}
                width={"1000px"}
                onCancel={() => props.toggleModal(props.modalName)}
            >
                <div className="modal-body p-0">
                    <div className="bg-secondary  border-0 mb-0">
                        <div className="bg-menu">
                            <div className="text-center">
                                <p className="font-weight-bold text-white mb-0">{getKeyword("QUESTION.QUESTION")}</p>
                            </div>
                        </div>
                        <div className="">
                            <div className="row">
                                <div className="col-md-12">
                                    {props.question.description}
                                    <FullInpurProvider label={props.question.name} form={form} name={"preview"} onChange={onChange}
                                        other={props.question.other} multiple={props.question.multiple}
                                        api={{ apiLink: props.question.apiLink, apiMethod: props.question.apiMethod, apiBody: props.question.apiBody, apiLabel: props.question.apiLabel, apiSelect: props.question.apiSelect }}
                                        onChangeImages={onChangeImages} type={props.question.type} required={false} description={props.question.description}
                                        code={getValueManager(props.question, "response.id")} lines={getValueManagerArray(props.question, "lines")}></FullInpurProvider>
                                </div>
                            </div>
                            <div className="text-center d-flex justify-content-between">
                                <button
                                    className="my-4"
                                    color="white"
                                    type="button"
                                    onClick={(e) => props.toggleModal(props.modalName)}
                                >
                                    {getKeyword("STD_VERB.CLOSE")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    );
};
export default QSQuestionPreview;
