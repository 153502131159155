import { getValueManager } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "antd";
import { getValueManagerArray } from "../../../core/manager/ManagerUtils";
import { replaceIndex } from "../../../core/manager/ManagerUtils";
import TecTableProvider from "../../../core/common/outputs/TecTableProvider";
import { dateStrToTimeString } from "../../../core/utils/helpers";
import SharedContext from "../../../contexts/shared/sharedContext";
import Logger from "../../../core/utils/Logger";


const QSQuestionShow = (props) => {
    const { GetResource, getKeyword } = useContext(SharedContext);
    const form = props.form;
    const id = getValueManager(form, "id", null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const onChange = (v, n, indexes = null) => {
        const f = updateValueManager(form, n, v, indexes);
        props.updateForm({ ...f });
    };

    return (
        <div>
            <Modal
                className="modal-dialog-centered"
                size="lg"
                style={{ maxWidth: "1000px", width: "100%" }}
                open={props.showModal && !isLoading}
                width={"1000px"}
                onCancel={() => props.toggleModal(props.modalName)}
            >
                <div className="modal-body p-0">
                    <div className="bg-secondary  border-0 mb-0">
                        <div className="bg-menu">
                            <div className="text-center">
                                <p className="bold text-white mb-0">{getKeyword("QUESTION.QUESTION")}</p>
                            </div>

                        </div>
                        <div className="px-lg-5 py-lg-1">
                            <div className="text-center d-flex justify-content-between border-bottom mgb-30">
                                <button
                                    className="btn-1"
                                    color="white"
                                    type="button"
                                    onClick={(e) => { props.toggleModal(props.modalName); props.update(getValueManager(form, "id", null)); }}
                                >
                                    <i className="fa fa-pencil-square-o mr-2" ></i>  {getKeyword("STD_VERB.EDIT")}
                                </button>
                            </div>
                            <div className="row">
                                <div className="col-md-6 border-right">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.ID")}:</span > <span className="text-dark">{getValueManager(form, "id", null)}</span></p>
                                </div>
                                <div className="col-md-6">

                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.DESIGNATION")}:</span > <span className="text-dark">{getValueManager(form, "name", null)}</span></p>

                                </div>
                                <div className="col-md-6 border-right">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.STATUS")}:</span > <span className="text-dark">{getValueManager(form, "enabled", null) ? getKeyword("COMMON.ENABLED") : getKeyword("COMMON.DISABLED")}</span></p>

                                </div>
                                <div className="col-md-6">

                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.TYPE")}:</span > <span className="text-dark"><TecTableProvider
                                        code={getValueManager(form, "type", null)}
                                    /></span></p>

                                </div>
                                {getValueManager(form, "type", null) == "QUESTION_TYPE_CHOICE" ||
                                    getValueManager(form, "type", null) == "QUESTION_TYPE_CHOICE_MULTIPLE" ||
                                    getValueManager(form, "type", null) == "QUESTION_TYPE_RADIO" ||
                                    getValueManager(form, "type", null) == "QUESTION_TYPE_RADIO_MULTIPLE"
                                    ?
                                    <div className="col-md-6 border-right">
                                        <p className="text-dark"> <span className="bold">Réponse:</span > <span className="text-dark">{getValueManager(form, "response.designation", null)}</span></p>

                                    </div> : null}
                                <div className="col-md-6">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.CREATED_AT")} :</span > <span className="text-dark">{getValueManager(form, "createdAt", null) ? dateStrToTimeString(getValueManager(form, "createdAt", null)) : null}</span></p>

                                </div>
                                <div className="col-md-6 border-right">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.CREATED_BY")}:</span > <span className="text-dark">{getValueManager(form, "createdByUser.lastName", null) + " " + getValueManager(form, "createdByUser.firstName", null)}</span></p>

                                </div>
                                <div className="col-md-6">

                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.UPDATED_AT")}:</span > <span className="text-dark">{getValueManager(form, "updatedAt", null) ? dateStrToTimeString(getValueManager(form, "updatedAt", null)) : null}</span></p>

                                </div>
                                <div className="col-md-6">

                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.UPDATED_BY")}:</span > <span className="text-dark">{getValueManager(form, "updatedByUser.lastName", null) + " " + getValueManager(form, "updatedByUser.firstName", null)}</span></p>

                                </div>
                                <div className="col-md-12">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.DESCRIPTION")}:</span > <span className="text-dark">{getValueManager(form, "description", null)}</span></p>

                                </div>
                                {getValueManager(form, "type", null) == "QUESTION_TYPE_CHOICE" ||
                                    getValueManager(form, "type", null) == "QUESTION_TYPE_CHOICE_MULTIPLE" ||
                                    getValueManager(form, "type", null) == "QUESTION_TYPE_RADIO" ||
                                    getValueManager(form, "type", null) == "QUESTION_TYPE_RADIO_MULTIPLE"
                                    ?
                                    <div className="col-md-12">
                                        <strong className="text-dark">Liste des réponses :</strong>&nbsp;
                                        <table className="table-table">
                                            <thead>
                                                <tr>
                                                    <th>Code réponse</th>
                                                    <th>{getKeyword("COMMON.VALUE")}</th>
                                                    <th>{getKeyword("COMMON.DESCRIPTION")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getValueManagerArray(form, "response.data", null).filter(f => f.enabled).map(
                                                    (m) => <tr>
                                                        <td>{m.code}</td>
                                                        <td>{m.value}</td>
                                                        <td>{m.description}</td>
                                                    </tr>,
                                                )}
                                            </tbody>
                                        </table>
                                        <br />
                                        <br />
                                    </div> : null}
                                {/* <div className="col-md-12">
                                    <strong className="text-dark">Historique :</strong>&nbsp;
                                    <table className="table-table">
                                        <thead>
                                            <tr>
                                                <th>{getKeyword("COMMON.VERSION")}</th>
                                                <th>{getKeyword("COMMON.DESIGNATION")}</th>
                                                <th>{getKeyword("COMMON.DESCRIPTION")}</th>
                                                <th>{getKeyword("COMMON.TYPE")}</th>
                                                <th>Réponse</th>
                                                <th>{getKeyword("COMMON.UPDATED_AT")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getValueManagerArray(form, "histories", null).map(
                                                (m) => <tr><td>{m.version}</td><td>{m.name}</td><td>{m.description}</td>
                                                    <td><TecTableProvider
                                                        code={getValueManager(m, "type", null)}
                                                    /></td><td>{getValueManager(form, "response.designation", null)}</td>
                                                    <td>{getValueManager(m, "createdAt", null) ? dateStrToTimeString(getValueManager(m, "createdAt", null)) : null}</td></tr>,
                                            )}
                                        </tbody>
                                    </table>
                                    <br />
                                    <br />
                                </div> */}
                            </div>
                            <div className="text-center d-flex justify-content-between">
                                <button
                                    className="my-4"
                                    color="white"
                                    type="button"
                                    onClick={(e) => props.toggleModal(props.modalName)}
                                >
                                    {getKeyword("STD_VERB.CLOSE")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    );
};
export default QSQuestionShow;
