import React, { useContext, useEffect, useState } from "react";

import "./Inputs.css";
import { Modal } from "antd";
import LINKS, { ASSETS_BASE } from "../../../config";
import SharedContext from "../../../contexts/shared/sharedContext";
import Logger from "../../utils/Logger";
import Crop from "./Crop";
import { getArray, isTypeOf } from "../../manager/ManagerUtils";
import FileProvider from "../outputs/FileProvider";
import ImagePreviewProvider from "../outputs/ImagePreviewProvider";

const EXTENSIONS = [
  "bmp",
  "jpg",
  "jpeg",
  "png",
  "gif",
  "svg",
  "webp",
  "ico",
  "pjpeg",
  "tiff",
];
const ALLOWED_SIZE = "50000000";
const NAME = "file";
const NUMBER_OF_FILES = 50;
const STYLE = "normal";

const validateExtension = (file, extensions) => {
  const name = file.name;
  if (!name) {
    return false;
  }
  if (!extensions || extensions.length === 0) {
    return false;
  }
  if(!isTypeOf(name, "String", true)){
    return false;
  }
  const namesSplit = name.split(".");
  if (namesSplit.length < 2) {
    return false;
  }
  const ext = namesSplit[namesSplit.length - 1];
  return extensions.find((e) => e === ext.toLowerCase());
};

const validateSize = (file, size) => {
  return file.size / 1024 <= size;
};

const isImage = (name) => {
  return validateExtension({ name }, EXTENSIONS);
};

export const FileInput = (props) => {
  const name = props.name || NAME;
  const isMultiple = props.multiple;
  const extensions = props.extensions || EXTENSIONS;
  const allowedSize = props.allowedSize || ALLOWED_SIZE;
  const style = props.style || STYLE;
  const replace = props.replace || false;
  const numberOfFiles = !isMultiple
    ? 1
    : props.numberOfFiles || NUMBER_OF_FILES;

  const minHeight = props.minHeight || 245;

  const [pictures, setPictures] = useState([]);
  const [cropPopup, setCropPopup] = useState(false);
  const [cropSelected, setCropSelected] = useState(false);
  const { keywords, getKeyword } = useContext(SharedContext);

  useEffect(() => {
    props.pictures &&
      props.pictures.files.forEach((f) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          pictures.push({
            file: f,
            name: f.name,
            size: f.size,
            image: e.target.result,
          });
          setPictures([...pictures]);
        };
        reader.readAsDataURL(f);
      });
  }, []);

  useEffect(() => {
   if(props.version){
    setPictures([]);
   } 
  }, [props.version]);

  const updateFormData = (e) => {
    const files = e.target.files;
    const newFiles = Object.keys(files)
      .filter((k) => k !== "length")
      .map((k) => files[k]);
    uploadTheFile(newFiles);
  };

  const handleDrop = (e) => {
    let files = [...e.dataTransfer.files];
    uploadTheFile(files);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  const uploadTheFile = (files) => {
    if (files && files.length > 0) {
      if (!isMultiple) {
        const f = files[0];
        if (
          validateSize(f, allowedSize)
          // && validateExtension(f, extensions)
        ) {
          const reader = new FileReader();
          reader.onload = function (e) {
            setPictures([
              {
                file: f,
                name: f.name,
                size: f.size,
                image: e.target.result,
              },
            ]);
          };
          reader.readAsDataURL(f);
          props.onChange([f], props.name);
          if (props.onChangeOld) {
            props.onChangeOld("", props.name);
          }
        }
      } else {
        const result = [];
        const info = { filesNumber: 0 };
        const isDone = () => {
          info.filesNumber = info.filesNumber + 1;
          if (
            info.filesNumber === files.length ||
            info.filesNumber === numberOfFiles
          ) {
            if (replace) {
              setPictures(result);
              props.onChange(result, props.name);
            } else {
              const rs = [...pictures, ...result].slice(0, numberOfFiles);
              setPictures(rs);
              props.onChange(rs.map((m) => m.file), props.name);
            }
          }
        };
        for (
          let index = 0;
          index < numberOfFiles && index < files.length;
          index++
        ) {
          const f = files[index];
          if (f) {
            if (
              validateSize(f, allowedSize)
              // &&
              // validateExtension(f, extensions)
            ) {
              const reader = new FileReader();
              reader.onload = function (e) {
                result.push({
                  file: f,
                  name: f.name,
                  size: f.size,
                  image: e.target.result,
                });
                isDone();
              };
              reader.readAsDataURL(f);
            } else {
              Logger("ALLOWED_SIZE_AND_EXTENSIONS", keywords);
              isDone();
            }
          } else {
            isDone();
          }
        }
      }
    }
  };

  const deletePicture = (i) => {
    const rs = pictures.filter((p, ii) => ii !== i);
    setPictures(rs);
    if (props.onChange) {
      props.onChange(rs, props.name);
    }
  };

  const deletePictureFromValue = (i) => {
    if (props.onChangeOld) {
      if (isMultiple) {
        props.onChangeOld(
          props.value.filter((f, ii) => ii !== i),
          props.name
        );
      } else {
        props.onChangeOld("", props.name);
      }
    }
  };

  let vals = [];
  if (props.value) {
    if (isMultiple) {
      vals = props.value;
    } else {
      vals = [props.value];
    }
  }

  const cropImage = (blob, p) => {
    p.file = blob;
    if (p.type === "old") {
      deletePictureFromValue(p.index);
      p = {
        file: blob,
        image: p.image,
        name: blob.name,
        size: blob.size,
      };
      pictures.push(p);
    }
    props.onChange(pictures.map((m) => m.file), props.name);
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      var base64data = reader.result;
      p.image = base64data;
      setPictures([...pictures]);
    };
    cropActionCancel();
    // }
  };

  const cropPicture = (p) => {
    setCropSelected(p);
    setCropPopup(true);
  };

  const cropOldPicture = (p, index) => {
    const toDataURL = (url) =>
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setCropSelected({
              name: p,
              image: reader.result,
              index: index,
              type: "old",
            });
            setCropPopup(true);
          };
          reader.readAsDataURL(blob);
        });
    toDataURL(LINKS.imageURL + p);
  };

  const cropActionCancel = () => {
    setCropSelected(null);
    setCropPopup(false);
  };

  const getPreview = () => {
    return <ul className="FileUploadList">
      {props.multiple ? <>{getArray(props.value).map(vl => (
        <li className="input-response"><FileProvider file={vl}></FileProvider></li>
      ))}</>
        :
        <li className="input-response"><FileProvider file={props.value}></FileProvider></li>
      }
    </ul>
  }

  return (
    <div className="FileUpload InputField">

      {style === "IMAGE_BACKGROUND" && !props.label ? null : props.label ? (
        <label className="Ilabel">
          {getKeyword(props.label)}&nbsp;
          {props.optText ? (
            <span>
              {props.optText !== true ? props.optText : "(Optionnel)"}
            </span>
          ) : (
            ""
          )}
          <span className="requiredLb">{props.required ? " *" : ""}</span>
        </label>
      ) : null}
      {props.preview ?
        <>{getPreview()}</>
        :
        <div
          className={`content ${!isMultiple ? "notMultiple" : "multiple"
            }  ${style}`}
        >
          <label
            className="import"
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragLeave={(e) => handleDragLeave(e)}
            htmlFor={name}
          >
            <div
              className={`imp${pictures.length > 0 && isImage(pictures[0].name) ? ' NoIcn' : ''}`}
              style={{
                minHeight: `${minHeight}px`,
                height: `${minHeight}px`,
                // background:
                //   style == "IMAGE_BACKGROUND_MULTIPLE" || props.hideBack
                //     ? "initial"
                //     : pictures.length > 0 && isImage(pictures[0].name)
                //       ? `url(${pictures[0].image})`
                //       : vals.length > 0 && isImage(vals[0])
                //         ? `url(${LINKS.imageURL}${vals[0]})`
                //         : "initial",
              }}
            >
              {style == "IMAGE_BACKGROUND_MULTIPLE" || props.hideBack ? null : (
                <>
                  {pictures.length > 0 && isImage(pictures[0].name) ? (
                    <i
                      className="fa fa-times close dlt"
                      aria-hidden="true"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        deletePicture(0);
                      }}
                    ></i>
                  ) : (
                    <>
                      {vals.length > 0 && isImage(vals[0]) ? (
                        <i
                          className="fa fa-times close dlt"
                          aria-hidden="true"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            deletePictureFromValue(0);
                          }}
                        ></i>
                      ) : null}
                    </>
                  )}
                </>
              )}
              {props.styleFull === "CAMERA" ? (
                <React.Fragment>
                  <i className="fas fa-camera" aria-hidden="true"></i>
                </React.Fragment>
              ) : null}

              {props.styleFull === "PLUS" ? (
                <React.Fragment>
                  <i className="fas fa-plus" aria-hidden="true"></i>
                </React.Fragment>
              ) : null}
              {props.styleFull !== "PLUS" && props.styleFull !== "CAMERA" ? (
                <React.Fragment>
                  <div className="d-flex justify-content-between">

                      <img
                           style={{maxWidth: '100px' }}
                          src={`${ASSETS_BASE}/home/images/upload.png`}
                          alt="attach"
                      />
                   <div className="">
                      <p className="p bl">{getKeyword("COMMON.DRAG_FILE")}</p>
                      <p className="p bl">{getKeyword("STD.OR")}</p>
                    </div>
                  </div>




                </React.Fragment>
              ) : null}
              <input
                onChange={(e) => updateFormData(e)}
                type="file"
                id={name}
                name={name}
                accept={props.accept ? props.accept : (props.isImage ? "image/*" : null)}
                multiple={isMultiple}
              ></input>
            </div>
          </label>
          {style === "IMAGE_BACKGROUND" ? null : (
            <div className={`imgs style1 ${style}`}>
              {
                (pictures && pictures.length > 0) || vals.length > 0 ? (
                  <ul>
                    {getArray(vals).map((p, i) => (
                      <li
                        // style={{ height: minHeight / 3 - 5 }} 
                        key={i}>
                        {isImage(p) ? (
                          <ImagePreviewProvider file={p} className="img" alt="upload"></ImagePreviewProvider>
                          // <img
                          //   className="img"
                          //   src={LINKS.imageURL + p}
                          //   alt="upload"
                          // ></img>
                        ) : (
                          <p className="simple-file">{p}</p>
                        )}
                        {/* <i
                        className="fas fa-crop crop"
                        aria-hidden="true"
                        onClick={() => cropOldPicture(p, i)}
                      ></i> */}
                        <i
                          className="fas fa-times close"
                          aria-hidden="true"
                          onClick={() => deletePictureFromValue(i)}
                        ></i>
                      </li>
                    ))}
                    {pictures.map((p, i) => (
                      <li
                        // style={{ height: minHeight / 3 - 5 }} 
                        key={i}>
                        {isImage(p.name) ? (
                          <img className="img" src={p.image} alt="upload"></img>
                        ) : (
                          <p className="simple-file img">{p.name}</p>
                        )}
                        {/* {!isImage(p.name) ? null : (
                        <i
                          className="fas fa-crop crop"
                          aria-hidden="true"
                          onClick={(e) => cropPicture(p)}
                        ></i>
                      )} */}
                        <i
                          className="fas fa-times close"
                          aria-hidden="true"
                          onClick={(e) => deletePicture(i)}
                        ></i>
                      </li>
                    ))}
                  </ul>
                ) : null
                // <img
                //   className="img"
                //   src={`${ASSETS_BASE}/imgs/photo_empty.png`}
                //   alt="upload"
                // ></img>
              }
            </div>
          )}
          <Modal
            title={getKeyword("CROP")}
            visible={cropPopup}
            className="hidefooter"
            // onOk={cropActionDo}
            onCancel={cropActionCancel}
          >
            {!cropSelected ? null : (
              <Crop
                image={cropSelected.image}
                name={cropSelected.name}
                crop={(blob) => cropImage(blob, cropSelected)}
                cancel={cropActionCancel}
              ></Crop>
            )}
          </Modal>
        </div>
      }
    </div>
  );
};

export default FileInput;
