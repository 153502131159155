import { replaceIndex } from "../../core/manager/ManagerUtils";
import { getValueManager } from "../../core/manager/ManagerUtils";
import { updateValueManager } from "../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { useDrag, DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import QSQuestionCreatorBlock from "./creator/Block";
import QSQuestionCreatorForm from "./creator/block-form";
import QSQuestionCreatorCard from "./creator/Card";
import QSQuestionCreatorCardBlock from "./creator/CardBlock";
import QSQuestionCreatorCardBlockSetting from "./creator/CardBlockSetting";
import QSQuestionCreatorCardSetting from "./creator/CardSetting";
import QSQuestionPreviewModal from "./show/question-master-preview";
// import NotificationAlert from "react-notification-alert";
// import ReactBSAlertShared from "../../core/common/notifications/ReactBSAlertShared";
import { useParams } from "react-router";
import SharedContext from "../../contexts/shared/sharedContext";
import { transformFormFromDbToJs } from "./creator/helpers";
import { LoggerSuccess } from "../../core/utils/Logger";
import QSQuestionForm from "./form/question-form";
import {getRandom} from "../../core/utils/helpers";


const QSQuestionCreator = (props) => {
    const { id } = useParams();
    const { GetResource, getKeyword } = useContext(SharedContext);
    const [isPreview, setIsPreview] = useState(false);
    const [alert, setalert] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [formQuestion, setFormQuestion] = useState({});
    const [forceSearch, setForceSearch] = useState(null);

    const notificationAlertRef = React.useRef(null);
    const notify = (type) => {
        // let options = {
        //     place: "tc",
        //     message: (
        //         <div className="alert-text">
        //             <span className="alert-title" data-notify="title">
        //                 &nbsp;
        //                 {replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")])}
        //             </span>
        //         </div>
        //     ),
        //     type: type,
        //     icon: "ni ni-bell-55",
        //     autoDismiss: 7,
        // };
        // notificationAlertRef.current.notificationAlert(options);
    };

    const successAlert = (message) => {
        LoggerSuccess(message);
    };

    // myallblockitem
    const [page, setPage] = useState(0);
    const [tab, setTab] = useState(0);
    const [data, setData] = useState({
        position: null,
        isDragging: true,
        timeOutFunction: null
    })
    const [names, setNames] = useState([]);
    const [modalState, setModalState] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);
    const [form, setForm] = useState([]);
    const [blockForm, setBlockForm] = useState({

            name: "",
            code: getRandom(6),
            description: "" }

    );
    const [images, setImages] = useState({});
    const [version, setVersion] = useState(0);
    const [items, setItems] = useState([{
        title: "Page 1", items: [
        ]
    }])

    const [preview, setPreview] = useState(JSON.parse(JSON.stringify(items)));

    useEffect(() => {
        if (id) {
            getBackendForm(id);
        }
    }, []);

    const addTo = (item, indexes, indexPut) => {
        if (item.isOld) {
            checkSecondUnUsed(item.index);
        }
        let toAdd = { ...item };
        if (!item.isOld && toAdd.type == "BLOCK") {
            toAdd.items = [];
            toAdd.title = "Title";
        }
        console.log("PREV", item, indexes, indexPut);
        if (!item.isOld && toAdd.type == "CARD") {
            let nameIndex = (names.length + 1);
            let name = "collect_nom_" + nameIndex;
            while (names.find(n => n == name)) {
                nameIndex++;
                name = "collect_nom_" + nameIndex;
            }
            names.push(name);
            setNames(names);
            toAdd.name = name;
            toAdd.required = false;
        }
        let clone = items[page];
        for (let index = 0; index < indexes.length; index++) {
            let currentIndex = indexes[index];
            if (clone && clone.items && clone.items.length > currentIndex && clone.items[currentIndex] && clone.items[currentIndex].items) {
                clone = clone.items[currentIndex];
            } else {
                return;
            }
            if (!clone) {
                return;
            }
        }
        const finalItems = [];
        let done = false;
        clone.items.forEach((f, i) => {
            if (!done && indexPut <= i) {
                done = true;
                finalItems.push(toAdd);
            }
            finalItems.push(f);
        })
        if (!done) {
            finalItems.push(toAdd);
        }
        clone.items = finalItems;
        items[page].items = removeSecondUnUsed(items[page].items);
        setItems(JSON.parse(JSON.stringify(items)));
        setPreview(JSON.parse(JSON.stringify(items)));
        setVersion(version + 1);
        deleteHover();
    }

    const onChange = (v, n, indexes = null) => {

        const f = updateValueManager(form, n, v, indexes);
        setForm({ ...f });
    };

    const onChangeBlock = (v, n, indexes = null) => {
        const f = updateValueManager(blockForm, n, v, indexes);
        setBlockForm({ ...f });
    };


    const onChangeImages = (
        files,
        name,
        multiple,
        indexes,
        usedFor = "PRODUCT_DOCUMENT"
    ) => {
        images[name] = { files, name, multiple, indexes, usedFor };
        setImages({ ...images });
    };

    const arraysEqual = (a, b) => {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }


    const removeUnUsed = (its) => {
        return its.filter((item, index) => {
            if (item.isPreview) {
                return false;
            }
            if (item.type == "BLOCK") {
                item.items = removeUnUsed(item.items);
            }
            return true;
        })
    }

    const checkSecondUnUsed = (indexes) => {
        let clone = items[page];
        for (let index = 0; index < indexes.length - 1; index++) {
            let currentIndex = indexes[index];
            if (clone && clone.items && clone.items.length > currentIndex && clone.items[currentIndex] && clone.items[currentIndex].items) {
                clone = clone.items[currentIndex];
            } else {
                return;
            }
            if (!clone) {
                return;
            }
        }
        let currentIndex = indexes[indexes.length - 1];
        if (clone && clone.items && clone.items.length > currentIndex && clone.items[currentIndex]) {
            clone.items[currentIndex] = { ...clone.items[currentIndex], toBeRemoved: true };
        }
    }

    const removeSecondUnUsed = (its) => {
        return (its && its.filter((item, index) => {
            if (item.toBeRemoved) {
                return false;
            }
            if (item.type == "BLOCK") {
                item.items = removeSecondUnUsed(item.items);
            }
            return true;
        })) || []
    }

    const changePreview = (item, indexes, indexPut) => {
        if (!data.position || !arraysEqual(data.position.indexes, indexes) || data.position.indexPut != indexPut) {
            data.position = { indexes, indexPut }
            setData({ ...data })
            let id = "data-index";
            if (indexes.length > 0) {
                indexes.forEach(f => {
                    id += "-" + f;
                })
            } else {
                id += "-";
            }
            const parentElement = document.getElementById(id);
            let currentElement = parentElement.getElementsByClassName("qc-core-js");
            if (currentElement && currentElement.length > 0) {
                currentElement = currentElement[0];
            }
            deleteHover();
            var child = document.createElement('hr');
            child.className = "qc-my-position-add";
            currentElement.insertBefore(child, currentElement.children[indexPut]);
        }
    }

    const deleteHover = () => {
        const boxes = document.querySelectorAll('.qc-my-position-add');
        boxes.forEach(box => {
            box.remove();
        });
    }

    const toggleModalsimple = (state) => {
        modalState[state] = !modalState[state];
        setModalState({ ...modalState });
    };

    const toggleModal = (state, item) => {
        modalState[state] = !modalState[state];
        setModalState({ ...modalState });
        setSelectedItem(item);
    };

    const updateSetting = (form) => {
        let clone = items[page];
        const indexes = selectedItem.data.index;
        for (let index = 0; index < indexes.length - 1; index++) {
            let currentIndex = indexes[index];
            if (clone && clone.items && clone.items.length > currentIndex && clone.items[currentIndex] && clone.items[currentIndex].items) {
                clone = clone.items[currentIndex];
            } else {
                return;
            }
            if (!clone) {
                return;
            }
        }
        let currentIndex = indexes[indexes.length - 1];
        if (clone && clone.items && clone.items.length > currentIndex && clone.items[currentIndex]) {
            clone.items[currentIndex] = { ...clone.items[currentIndex], ...form };
            names.push(clone.items[currentIndex].name);
            setItems(JSON.parse(JSON.stringify(items)));
            setPreview(JSON.parse(JSON.stringify(items)));
            setVersion(version + 1);
        }
    }

    const changeSettings = (item) => {
        let clone = items[page];
        const indexes = item.index;
        for (let index = 0; index < indexes.length - 1; index++) {
            let currentIndex = indexes[index];
            if (clone && clone.items && clone.items.length > currentIndex && clone.items[currentIndex] && clone.items[currentIndex].items) {
                clone = clone.items[currentIndex];
            } else {
                return;
            }
            if (!clone) {
                return;
            }
        }
        let currentIndex = indexes[indexes.length - 1];
        if (clone && clone.items && clone.items.length > currentIndex && clone.items[currentIndex]) {
            if (clone.items[currentIndex].type == "CARD") {
                toggleModal("formModal", { data: item, item: clone.items[currentIndex] });
            } else if (clone.items[currentIndex].type == "BLOCK") {
                toggleModal("formModal2", { data: item, item: clone.items[currentIndex] });
            }
        }

        return null;
    }

    const deleteItem = (item) => {
        checkSecondUnUsed(item.index);
        items[page].items = removeSecondUnUsed(items[page].items);
        setItems(JSON.parse(JSON.stringify(items)));
        setPreview(JSON.parse(JSON.stringify(items)));
        setVersion(version + 1);
        deleteHover();
    }

    const formValid = getValueManager(blockForm, "name");

    const fillCardsAndBlocks = (cards, blocks, its, indexes) => {
        (its && its.forEach((item, index) => {
            if (item.type == "CARD") {
                cards.push({ ...item, index: [...indexes, index] })
            }
            if (item.type == "BLOCK") {
                item.items = fillCardsAndBlocks(cards, blocks, item.items, [...indexes, index]);
                blocks.push({ ...item, index: [...indexes, index], items: null })
            }
        }))
    }

    const transformSavedToUpdate = (backendItem) => {
        setBlockForm({ ...backendItem, cards: null, blocks: null });
        const cards = backendItem.cards;
        if (cards) {
            setNames(cards.map(m => m.name));
        }
        const newForm = transformFormFromDbToJs(backendItem);
        setItems(newForm);
        setPreview(JSON.parse(JSON.stringify(newForm)));
    }


    const getBackendForm = (idParam) => {
        setIsLoading(true);
        GetResource(
            "QUESTION_MASTER_MANAGEMENT",
            "GET_ID",
            { id: idParam },
            null
        ).then((res) => {
            transformSavedToUpdate(res.payload);
            setIsLoading(false);
        }, err => {

        });
    };

    const save = (event) => {
        const itemsC = JSON.parse(JSON.stringify(items));
        const blocks = [];
        const cards = [];
        fillCardsAndBlocks(cards, blocks, itemsC[page].items, []);
        GetResource("QUESTION_MASTER_MANAGEMENT", "ADD", { ...blockForm, cards, blocks }, null)
            .then((res) => {
                successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
                transformSavedToUpdate(res.payload);
            })
            .catch((err) => {
                if (err && err.status == 409) {
                    notify("danger");
                }
            });
    };

    const update = (event) => {
        const itemsC = JSON.parse(JSON.stringify(items));
        const blocks = [];
        const cards = [];
        fillCardsAndBlocks(cards, blocks, itemsC[page].items, []);
        GetResource("QUESTION_MASTER_MANAGEMENT", "UPDATE", { ...blockForm, cards, blocks }, null).then((res) => {
            successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
            transformSavedToUpdate(res.payload);
        });
    };

    const setAlert = (message) => {
        setalert(message);
    };

    const addNewQuestion = () => {
        setFormQuestion({});
        toggleModal("formModalQuestion");
    }

    const updateItem = (itemId) => {
        GetResource("QUESTION_MANAGEMENT", "GET_ID", { id: itemId }, null).then(
            (res) => {
                setFormQuestion({ ...res.payload });
                toggleModal("formModalQuestion");
            }
        );
    };

    const onAdd = (itemId) => {
        setForceSearch(itemId);
    }

    const onUpdate = (itemId) => {
        setForceSearch(itemId);
    }

    return (
        <div >
            {isLoading ? null : <div>
                {/* <div className="rna-wrapper">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
                <ReactBSAlertShared
                    alert={alert}
                    hideAlert={setAlert}
                ></ReactBSAlertShared> */}
                <DndProvider backend={HTML5Backend}>
                    <div className="qc-tio">
                        <div className="qc-tio-1">
                            {/* {!isPreview ? */}
                            <QSQuestionCreatorBlock version={version} deleteHover={deleteHover} items={data.isDragging ? preview[page].items : items[page].items} setItems={addTo}
                                root={true} indexes={[]} parentIndex="" changePreview={changePreview}
                                onChange={onChange} onChangeImages={onChangeImages} form={form} changeSettings={changeSettings} delete={deleteItem}></QSQuestionCreatorBlock>
                            {/* : */}

                            {/* } */}
                        </div>
                        <div className="qc-tio-2">
                            <div className="qc-main-nav">
                                <ul className="qc-nav pr-4">
                                    <li className={tab === 0 ? "active" : ""} onClick={() => setTab(0)}><a>Infos / Opérations</a></li>
                                    {/* <li className={tab == 0 ? "active" : ""} onClick={() => setTab(0)}><a>Info</a></li> */}
                                    <li className={tab === 1 ? "active" : ""} onClick={() => setTab(1)}><a>Question</a></li>
                                </ul>
                            </div>
                            {tab === 0 ? <div className="qc-sidebar-block">
                                <h1>Infos / opérations</h1>
                                <QSQuestionCreatorForm onChange={onChangeBlock} form={blockForm}></QSQuestionCreatorForm>
                                <div className="text-center d-flex justify-content-between">
                                    <button
                                        className="btn-2"
                                        color="white"
                                        type="button"
                                        onClick={(e) => toggleModalsimple("formModalPreview")}
                                    >
                                        {getKeyword("STD_VERB.PREVIEW")}
                                    </button>
                                    <button
                                        className="btn-1"
                                        color="danger"
                                        type="button"
                                        disabled={!formValid}
                                        onClick={(e) => blockForm && blockForm.id ? update() : save()}
                                    >
                                        {getKeyword("STD_VERB.SAVE")}
                                    </button>
                                </div>
                            </div> : null}
                            {tab == 1 ? <div className="qc-sidebar-block">
                                <h1>Elements</h1>
                                <QSQuestionCreatorCardBlock deleteHover={deleteHover}></QSQuestionCreatorCardBlock>
                                <QSQuestionCreatorCard deleteHover={deleteHover} forceSearch={forceSearch}
                                    disableForceSearch={() => setForceSearch(null)}></QSQuestionCreatorCard>
                                <button
                                    className="btn-1"
                                    color="danger"
                                    type="button"
                                    onClick={(e) => addNewQuestion()}
                                >
                                    Ajouter une question
                                </button>
                                {modalState["formModalQuestion"] ? (
                                    <QSQuestionForm
                                        modalName="formModalQuestion"
                                        toggleModal={toggleModal}
                                        showModal={modalState["formModalQuestion"]}
                                        onAdd={onAdd}
                                        form={formQuestion}
                                        updateForm={(form) => setFormQuestion(form)}
                                        onUpdate={onUpdate}
                                    ></QSQuestionForm>
                                ) : null}
                            </div> : null}
                        </div>
                    </div>
                </DndProvider>
                {modalState["formModal"] && selectedItem ? (
                    <QSQuestionCreatorCardSetting
                        modalName="formModal"
                        toggleModal={toggleModal}
                        showModal={modalState["formModal"]}
                        updateSettings={updateSetting}
                        item={selectedItem}
                        names={names}
                    ></QSQuestionCreatorCardSetting>
                ) : null}
                {modalState["formModal2"] && selectedItem ? (
                    <QSQuestionCreatorCardBlockSetting
                        modalName="formModal2"
                        toggleModal={toggleModal}
                        showModal={modalState["formModal2"]}
                        updateSettings={updateSetting}
                        item={selectedItem}
                        names={names}
                    ></QSQuestionCreatorCardBlockSetting>
                ) : null}
                {modalState["formModalPreview"] ? (
                    <QSQuestionPreviewModal
                        modalName="formModalPreview"
                        toggleModal={toggleModal}
                        showModal={modalState["formModalPreview"]}
                        updateSettings={updateSetting}
                        item={selectedItem}
                        names={names}
                        version={version}
                        items={items}
                        page={page}
                        onChange={onChange}
                        onChangeImages={onChangeImages}
                        form={form}
                    ></QSQuestionPreviewModal>

                ) : null}
            </div>}</div>
    )
}

export default QSQuestionCreator;
