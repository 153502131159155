import React, { useContext } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";

import "./Inputs.css";
import { useState } from "react";

export const PasswordInput = (props) => {
  const sharedContext = useContext(SharedContext);
  const { getKeyword } = sharedContext;
  const [eyePassword, setEyePassword] = useState(false);

  const onChange = (event) => {
    if (props.onChange) {
      props.onChange(event.target.value, props.name);
    }
  };

  return (
    <div className="TextInput InputField">
      <label className="Ilabel">{getKeyword(props.label)} <span className="requiredLb">{props.required ? " *" : ""}</span></label>
      <div className="input">
        <input
          type={eyePassword?"text":"password"}
          value={props.value}
          onChange={onChange}
          placeholder={
            props.placeHolder
              ? getKeyword(props.placeHolder)
              : getKeyword(props.label)
          }
        ></input>
        <span className="icon" onClick={() => setEyePassword(!eyePassword)}>
        <i className="fas fa-eye show-pass"></i>
        </span>
      </div>
      {props.error ? (
        <p className="errorInput">
          <i className="fas fa-exclamation-triangle"></i> {props.error}
        </p>
      ) : null}
    </div>
  );
};

export default PasswordInput;
