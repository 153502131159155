import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import QSQuestionCreatorPreview from "../preview/FormPreview";
import { Modal } from "antd";


const QSQuestionPreviewModal = (props) => {
    const { GetResource, getKeyword } = useContext(SharedContext);

    return (
        <div>
            <Modal
                className="modal-dialog-centered"
                size="lg"
                style={{ maxWidth: "1000px", width: "100%" }}
                open={props.showModal}
                width={"1000px"}
                onCancel={() => props.toggleModal(props.modalName)}
            >
                <div className="modal-body p-0">
                    <div className="bg-secondary  border-0 mb-0">
                        <div className="bg-menu">
                            <div className="text-center">
                                <p className="font-weight-bold text-white mb-0"> {getKeyword("QUESTION.FORM")}</p>
                            </div>
                        </div>
                        <div className="px-lg-5 py-lg-1">
                            <div className="row">
                                <div className="col-md-12 qc-preview">
                                    <QSQuestionCreatorPreview version={props.version} items={props.items[props.page].items}
                                        root={true} indexes={[]} parentIndex=""
                                        onChange={props.onChange} onChangeImages={props.onChangeImages} form={props.form}></QSQuestionCreatorPreview>
                                </div>
                            </div>
                            <div className="text-center d-flex justify-content-between">
                                <button
                                    className="my-4"
                                    color="white"
                                    type="button"
                                    onClick={(e) => props.toggleModal(props.modalName)}
                                >
                                    {getKeyword("STD_VERB.CLOSE")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    );
};
export default QSQuestionPreviewModal;
