import DateInput from "../../../core/common/inputs/DateInput";
import TextInput from "../../../core/common/inputs/TextInput";

import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { useHistory } from "react-router";
import {
  updateObjectFromImage,
  getValueManager,
  updateValueManager,
} from "../../../core/manager/ManagerUtils";
import FileInput from "../../../core/common/inputs/FileInput";
import SelectInputResource from "../../../core/common/inputs/SelectInputResource";
import SelectInputTableTechnique from "../../../core/common/inputs/SelectInputTableTechnique";
import TechnicalTable from "../../../variables/technical-table";
import { validateEmail, validatePassword } from "../../../core/utils/helpers";
import Logger  from "../../../core/utils/Logger";
import PasswordInput from "../../../core/common/inputs/PasswordInput";
import { SETTING_SUCCESS } from "../../../contexts/shared/types";

import "./invitConfirm.css";

const InviteConfirm = (props) => {
  const { GetResource, ImageSaver, getKeyword, settingData } = useContext(SharedContext);
  const history = useHistory();

  const [form, setForm] = useState({
    code: "",
    name: "",
    description: "",
    // profile: null,

  });

  const [images, setImages] = useState({});
  const [isLoading, setIsLoading] = useState({
    loading: false,
    size: 0,
    count: 0,
  });

  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(false);
  const [loginTxt, setLoginTxt] = React.useState("");
  const [loginType, setLoginType] = React.useState("filiale");

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    setForm({ ...f });
  };

  useEffect(() => {
    GetResource(
      "TABLE_TECHNIQUE",
      "GET_TABLE_BY_CODE",
      { value: "PWD" },
      SETTING_SUCCESS
    ).then((res) => { });
  }, []);

  useEffect(() => {
    console.log("props", props);
    console.log("props", props.location.state?.type);
    switch (props.location.state?.type) {
      case "filiale":
        setLoginType("filiale");
        setLoginTxt("en tant que filiale");
        break;
      case "startup":
        setLoginType("startup");
        setLoginTxt("en tant que startup");
        break;
      case "interne":
        history.push({
          pathname: '/auth/login',
          state: {type : loginType},
        })
        break;
      default:
        history.push({
          pathname: '/auth/lunch',
          state: {type : loginType},
        })
        break;

    }
  }, [props]);
  const onChangeImages = (
    files,
    name,
    multiple,
    indexes,
    usedFor = "PROFILE"
  ) => {
    images[name] = { files, name, multiple, indexes, usedFor };
    setImages({ ...images });
  };

  const addNewTable = (event) => {
    setLoading(true);
    console.log("form", form)
    uploadImages(() => {
      GetResource("USER_INVITE_MANAGEMENT", "ADD", form, null)
        .then((res) => {
          setLoading(false);
          setSuccess(true);
        })
        .catch((err) => {
          setLoading(false);
          setSuccess(false);
          if (
            err &&
            err.status === 409 &&
            err.data &&
            err.data.message === "ALREADY_EXISTS" &&
            err.data.detail === "EMAIL"
          ) {
            notify("danger", getKeyword("USER.EMAIL_USED"));
          } else if (
            err &&
            err.status === 409 &&
            err.data &&
            err.data.message === "ALREADY_EXISTS" &&
            err.data.detail === "USERNAME"
          ) {
            notify("danger", getKeyword("USER.LOGIN_USED"));
          } else if (
            err &&
            err.status === 403 &&
            err.data &&
            err.data.message === "FORBIDDEN" &&
            err.data.detail === "USERS_LIMIT"
          ) {
            notify("danger", getKeyword("USER.USERS_LIMIT"));
          }
          else if (
              err &&
              err.status === 409 &&
              err.data &&
              err.data.message === "ALREADY_EXISTS" &&
              err.data.detail === "STARTUP"
          ) {
            notify("danger", getKeyword("USER.STARTUP_USED"));
          }
          else if (
              err &&
              err.status === 409 &&
              err.data &&
              err.data.message === "ALREADY_EXISTS" &&
              err.data.detail === "AFFILIATE"
          ) {
            notify("danger", getKeyword("USER.FILIALE_USED"));
          }
          else {
            notify("danger", err.data.message + " " + err.data.detail );
          }
        });
    });
  };

  const uploadImages = (callback) => {
    ImageSaver(
      (params, images) => {
        updateObjectFromImage(form, images);
        callback();
      },
      (params, status) => setIsLoading(status),
      {},
      images,
      form
    );
  };


  let resV = false;
  let html = null;
  if (getValueManager(form, "password", null)) {

    resV = validatePassword(settingData["PWD"], getValueManager(form, "password", null));
    if (resV && resV.error) {
      html = <ul>
        {resV.items.map(item => (
          <li style={{ color: item.valid ? "green" : "red" }}>{item.msg}</li>
        ))}
      </ul>
    }
  }

  const formValid =
    // getValueManager(form, "dateOfBirth", null) &&
    // getValueManager(form, "profile", null) &&
    getValueManager(form, "email", null) &&
    validateEmail(getValueManager(form, "email", null)) &&
    // getValueManager(form, "phone", null) &&
    getValueManager(form, "firstName", null) &&
    getValueManager(form, "lastName", null) &&
    getValueManager(form, "userType", null) &&
    ((getValueManager(form, "userType", null) === "USER_TYPE_AFFILIATE" &&
      getValueManager(form, "affiliateId", null)) ||
      (getValueManager(form, "userType", null) === "USER_TYPE_STARTUP" &&
        getValueManager(form, "startupId", null)) ||
      (getValueManager(form, "userType", null) !== "USER_TYPE_STARTUP" &&
        getValueManager(form, "userType", null) !== "USER_TYPE_AFFILIATE")) &&
    getValueManager(form, "password", null) &&
    getValueManager(form, "password", null) ===
    getValueManager(form, "newPasswordVerif", null) &&
    !html;

  console.log(form);

  const notify = (type, message) => {
    console.log("MESA", message);
    Logger(message);
  };

  return (
    <div className=" pt-5 mb-3 form-invite">
      <div className="">

        <div className="row">
          <div className="col-12">
            <div className="">
              {success ?
                <h3> Inscription avec succès. Nous vous enverrons un e-mail une fois votre inscription confirmée. </h3>
                :

                <>
                  <h1 className="text-center">S'inscrire {loginTxt}</h1>

                  <div className="mt--6">
                    <div className="row">
                      <div className="col">
                        <div className="bg-secondary  border-0">
                          <div className="px-lg-5 py-lg-5">
                            <div role="form">
                              <div className="row">
                                <div className="col-6">
                                  <TextInput
                                    label="Adresse Email principal"
                                    name="email"
                                    onChange={(v, n) => onChange(v, n)}
                                    value={getValueManager(form, "email", null)}
                                    required={true}
                                    error={
                                      getValueManager(form, "email", null) &&
                                        !validateEmail(
                                          getValueManager(form, "email", null)
                                        )
                                        ? getKeyword("STD_ERROR.EMAIL")
                                        : null
                                    }
                                  ></TextInput>
                                </div>
                                {/*<div*/}
                                {/*  className={*/}
                                {/*    getValueManager(form, "userType", null) ===*/}
                                {/*      "USER_TYPE_EXTERNE"*/}
                                {/*      ? "col-6"*/}
                                {/*      : "col-6"*/}
                                {/*  }*/}
                                {/*>*/}
                                {/*  <SelectInputTableTechnique*/}
                                {/*    required={true}*/}
                                {/*    label="Type utilisateur"*/}
                                {/*    name="userType"*/}
                                {/*    code={TechnicalTable.USER_TYPE}*/}
                                {/*    onChange={(v, n) => onChange(v, n)}*/}
                                {/*    value={getValueManager(*/}
                                {/*      form,*/}
                                {/*      "userType",*/}
                                {/*      null*/}
                                {/*    )}*/}
                                {/*    filter={(v) =>*/}
                                {/*      v.code === "USER_TYPE_AFFILIATE" ||*/}
                                {/*      v.code === "USER_TYPE_STARTUP"*/}
                                {/*    }*/}
                                {/*  ></SelectInputTableTechnique>*/}
                                {/*</div>*/}
                                {loginType === 'filiale' ? (
                                  <div className="col-6">
                                    <TextInput
                                        label="Filiale"
                                        name="affiliateNameV1"
                                        onChange={(v, n) => onChange(v, n)}
                                        value={getValueManager(form, "affiliateNameV1", null)}
                                        required={true}

                                    ></TextInput>
                                    {/*<SelectInputResource*/}
                                    {/*  label="Filiale"*/}
                                    {/*  name="affiliateId"*/}
                                    {/*  resource="AFFILIATE_MANAGEMENT"*/}
                                    {/*  method="GET_ALL"*/}
                                    {/*  multiple={false}*/}
                                    {/*  onChange={onChange}*/}
                                    {/*  value={getValueManager(*/}
                                    {/*    form,*/}
                                    {/*    "affiliateId",*/}
                                    {/*    null*/}
                                    {/*  )}*/}
                                    {/*  map={{*/}
                                    {/*    select: "id",*/}
                                    {/*    label: "name",*/}
                                    {/*  }}*/}
                                    {/*  required={true}*/}
                                    {/*></SelectInputResource>*/}
                                  </div>
                                ) : null}
                                {loginType === 'startup'  ? (
                                    <>
                                      <div className="col-6">
                                        <TextInput
                                            label="Startup"
                                            name="startupNameV1"
                                            onChange={(v, n) => onChange(v, n)}
                                            value={getValueManager(form, "startupNameV1", null)}
                                            required={true}

                                        ></TextInput>
                                        {/*<SelectInputResource*/}
                                        {/*  label="Startup"*/}
                                        {/*  name="startupId"*/}
                                        {/*  resource="STARTUP_MANAGEMENT"*/}
                                        {/*  method="GET_ALL"*/}
                                        {/*  multiple={false}*/}
                                        {/*  onChange={onChange}*/}
                                        {/*  value={getValueManager(*/}
                                        {/*    form,*/}
                                        {/*    "startupId",*/}
                                        {/*    null*/}
                                        {/*  )}*/}
                                        {/*  map={{*/}
                                        {/*    select: "id",*/}
                                        {/*    label: "name",*/}
                                        {/*  }}*/}
                                        {/*  required={true}*/}
                                        {/*></SelectInputResource>*/}
                                      </div>
                                      <div className="col-6">
                                        <TextInput
                                            label="Activités"
                                            name="activityStartup"
                                            onChange={(v, n) => onChange(v, n)}
                                            value={getValueManager(
                                                form,
                                                "activityStartup",
                                                null
                                            )}
                                            required={true}
                                        ></TextInput>
                                      </div>
                                    </>
                                ) : null}
                                <div className="col-6">
                                  <TextInput
                                    label="Nom"
                                    name="lastName"
                                    onChange={(v, n) => onChange(v, n)}
                                    value={getValueManager(
                                      form,
                                      "lastName",
                                      null
                                    )}
                                    required={true}
                                  ></TextInput>
                                </div>
                                <div className="col-6">
                                  <TextInput
                                    label="Prénom"
                                    name="firstName"
                                    onChange={(v, n) => onChange(v, n)}
                                    value={getValueManager(
                                      form,
                                      "firstName",
                                      null
                                    )}
                                    required={true}
                                  ></TextInput>
                                </div>
                                <div className="col-6">
                                  <DateInput
                                    label="Date de naissance"
                                    name="dateOfBirth"
                                    onChange={(v, n) => onChange(v, n)}
                                    value={getValueManager(
                                      form,
                                      "dateOfBirth",
                                      null
                                    )}
                                  ></DateInput>
                                </div>

                                <div className="col-6">
                                  <TextInput
                                    label="Adresse"
                                    name="address"
                                    onChange={(v, n) => onChange(v, n)}
                                    value={getValueManager(form, "address", null)}
                                  ></TextInput>
                                </div>

                                <div className="col-6">
                                  <TextInput
                                    label="Téléphone principal"
                                    name="phone"
                                    onChange={(v, n) => onChange(v, n)}
                                    value={getValueManager(form, "phone", null)}
                                  // required={true}
                                  ></TextInput>
                                </div>
                                <div className="col-6">
                                  <TextInput
                                    label="Téléphone secondaire"
                                    name="secondaryPhoneNumber"
                                    onChange={(v, n) => onChange(v, n)}
                                    value={getValueManager(
                                      form,
                                      "secondaryPhoneNumber",
                                      null
                                    )}
                                  ></TextInput>
                                </div>

                                <div className="col-12"></div>
                                <div className="col-6">
                                  <PasswordInput
                                    label={"Mot de passe"}
                                    name="password"
                                    onChange={(v, n) => onChange(v, n)}
                                    value={getValueManager(
                                      form,
                                      "password",
                                      null
                                    )}
                                    required={true}
                                  ></PasswordInput>
                                  {html ? (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                      {html}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-6">
                                  <PasswordInput
                                    label={getKeyword("PROFILE.COMFIRM_PASSWORD")}
                                    name="newPasswordVerif"
                                    onChange={(v, n) => onChange(v, n)}
                                    value={getValueManager(
                                      form,
                                      "newPasswordVerif",
                                      null
                                    )}
                                    required={true}
                                    error={
                                      getValueManager(
                                        form,
                                        "newPasswordVerif",
                                        null
                                      ) &&
                                        getValueManager(
                                          form,
                                          "newPasswordVerif",
                                          null
                                        ) !==
                                        getValueManager(
                                          form,
                                          "password",
                                          null
                                        )
                                        ? getKeyword("STD_ERROR.PASSWORD_MISMATCH")
                                        : null
                                    }
                                  ></PasswordInput>
                                </div>

                                <div className="col-12">
                                  <FileInput
                                    label="Photo"
                                    name="image"
                                    onChange={(files) =>
                                      onChangeImages(files, "image", false)
                                    }
                                    onChangeOld={(v, n) => onChange(v, n)}
                                    value={getValueManager(form, "image", null)}
                                  ></FileInput>
                                </div>
                              </div>

                              <div className="d-flex justify-content-around pt-5">
                                <button
                                    className="btnClickCancel"
                                  color="white"
                                  type="button"
                                  onClick={(e) => {
                                    history.push("/auth/login");
                                  }}
                                >
                                  Annuler
                                </button>
                                <button
                                  className="btnClick"
                                  color="danger"
                                  type="button"
                                  disabled={   loading}
                                  onClick={(e) => addNewTable()}
                                >
                                   S'inscrire
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InviteConfirm;
