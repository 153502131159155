import React from "react";
import './styles/presentation.css';
import {ASSETS_BASE} from "../../config";

const Presentation = (props) => {
    return <div className='presentationBlock'>


        <div className="presentation">
            <div className="container mb-5">
                <h2 className="text-light">Présentation</h2>
                <h3></h3>
                <div className="contenue-presentation">
                    <p className="text-light content">
                        S’inscrivant dans ce même esprit d’impact positif, le programme Positive
                        Link lancé en 2019, vise à créer un lien durable entre le Groupe et les
                        Startups marocaines. Ce programme illustre l’une des priorités d’Al Mada
                        : accompagner efficacement l’émergence et le développement d’un
                        écosystème de start-ups performantes sur la scène économique
                        panafricaine.
                    </p>
                    <div className="text-end">
                        <button className="btn warning text-light">
                            En savoir plus
                            <img src={`${ASSETS_BASE}/home/images/arrow-right.png`}   alt="Arrow" />
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="avatar-wrapper">
                            <div className="textWrap">
                                <p>Vous êtes une</p>
                                <div className="parentblock">
                                    <div className="home__textSlides">
                                        <span>start-up ?</span>
                                        <span>fondateur ?</span>
                                        <span>filiale ?</span>
                                    </div>
                                </div>

                            </div>
                            <img
                                className="avatar"
                                src={`${ASSETS_BASE}/home/images/layer2.png`}

                                alt="avatar"
                            />

                            {/*<h2 className="home__secHeading">Facilitating*/}
                            {/*    <div className="home__textSlides">*/}
                            {/*        <span>Trade</span>*/}
                            {/*        <span>Investment </span>*/}
                            {/*        <span>Commerce</span>*/}
                            {/*    </div>*/}

                            {/*</h2>*/}

                        </div>
                    </div>
                    <div id='account' className="col-md-6 center">
                        <div className="flex flexBloc">
                            <a href="/auth/lunch" className="buutn">Inscription </a>
                            <span
                                data-text="Vous n’êtes pas encore inscrits à la plateforme PL?"
                                className="tool left question-mark"
                            >?</span
                            >
                        </div>
                        <div className="flex flexBloc">
                            <a href="/auth/lunch" className="buutn">Connexion</a>
                            <span
                                data-text="Vous n’êtes pas encore inscrits à la plateforme PL?"
                                className="tool left question-mark"
                            >?</span
                            >
                        </div>
                        <div className="flex flexBloc">
                            <a href="/auth/reset" className="buutn">Mot de passe oublié </a>
                            <span
                                data-text="Vous n’êtes pas encore inscrits à la plateforme PL?"
                                className="tool left question-mark"
                            >?</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div className="color">
                <div className="container ">
                    <div className="row g-0 overflow-hidden ">
                        <div className="col-md-7 py-3">
                            <h2 className="px-5 filial-title">
                                Vous êtes une filiale du Groupe Al Mada?
                            </h2>
                        </div>
                        <div className="col-md-5 d-flex mx-auto my-auto">
                            <div className="btn-filiale d-flex mx-auto my-margin">
                                <div className="connexion-btn mx-3">
                                    <a href="/auth/lunch">Connexion</a>
                                </div>
                                <div className="mdp-btn">
                                    <a href="/auth/reset">Mot de passe oublié</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
        ;
};

export default Presentation;
