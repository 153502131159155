import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import LINKS, { URL_BASE } from "../config";
import AuthContext from "../contexts/auth/authContext";
import SharedContext from "../contexts/shared/sharedContext";
import { getArray } from "../core/manager/ManagerUtils";
import { Logger } from "../core/utils/Logger";
import { hasAccessPermission, userHasOneOfPermissions, userIsOfTypes } from "../core/utils/security";
import { getHomeLink } from "./routes/HomeRoutes";

const PrivateRoute = (props) => {
  const { component: Component, roles, redirect, permission, ...rest } = props;
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const sharedContext = useContext(SharedContext);
  const { keywords, getCurrentProfil } = sharedContext;

  const { loadUser } = authContext;
  const [auth, setAuth] = useState(1);
  useEffect(() => {
    loadUser()
      .then((res) => {
        getCurrentProfil().then(res => {
          if (hasAccessPermission(res, permission.forcedTypes, permission.types, permission.roles)) {
            setAuth(2);
          } else {
            console.log("MYREDIRECT");
            history.push(getHomeLink(res));
            setAuth(2);
          }
        }).catch((error) => {
          if (error && (error.status === 401 || error.status === 403)) {
            window.location.href = LINKS.login(window.location.pathname);
            return;
          }
          setAuth(2);
          Logger("INTERNAL_ERROR_OCCURED", keywords);
        });
      })
      .catch((error) => {
        if (error && (error.status === 401 || error.status === 403)) {
          // history.push(`${URL_BASE}/login`);
          window.location.href = LINKS.login(window.location.pathname);
          return;
        }
        setAuth(2);
        Logger("INTERNAL_ERROR_OCCURED", keywords);
      });
  }, []);

  return (
    <React.Fragment>
      {auth != 2 ? (
        <div>Loading</div>
      ) : (
        <Route
          exact
          {...rest}
          render={(props) => {
            return <Component {...props} />;
          }}
        />
      )}
    </React.Fragment>
  );
};
export default PrivateRoute;
