import React, { useContext } from "react";
import { Select } from "antd";
// import { Option } from "antd/lib/mentions";
import SharedContext from "../../../contexts/shared/sharedContext";

import "./Inputs.css";
import { getArray, getValueManager, replaceIndex } from "../../manager/ManagerUtils";
import TooltipProvider from "../outputs/TooltipProvider";

const Option = Select.Option;

export const SelectInput = (props) => {
  const sharedContext = useContext(SharedContext);
  const { getKeyword } = sharedContext;
  const otherName = getKeyword("STD.OTHER_SELECT");
  const otherValue = "_OTHER_";

  let data = props.dependencies
    ? props.dependencies[props.data.linkId]
      ? props.dependencies[props.data.linkId]
      : []
    : props.data.items;

  if (props.filter) {
    data = data.filter(item => props.filter(item));
  }

  if (props.other && !props.multiple) {
    if (!props.data.label) {
      data = [...data, otherValue];
    } else {
      data = [...data, { [props.data.label]: otherName, [props.data.select]: otherValue }];
    }
  }

  const onChange = (value) => {
    console.log("CHAGE", value);
    if (props.onChange) {
      props.onChange(value, props.name);
    }
  };

  const valuesContainsOther = () => {
    if (!props.value) {
      return false;
    }
    return props.value == otherValue;
  }

  const onChangeOther = (event) => {
    if (props.onChange) {
      props.onChange(event.target.value, props.otherName);
    }
  }

  const getValuesToShow = () => {
    return <ul>
      {props.multiple ?
        <>
          {getArray(props.value).map(val => (
            <li>{getValueManager(data.find(f => f[props.data.select] == val), props.data.label) || val}</li>
          ))}
        </>
        :
        <li>{props.value == otherValue ? props.otherValue : (getValueManager(data.find(f => props.value && f[props.data.select] == props.value), props.data.label) || props.value)}</li>
      }
    </ul>
  }

  return (
    <div className="SelectInput InputField">
      {!props.label ? null : (
        <label className="Ilabel">
          {replaceIndex(
            getKeyword(props.label),
            props.lableIndexesNames,
            props.lableIndexes
          )}&nbsp;
          {props.optText ? (
            <span>
              {props.optText !== true ? props.optText : "(Optionnel)"}
            </span>
          ) : (
            ""
          )}
          <span className="requiredLb">{props.required ? " *" : ""}</span>
          <TooltipProvider tooltip={props.description}></TooltipProvider>
        </label>
      )}
      <div className="input">
        {props.isLoading ? (
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <>
            {props.preview ?
              <p className="input-response">{getValuesToShow()}</p>
              :
              <Select
                style={{ width: "100%" }}
                value={props.value || undefined}
                onChange={onChange}
                mode={props.multiple ? "multiple" : null}
                placeholder={
                  props.placeHolder
                    ? getKeyword(props.placeHolder)
                    : getKeyword(props.label)
                }
                showSearch={true}
                allowClear={true}
                filterOption={(input, option) => {
                  const val = option.children || option.value;
                  if (!val) {
                    return false;
                  }
                  return (
                    option.key &&
                    ("start" == props.search ? val.toLowerCase().startsWith(input.toLowerCase()) : val.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                  );
                }}
              >
                {/* {!props.multiple ?
                  <Option value="">
                    --&nbsp;
                    {props.placeHolder
                      ? getKeyword(props.placeHolder)
                      : getKeyword(props.label)}&nbsp;
                    --
                  </Option> : null} */}
                {data.map((o, i) => (
                  <Option key={props.name + (props.data.label ? o[props.data.select] : o)} value={props.data.label ? o[props.data.select] : o}>
                    {props.data.label
                      ? props.allowTraduction
                        ? getKeyword(o[props.data.label])
                        : o[props.data.label]
                      : props.allowTraduction
                        ? getKeyword(o)
                        : o}
                  </Option>
                ))}
              </Select>
            }</>
        )}
      </div>
      {!props.preview && !props.multiple && props.other && valuesContainsOther() ?
        <div className="TextInput">
          <div className="input">
            <input
              className="inp"
              type="text"
              value={props.otherValue}
              name={"TextInput-" + props.otherName}
              onChange={onChangeOther}
              placeholder={getKeyword("STD.OTHER")}
            ></input>
          </div></div> : null}
      {props.error ? (
        <p className="errorInput">
          <i className="fas fa-exclamation-triangle"></i> {props.error}
        </p>
      ) : null}
    </div>
  );
};

export default SelectInput;
