import AreaInput from "../../../../core/common/inputs/AreaInput";
import TextInput from "../../../../core/common/inputs/TextInput";
import { getValueManager } from "../../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../../contexts/shared/sharedContext";
import GroupInput from "../../../../core/common/inputs/GroupInput";
import { getValueManagerArray } from "../../../../core/manager/ManagerUtils";
import { replaceIndex } from "../../../../core/manager/ManagerUtils";
import SelectInputTableTechnique from "../../../../core/common/inputs/SelectInputTableTechnique";
import TechnicalTable from "../../../../variables/technical-table";
import { Modal } from "antd";
import Logger from "../../../../core/utils/Logger";

const MY_SELECT2 = [
  {
    group: "Permissions",
    items: [
      {
        permission: "PFBV",
      },

      {
        permission: "PFBU",
      }
    ],
  }

];

const MY_SELECT_AFFILIATE = [
  {
    group: "Permissions",
    items: [
      {
        permission: "PFBV",
      },

      {
        permission: "PFBU",
      },
      {
        permission: "PFBG",
      }
    ],
  }

];

const MY_SELECT = [
  {
    group: "Permissions",
    items: [
      // {
      //   permission: "PLGV",
      // },
      {
        permission: "PFLV",
      },
      {
        permission: "PSTV",
      },
      {
        permission: "PATV",
      },
      {
        permission: "PCTV",
      },
      {
        permission: "PRPV",
      },

      {
        permission: "PPRV",
      },

      {
        permission: "PQSV",
      },

      {
        permission: "PTBV",
      },

      {
        permission: "PFBV",
      },

      {
        permission: "PFBU",
      },

      {
        permission: "PFBG",
      },
    ],
  }

];



const RoleForm = (props) => {
  const { GetResource, getKeyword } = useContext(SharedContext);
  const form = props.form;
  const id = getValueManager(form, "id", null);
  const [permissions, setPermissions] = useState(MY_SELECT);
  const [permissions2, setPermissions2] = useState(MY_SELECT2);
  const [permissions3, setPermissions3] = useState(MY_SELECT_AFFILIATE);
  const [isLoading, setIsLoading] = useState(false);

  const getPermissionByRole =(role) => {
    if (role === "USER_TYPE_AFFILIATE") {
      return MY_SELECT_AFFILIATE;
    }
    else if (role === "USER_TYPE_INTERNAL") {
      return MY_SELECT;
    }
    return MY_SELECT2;
  }
  const notify = (type) => {
    Logger(replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")]));
  };

  useEffect(() => {
    setIsLoading(true);
    GetResource("PERMISSION", "GET", form, null)
      .then((res) => {
        setIsLoading(false);
        permissions.forEach(
          (g) =>
          (g.items = g.items.filter((item) => {
            let perm = res.payload.find(
              (p) => p.permission == item.permission
            );
            if (!perm) {
              return false;
            }
            item.id = perm.id;
            item.description = perm.description;
            return true;
          }))
        );
        setPermissions([...permissions]);

        permissions2.forEach(
          (g) =>
          (g.items = g.items.filter((item) => {
            let perm = res.payload.find(
              (p) => p.permission == item.permission
            );
            if (!perm) {
              return false;
            }
            item.id = perm.id;
            item.description = perm.description;
            return true;
          }))
        );
        setPermissions2([...permissions2]);

        permissions3.forEach(
            (g) =>
                (g.items = g.items.filter((item) => {
                  let perm = res.payload.find(
                      (p) => p.permission === item.permission
                  );
                  if (!perm) {
                    return false;
                  }
                  item.id = perm.id;
                  item.description = perm.description;
                  return true;
                }))
        );
        setPermissions3([...permissions3]);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    props.updateForm({ ...f });
  };

  const addNewTable = (event) => {
    GetResource("ROLE_MANAGEMENT", "ADD", form, null)
      .then((res) => {
        props.onAdd();
        props.toggleModal(props.modalName);
      })
      .catch((err) => {
        if (err && err.status == 409) {
          notify("danger");
        }
      });
  };

  const updateTable = (event) => {
    GetResource("ROLE_MANAGEMENT", "UPDATE", form, null).then((res) => {
      props.onUpdate(props.id);
      props.toggleModal(props.modalName);
    });
  };

  const formValid =
    getValueManager(form, "name", null) && getValueManager(form, "type", null);

  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        style={{ maxWidth: "1000px", width: "100%" }}
        open={props.showModal && !isLoading}
        width={"1000px"}
        onCancel={() => props.toggleModal(props.modalName)}
      >
        <div className="modal-body p-0">
          <div className="bg-secondary  border-0 mb-0">
            <div className="bg-menu text-white mb-3">
              <div className="text-center">
                <p className="font-weight-bold mb-0"> {getKeyword("USER.ROLE")}</p>
              </div>
            </div>
            <div className="px-lg-5 py-lg-1">
              <form role="form">
                <div className="row">
                  <div className="col-md-12">
                    <TextInput
                      label={getKeyword("COMMON.DESIGNATION")}
                      name="name"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "name", null)}
                      required={true}
                    ></TextInput></div>
                  <div className="col-12">
                    <SelectInputTableTechnique
                      required={true}
                      label="Type utilisateur cible"
                      name="type"
                      code={TechnicalTable.USER_TYPE}
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(
                        form,
                        "type",
                        null
                      )}
                      filter={(v) => v.code == "USER_TYPE_INTERNAL" || v.code == "USER_TYPE_AFFILIATE" || v.code == "USER_TYPE_STARTUP"}
                    ></SelectInputTableTechnique>
                  </div>
                  <div className="col-12">
                    <AreaInput
                      label={getKeyword("COMMON.DESCRIPTION")}
                      name="description"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "description", null)}
                    ></AreaInput>
                  </div>
                </div>
                {getValueManager(
                  form,
                  "type",
                  null
                ) ?
                  <GroupInput
                    label={getKeyword("USER.PERMISSIONS")}
                    name="permissions"
                    items={
                      getPermissionByRole(getValueManager(
                          form,
                          "type",
                          null
                      ))

                  }
                    onChange={(v, n) =>
                      onChange(
                        v.map((m) => ({ id: m })),
                        n
                      )
                    }
                    value={getValueManagerArray(form, "permissions", null).map(
                      (m) => m.id
                    )}
                  ></GroupInput> : null}
                <div className="text-center d-flex justify-content-between">
                  <button
                    className="my-4"
                    color="white"
                    type="button"
                    onClick={(e) => props.toggleModal(props.modalName)}
                  >
                    {getKeyword("STD_VERB.CANCEL")}
                  </button>
                  <button
                    className="btn-1"
                    color="danger"
                    type="button"
                    disabled={!formValid}
                    onClick={(e) => (id ? updateTable() : addNewTable())}
                  >
                    {id ? getKeyword("STD_VERB.EDIT") : getKeyword("STD_VERB.ADD")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default RoleForm;
