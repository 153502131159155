import React, { useContext, useEffect, useState } from "react";

import SharedContext from "../../contexts/shared/sharedContext";
import GlobalTable from "../../core/common/table/GlobalTable";
import GlobalFilters from "../../core/common/table/GlobalFilters";
import { USEFULL_VARIABLES } from "../../variables/shared";
import { getJsonArray, getValueManager, updateValueManager } from "../../core/manager/ManagerUtils";
import { useHistory } from "react-router";
import ItemMenu from "../../core/common/table/ItemMenu";
import { LoggerSuccess } from "../../core/utils/Logger";
import ConfirmationPopupShared from "../../core/common/notifications/ConfirmationPopup";
import StartupImport from "./forms/startup-import";
import { userIsInternal } from "../../core/utils/security";
import { DownloadFile } from "../../core/utils/download";
import {ASSETS_BASE} from "../../config";
const TABLE = [
  {
    label: "Nom",
    value: "name",
    order: true,
    type: "CLICK",
    call: "show"
  },
  {
    label: "Activités",
    value: "activity",
    type: "RENDER",
    render: (props) => (<>{getJsonArray(getValueManager(
      props.item,
      "form.activity",
      null
    )).length > 0 ? getJsonArray(getValueManager(
      props.item,
      "form.activity",
      null
    )).reduce((a, b) => (a ? a + ", " : "") + b.value, "") : ""}</>)
  },
  {
    label: "Contact principal",
    value: ["form.mainContactFirstName", "form.mainContactLastName"],
    multiple: true,
  },
  {
    label: "COMMON.STATUS",
    value: "enabled",
    type: "BOOLEAN_COLOR",
    class: "",
    data: {
      active: "Actif",
      notActive: "Inactif"
    }
  },
  {
    label: "Actions",
    value: "actions",
    type: "RENDER",
    class: "table-actions",
    render: (props) => (
      <>
        <ItemMenu menus={[
          {
            name: props.getKeyword("STD_VERB.VIEW_DETAILS"),
            click: () => props.update(props.id, true)
          },
          {
            name: props.getKeyword("STD_VERB.EDIT"),
            click: () => props.update(props.id, false),
            hide: !userIsInternal(props.profile)
          },
          {
            name: props.getKeyword("STD_VERB.DISABLE"),
            click: () => props.disable(props.id),
            hide: !props.item.enabled || !userIsInternal(props.profile)
          },
          {
            name: props.getKeyword("STD_VERB.ENABLE"),
            click: () => props.enable(props.id),
            hide: props.item.enabled || !userIsInternal(props.profile)
          }
        ]}>
        </ItemMenu>
      </>
    ),
  },
];

const FILTERS = [
  {
    label: "Code",
    value: "code",
    className: "col-md-4"
  },
  {
    label: "Nom",
    value: "name",
    className: "col-md-4"
  },
  {
    label: "Activité",
    value: "activity",
    className: "col-md-4"
  }
];

const FILTER_INIT = {
  code: "",
  name: "",
  activity: ""
};

const Startup = (props) => {
  const history = useHistory();
  const { GetResource, getKeyword, profile } = useContext(SharedContext);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    size: USEFULL_VARIABLES.paginationSize,
    order: null,
    direction: "DESC",
  });
  const [formFilter, setFormFilter] = useState(
    JSON.parse(JSON.stringify(FILTER_INIT))
  );
  const [modalState, setModalState] = useState({});

  const toggleModal = (state) => {
    setModalState({ ...modalState, [state]: !modalState[state] });
  };
  const [form, setForm] = useState({
    code: "",
    designation: "",
    description: "",
  });

  const [isFilter, setIsFilter] = useState(false);

  const [deleteMessage, setDeleteMessage] = useState(null);

  const successAlert = (message) => {
    LoggerSuccess(message);
  };

  useEffect(() => {
    paginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  }, []);

  const reload = () => {
    paginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const initForm = () => {
    setForm({
      code: "",
      designation: "",
      description: "",
    });
  };

  const onUpdate = () => {
    reload();
    successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    initForm();
  };

  const onChangeFilters = (v, n, indexes = null) => {
    const f = updateValueManager(formFilter, n, v, indexes);
    setFormFilter({ ...f });
  };

  const paginate = (page, size, order, direction) => {
    if (isFilter) {
      loadFilter(page, size, order, direction);
    } else {
      loadPaginate(page, size, order, direction);
    }
  };

  const filter = () => {
    pagination.page = 1;
    setIsFilter(true);
    loadFilter(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const loadPaginate = (page, size, order, direction) => {
    setLoaded(false);
    GetResource(
      "STARTUP_MANAGEMENT",
      "GET",
      {
        page,
        size,
        order,
        direction,
      },
      null
    ).then((res) => {
      setData(res.payload);
      setPagination({ ...pagination, page, size, order, direction });
      setLoaded(true);
    });
  };

  const loadFilter = (page, size, order, direction) => {
    setLoaded(false);
    GetResource(
      "STARTUP_MANAGEMENT",
      "FILTER",
      {
        ...formFilter,
        data: {
          page,
          size,
          order,
          direction,
        },
      },
      null
    ).then((res) => {
      setData(res.payload);
      setLoaded(true);
    });
  };

  const initializeFilter = () => {
    setFormFilter(JSON.parse(JSON.stringify(FILTER_INIT)));
    setIsFilter(false);
    loadPaginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const updateItem = (id, show) => {
    if (userIsInternal(profile)) {
      history.push("/admin/startup-edit/" + id + (show ? "?show=true" : ""));
    } else {
      history.push("/external/startup-show/" + id + (show ? "?show=true" : ""));
    }
  };

  const enableItem = (id) => {
    GetResource(
      "STARTUP_MANAGEMENT",
      "ACTIVATION",
      { id, enabled: true },
      null
    ).then((res) => {
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));

    });
  };

  const disableItem = (id) => {
    GetResource(
      "STARTUP_MANAGEMENT",
      "DESACTIVATION",
      { id, enabled: false },
      null
    ).then((res) => {
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };

  const deleteItem = (itemId) => {
    setDeleteMessage({
      message: getKeyword("NOTIFICATION.DELETE_TEXT"),
      id: itemId,
    });
  };

  const confirmDelete = () => {
    GetResource(
      "STARTUP_MANAGEMENT",
      "DELETE",
      { id: deleteMessage.id },
      null
    ).then((res) => {
      setDeleteMessage(null);
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };

  const exportExcel = () => {
    GetResource(
      "STARTUP_MANAGEMENT",
      "EXPORT",
      {},
      null
    ).then((res) => {
      DownloadFile(res.payload.file, "Affiliés");
    });
  }

    const downloadFile = () => {
        const response = `${ASSETS_BASE}/templates/reponses.xlsx`;
        window.open(response.file);
    }






  return (
    <>
      {deleteMessage ? (
        <ConfirmationPopupShared
          message={deleteMessage.message}
          close={() => setDeleteMessage(null)}
          confirm={confirmDelete}
        ></ConfirmationPopupShared>
      ) : null}
      <div className="rna-wrapper">
        <GlobalFilters
          filters={FILTERS}
          form={formFilter}
          onChange={onChangeFilters}
          filter={filter}
          initialize={initializeFilter}
        ></GlobalFilters>
        <div className="card-block">
          <div className="card-header">
            <div>
              <h3 className="mb-0">Startups</h3>
            </div>
            <div>


              <button
                className="btn-4 mgr-15"
                id="tooltip969372949"
                onClick={(e) => { exportExcel() }}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">{getKeyword("STD_VERB.EXPORT")}</span>
              </button>

              {userIsInternal(profile) ? <>
                <a
                    style={{lineHeight: 'inherit'}}
                    className="btn-4 mgr-15"
                    id="tooltip969372949"
                    href={`${ASSETS_BASE}/templates/startup.xlsx`}
                >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-plus" />
                </span>
                  <span className="btn-inner--text">template</span>
                </a>

                <button
                  className="btn-2 mgr-15"
                  id="tooltip969372949"
                  onClick={(e) => { toggleModal("formModal"); }}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">{getKeyword("STD_VERB.IMPORT")}</span>
                </button>
                <button
                  className="btn-1"
                  id="tooltip969372949"
                  onClick={(e) => history.push("/admin/startup-new")}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">{getKeyword("STD.NEW_FEMALE")}</span>
                </button>
              </> : null}
            </div>
          </div>
          {modalState["formModal"] ? (
            <StartupImport
              modalName="formModal"
              toggleModal={toggleModal}
              showModal={modalState["formModal"]}
              onImport={onUpdate}
            ></StartupImport>
          ) : null}
          {isLoaded ? (
            <GlobalTable
              table={TABLE}
              data={data.data}
              count={data.count}
              pagination={pagination}
              paginate={paginate}
              update={updateItem}
              enable={enableItem}
              disable={disableItem}
              delete={deleteItem}
              show={(id) => updateItem(id, true)}
            ></GlobalTable>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Startup;
