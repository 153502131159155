import React, { useState, useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import SharedContext from "../../contexts/shared/sharedContext";
import { hasAccessPermission } from "../../core/utils/security";
import Page404 from "../../modules/pages/404";
import { AdminHeaderRoutes, AdminRoutes, MoreAdminRoutes } from "../../routes/routes/AdminRoutes";
import AdminHeader from "../Header/AdminHeader";

import './Layout.css';
import { LayoutProvider } from "./LayoutHelpers";

const AdminLayout = (props) => {

    const { profile } = useContext(SharedContext);

    return <div>
        <AdminHeader routes={AdminRoutes} topRoutes={AdminHeaderRoutes}></AdminHeader>
        <div className="layout-style">
            <Switch>
                {AdminRoutes.filter(
                    (r) =>
                        hasAccessPermission(profile, r.pForcedTypes, r.pTypes, r.pRoles)
                ).map(route => <Route
                    key={route.name}
                    exact
                    path={route.path}
                    render={(props) => <LayoutProvider props={props} menu={route.menu} component={route.component}></LayoutProvider>}
                />)}
                {MoreAdminRoutes.filter(
                    (r) =>
                        hasAccessPermission(profile, r.pForcedTypes, r.pTypes, r.pRoles)
                ).map(route => <Route
                    key={route.name}
                    exact
                    path={route.path}
                    render={(props) => <LayoutProvider props={props} menu={route.menu} component={route.component}></LayoutProvider>}
                />)}
                {AdminHeaderRoutes.filter(
                    (r) =>
                        hasAccessPermission(profile, r.pForcedTypes, r.pTypes, r.pRoles)
                ).filter(f => !f.children || f.children.length == 0).map(route => <Route
                    key={route.name}
                    exact
                    path={route.path}
                    render={(props) => <LayoutProvider props={props} menu={route.menu} component={route.component}></LayoutProvider>}
                />)}
                {AdminHeaderRoutes.filter(
                    (r) =>
                        hasAccessPermission(profile, r.pForcedTypes, r.pTypes, r.pRoles)
                ).map(routeR => routeR.children.map(route => <Route
                    key={route.name}
                    exact
                    path={route.path}
                    render={(props) => <LayoutProvider props={props} menu={route.menu} component={route.component}></LayoutProvider>}
                />))}
                <Route path="**" component={Page404} />
            </Switch>
        </div>
    </div>
        ;
};

export default AdminLayout;
