import AreaInput from "../../../core/common/inputs/AreaInput";
import TextInput from "../../../core/common/inputs/TextInput";
import { getValueManager, getValueManagerArray } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import SelectInputTableTechnique from "../../../core/common/inputs/SelectInputTableTechnique";
import ReferentielTable from "../../../variables/referentiel-table";
import SelectInputResource from "../../../core/common/inputs/SelectInputResource";
import CheckboxStyle from "../../../core/common/inputs/Checkbox";
import TechnicalTable from "../../../variables/technical-table";

export const QuestionFormTableType = (props) => {
  const { getKeyword } = useContext(SharedContext);
  const form = props.form;

  const addLine = () => {
    let arr = getValueManagerArray(props.form, "lines", null);
    arr.push({
      name: "",
      type: "",
      response: null
    });
    props.onChange(arr, "lines");
  };

  const deleteFrom = (name, index) => {
    let arr = getValueManagerArray(props.form, name);
    if (!arr) {
      arr = [];
    }
    props.onChange(
      arr.filter((vv, i) => i != index),
      name
    );
  };


  const swapItems = (arr, a, b) => {
    if (a >= 0 && b >= 0 && arr.length > a && arr.length > b) {
      arr[a] = arr.splice(b, 1, arr[a])[0];
      return arr;
    } else {
      return arr;
    }
  }

  const bringUp = (index) => {
    props.onChange(swapItems(getValueManagerArray(props.form, "lines"), index, index - 1).map((m, index) => {
      m.order = index;
      return m;
    }), "lines");
  }

  const bringDown = (index) => {
    props.onChange(swapItems(getValueManagerArray(props.form, "lines"), index, index + 1).map((m, index) => {
      m.order = index;
      return m;
    }), "lines");
  }

  return (
    <div className="fair--create-area_inputs question-table-css">
      <label className="fair--create-area_labels question-table-css-head">
        Liste des questions
      </label>
      {getValueManagerArray(props.form, "lines").map((et, index) => (
        <div className="question-table-block">
          {index != 0 ?
            <i className="fas fa-sort-numeric-up-alt bring-top" onClick={() => bringUp(index)}></i> : null}
          {index + 1 < getValueManagerArray(props.form, "lines").length ?
            <i className="fas fa-sort-numeric-down-alt bring-bottom" onClick={() => bringDown(index)}></i> : null}
          <div className="row">
            <i
              className="fas fa-times question-table-block-close"
              onClick={() => deleteFrom("lines", index)}
            ></i>
            <div className="bloc-group col-md-6" key={et.id + "key" + index}>
              <div className="mgb-20">
                <TextInput
                  label={getKeyword("COMMON.DESIGNATION")}
                  name="lines.?0.name"
                  onChange={(v, n) => props.onChange(v, n, [index])}
                  value={getValueManager(form, "lines.?0.name", [index])}
                  required={true}
                ></TextInput>
              </div>
            </div>
            <div className="bloc-group col-md-6 mgb-20">
              <SelectInputTableTechnique
                label="Type"
                name="lines.?0.type"
                onChange={(v, n) => props.onChange(v, n, [index])}
                value={getValueManager(form, "lines.?0.type", [index])}
                code={ReferentielTable.QUESTION_TYPE}
                filter={(e) => e.code != "QUESTION_TYPE_TABLE"}
                required={true}
              ></SelectInputTableTechnique>
            </div>
            {getValueManager(form, "type", null) == "QUESTION_TYPE_FILE"
              || getValueManager(form, "type", null) == "QUESTION_TYPE_IMAGE"
              || getValueManager(form, "type", null) == "QUESTION_TYPE_API" ?
              <div className="col-md-12">
                <CheckboxStyle
                  label={"Multiple?"}
                  name="lines.?0.multiple"
                  onChange={(v, n) => props.onChange(v, n, [index])}
                  value={getValueManager(form, "lines.?0.multiple", [index])}
                ></CheckboxStyle>
              </div> : null}
            {getValueManager(form, "lines.?0.type", [index]) == "QUESTION_TYPE_CHOICE" ||
              getValueManager(form, "lines.?0.type", [index]) == "QUESTION_TYPE_CHOICE_MULTIPLE" ||
              getValueManager(form, "lines.?0.type", [index]) == "QUESTION_TYPE_RADIO" ||
              getValueManager(form, "lines.?0.type", [index]) == "QUESTION_TYPE_RADIO_MULTIPLE"
              ?
              <div className="bloc-group col-md-12 mgb-20">
                <SelectInputResource
                  label="Réponse"
                  name="lines.?0.response"
                  resource="TABLE_RESPONSES"
                  method="GET_ALL"
                  required={true}
                  multiple={false}
                  onChange={(v, n) =>
                    v ? props.onChange({ id: v },
                      n, [index]
                    ) : props.onChange(null,
                      n, [index]
                    )
                  }
                  value={getValueManager(form, "lines.?0.response.id", [index])}
                  map={{
                    select: "id",
                    label: "designation",
                  }}
                ></SelectInputResource>
              </div>
              : null}
            {getValueManager(form, "lines.?0.type", [index]) == "QUESTION_TYPE_CHOICE" ||
              getValueManager(form, "lines.?0.type", [index]) == "QUESTION_TYPE_RADIO" ||
              (getValueManager(form, "lines.?0.type", [index]) == "QUESTION_TYPE_API" && !getValueManager(form, "multiple", null))
              ?
              <div className="col-md-12">
                <CheckboxStyle
                  label={"Autoriser ajout d'autre valeur?"}
                  name="lines.?0.description"
                  onChange={(v, n) => props.onChange(v, n, [index])}
                  value={getValueManager(form, "lines.?0.other", [index])}
                ></CheckboxStyle>
              </div> : null}

            {getValueManager(form, "lines.?0.type", [index]) == "QUESTION_TYPE_API" ? <>
              <div className="col-md-12">
                <TextInput
                  label={"Lien"}
                  name="lines.?0.apiLink"
                  onChange={(v, n) => props.onChange(v, n, [index])}
                  value={getValueManager(form, "lines.?0.apiLink", [index])}
                  required={true}
                ></TextInput>
              </div>
              <div className="col-md-12">
                <SelectInputTableTechnique
                  label="Méthode"
                  name="lines.?0.apiMethod"
                  onChange={(v, n) => props.onChange(v, n, [index])}
                  value={getValueManager(form, "lines.?0.apiMethod", [index])}
                  code={TechnicalTable.API_METHOD}
                  required={true}
                ></SelectInputTableTechnique>
              </div>
              <div className="col-md-12">
                <AreaInput
                  label="Body"
                  name="lines.?0.apiBody"
                  onChange={(v, n) => props.onChange(v, n, [index])}
                  value={getValueManager(form, "lines.?0.apiBody", [index])}
                ></AreaInput>
              </div>
              <div className="col-md-12">
                <TextInput
                  label={"Attribut à afficher"}
                  name="lines.?0.apiLabel"
                  onChange={(v, n) => props.onChange(v, n, [index])}
                  value={getValueManager(form, "lines.?0.apiLabel", [index])}
                  required={true}
                ></TextInput>
              </div>
              <div className="col-md-12">
                <TextInput
                  label={"Attribut à sélectionner"}
                  name="lines.?0.apiSelect"
                  onChange={(v, n) => props.onChange(v, n, [index])}
                  value={getValueManager(form, "lines.?0.apiSelect", [index])}
                  required={true}
                ></TextInput>
              </div>
            </> : null}

            <div className="bloc-group col-md-12">
              <AreaInput
                label={getKeyword("COMMON.DESCRIPTION")}
                name="lines.?0.description"
                onChange={(v, n) => props.onChange(v, n, [index])}
                value={getValueManager(form, "lines.?0.description", [index])}
              ></AreaInput>
            </div>
          </div>
        </div>
      ))}

      <button className="add-group" type="button" onClick={addLine}>
        {"Ajouter"}
      </button>
    </div>
  );
};

export default QuestionFormTableType;
