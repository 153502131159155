import { dateToString } from "../../utils/helpers";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { getValueManager } from "../../manager/ManagerUtils";
import { DownloadFile } from "../../utils/download";

export const ImagePreviewProvider = (props) => {
    const sharedContext = useContext(SharedContext);
    const { GetImageByte } = sharedContext;
    const file = props.file;
    const [src, setSrc] = useState("");

    useEffect(() => {
        download();
    }, [props.file]);

    const download = () => {
        GetImageByte(file).then((res) =>
            setSrc(res.payload.file)
            // DownloadFile(, (imageData[file] &&
            //     imageData[file]
            //         .text) || file)
        )
    }

    return (
        <img src={"data:image/png;base64, " + src} className={props.className} alt={props.alt}></img>
    );
};

export default ImagePreviewProvider;
