import React from "react";
import './styles/contact.css';

const Contact = (props) => {
    return <div className='contactBlock'>

        <div className="container-fluid">
            <h3>Contact</h3>
            <div className="row">
                <div className="col-md-6 mt-4">
                    <div className="para">
                        <p>Nous sommes à votre disposition pour répondre à toutes vos questions et demandes, n'hésitez
                            pas à nous contacter via le formulaire de contact ou par email.</p>
                    </div>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <div className="form-outline my-1">
                                    <label className="form-label" htmlFor="email">Email</label>
                                    <input name="email" type="text" id="email"
                                           placeholder="email@test.com" className="form-control"/>

                                </div>

                                <div className="form-outline my-3">
                                    <label className="form-label" htmlFor="message">Message</label>
                                    <textarea  name="message" type="text" id="message"
                                               placeholder="Your message..." cols="10" rows="7"
                                               className="form-control"></textarea>

                                </div>
                                <button className="btn btn-block mb-4 ">Send</button>
                            </form>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

};

export default Contact;
