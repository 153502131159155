import React from "react";
import ExpiredPasswordConfirm from "../../modules/auth/ExpiredPasswordConfirm";
import Login from "../../modules/auth/Login";
import ResetPassword from "../../modules/auth/Reset";
import ResetConfirm from "../../modules/auth/ResetConfirm";
import InviteConfirm from "../../modules/user/utilisateur/InviteConfirm";
import LunchAuth from "../../modules/auth/LunchAuth";
export const AuthRoutes = [
    {
        "name": "lunch",
        "path": "/auth/lunch",
        "component": LunchAuth,
    },
    {
        "name": "Login",
        "path": "/auth/login",
        "component": Login,
    },
    {
        "name": "invite",
        "path": "/auth/invite",
        "component": InviteConfirm,
    },
    {
        "name": "Reset password",
        "path": "/auth/reset",
        "component": ResetPassword,
    },
    {
        name: "Reset password comfirm",
        path: "/auth/password",
        component: ResetConfirm,
    },
    {
        name: "Expired password",
        path: "/auth/expired-password",
        component: ExpiredPasswordConfirm,
    },
]

// export const AuthHeaderRoutes = [
//     {
//         "name": "Tables",
//         children: [
//             {
//                 "name": "Logs",
//                 "path": "/admin/referiental-table",
//                 "component": TableReferentiel,
//             }
//         ]
//     }
// ]
