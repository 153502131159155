import { dateToString } from "../../../core/utils/helpers";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { Button, Divider, Tooltip } from 'antd';


export const TooltipProvider = (props) => {
    const sharedContext = useContext(SharedContext);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };

    return (
        <>{props.tooltip ? <Tooltip className="tooltip-provider" color={'#f50'} title={props.tooltip}>
            <Button><i className="fas fa-info-circle"></i></Button>
        </Tooltip> : null}</>
    );
};

export default TooltipProvider;
