import React, { useContext, useEffect, useState } from "react";
import { getValueManager, getValueManagerArray } from "../../core/manager/ManagerUtils";

const app = {};

const posList = [
    'left',
    'right',
    'top',
    'bottom',
    'inside',
    'insideTop',
    'insideLeft',
    'insideRight',
    'insideBottom',
    'insideTopLeft',
    'insideTopRight',
    'insideBottomLeft',
    'insideBottomRight'
];
app.configParameters = {
    rotate: {
        min: -90,
        max: 90
    },
    align: {
        options: {
            left: 'left',
            center: 'center',
            right: 'right'
        }
    },
    verticalAlign: {
        options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom'
        }
    },
    position: {
        options: posList.reduce(function (map, pos) {
            map[pos] = pos;
            return map;
        }, {})
    },
    distance: {
        min: 0,
        max: 100
    }
};
app.config = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
    onChange: function () {
        // const labelOption = {
        //     rotate: app.config.rotate,
        //     align: app.config.align,
        //     verticalAlign: app.config.verticalAlign,
        //     position: app.config.position,
        //     distance: app.config.distance
        // };
        // // myChart.setOption({
        // //     series: [
        // //         {
        // //             label: labelOption
        // //         },
        // //         {
        // //             label: labelOption
        // //         },
        // //         {
        // //             label: labelOption
        // //         },
        // //         {
        // //             label: labelOption
        // //         }
        // //     ]
        // // });
    }
};
const option = (data) => {
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: getValueManagerArray(data, "legends.data").map(i => i.name),
            show: true
            // show: !getValueManager(data, "legends.hide")
        },
        toolbox: {
            show: false,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ['line', 'bar', 'stack'] },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        // xAxis: [
        //     {
        //         type: 'category',
        //         axisTick: { show: true },
        //         data: getValueManagerArray(data, "xAxis")
        //     }
        // ],
        xAxis:data.xAxis,
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: getValueManagerArray(data, "series").map((sr, index) => ({
            name: getValueManager(data, "legends.data.?0.name", [index]),
            type: 'bar',
            barGap: 0,
            label: {
                show: true,
                position: app.config.position,
                distance: app.config.distance,
                align: app.config.align,
                verticalAlign: app.config.verticalAlign,
                rotate: app.config.rotate,
                // formatter: '{c}  {name|{a}}',
                formatter: getValueManager(sr, "formatter") || '{c}  {name|{a}}',
                fontSize: 16,
                rich: {
                    name: {}
                }
            },
            itemStyle: {
                color: getValueManager(sr, "color") || null
            },
            emphasis: {
                focus: 'series'
            },
            data: getValueManagerArray(sr, "data")
        }))
    }
};


export const CHART_BAR_MULTIPLE3 = option;
