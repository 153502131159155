import AreaInput from "../../../core/common/inputs/AreaInput";
import TextInput from "../../../core/common/inputs/TextInput";
import { getValueManager } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import CodeInput from "../../../core/common/inputs/CodeInput";

const QSQuestionCreatorForm = (props) => {
  const { GetResource, getKeyword } = useContext(SharedContext);
  const form = props.form;
  const id = getValueManager(form, "id", null);

  useEffect(() => { }, []);

  return (
    <div>
      <form role="form">
        <div className="row">
        <div className="col-md-12">
            <CodeInput
              label={getKeyword("COMMON.CODE")}
              name="code"
              onChange={(v, n) => props.onChange(v, n)}
              value={getValueManager(form, "code", null)}
              required={true}
            ></CodeInput>
          </div>
          <div className="col-md-12">
            <TextInput
              label={getKeyword("COMMON.DESIGNATION")}
              name="name"
              onChange={(v, n) => props.onChange(v, n)}
              value={getValueManager(form, "name", null)}
              required={true}
            ></TextInput>
          </div>
        </div>
        <AreaInput
          label={getKeyword("COMMON.DESCRIPTION")}
          name="description"
          onChange={(v, n) => props.onChange(v, n)}
          value={getValueManager(form, "description", null)}
        ></AreaInput>
      </form>
    </div>
  );
};
export default QSQuestionCreatorForm;
