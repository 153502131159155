import React from "react";
import AdminLayout from "../layouts/Layout/AdminLayout";
import AuthLayout from "../layouts/Layout/AuthLayout";
import ExternalLayout from "../layouts/Layout/ExternalLayout";
import HomePage from "../modules/home/Home";

export const LAYOUTS = [
    {
        "name": "home",
        "path": "/home",
        "component": HomePage,
        "private": false
    },
    {
        "name": "admin",
        "path": "/admin",
        "component": AdminLayout,
        "pTypes": ["USER_TYPE_SUPER_ADMIN", "USER_TYPE_INTERNAL"],
        "private": true
    },
    {
        "name": "auth",
        "path": "/auth",
        "component": AuthLayout,
        "private": false
    },
    {
        "name": "external",
        "path": "/external",
        "component": ExternalLayout,
        "pTypes": ["USER_TYPE_STARTUP", "USER_TYPE_AFFILIATE"],
        "private": true
    }
]
