import SharedContext from "../../../contexts/shared/sharedContext";
import React, { useContext, useEffect, useState } from "react";
import { useDrag } from 'react-dnd'
import QSQuestionCreatorCardItem from "./CardItem";


const QSQuestionCreatorCard = (props) => {
    const { GetResource } = useContext(SharedContext);
    const [data, setData] = useState([]);
    const [val, setVal] = useState("");

    useEffect(() => {
        if (props.forceSearch) {
            filter(props.forceSearch, true);
            props.disableForceSearch();
        }
    }, [props.forceSearch])

    const filter = (valUser, forceSearch = false) => {
        let newVal = forceSearch ? valUser : valUser.target.value;
        setVal(newVal);
        if (newVal) {
            loadFilter(newVal, forceSearch);
        } else {
            setData([]);
        }
    }

    const loadFilter = (val, forceSearch = false) => {
        GetResource(
            "QUESTION_MANAGEMENT",
            "FILTER",
            {
                typeOfFilter: "OR",
                id: val,
                name: forceSearch ? null : val,
                data: {
                    page: 1,
                    size: 8,
                    order: "name",
                    direction: "ASC",
                },
            },
            null
        ).then((res) => {
            setData(res.payload.data);
        }).catch(ex => {
            setData([]);
        });
    };

    return (
        <div>
            <input placeholder="Recherche par Code question OU Désignation" value={val} onChange={filter} className="qc-card-search"></input>
            {data.length == 0 ? <p>Aucune question sélectionnée, veuillez utiliser le filtre de question pour sélectionner la question en fonction du code et de la désignation ou bien:</p> : null}
            {data.length > 0 && data.map(m => (
                <QSQuestionCreatorCardItem key={m.id} {...props} question={m}></QSQuestionCreatorCardItem>
            ))}
        </div>
    )
}

export default QSQuestionCreatorCard;