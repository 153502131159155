function Upload(element) {
  var reader = new FileReader();
  let file = element.files[0];
  reader.onload = function () {
      var arrayBuffer = this.result;
      Download(arrayBuffer, file.type);
  }
  reader.readAsArrayBuffer(file);
}

export const Download = (arrayBuffer, type) => {
  var blob = new Blob([arrayBuffer], { type: type });
  var url = URL.createObjectURL(blob);
  window.open(url);
}

export function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

export function DownloadFile(data, filename, extension = 'xlsx') {
  const blob = new Blob([base64ToArrayBuffer(data)]);
  console.log(blob);
  const fileName = `${filename}.${extension}`;
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}