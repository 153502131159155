const TechnicalTable = {
  USER_TYPE: "USER_TYPE",
  QUESTION_TYPE: "QUESTION_TYPE",
  PROCESSUS_COLLECT_TYPE: "PROCESSUS_COLLECT_TYPE",
  REQUEST_TYPE: "REQUEST_TYPE",
  PROCESS_COLLECT_TYPE: "PROCESS_COLLECT_TYPE",
  PROCESS_COLLECT_REPEAT: "PROCESS_COLLECT_REPEAT",
  API_METHOD: "API_METHOD",
};

export default TechnicalTable;
