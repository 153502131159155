import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { Menu, Dropdown } from "antd";

import "./Header.css";
import SharedContext from "../../contexts/shared/sharedContext.js";
import AuthContext from "../../contexts/auth/authContext.js";
import LINKS, { ASSETS_BASE, URL_BASE } from "../../config";
import { hasAccessPermission, userIsInternal } from "../../core/utils/security";
// import { MSTRANSLATE_BACKEND_LINKS } from "../../../yami/translate/links.js";
// import { MSUSER_FRONTEND_LINKS } from "../../../yami/user/links.js";

const AdminHeader = (props) => {
    const ROUTES = props.routes || [];
    const TOP_ROUTES = props.topRoutes || [];
    const { menuName, keywords, getKeyword, getKeywords, GlobalGet, profile, GetResource } =
        useContext(SharedContext);

    const authContext = useContext(AuthContext);
    const { loadUser, currentUser, logout } = authContext;

    const [lngs, setLngs] = useState([]);

    const history = useHistory();
    const [lng, setLng] = useState("EN");

    const [close, setClose] = useState(false);

    const [notification, setNotification] = useState(0);

    useEffect(() => {
        loadUser({ noredirect: true }).then((res) => {
            let lngDe = "default";
            if (res && res.languageId) {
                lngDe = res.languageId;
            } else if (localStorage.getItem("lng")) {
                lngDe = localStorage.getItem("lng");
            }
            getKeywords(lngDe);
        });
        GetResource(
            "NOTIFICATION_MANAGEMENT",
            "GET",
            {},
            null
          ).then((res) => {
            setNotification(res.payload.length);
          });
        // GlobalGet({}, MSTRANSLATE_BACKEND_LINKS.languages, null).then((res) => {
        //     setLngs(res.payload.data);
        // });
    }, []);

    const changeLanguage = (lng) => {
        const l = lngs.find((l) => l.id === lng);
        if (l) {
            setLng(l.code);
            getKeywords(l.slug);
        }
    };

    const navigateTo = (link) => {
        history.push(link);
        console.log("Redirect me to", link);
    };

    const navigateToExternalLogin = () => {
        window.location.href = LINKS.login(window.location.pathname);
    };

    const onLogout = () => {
        logout();
    };

    const getActions = () => (
        <Menu>
            <Menu.Item key="2">
                <p
                    className="p-act"
                    onClick={() =>
                        userIsInternal(profile) ?
                            navigateTo("/admin/profile?show=true") : navigateTo("/external/profile?show=true")
                    }
                >
                    <i className="fa fa-user" aria-hidden="true"></i> Profil
                </p>
            </Menu.Item>
            <Menu.Item key="1">
                <p className="p-act" onClick={() => onLogout()}>
                    <i className="fas fa-sign-out-alt" aria-hidden="true"></i> Se déconnecter
                </p>
            </Menu.Item>
        </Menu>
    );

    const getLanguages = () => (
        <Menu>
            {lngs.map((l) => (
                <Menu.Item key={l.id}>
                    <p
                        className="p-act"
                        onClick={() => changeLanguage(l.id)}
                        title={l.language}
                    >
                        {l.language}
                    </p>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <div className="HeaderEC">
            <nav className="navBar">
                <div className="logo">
                    <img src={`${ASSETS_BASE}/imgs/icons/LOGO.png`} alt="logo" />
                </div>

                <div className="blockItemHeader">
                    {TOP_ROUTES.filter(
                        (r) =>
                            hasAccessPermission(profile, r.pForcedTypes, r.pTypes, r.pRoles)
                    ).map((it, i) => (
                        <div key={i} className="itemHeader">
                            <p className={`name ${(it.menus && it.menus.find(m => menuName === m)) || menuName === it.menu ? "active" : ""}`}
                                onClick={() => it.children && it.children.length > 0 ? null : navigateTo(it.path)}>
                                &nbsp;
                                <img src={it.icon} /> {getKeyword(it.name)}&nbsp;
                                {it.children && it.children.length > 0 ?
                                    <i className="fa fa-angle-down" aria-hidden="true"></i> : null}
                            </p>
                            {it.children && it.children.length > 0 ?
                                <div className="subMenu">
                                    <i className="fa fa-caret-up" aria-hidden="true"></i>
                                    <ul>
                                        {it.children
                                            .filter(
                                                (r) =>
                                                    hasAccessPermission(profile, r.pForcedTypes, r.pTypes, r.pRoles)
                                            )
                                            .map((r, ii) => (
                                                <li
                                                    key={i + "-" + ii}
                                                    className={`itemSubMenu ${menuName === r.menu ? "active" : ""
                                                        }`}
                                                    onClick={(e) => navigateTo(r.path)}
                                                >
                                                    <div className="c">
                                                        {getKeyword(r.name)}&nbsp;
                                                        <i
                                                            className="fa fa-long-arrow-right"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                </li>
                                            ))}
                                    </ul>
                                </div> : null}
                        </div>
                    ))}
                    <div className="itemHeader">
                        <i onClick={() =>
                            userIsInternal(profile) ?
                                navigateTo("/admin/notification") : navigateTo("/external/notification")
                        } className="far fa-bell"></i> ({notification})
                    </div>
                    {/* <div className="itemHeader">
                        <Dropdown overlay={getLanguages()}>
                            <div
                                onClick={() => {
                                    changeLanguage("en");
                                }}
                                className="itemLng"
                            >
                                {lng}
                            </div>
                        </Dropdown>
                    </div> */}
                    {!currentUser ? (
                        <React.Fragment>
                            {/* <div className="itemHeader">
                                <button
                                    onClick={(e) => navigateTo(`${URL_BASE}/register`)}
                                    className="cbt l"
                                >
                                    Register
                                </button>
                            </div> */}
                            <div className="itemHeader">
                                <button
                                    onClick={(e) => navigateToExternalLogin()}
                                    className="cbt white l"
                                >
                                    Connexion
                                </button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div>
                            <Dropdown overlay={getActions()}>
                                <div className="itemHeader">
                                    <img src={`${ASSETS_BASE}/imgs/user.png`} alt="user" />
                                </div>
                            </Dropdown>
                        </div>
                    )}
                </div>
            </nav>
            {ROUTES && ROUTES.length > 0 ?
                <nav className="sideBar">
                    <div className="blockSideBar">
                        {ROUTES.filter(
                            (r) =>
                                hasAccessPermission(profile, r.pForcedTypes, r.pTypes, r.pRoles)
                        ).map((r, i) => (
                            <div
                                key={"menu" + i}
                                className={`itemSideBar ${menuName === r.menu ? "active" : ""}`}
                                onClick={(e) => navigateTo(r.path)}
                            >
                                <div className="c">
                                    <span className="icon">
                                        <i className={r.icon} aria-hidden="true"></i>
                                    </span>
                                    {getKeyword(r.name)}
                                </div>
                            </div>
                        ))}
                    </div>
                </nav> : null}
        </div>
    );
};

export default AdminHeader;
