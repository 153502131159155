import React, { useContext, useEffect, useState } from "react";

import SharedContext from "../../../contexts/shared/sharedContext";
import GlobalTable from "../../../core/common/table/GlobalTable";
import GlobalFilters from "../../../core/common/table/GlobalFilters";
import { USEFULL_VARIABLES } from "../../../variables/shared";
import { updateValueManager } from "../../../core/manager/ManagerUtils";

import { useParams } from "react-router";
import { getValueManager } from "../../../core/manager/ManagerUtils";
import TableReponsesLineForm from "./form/table-reponses-line-form";
import { replaceIndex } from "../../../core/manager/ManagerUtils";
import { LoggerSuccess } from "../../../core/utils/Logger";
import ItemMenu from "../../../core/common/table/ItemMenu";
import ConfirmationPopupShared from "../../../core/common/notifications/ConfirmationPopup";
import TableResponsesImport from "./form/table-responses-import";
import { DownloadFile } from "../../../core/utils/download";
import {ASSETS_BASE} from "../../../config";

const TABLE = [
  {
    label: "Order",
    value: "ord",
  },
  {
    label: "COMMON.CODE",
    value: "value",
  },
  {
    label: "COMMON.DESIGNATION",
    value: "designation",
  },
  {
    label: "COMMON.CREATED_AT2",
    value: "createdAt",
    type: "DATE",
  },
  {
    label: "STD.ACTIONS",
    value: "actions",
    type: "RENDER",
    class: "table-actions",
    render: (props) => (
      <>
        <ItemMenu menus={[
          {
            name: "aller en haut",
            click: () => props.goTop(props.id)
          },
          {
            name: "aller en bas",
            click: () => props.goBottom(props.id)
          },
          {
            name: props.getKeyword("STD_VERB.EDIT"),
            click: () => props.update(props.id)
          },
          {
            name: props.getKeyword("STD_VERB.DELETE"),
            click: () => props.delete(props.id)
          },
        ]}>

        </ItemMenu>
      </>
    ),
  },
];

const FILTER_INIT = {
  from: null,
  to: null,
  resource: null,
};

const TableReponsesLine = (props) => {
  const { GetResource, getKeyword } = useContext(SharedContext);
  const [table, setTable] = useState(null);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const { id } = useParams();
  const [pagination, setPagination] = useState({
    page: 1,
    size: USEFULL_VARIABLES.paginationSize,
    order: null,
    direction: "DESC",
  });
  const [formFilter, setFormFilter] = useState(
    JSON.parse(JSON.stringify(FILTER_INIT))
  );
  const [modalState, setModalState] = useState({});

  const [alert, setalert] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const [form, setForm] = useState({
    table: { id },
    code: "",
    designation: "",
    description: "",
    value: "",
  });

  const [deleteMessage, setDeleteMessage] = useState(null);

  const successAlert = (message) => {
    LoggerSuccess(message);
  };

  const toggleModal = (state) => {
    setModalState({ ...modalState, [state]: !modalState[state] });
  };

  useEffect(() => {
    paginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
    GetResource("TABLE_RESPONSES", "GET_TABLE_BY_ID", { id }, null).then(
      (res) => {
        setTable(res.payload);
      }
    );
  }, []);

  const reload = () => {
    paginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const initForm = () => {
    setForm({
      table: { id },
      code: "",
      designation: "",
      description: "",
    });
  };

  const beforeAdd = () => {
    initForm();
    toggleModal("formModal");
  };

  const onAdd = () => {
    reload();
    successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    initForm();
  };

  const onUpdate = () => {
    reload();
    successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    initForm();
  };

  const paginate = (page, size, order, direction) => {
    setLoaded(false);
    GetResource(
      "TABLE_RESPONSES",
      "GET_ALL_LINE",
      {
        id,
      },
      null
    ).then((res) => {
      setData(res.payload);
      setPagination({ ...pagination, page, size, order, direction });
      setLoaded(true);
    });
  };

  const onChangeFilters = (v, n, indexes = null) => {
    const f = updateValueManager(formFilter, n, v, indexes);
    setFormFilter({ ...f });
  };

  const filter = () => {
    console.log("filter clicked");
  };

  const initializeFilter = () => {
    setFormFilter(JSON.parse(JSON.stringify(FILTER_INIT)));
  };

  const updateItem = (idLine) => {
    let item = data.find((f) => f.id == idLine);
    if (item) {
      setForm({ ...item, table: { id } });
      toggleModal("formModal");
    }
  };

  const enableItem = (idLine) => {
    GetResource(
      "TABLE_RESPONSES",
      "ENABLE_LINE",
      { id: idLine, enabled: true },
      null
    ).then((res) => {
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };
  const disableItem = (idLine) => {
    GetResource(
      "TABLE_RESPONSES",
      "ENABLE_LINE",
      { id: idLine, enabled: false },
      null
    ).then((res) => {
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };


  const deleteItem = (itemId) => {
    setDeleteMessage({
      message: getKeyword("NOTIFICATION.DELETE_TEXT"),
      id: itemId,
    });
  };

  const confirmDelete = () => {
    GetResource(
      "TABLE_RESPONSES",
      "DELETE_LINE",
      { id: deleteMessage.id },
      null
    ).then((res) => {
      setDeleteMessage(null);
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  }

  const goTop = (idLine) => {
    GetResource(
      "TABLE_RESPONSES",
      "ORDER",
      { id: idLine, up: true },
      null
    ).then((res) => {
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };

  const goBottom = (idLine) => {
    GetResource(
      "TABLE_RESPONSES",
      "ORDER",
      { id: idLine, up: false },
      null
    ).then((res) => {
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };

  const exportExcel = () => {
    GetResource(
      "TABLE_RESPONSES",
      "EXPORT",
      {id: id},
      null
    ).then((res) => {
      DownloadFile(res.payload.file, "Réponses");
    });
  }

  return (
    <>
      {deleteMessage ? (
        <ConfirmationPopupShared
          message={deleteMessage.message}
          close={() => setDeleteMessage(null)}
          confirm={confirmDelete}
        ></ConfirmationPopupShared>
      ) : null}
      <div className="mt--6"  >
        <div className="body-content mgb-30">
          <div className="col">
            <div className="card-body">
              <div xs="12">
                <p className="d-flex text_dark"> <span className="font-weight-bold w-20 "> {getKeyword("COMMON.DESIGNATION")} :</span>{getValueManager(table, "designation")}</p>
                <p className="d-flex text_dark"> <span className="font-weight-bold w-20 ">{getKeyword("COMMON.DESCRIPTION")} : </span> {getValueManager(table, "description")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="card-block">
          <div className="card-header">
            <div>
              <h3 className="mb-0">Table des réponses</h3>
            </div>
            <div>
              <a
                  style={{lineHeight: 'inherit'}}
                  className="btn-4 mgr-15"
                  id="tooltip969372949"
                  href={`${ASSETS_BASE}/templates/reponses.xlsx`}

              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">template</span>
              </a>
              <button
                className="btn-4 mgr-15"
                id="tooltip969372949"
                onClick={(e) => { exportExcel() }}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">{getKeyword("STD_VERB.EXPORT")}</span>
              </button>
              <button
                className="btn-2 mgr-15"
                id="tooltip969372949"
                onClick={(e) => { toggleModal("formModalImport"); }}
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">{getKeyword("STD_VERB.IMPORT")}</span>
              </button>
              <button
                className="btn-1"
                id="tooltip969372949"
                onClick={(e) => toggleModal("formModal")}
                size="lg"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">{getKeyword("STD.NEW")}</span>
              </button>
              {modalState["formModal"] ? (
                <TableReponsesLineForm
                  modalName="formModal"
                  toggleModal={toggleModal}
                  showModal={modalState["formModal"]}
                  onAdd={onAdd}
                  form={form}
                  updateForm={(form) => setForm(form)}
                  onUpdate={onUpdate}
                ></TableReponsesLineForm>
              ) : null}
              {modalState["formModalImport"] ? (
                <TableResponsesImport
                  modalName="formModalImport"
                  toggleModal={toggleModal}
                  showModal={modalState["formModalImport"]}
                  onImport={onUpdate}
                  id={id}
                ></TableResponsesImport>
              ) : null}
            </div>
          </div>
          {isLoaded ? (
            <GlobalTable
              table={TABLE}
              data={data}
              count={data.length}
              pagination={pagination}
              paginate={paginate}
              update={updateItem}
              enable={enableItem}
              disable={disableItem}
              delete={deleteItem}
              goTop={goTop}
              goBottom={goBottom}
            ></GlobalTable>
          ) : null}
        </div>
      </div >
    </>
  );
};

export default TableReponsesLine;
