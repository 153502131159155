import AreaInput from "../../../core/common/inputs/AreaInput";
import TextInput from "../../../core/common/inputs/TextInput";
import { getJsonArray, getValueManager, parseJsonArray } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, useSearchParams } from "react-router";
import StartupContact from "../startup-contact";
import { updateObjectFromImage, replaceIndex } from "../../../core/manager/ManagerUtils";
import FileInput from "../../../core/common/inputs/FileInput";
import queryString from "query-string";
import NavShared from "../../../core/common/components/NavShared";

import { dateDateToString, dateStrToTimeString, getRandom } from "../../../core/utils/helpers";
import { userIsInternal } from "../../../core/utils/security";
import { userIsOfTypes } from "../../../core/utils/security";
import SharedContext from "../../../contexts/shared/sharedContext";
import Logger, { LoggerSuccess } from "../../../core/utils/Logger";
import LINKS from "../../../config";
import StartupListInput from "./startup-list-input";
import DateInput from "../../../core/common/inputs/DateInput";

const StartupForm = (props) => {
  const { GetResource, ImageSaver, getKeyword, getCurrentProfil, profile } = useContext(SharedContext);
  const history = useHistory();
  const { id } = useParams();
  const [alert, setalert] = React.useState(false);
  const [searchParams] = useState(
    queryString.parse(props.location.search, { ignoreQueryPrefix: true })
  );
  const isExternal = searchParams.external;
  const [notFoundYet, setNotFoundYet] = useState(true);
  const [operation, setOperation] = useState("");
  const [idAdd, setIdAdd] = useState(null);
  const [tab, setTab] = useState(1);
  const [form, setForm] = useState({
    data: {
      name: "",
      code: getRandom(6),
      comment: "",
    },
    form: {
      lastName: "",
      firstName: "",
      emailAddress: "",
      phoneNumber: "",
      contactType: "",
    },
  });

  const [modalState, setModalState] = useState({});

  const toggleModal = (state) => {
    setModalState({ ...modalState, [state]: !modalState[state] });
  };


  const successAlert = (message) => {
    LoggerSuccess(message);
  };

  const [showForm, setFormShow] = useState({});

  const [images, setImages] = useState({});
  const [isLoading, setIsLoading] = useState({
    loading: false,
    size: 0,
    count: 0,
  });

  useEffect(() => {
    if (!profile) {
      getCurrentProfil();
    }
    if (id) {
      getStartup(id);
    } else if (isExternal) {
      getStartupExternal();
    }
  }, []);

  const getStartup = (idParam) => {
    GetResource(
      "STARTUP_MANAGEMENT",
      "GET_ID_FORM",
      { id: idParam },
      null
    ).then((res) => {
      setForm(res.payload);
      setFormShow(JSON.parse(JSON.stringify(res.payload)));
    });
  };

  const getStartupExternal = () => {
    GetResource(
      "STARTUP_MANAGEMENT",
      "GET_ID_FORM_EXTERNAL",
      {},
      null
    ).then((res) => {
      setForm(res.payload);
      setFormShow(JSON.parse(JSON.stringify(res.payload)));
      setNotFoundYet(false);
      console.log("REs", res.payload);
      setIdAdd(res.payload.data.id);
    });
  };

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    setForm({ ...f });
    console.log("form",form)
  };

  const onChangeImages = (
    files,
    name,
    multiple,
    indexes,
    usedFor = "STARTUP_DOCUMENT"
  ) => {
    images[name] = { files, name, multiple, indexes, usedFor };
    setImages({ ...images });
  };

  const addNewTable = (event) => {
    uploadImages(() => {
      GetResource("STARTUP_MANAGEMENT", "ADD", form, null).then((res) => {
        setIdAdd(res.payload.id);
        getStartup(res.payload.id);
        setFormShow(JSON.parse(JSON.stringify(form)));
        setOperation("SHOW");
        setNotFoundYet(false);
        successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
      }).catch((err) => {
        if (err && err.status === 409) {
          notify('danger', replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")]));
        }
      });;
    });
  };

  const updateTable = (event) => {
    uploadImages(() => {
      GetResource("STARTUP_MANAGEMENT", "UPDATE", form, null).then((res) => {
        setFormShow(JSON.parse(JSON.stringify(form)));
        setOperation("SHOW");
        setNotFoundYet(false);
        successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
      }).catch((err) => {
        if (err && err.status === 409) {
          notify('danger', replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")]));
        }
      });;
    });
  };

  const uploadImages = (callback) => {
    ImageSaver(
      (params, images) => {
        updateObjectFromImage(form, images);
        callback();
      },
      (params, status) => setIsLoading(status),
      {},
      images,
      form
    );
  };

  const formValid =
      getValueManager(form, "data.name", null) &&
      getValueManager(form, "form.activity", null) &&
     ( getValueManager(form, "form.contactEmailAddress", null) &&  getValueManager(form, "form.contactEmailAddress", null) !== '[]') &&
    getValueManager(form, "data.code", null);

  const finalId = id || idAdd;

  const makeMain = () => {
    getStartup(id || idAdd);
  };

  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    Logger(message);
  };
  const setTabProcess = (tabI) => {
    if (finalId) {
      setTab(tabI);
    } else {
      if (tabI == 2) {
        notify(
          "danger",
          <>
            <strong>Note!</strong> Merci d'ajouter et de sauvegarder les
            informations startup avant de pouvoir ajouter des contacts
          </>
        );
      }
    }
  };

  const enableItem = (id) => {
    GetResource(
      "STARTUP_MANAGEMENT",
      "ACTIVATION",
      { id, enabled: true },
      null
    ).then((res) => {
      getStartup(id);
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));

    });
  };

  const disableItem = (id) => {
    GetResource(
      "STARTUP_MANAGEMENT",
      "DESACTIVATION",
      { id, enabled: false },
      null
    ).then((res) => {
      getStartup(id);
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };

  return (
    <>
      {/* <NavShared
        tab={tab}
        setTab={(tab) => setTabProcess(tab)}
        items={[
          { name: "Startup" },
          { name: "Contacts secondaires" },
        ]}
      ></NavShared> */}
      {tab !== 1 ? null : (
        <div className="row"><div className="col-12">
          <div className="body-content">
            {(searchParams.show && !operation) ||
              (operation && operation === "SHOW") || !userIsInternal(profile) ? (
              <>
                {userIsInternal(profile) ?
                  <div className="text-center">
                    <div className="mt--6"  >

                      <div className="">
                        <div className="d-flex justify-content-center">
                          {getValueManager(showForm, "data.enabled", null) ?
                            <div className="ml-5 ml-3">
                              <button
                                className="btn-3"
                                color="danger"
                                type="button"
                                id="tooltip969372949"
                                onClick={(e) => disableItem(getValueManager(showForm, "data.id", null))}
                              >
                                <i className="fa fa-ban mr-2" aria-hidden="true"></i>  Désactiver
                              </button>
                            </div>
                            :
                            <div className="ml-5 ml-3">
                              <button
                                className="btn-1"
                                color="green"
                                type="button"
                                id="tooltip969372949"
                                onClick={(e) => enableItem(getValueManager(showForm, "data.id", null))}
                              >
                                <i className="fa fa-check mr-2" aria-hidden="true"></i>
                                Réactiver
                              </button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>

                  </div> : null}
                <br />
                <br />
                <br />
                <div className="mt--6"  >
                  <div className="row">
                    <div className="col">
                      <div className="bg-secondary shadow border-0">
                        <div className="px-lg-5 py-lg-5">
                          <div className="row">
                            {getValueManager(showForm, "form.logo", null) ? (
                              <>
                                <div className="col-12 card-profile-image">
                                  <img
                                    src={
                                      LINKS.imageURLPrivate +
                                      getValueManager(showForm, "form.logo", null)
                                    }
                                  ></img>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                              </>
                            ) : null}
                          </div>
                          <h2 className="text-dark font-25">Informations </h2><br />
                          <div className="row">
                            <div className="col-6">
                              <div className="d-flex border-right">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>Code :</strong>&nbsp;
                                </p>
                                <span className="w-50 text-dark">
                                  {getValueManager(showForm, "data.code", null)}
                                </span>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="d-flex">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>Nom :</strong>&nbsp;
                                </p>
                                <span className="w-50 text-dark">
                                  {getValueManager(showForm, "data.name", null)}
                                </span>
                              </div>

                            </div>
                          </div>


                        </div>



                      </div>

                      <div>
                        <div>
                          <div className="row">
                            <div className="col-6">
                              <div className="d-flex border-right">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>Statut :</strong>&nbsp;
                                </p>
                                <span className="w-50 text-dark">
                                  {getValueManager(showForm, "data.enabled", null)
                                    ? "Actif"
                                    : "Inactif"}
                                </span>
                              </div>
                            </div>
                            <div className="col-6">

                              <div className="d-flex">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>Activités  :</strong>&nbsp;
                                </p>
                                <span className="w-50 text-dark">
                                  {getJsonArray(getValueManager(
                                    showForm,
                                    "form.activity",
                                    null
                                  )).length > 0 ? getJsonArray(getValueManager(
                                    showForm,
                                    "form.activity",
                                    null
                                  )).reduce((a, b) => (a ? a + ", " : "") + b.value, "") : ""}
                                </span>
                              </div>

                            </div>
                            <div className="col-6">

                              <div className="d-flex">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong> Numéro de téléphone principal :</strong>&nbsp;
                                </p>
                                <a href="tel:0688122050" className="w-50 text-gradient-lite font-weight-bold">
                                  {getValueManager(
                                    showForm,
                                    "form.principalPhoneNumber",
                                    null
                                  )}
                                </a>
                              </div>


                            </div>
                            <div className="col-6">

                              <div className="d-flex border-right">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>Numéro de Téléphone secondaire:</strong>&nbsp;
                                </p>
                                <a  className="w-50 text-gradient-lite font-weight-bold">
                                  {getValueManager(
                                    showForm,
                                    "form.secondaryPhoneNumber",
                                    null
                                  )}
                                </a>
                              </div>
                            </div>
                            <div className="col-6">

                              <div className="d-flex">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>Adresse :</strong>&nbsp;
                                </p>
                                <span className="w-50 text-dark">
                                  {getValueManager(showForm, "form.address", null)}
                                </span>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex border-right">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>Site web :</strong>&nbsp;
                                </p>
                                <a href="mailto: abc@example.com" className="w-50 text-gradient-lite font-weight-bold">
                                  {getValueManager(showForm, "form.website", null)}
                                </a>
                              </div>

                            </div>
                            <div className="col-6">

                              <div className="d-flex">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>Email adresse de contact :</strong>&nbsp;
                                </p>
                                <a href="mailto: abc@example.com" className="w-50 text-gradient-lite font-weight-bold ">
                                  {getValueManager(
                                    showForm,
                                    "form.contactEmailAddress",
                                    null
                                  )}
                                </a>
                              </div>

                            </div>
                            <div className="col-6">

                              <div className="d-flex">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>date de création :</strong>&nbsp;
                                </p>
                                <span className="w-50 text-dark">
                                {getValueManager(showForm, "form.creationDate", null) ? dateDateToString(new Date(getValueManager(form, "form.creationDate", null))) : null}
                                </span>
                              </div>

                            </div>
                            <div className="col-12">

                              <div className="d-flex ">
                                <p className="w-25 font-weight-bold">
                                  <strong className="text-dark">Description :</strong>&nbsp;
                                </p>
                                <span className="w-50 text-dark">
                                  {getValueManager(
                                    showForm,
                                    "form.description",
                                    null
                                  )}
                                </span>
                              </div>




                            </div>
                            {userIsInternal(profile) ? <>
                              <div className="col-6">
                                <div className="d-flex border-right">
                                  <p className="w-50 font-weight-bold text-dark">
                                    <strong>{getKeyword("COMMON.CREATED_AT2")} :</strong>&nbsp;
                                  </p>
                                  <span className="w-50 text-dark">
                                    {getValueManager(form, "data.createdAt", null) ? dateStrToTimeString(getValueManager(form, "data.createdAt", null)) : null}
                                  </span>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="d-flex border-right">
                                  <p className="w-50 font-weight-bold text-dark">
                                    <strong>{getKeyword("COMMON.CREATED_BY")} :</strong>&nbsp;
                                  </p>
                                  <span className="w-50 text-dark">
                                    {getValueManager(form, "data.createdByUser.lastName", null) + " " + getValueManager(form, "data.createdByUser.firstName", null)}
                                  </span>
                                </div>
                              </div>
                            </> : null}
                          </div>
                        </div>
                      </div>



                      <div>
                        <div>
                          <h2 className=" text-dark font-25">Contact principal </h2> <br />
                          <div className="row">
                            <div className="col-6">

                              <div className="d-flex border-right">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>Nom :</strong>&nbsp;
                                </p>
                                <span className="w-50 text-dark">
                                  {getValueManager(
                                    showForm,
                                    "form.mainContactLastName",
                                    null
                                  )}
                                </span>
                              </div>


                            </div>
                            <div className="col-6">

                              <div className="d-flex">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>Prénom :</strong>&nbsp;
                                </p>
                                <span className="w-50 text-dark">
                                  {getValueManager(
                                    showForm,
                                    "form.mainContactFirstName",
                                    null
                                  )}
                                </span>
                              </div>

                            </div>
                            <div className="col-6">

                              <div className="d-flex border-right">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>Email :</strong>&nbsp;
                                </p>
                                <a href="tel:0688122050" className="w-50 text-gradient-lite font-weight-600">
                                  {getValueManager(
                                    showForm,
                                    "form.mainContactEmail",
                                    null
                                  )}
                                </a>
                              </div>

                            </div>
                            <div className="col-6">

                              <div className="d-flex">
                                <p className="w-50 font-weight-bold text-dark">
                                  <strong>Téléphone :</strong>&nbsp;
                                </p>
                                <a href="tel:0688122050" className="w-50 text-gradient-lite font-weight-bold">
                                  {getValueManager(
                                    showForm,
                                    "form.mainContactPhone",
                                    null
                                  )}
                                </a>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-center d-flex justify-content-around">
                        {userIsInternal(profile) ?
                          <button
                            className="my-4"
                            color="white"
                            type="button"
                            onClick={(e) => history.push("/admin/startup")}
                          >
                            Liste des startups
                          </button> : null}
                        {!userIsInternal(profile) ?
                          <button
                            className="my-4"
                            color="white"
                            type="button"
                            onClick={(e) => history.push("/external/startup")}
                          >
                            Liste des startups
                          </button> : null}
                        {userIsInternal(profile) ?
                          <button
                            className="btn-1"
                            color="danger"
                            type="button"
                            onClick={(e) => setOperation("update")}
                          >
                            Modifier
                          </button> : null}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="mt--6"  >
                <div className="row">
                  <div className="col">
                    <form role="form">
                      <div>
                        <div>
                          <h2 className="">Informations</h2>
                          <div className="row">
                            <div className="col-6">
                              <TextInput
                                disabled={true}
                                label="Code"
                                name="data.code"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(form, "data.code", null)}
                                required={true}
                              ></TextInput>
                            </div>
                            <div className="col-6">
                              <TextInput
                                label="Nom"
                                name="data.name"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(form, "data.name", null)}
                                required={true}
                              ></TextInput>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="row">
                            <div className="col-12">
                              <AreaInput
                                label="Description"
                                name="form.description"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(
                                  form,
                                  "form.description",
                                  null
                                )}
                              ></AreaInput>
                            </div>
                            <div className="col-6">
                              <TextInput
                                label="Numéro de téléphone principal"
                                name="form.principalPhoneNumber"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(
                                  form,
                                  "form.principalPhoneNumber",
                                  null
                                )}
                              ></TextInput>
                            </div>
                            <div className="col-6">
                              <TextInput
                                label="Numéro de Téléphone secondaire"
                                name="form.secondaryPhoneNumber"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(
                                  form,
                                  "form.secondaryPhoneNumber",
                                  null
                                )}
                              ></TextInput>
                            </div>
                            <div className="col-6">
                              <TextInput
                                label="Adresse"
                                name="form.address"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(
                                  form,
                                  "form.address",
                                  null
                                )}
                              ></TextInput>
                            </div>
                            <div className="col-6">
                              <TextInput
                                label="Site web"
                                name="form.website"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(
                                  form,
                                  "form.website",
                                  null
                                )}
                              ></TextInput>
                            </div>
                            <div className="col-6">
                              <TextInput
                                label="Email adresse de contact"
                                name="form.contactEmailAddress"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(
                                  form,
                                  "form.contactEmailAddress",
                                  null
                                )}
                                required={true}
                              ></TextInput>
                            </div>
                            <div className="col-6">
                              <DateInput
                                label="Date de création"
                                name="form.creationDate"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(
                                  form,
                                  "form.creationDate",
                                  null
                                )}
                              ></DateInput>
                            </div>
                            <div className="col-12">
                              <StartupListInput
                                label="Activité"
                                name="form.activity"
                                onChange={(v, n) => onChange(parseJsonArray(v), n)}
                                value={getJsonArray(getValueManager(
                                  form,
                                  "form.activity",
                                  null
                                ))}
                                required={true}
                              ></StartupListInput>
                            </div>
                            <div className="col-12">
                              <FileInput
                                label="Logo"
                                name="form.logo"
                                onChange={(files) =>
                                  onChangeImages(files, "form.logo", false)
                                }
                                onChangeOld={(v, n) => onChange(v, n)}
                                value={getValueManager(form, "form.logo", null)}
                                isImage={true}
                              ></FileInput>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h2>Contact principal</h2>
                          <div className="row">
                            <div className="col-6">
                              <TextInput
                                label="Nom"
                                name="form.mainContactFirstName"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(
                                  form,
                                  "form.mainContactFirstName",
                                  null
                                )}
                              ></TextInput>
                            </div>
                            <div className="col-6">
                              <TextInput
                                label="Prénom"
                                name="form.mainContactLastName"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(
                                  form,
                                  "form.mainContactLastName",
                                  null
                                )}
                              ></TextInput>
                            </div>
                            <div className="col-6">
                              <TextInput
                                label="Email"
                                name="form.mainContactEmail"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(
                                  form,
                                  "form.mainContactEmail",
                                  null
                                )}
                              ></TextInput>
                            </div>
                            <div className="col-6">
                              <TextInput
                                label="Téléphone"
                                name="form.mainContactPhone"
                                onChange={(v, n) => onChange(v, n)}
                                value={getValueManager(
                                  form,
                                  "form.mainContactPhone",
                                  null
                                )}
                              ></TextInput>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center d-flex justify-content-around">
                        <button
                          className="my-4"
                          color="white"
                          type="button"
                          onClick={(e) => {
                            finalId
                              ? setOperation("SHOW")
                              : history.push("/admin/startup");
                          }}
                        >
                          Annuler
                        </button>
                        <button
                          className="btn-1"
                          color="danger"
                          type="button"
                          disabled={!formValid}
                          onClick={(e) =>
                            finalId ? updateTable() : addNewTable()
                          }
                        >
                          {finalId ? "Modifier" : "Ajouter"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div></div></div>
      )
      }
      {
        tab !== 2 ? null : (
          <>
            <div className="mt--6"  >
              <div className="row">
                <div className="col-12">
                  <StartupContact
                    id={finalId}
                    makeMain={makeMain}
                  ></StartupContact>
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  );
};
export default StartupForm;
