import React, { useContext, useEffect, useState } from "react";
import QSQuestionCreatorCardBlockPreview from "./CardBlockPreview";
import QSQuestionCreatorCardPreview from "./CardPreview";
// import {Modal} from "antd";





const QSQuestionCreatorPreview = (props) => {
    // const [isModalOpen, setIsModalOpen] = useState(true);
    //
    // const showModal = () => {
    //     setIsModalOpen(true);
    // };
    //
    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };
    //
    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };
    const getRender = (myItems) => {
        let core = [];
        myItems.forEach((item, index) => {
            core.push(<div key={("myallblockpreview" + props.parentIndex) + "-" + index + "-" + props.version}>
                {item.type == "CARD" ? <div className={("myallblockpreview" + props.parentIndex)}>
                    <QSQuestionCreatorCardPreview showError={props.showError} preview={props.preview} question={item.question} item={item} index={[...props.indexes, index]}
                        onChange={props.onChange} onChangeImages={props.onChangeImages} form={props.form}></QSQuestionCreatorCardPreview></div> : null}
                {item.type == "BLOCK" ? <div className={("myallblockpreview" + props.parentIndex)}><QSQuestionCreatorPreview showError={props.showError}
                    root={false} items={item.items} item={item} setItems={props.setItems}
                    preview={props.preview}
                    parentIndex={(props.parentIndex ? props.parentIndex + "-" : "") + index} indexes={[...props.indexes, index]}
                    onChange={props.onChange} onChangeImages={props.onChangeImages} form={props.form} /></div> : null}

            </div>)
        })
        return core;
    }
    return (
        <div
            // id="myallblock"
            className={(props.root ? "qc-root " : "qc-block ")}
            id={"data-index-" + props.parentIndex}
        >
            {/*<Modal title="confirmation" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>*/}
            {/*    <p>Nous vous informons que votre questionnaire ne sera pas enregistré tant qu'il n'aura pas été sauvegardé.</p>*/}
            {/*</Modal>*/}
            {/* {getRender(props.items)} */}
            {props.root ? null :
             <>

                 {getRender(props.items)}
                 <QSQuestionCreatorCardBlockPreview item={props.item} index={[...props.indexes]} preview={props.preview}
                                                    onChange={props.onChange} onChangeImages={props.onChangeImages} form={props.form}></QSQuestionCreatorCardBlockPreview>
             </>
            }

            {props.root ? (<div className="qc-core-js">{getRender(props.items)}</div>) : <div className="qc-core qc-core-js">{getRender(props.items)}
            </div>}
        </div>
    )
}

export default QSQuestionCreatorPreview;
