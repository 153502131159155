import { dateToString } from "../../utils/helpers";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { getValueManager } from "../../manager/ManagerUtils";
import { DownloadFile } from "../../utils/download";

export const FileProvider = (props) => {
    const sharedContext = useContext(SharedContext);
    const { GetImageInfo, imageData, GetImageByte } = sharedContext;
    const file = props.file;

    useEffect(() => {
        if (!imageData[file]) {
            GetImageInfo(file).then(() => { });
        }
    }, [props.file]);

    const download = () => {
        GetImageByte(file).then((res) =>
            DownloadFile(res.payload.file, (imageData[file] &&
                imageData[file]
                    .text) || file))
    }

    return (
        <button onClick={download}>{(imageData[file] &&
            imageData[file]
                .text) ||
            file}</button>
    );
};

export default FileProvider;
