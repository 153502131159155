import { getValueManager } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "antd";
import SharedContext from "../../../contexts/shared/sharedContext";
import { getValueManagerArray } from "../../../core/manager/ManagerUtils";
import { replaceIndex } from "../../../core/manager/ManagerUtils";
import TecTableProvider from "../../../core/common/outputs/TecTableProvider";
import Logger from "../../../core/utils/Logger";


const CLCatalogShow = (props) => {
    const { GetResource, getKeyword } = useContext(SharedContext);
    const form = props.form;
    const id = getValueManager(form, "id", null);
    const [isLoading, setIsLoading] = useState(false);

    const notify = (type) => {
        Logger(replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")]));
    };

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const onChange = (v, n, indexes = null) => {
        const f = updateValueManager(form, n, v, indexes);
        props.updateForm({ ...f });
    };

    const formValid =
        getValueManager(form, "code", null) && getValueManager(form, "name", null) && getValueManager(form, "type", null);

    return (
        <div>
            <Modal
                className="modal-dialog-centered"
                size="lg"
                style={{ maxWidth: "1000px", width: "100%" }}
                open={props.showModal && !isLoading}
                width={"1000px"}
                onCancel={() => props.toggleModal(props.modalName)}
            >
                <div className="modal-body p-0">
                    <div className="bg-secondary  border-0 mb-0">
                        <div className="bg-menu">
                            <div className="text-center">
                                <p className="bold text-white mb-0">Questionnaire</p>
                            </div>

                        </div>
                        <div className="px-lg-5 py-lg-1">
                            <div className="text-center d-flex justify-content-between border-bottom mgb-30">
                                <button
                                    className="btn-1"
                                    color="white"
                                    type="button"
                                    onClick={(e) => { props.toggleModal(props.modalName); props.update(getValueManager(form, "id", null)); }}
                                >
                                    <i className="fa fa-pencil-square-o mr-2" ></i>  {getKeyword("STD_VERB.EDIT")}
                                </button>
                                <button
                                    className="btn-1"
                                    color="danger"
                                    type="button"
                                    onClick={(e) =>
                                        getValueManager(form, "enabled", null) ? (() => { props.toggleModal(props.modalName); props.disable(getValueManager(form, "id", null)) })() :
                                            (() => { props.toggleModal(props.modalName); props.disable(getValueManager(form, "id", null)) })()
                                    }
                                >
                                    <i className="fas fa-unlock mr-2"></i> {getValueManager(form, "enabled", null) ? getKeyword("STD_VERB.DISABLE") : getKeyword("STD_VERB.ENABLE")}
                                </button>
                            </div>
                            <div className="row">
                                <div className="col-md-6 border-right">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.ID")}:</span > <span className="text-dark">{getValueManager(form, "id", null)}</span></p>
                                </div>
                                <div className="col-md-6">

                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.DESIGNATION")}:</span > <span className="text-dark">{getValueManager(form, "name", null)}</span></p>

                                </div>
                                <div className="col-md-6 border-right">
                                    <p className="text-dark"> <span className="bold">{getKeyword("COMMON.STATUS")}:</span > <span className="text-dark">{getValueManager(form, "enabled", null) ? getKeyword("COMMON.ENABLED") : getKeyword("COMMON.DISABLED")}</span></p>

                                </div>
                            </div>
                            <div className="text-center d-flex justify-content-between">
                                <button
                                    className="my-4"
                                    color="white"
                                    type="button"
                                    onClick={(e) => props.toggleModal(props.modalName)}
                                >
                                    {getKeyword("STD_VERB.CLOSE")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    );
};
export default CLCatalogShow;
