import React from "react";
import './styles/services.css';
import {ASSETS_BASE} from "../../config";

const Services = (props) => {
    return <div className='serviceBlock'>
        <div className="container">
            <h3>Services</h3>
            <div className="row">
                <div className="col-md-4 my-2 mx-auto">
                    <img src={`${ASSETS_BASE}/home/images/3.png`}  alt="" srcSet="" className="centered-image" />
                </div>

                <div className="col-md-1"></div>
                <div className="col-md-7 my-2">
                    <ul className="">
                        <li>Mise en relation avec les filiales du Groupe</li>
                        <li>Simplification du processus d’achat pour signer des contrats avec les startups et les doter
                            de
                            références
                        </li>
                        <li>Organisation de journées portes ouvertes avec les participations d’Al Mada Groupe pour
                            signer des
                            contrats
                        </li>
                        <li>Organisation de sessions de Reverse Pitching</li>
                        <li>Organisation d’événements de Match Making</li>
                        <li>Implication de la direction du groupe pour soutenir les start-ups, leur donner des conseils
                            et les
                            aider.
                        </li>
                        <li>Information et assistance technique</li>
                        <li>Organisation d’ateliers et formations en management, finance, juridique, communication…</li>
                        <li>Mise en réseau au sein et en dehors du Groupe</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
        ;
};

export default Services;
