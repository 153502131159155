import React from "react";
import Affiliate from "../../modules/business/affiliate";
import AffiliateForm from "../../modules/business/forms/affiliate-form";
import CLCatalog from "../../modules/collection/catalog";
import Logs from "../../modules/logs/logs";
import NTNotification from "../../modules/notifications/Notifications";
import FeedbackFill from "../../modules/question/feedback-fill";
import QSFeedbackList from "../../modules/question/feedback-list";
import QSFeedbackListInternal from "../../modules/question/feedback-list-internal";
import QSQuestion from "../../modules/question/question";
import QSQuestionCreator from "../../modules/question/question-creator";
import QSQuestionMaster from "../../modules/question/question-master";
import RPReports from "../../modules/reports/Reports";
import StartupForm from "../../modules/startup/forms/startup-form";
import Startup from "../../modules/startup/startup";
import TableReferentiel from "../../modules/tables/table-referentiel/table-referentiel";
import TableReferentielLine from "../../modules/tables/table-referentiel/table-referentiel-line";
import TableReponses from "../../modules/tables/table-reponses/table-reponses";
import TableReponsesLine from "../../modules/tables/table-reponses/table-reponses-line";
import TableTechnique from "../../modules/tables/table-technique/table-technique";
import TableTechniqueLine from "../../modules/tables/table-technique/table-technique-line";
import ProfileUser from "../../modules/user/permission/profile";
import Role from "../../modules/user/permission/role";
import ProfileForm from "../../modules/user/profile/profile";
import UtilisateurForm from "../../modules/user/utilisateur/form/UtilisateurForm";
import Utilisateurs from "../../modules/user/utilisateur/Utilisateurs";
import UtilisateursInvite from "../../modules/user/utilisateur/UtilisateursInvite";

export const AdminRoutes = [
    {
        "name": "Filiales ",
        "path": "/admin/affiliate",
        "component": Affiliate,
        "icon": "fa fa-building",
        "menu": "AFFILIATE",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PFLV"]
    },
    {
        "name": "Startups",
        "path": "/admin/startup",
        "component": Startup,
        "icon": "fas fa-industry",
        "menu": "STARTUP",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PSTV"]
    },
    {
        "name": "Liste d'utilisateurs",
        "path": "/admin/user-management",
        "component": Utilisateurs,
        "icon": "fa fa-user",
        "menu": "USER",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PATV"]
    },
    {
        "name": "Inscription",
        "path": "/admin/invite-management",
        "component": UtilisateursInvite,
        "icon": "fas fa-envelope",
        "menu": "INVITATION",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PATV"]
    },
    {
        "name": "Questionnaires",
        "path": "/admin/catalogue",
        "component": CLCatalog,
        "icon": "fa fa-question",
        "menu": "CATALOGUE",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PCTV"]
    },
    {
        "name": "Mes enquêtes",
        "path": "/admin/feedback",
        "component": QSFeedbackList,
        "icon": "fas fa-pencil-alt",
        "menu": "FEEDBACK",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PFBU", "PFBV"]
    },
    {
        "name": "Enquêtes reçues",
        "path": "/admin/feedback-internal",
        "component": QSFeedbackListInternal,
        "icon": "fas fa-folder-open",
        "menu": "FEEDBACK_ADMIN",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PFBG"]
    },
    {
        "name": "Rapport",
        "path": "/admin/report",
        "component": RPReports,
        "icon": "fas fa-file-alt",
        "menu": "REPORT",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PRPV"]
    }
]

export const AdminHeaderRoutes = [
    // {
    //     "name": "Logs",
    //     "path": "/admin/logs",
    //     "component": Logs,
    //     children: [],
    //     "menu": "LOG",
    //     "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
    //     "pTypes": ["USER_TYPE_INTERNAL"],
    //     "pRoles": ["PLGV"]
    // },
    {
        "name": "Permissions",
        "menus": ["ROLE_PERMISSION", "PROFILE_PERMISSION"],
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PATV"],
        children: [
            {
                "name": "Rôle",
                "path": "/admin/role",
                "component": Role,
                "menu": "ROLE_PERMISSION"
            },
            // {
            //     "name": "Profil",
            //     "path": "/admin/profil",
            //     "component": ProfileUser,
            //     "menu": "PROFILE_PERMISSION"
            // }
        ]
    },
    {
        "name": "Créateur des formulaires",
        "menus": ["TABLE_RESPONSE", "QUESTION", "FORM"],
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PQSV"],
        children: [
            {
                "name": "Table des réponses",
                "path": "/admin/table-reponses",
                "component": TableReponses,
                "menu": "TABLE_RESPONSE"
            },
            {
                path: "/admin/question",
                name: "Questions",
                component: QSQuestion,
                "menu": "QUESTION"

            },
            {
                "path": "/admin/master-question",
                "name": "Créateur des formulaires",
                "component": QSQuestionMaster,
                "menu": "FORM"
            },
        ]
    },
    // {
    //     "name": "Tables",
    //     "menus": ["TABLE_TECHNICAL"],
    //     "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
    //     "pTypes": ["USER_TYPE_INTERNAL"],
    //     "pRoles": ["PTBV"],
    //     children: [

    //                     // {
    //         //     "name": "Table référentiel",
    //         //     "path": "/admin/referiental-table",
    //         //     "component": TableReferentiel,
    //         // },
    //     ]
    // }
]

export const MoreAdminRoutes = [
    {
        path: "/admin/creator-question",
        name: "Créateur de question",
        component: QSQuestionCreator,
        "menu": "FORM",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PQSV"],
    },
    {
        path: "/admin/edit-question/:id",
        name: "Créateur de question",
        component: QSQuestionCreator,
        "menu": "FORM",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PQSV"],
    },
    {
        path: "/admin/table-reponses-detail/:id",
        name: "Table réponses",
        component: TableReponsesLine,
        "menu": "TABLE_RESPONSE",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PTBV"],
    },
    {
        path: "/admin/table-technique-detail/:id",
        name: "Table technique",
        component: TableTechniqueLine,
        "menu": "TABLE_TECHNICAL",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PQSV"],
    },
    {
        path: "/admin/user-edit/:id",
        name: "User edit",
        component: UtilisateurForm,
        "menu": "USER",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PATV"],
    },
    {
        path: "/admin/user-new",
        name: "Utilisateurs add",
        component: UtilisateurForm,
        "menu": "USER",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PATV"],
    },
    {
        path: "/admin/affiliate-new",
        name: "Filiale",
        component: AffiliateForm,
        "menu": "AFFILIATE",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PFLV"],
    },
    {
        path: "/admin/affiliate-edit/:id",
        name: "Filiale",
        component: AffiliateForm,
        "menu": "AFFILIATE",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PFLV"],
    },
    {
        path: "/admin/startup-new",
        name: "Filiale",
        component: StartupForm,
        "menu": "STARTUP",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PSTV"],
    },
    {
        path: "/admin/startup-edit/:id",
        name: "Filiale",
        component: StartupForm,
        "menu": "STARTUP",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PSTV"],
    },
    {
        path: "/admin/profile",
        name: "Profile",
        component: ProfileForm,
        "menu": "PROFILE"
    }, {
        path: "/admin/notification",
        name: "Notifications",
        component: NTNotification,
        "menu": "NOTIFICATION"
    }, {
        path: "/admin/feedback/:id",
        name: "Feedback",
        component: FeedbackFill,
        "menu": "FEEDBACK",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PFBU"],
    },
    {
        "name": "Tables techniques",
        "path": "/admin/table-technique",
        "component": TableTechnique,
        "menu": "TABLE_TECHNICAL",
        "pForcedTypes": ["USER_TYPE_SUPER_ADMIN"],
        "pTypes": ["USER_TYPE_INTERNAL"],
        "pRoles": ["PTBV"],
    }
]
