import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import CheckboxStyle from "../../../core/common/inputs/Checkbox";
import CheckboxInput from "../../../core/common/inputs/CheckboxInput";
import RegexInput from "../../../core/common/inputs/RegexInput";
import TextInput from "../../../core/common/inputs/TextInput";
import { getArray, getValueManager, updateValueManager } from "../../../core/manager/ManagerUtils";
import { userIsAdmin } from "../../../core/utils/security";

export const QSQuestionCreatorCardSettingLines = (props) => {
  const { getKeyword, profile } = useContext(SharedContext);

  const onChange = (v, n, indexes) => {
    const f = updateValueManager(props.value, n, v, indexes);
    props.onChange(f, props.name);
  }

  return (
    <div className="fair--create-area_inputs">
      <div className="fair--create-area_cards">
        <label className="fair--create-area_labels font-weight-bold">
          {props.label}
        </label>
        <div className="fair--create-area_content OnlyBig">
          {getArray(props.lines).map((et, index) => (
            <div className="row align-items-center bloc-" key={et.id + "key" + index}>
              <p className="col-4">{getValueManager(et, "name")}</p>
              <div className="col-4">
                <CheckboxStyle
                  label="le champ est-il obligatoire?"
                  name={et.id + "_required"}
                  onChange={(v, n) => onChange(v, n, [])}
                  value={getValueManager(props.value, et.id + "_required")}
                ></CheckboxStyle>
              </div>
              {userIsAdmin(profile) ?
              <div className="mgb-20 col-4">
                <RegexInput
                  name={et.id + ""}
                  placeHolder={props.label}
                  onChange={(v, n) => onChange(v, n, [])}
                  value={getValueManager(props.value, et.id + "")}
                  required={true}
                  transform={val => val.toLowerCase()}
                  regex={/[^0-9a-z_-]/gmi}
                  deescription={"les caractères autorisés sont: a-z, 0-9, _ et -"}
                  label={"Nom dans la collecte"}
                ></RegexInput>
              </div>:null}

            </div>
          ))}

        </div>
      </div>
    </div>
  );
};

export default QSQuestionCreatorCardSettingLines;
