import { getValueManager } from "../../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "antd";
import { getValueManagerArray } from "../../../../core/manager/ManagerUtils";
import TecTableProvider from "../../../../core/common/outputs/TecTableProvider";
import SharedContext from "../../../../contexts/shared/sharedContext";

const MY_SELECT = [
  {
    group: "Gestion filiale",
    items: [
      {
        permission: "FGFAGS",
      },
      {
        permission: "FGFACR",
      },
      {
        permission: "FGFAMD",
      },
      {
        permission: "FGFACC",
      },
      {
        permission: "FGFADR",
      },
    ],
  },
];

const RoleShow = (props) => {
  const { GetResource, getKeyword } = useContext(SharedContext);
  const form = props.form;
  const id = getValueManager(form, "id", null);
  const [permissions, setPermissions] = useState(MY_SELECT);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    GetResource("PERMISSION", "GET", form, null)
      .then((res) => {
        setIsLoading(false);
        permissions.forEach(
          (g) =>
          (g.items = g.items.filter((item) => {
            let perm = res.payload.find(
              (p) => p.permission == item.permission
            );
            if (!perm) {
              return false;
            }
            item.id = perm.id;
            item.description = perm.description;
            return true;
          }))
        );
        setPermissions([...permissions]);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    props.updateForm({ ...f });
  };

  const formValid =
    getValueManager(form, "code", null) && getValueManager(form, "name", null) && getValueManager(form, "type", null);

  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        style={{ maxWidth: "1000px", width: "100%" }}
        open={props.showModal && !isLoading}
        width={"1000px"}
        onCancel={() => props.toggleModal(props.modalName)}
      >
        <div className="modal-body p-0">
          <div className="bg-secondary  border-0 mb-0">
            <div className="bg-menu text-white mb-3">
              <div className="text-center">
                <p className="font-weight-bold mb-0">{getKeyword("USER.ROLE")}</p>
              </div>

            </div>
            <div className="px-lg-5 py-lg-1">
              <div className="row">
                <div className="col-md-6">

                  <div className="d-flex border-right">
                    <p className="w-50 font-weight-bold text-dark">
                      <strong>{getKeyword("COMMON.ID")} :</strong>
                    </p>
                    <span className="w-50 text-dark "> {getValueManager(form, "id", null)}</span>
                  </div>

                </div>
                <div className="col-md-6">

                  <div className="d-flex border-right">
                    <p className="w-50 font-weight-bold text-dark">
                      <strong>{getKeyword("COMMON.DESIGNATION")} :</strong>
                    </p>
                    <span className="w-50 text-dark "> {getValueManager(form, "name", null)}</span>
                  </div>
                </div>
                <div className="col-md-6">

                  <div className="d-flex border-right">
                    <p className="w-50 font-weight-bold text-dark">
                      <strong>{getKeyword("USER.USER_ATTACK")} :</strong>
                    </p>
                    <span className="w-50 text-dark "> <TecTableProvider
                      code={getValueManager(
                        form,
                        "type",
                        null
                      )}
                    /></span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex border-right">
                    <p className="w-50 font-weight-bold text-dark">
                      <strong>{getKeyword("COMMON.STATUS")} : </strong>
                    </p>
                    <span className="w-50 text-dark ">{getValueManager(form, "enabled", null) ? getKeyword("COMMON.ENABLED") : getKeyword("COMMON.DISABLED")}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h3 className="text-dark font-weight-700">{getKeyword("USER.PERMISSIONS")}</h3>
                  <table className="table-table">
                    <thead>
                      <th>Code</th>
                      <th>Designation</th>
                    </thead>
                    <tbody>
                      {getValueManagerArray(form, "permissions", null).map(tr => (
                        <tr>
                          <td>{tr.permission}</td>
                          <td>{tr.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="text-center d-flex justify-content-between">
                <button
                  className="my-4"
                  color="white"
                  type="button"
                  onClick={(e) => props.toggleModal(props.modalName)}
                >
                  {getKeyword("STD_VERB.CLOSE")}
                </button>
                {/* <button
                  className="btn-2"
                  color="info"
                  type="button"
                  onClick={(e) =>
                    getValueManager(form, "enabled", null) ? (() => { props.toggleModal(props.modalName); props.disable(getValueManager(form, "id", null)) })() :
                      (() => { props.toggleModal(props.modalName); props.disable(getValueManager(form, "id", null)) })()
                  }
                >
                  {getValueManager(form, "enabled", null) ? getKeyword("STD_VERB.DISABLE") : getKeyword("STD_VERB.ENABLE")}
                </button> */}

                <button
                  className="btn-1"
                  color="danger"
                  type="button"
                  onClick={(e) => { props.toggleModal(props.modalName); props.update(getValueManager(form, "id", null)); }}
                >
                  {getKeyword("STD_VERB.EDIT")}
                </button>
              </div>
              <div className="text-center d-flex justify-content-between">

              </div>
            </div>
          </div>
        </div>
      </Modal >
    </div >
  );
};
export default RoleShow;
