import { AUTH_ERROR, CLEAR_ERRORS, LOAD_FAIL, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, USER_LOADED } from "./types";
import { CHANGE_LANGUAGES } from "../shared/types";

export default (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case CHANGE_LANGUAGES:
            return {
                ...state,
                keywords: payload
            }
        case LOGIN_SUCCESS:

            localStorage.setItem("token", payload.token);
            return {
                ...state,
                isAuth: true,
                currentUser: null,
                error: null
            };
        case USER_LOADED:
            return {
                ...state,
                isAuth: true,
                currentUser: payload,
                error: null
            };
        case LOGOUT:
        case LOAD_FAIL:
        case LOGIN_FAIL:
            return {
                ...state,
                isAuth: false,
                currentUser: null,
                error: null
            };
        case AUTH_ERROR:
            return {
                ...state,
                isAuth: false,
                currentUser: null,
                error: payload
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
}
