import React, { useContext, useEffect, useState } from "react";


const QSQuestionCreatorCardBlockPreview = (props) => {

    return (
        <div
            className="qc-card-drag-edit"
        >
            <div className="title">
                <div>{props.item.title}</div>
                <div></div>
            </div>
        </div>
    )
}

export default QSQuestionCreatorCardBlockPreview;