import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Page404 from "../modules/pages/404";
import { LAYOUTS } from "./layouts";
const Routes = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        {LAYOUTS.map((module) => {
          return module.private ? <PrivateRoute component={module.component} path={module.path} key={module.name}
            permission={{ forcedTypes: module.pForcedTypes, types: module.pTypes, roles: module.pRoles }} /> :
            <Route path={module.path} key={module.name} component={module.component} />
        }
        )}
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route path="/no-permission" component={Page404} />
        <Route path="**" component={Page404} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
