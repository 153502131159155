import AreaInput from "./AreaInput";
import DateInput from "./DateInput";
import NumberInput from "./NumberInput";
import TextInput from "./TextInput";
import { getArray, getJson, getJsonArray, getValueManagerArray, getValueManagerNumberFloatInput, isEmpty } from "../../manager/ManagerUtils";
import { getValueManager, getValueManagerNumber, getValueManagerNumberFloat } from "../../manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import FileInput from "./FileInput";
import SelectInputTableResponses from "./SelectInputResponses";
import SelectInputApi from "./SelectInputApi";
import CheckboxListInput from "./CheckboxListInput";
import CheckboxListResponseInput from "./CheckboxListResponseInput";
import { getErrorMessage } from "../../utils/helpers";
import { getFilterEval, validateForm } from "../../utils/form-validation";
import TooltipProvider from "../outputs/TooltipProvider";


const FullInpurProvider = (props) => {


    const addLine = () => {
        let arr = getValueManagerArray(props.form, props.name, null);
        arr.push({});
        props.onChange(arr, props.name);
    };

    const deleteFrom = (name, index) => {
        let arr = getValueManagerArray(props.form, name);
        if (!arr) {
            arr = [];
        }
        props.onChange(
            arr.filter((vv, i) => i != index),
            name
        );
    };

    const getErrorMessage = () => {
        if (!props.showError) {
            return null;
        }

        if ((getValueManager(props.item, "required") && isEmpty(getValueManager(props.form, getValueManager(props.item, "name"))))
            || (props.isTableRequired) && isEmpty(getValueManager(props.form, props.name, props.indexes))) {
            return "Field requis";
        }

        const validation = getValueManager(props.item, "hasValidation") && getJsonArray(getValueManager(props.item, "validation"));
        if (getArray(validation).length == 0) {
            return null;
        }
        // return validateForm(props.form, validation) ? null : getValueManager(props.item, "error") || "Invalide valeur";
        return null;
    }

    const getShowCondition = () => {
        if (props.update) {
            return true;
        }
        const validation = getValueManager(props.item, "conditionalVisibility") && getJsonArray(getValueManager(props.item, "visibility"));
        if (getArray(validation).length == 0) {
            return true;
        }
        console.log("VALIDATIONS", props, validation);
        return validateForm(props.form, validation);
    }

    const getFilter = (item) => {
        return getFilterEval(props.form, getValueManager(props.item, "filter"), item);
    }


    return (
        <>
            {!getShowCondition() ? null :
                <>
                    {props.type == "QUESTION_TYPE_STRING" ?
                        <TextInput
                            preview={props.preview}
                            label={props.label}
                            placeHolder={props.placeHolder}
                            name={props.name}
                            description={props.description}
                            onChange={(v, n) => props.onChange(v, n)}
                            onChangeImages={(v, n) => props.onChangeImages(v, n)}
                            value={getValueManager(props.form, props.name, props.indexes)}
                            required={props.required && !props.preview}
                            multiple={props.multiple}
                            other={props.other}
                            error={props.error || getErrorMessage()}
                        ></TextInput >
                        : null}
                    {props.type == "QUESTION_TYPE_TEXT" ?
                        <AreaInput
                            preview={props.preview}
                            label={props.label}
                            placeHolder={props.placeHolder}
                            name={props.name}
                            description={props.description}
                            onChange={(v, n) => props.onChange(v, n)}
                            onChangeImages={(v, n) => props.onChangeImages(v, n)}
                            value={getValueManager(props.form, props.name, props.indexes)}
                            required={props.required && !props.preview}
                            multiple={props.multiple}
                            other={props.other}
                            error={props.error || getErrorMessage()}
                        ></AreaInput >
                        : null}
                    {props.type == "QUESTION_TYPE_INT" ?
                        <NumberInput
                            preview={props.preview}
                            label={props.label}
                            placeHolder={props.placeHolder}
                            name={props.name}
                            description={props.description}
                            onChange={(v, n) => props.onChange(v, n)}
                            onChangeImages={(v, n) => props.onChangeImages(v, n)}
                            value={getValueManagerNumber(props.form, props.name, props.indexes)}
                            required={props.required && !props.preview}
                            multiple={props.multiple}
                            other={props.other}
                            isInt={true}
                            error={props.error || getErrorMessage()}
                        ></NumberInput >
                        : null}
                    {props.type == "QUESTION_TYPE_DECIMAL" ?
                        <NumberInput
                            preview={props.preview}
                            label={props.label}
                            placeHolder={props.placeHolder}
                            name={props.name}
                            description={props.description}
                            onChange={(v, n) => props.onChange(v, n)}
                            onChangeImages={(v, n) => props.onChangeImages(v, n)}
                            value={getValueManagerNumberFloatInput(props.form, props.name, props.indexes)}
                            required={props.required && !props.preview}
                            multiple={props.multiple}
                            other={props.other}
                            error={props.error || getErrorMessage()}
                        ></NumberInput >
                        : null}
                    {props.type == "QUESTION_TYPE_DATE" ?
                        <DateInput
                            preview={props.preview}
                            label={props.label}
                            placeHolder={props.placeHolder}
                            name={props.name}
                            description={props.description}
                            onChange={(v, n) => props.onChange(v, n)}
                            onChangeImages={(v, n) => props.onChangeImages(v, n)}
                            value={getValueManager(props.form, props.name, props.indexes)}
                            required={props.required && !props.preview}
                            multiple={props.multiple}
                            other={props.other}
                            error={props.error || getErrorMessage()}
                        ></DateInput >
                        : null}
                    {props.type == "QUESTION_TYPE_FILE" || props.type == "QUESTION_TYPE_IMAGE" ?
                        <FileInput
                            preview={props.preview}
                            label={props.label}
                            placeHolder={props.placeHolder}
                            name={props.name}
                            description={props.description}
                            onChangeOld={(v, n) => props.onChange(v, n)}
                            onChange={(v, n) => { props.onChangeImages(v, n, props.multiple); }}
                            value={getValueManager(props.form, props.name, props.indexes)}
                            required={props.required && !props.preview}
                            multiple={props.multiple}
                            other={props.other}
                            isImage={props.type == "QUESTION_TYPE_IMAGE"}
                            error={props.error || getErrorMessage()}
                        ></FileInput>
                        : null}
                    {props.type == "QUESTION_TYPE_RADIO" ?
                        <CheckboxListResponseInput
                            preview={props.preview}
                            label={props.label}
                            placeHolder={props.placeHolder}
                            name={props.name}
                            description={props.description}
                            onChange={(v, n) => props.onChange(v, n)}
                            onChangeImages={(v, n) => props.onChangeImages(v, n)}
                            value={getValueManager(props.form, props.name, props.indexes)}
                            required={props.required && !props.preview}
                            multiple={false}
                            other={props.other}
                            code={props.code}
                            otherName={props.name + "__other"}
                            otherValue={getValueManager(props.form, props.name + "__other", props.indexes)}
                            filter={getValueManager(props.item, "filter") ? (item) => getFilter(item) : null}
                            error={props.error || getErrorMessage()}
                        ></CheckboxListResponseInput >
                        : null}
                    {props.type == "QUESTION_TYPE_RADIO_MULTIPLE" ?
                        <CheckboxListResponseInput
                            preview={props.preview}
                            label={props.label}
                            placeHolder={props.placeHolder}
                            name={props.name}
                            description={props.description}
                            onChange={(v, n) => props.onChange(v, n)}
                            onChangeImages={(v, n) => props.onChangeImages(v, n)}
                            value={getValueManagerArray(props.form, props.name, props.indexes)}
                            required={props.required && !props.preview}
                            multiple={true}
                            other={props.other}
                            code={props.code}
                            otherName={props.name + "__other"}
                            otherValue={getValueManager(props.form, props.name + "__other", props.indexes)}
                            filter={getValueManager(props.item, "filter") ? (item) => getFilter(item) : null}
                            error={props.error || getErrorMessage()}
                        ></CheckboxListResponseInput >
                        : null}
                    {props.type == "QUESTION_TYPE_CHOICE" ?
                        <SelectInputTableResponses
                            preview={props.preview}
                            label={props.label}
                            placeHolder={props.placeHolder}
                            name={props.name}
                            description={props.description}
                            onChange={(v, n) => props.onChange(v, n)}
                            onChangeImages={(v, n) => props.onChangeImages(v, n)}
                            value={getValueManager(props.form, props.name, props.indexes)}
                            required={props.required && !props.preview}
                            multiple={false}
                            other={props.other}
                            code={props.code}
                            otherName={props.name + "__other"}
                            otherValue={getValueManager(props.form, props.name + "__other", props.indexes)}
                            filter={getValueManager(props.item, "filter") ? (item) => getFilter(item) : null}
                            error={props.error || getErrorMessage()}
                        ></SelectInputTableResponses >
                        : null}
                    {props.type == "QUESTION_TYPE_CHOICE_MULTIPLE" ?
                        <SelectInputTableResponses
                            preview={props.preview}
                            label={props.label}
                            placeHolder={props.placeHolder}
                            name={props.name}
                            description={props.description}
                            onChange={(v, n) => props.onChange(v, n)}
                            onChangeImages={(v, n) => props.onChangeImages(v, n)}
                            value={getValueManager(props.form, props.name, props.indexes)}
                            required={props.required && !props.preview}
                            multiple={true}
                            other={props.other}
                            code={props.code}
                            otherName={props.name + "__other"}
                            otherValue={getValueManager(props.form, props.name + "__other", props.indexes)}
                            filter={getValueManager(props.item, "filter") ? (item) => getFilter(item) : null}
                            error={props.error || getErrorMessage()}
                        ></SelectInputTableResponses >
                        : null}

                    {props.type == "QUESTION_TYPE_API" ?
                        <SelectInputApi
                            preview={props.preview}
                            label={props.label}
                            placeHolder={props.placeHolder}
                            name={props.name}
                            description={props.description}
                            onChange={(v, n) => props.onChange(v, n)}
                            onChangeImages={(v, n) => props.onChangeImages(v, n)}
                            value={getValueManager(props.form, props.name, props.indexes)}
                            required={props.required && !props.preview}
                            multiple={props.multiple}
                            other={props.other}
                            otherName={props.name + "__other"}
                            otherValue={getValueManager(props.form, props.name + "__other", props.indexes)}
                            api={props.api}
                            filter={getValueManager(props.item, "filter") ? (item) => getFilter(item) : null}
                            error={props.error || getErrorMessage()}
                        ></SelectInputApi >
                        : null}

                    {props.type == "QUESTION_TYPE_TABLE" ?
                        <div className="question-mttable-block">
                            <label className="Ilabel">{props.label} <span className="requiredLb">{props.required && !props.preview ? " *" : ""}</span></label>
                            <table className="question-mttable">
                                <thead><tr>{props.lines.map((m, index) => <th key={m.name + index}>  {m.name} <span className="requiredLb">{getJson(getValueManager(props.item, "secondNames"))[m.id + "_required"] && !props.preview ? " *" : ""}</span>  <TooltipProvider tooltip={m.description}></TooltipProvider>   </th>)}{props.preview ? null : <th className="actions">Action</th>}</tr></thead>
                                <tbody>{getValueManagerArray(props.form, props.name).map((et, index) => (
                                    <tr>{props.lines.map(m => <td>
                                        <FullInpurProvider
                                            // label={getValueManager(m, "name")}
                                            preview={props.preview}
                                            placeHolder={getValueManager(m, "name")}
                                            name={props.name + ".?0." + (getJson(getValueManager(props.item, "secondNames"))[m.id] || m.id)}
                                            type={getValueManager(m, "type")}
                                            code={getValueManager(m, "response.id")}
                                            value={getValueManager(props.form, props.name + ".?0." + m.id, [index])}
                                            onChange={(v, n) => props.onChange(v, n, [index])}
                                            onChangeImages={(v, n) => props.onChangeImages(v, n, [index])}
                                            lines={[]}
                                            form={props.form}
                                            required={getJson(getValueManager(props.item, "secondNames"))[m.id + "_required"]}
                                            isTableRequired={getJson(getValueManager(props.item, "secondNames"))[m.id + "_required"]}
                                            api={{ apiLink: m.apiLink, apiMethod: m.apiMethod, apiBody: m.apiBody, apiLabel: m.apiLabel, apiSelect: m.apiSelect }}
                                            indexes={[index]}
                                            showError={props.showError}
                                        >
                                        </FullInpurProvider>
                                    </td>)}
                                        {props.preview ? null : <td>            <i
                                            className="fas fa-times question-table-remove-line"
                                            onClick={() => deleteFrom(props.name, index)}
                                        ></i></td>}</tr>))}</tbody>
                            </table>
                            {props.preview ? null :
                                <div className="col-md-6 d-flex m-auto pt-3">
                                    <button className="table-add-line" type="button" onClick={addLine}>
                                        <i className="fas fa-plus mr-2"></i>  Ajouter une ligne
                                    </button>
                                </div>
                            }
                            {props.error || getErrorMessage() ? <p className="errorInput">
                                <i className="fas fa-exclamation-triangle"></i> Field requis
                            </p> : null}
                        </div>
                        : null}
                </>
            }</>
    )
}

export default FullInpurProvider;
