import React, { useState, useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Page404 from "../../modules/pages/404";
import { AdminHeaderRoutes, AdminRoutes } from "../../routes/routes/AdminRoutes";
import { AuthRoutes } from "../../routes/routes/AuthRoutes";
import AdminHeader from "../Header/AdminHeader";
import AuthHeader from "../Header/AuthHeader";

import './Layout.css';
import './LayoutAuth.css';
import {ASSETS_BASE} from "../../config";

const AuthLayout = (props) => {
    return <div className='layoutAuth'>

        <div className="container-fluid">
            <div className="row" style={{minHeight:'100vh'}}>
                <div className="col-md-4  box">
                    <div className="">
                        <a href="/home">  <img  className="img" src={`${ASSETS_BASE}/home/images/Image1.png`}   height="200px" width="400px" alt=""/> </a>

                        <div className=" boxBottomContent d-flex justify-content-center">
                            <div>
                                <img className="downImg" src={`${ASSETS_BASE}/home/images/petlogo.png`}   alt=""/>
                            </div>
                            <div className="">
                                <div className="f">
                                    <a className="ma" href="mailto:almada@maroc.ma">ALMADA@MAROC.ma</a>
                                    <br/>
                                    <a className="ma"  href='https://www.almada.ma'>https://www.almada.ma/</a>

                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-8">
                    <div className="flexCenter">
                        <Switch>
                            {AuthRoutes.map(route => <Route
                                exact
                                path={route.path}
                                component={route.component}
                            />)}
                            {AdminHeaderRoutes.map(routeR => routeR.children.map(route => <Route
                                exact
                                path={route.path}
                                component={route.component}
                            />))}
                            <Route path="**" component={Page404} />
                        </Switch>
                    </div>

                </div>
            </div>
        </div>


        {/*<AuthHeader routes={[]}></AuthHeader>*/}
        {/*<div className="layout-style">*/}
        {/*    <Switch>*/}
        {/*        {AuthRoutes.map(route => <Route*/}
        {/*            exact*/}
        {/*            path={route.path}*/}
        {/*            component={route.component}*/}
        {/*        />)}*/}
        {/*        {AdminHeaderRoutes.map(routeR => routeR.children.map(route => <Route*/}
        {/*            exact*/}
        {/*            path={route.path}*/}
        {/*            component={route.component}*/}
        {/*        />))}*/}
        {/*        <Route path="**" component={Page404} />*/}
        {/*    </Switch>*/}
        {/*</div>*/}
    </div>
        ;
};

export default AuthLayout;
