import React, { useContext, useEffect, useState } from "react";
import { Modal } from "antd";

const ConfirmationPopupShared = (props) => {
  return (
    <div>
      <Modal
        className="modal-dialog-centered modal-danger"
        size="lg"
        style={{ maxWidth: "1000px", width: "100%" }}
        open={props.message}
        width={"1000px"}
        onCancel={() => props.close()}
      >
        {props.message ? (
          <>
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-notification">
                {/* Votre attention est requise */}
              </h6>
              {/* <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => props.close()}
              >
                <span aria-hidden={true}>×</span>
              </button> */}
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i className="ni ni-bell-55 ni-3x" />
                <h4 className="heading mt-4">{props.message}</h4>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                className="text-white ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => props.close()}
              >
                NON
              </button>
              <button
                className="btn-1"
                color="default"
                type="button"
                onClick={() => props.confirm()}
              >
                OUI
              </button>
            </div>
          </>
        ) : null}
      </Modal>
    </div>
  );
};

export default ConfirmationPopupShared;
