import React, { useContext, useEffect, useState } from "react";

import SharedContext from "../../../contexts/shared/sharedContext";
import GlobalTable from "../../../core/common/table/GlobalTable";
import { USEFULL_VARIABLES } from "../../../variables/shared";
import { updateValueManager } from "../../../core/manager/ManagerUtils";

import { useParams } from "react-router";
import { getValueManager } from "../../../core/manager/ManagerUtils";
import TableTechniqueLineForm from "./form/table-technique-line-form";
import { replaceIndex } from "../../../core/manager/ManagerUtils";
import { LoggerSuccess } from "../../../core/utils/Logger";
import ItemMenu from "../../../core/common/table/ItemMenu";

const TABLE = [
  {
    label: "COMMON.CODE",
    value: "code",
    order: true,
  },
  {
    label: "COMMON.DESIGNATION",
    value: "designation",
    order: true,
  },
  {
    label: "COMMON.VALUE",
    value: "value",
    order: true,
  },
  {
    label: "COMMON.CREATED_AT2",
    value: "createdAt",
    type: "DATE",
    order: true,
  },
  {
    label: "STD.ACTIONS",
    value: "actions",
    type: "RENDER",
    class: "table-actions",
    render: (props) => (
      <>
        <ItemMenu menus={[
          {
            name: props.getKeyword("STD_VERB.EDIT"),
            click: () => props.update(props.id)
          },
          {
            name: props.getKeyword("STD_VERB.DISABLE"),
            click: () => props.disable(props.id),
            hide: !props.item.enabled
          },
          {
            name: props.getKeyword("STD_VERB.ENABLE"),
            click: () => props.enable(props.id),
            hide: props.item.enabled
          }
        ]}>

        </ItemMenu>
      </>
    ),
  },
];


const FILTER_INIT = {
  from: null,
  to: null,
  resource: null,
};

const TableTechniqueLine = (props) => {
  const { GetResource, getKeyword } = useContext(SharedContext);
  const [table, setTable] = useState(null);
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const { id } = useParams();
  const [pagination, setPagination] = useState({
    page: 1,
    size: USEFULL_VARIABLES.paginationSize,
    order: null,
    direction: "DESC",
  });
  const [formFilter, setFormFilter] = useState(
    JSON.parse(JSON.stringify(FILTER_INIT))
  );
  const [modalState, setModalState] = useState({});

  const [alert, setalert] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const [form, setForm] = useState({
    table: { id },
    code: "",
    designation: "",
    description: "",
    value: "",
  });

  const successAlert = (message) => {
    LoggerSuccess(message);
  };

  const toggleModal = (state) => {
    setModalState({ ...modalState, [state]: !modalState[state] });
  };

  useEffect(() => {
    paginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
    GetResource("TABLE_TECHNIQUE", "GET_TABLE_BY_ID", { id }, null).then(
      (res) => {
        setTable(res.payload);
      }
    );
  }, []);

  const reload = () => {
    paginate(
      pagination.page,
      pagination.size,
      pagination.order,
      pagination.direction
    );
  };

  const initForm = () => {
    setForm({
      table: { id },
      code: "",
      designation: "",
      description: "",
    });
  };

  const beforeAdd = () => {
    initForm();
    toggleModal("formModal");
  };

  const onAdd = () => {
    reload();
    successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    initForm();
  };

  const onUpdate = () => {
    reload();
    successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    initForm();
  };

  const paginate = (page, size, order, direction) => {
    setLoaded(false);
    GetResource(
      "TABLE_TECHNIQUE",
      "GET_ALL_LINE",
      {
        id,
      },
      null
    ).then((res) => {
      setData(res.payload);
      setPagination({ ...pagination, page, size, order, direction });
      setLoaded(true);
    });
  };

  const onChangeFilters = (v, n, indexes = null) => {
    const f = updateValueManager(formFilter, n, v, indexes);
    setFormFilter({ ...f });
  };

  const filter = () => {
    pagination.page = 1;
    console.log("filter clicked");
  };

  const initializeFilter = () => {
    setFormFilter(JSON.parse(JSON.stringify(FILTER_INIT)));
  };

  const updateItem = (idLine) => {
    let item = data.find((f) => f.id == idLine);
    if (item) {
      setForm({ ...item, table: { id } });
      toggleModal("formModal");
    }
  };

  const enableItem = (idLine) => {
    GetResource(
      "TABLE_TECHNIQUE",
      "ENABLE_LINE",
      { id: idLine, enabled: true },
      null
    ).then((res) => {
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };
  const disableItem = (idLine) => {
    GetResource(
      "TABLE_TECHNIQUE",
      "ENABLE_LINE",
      { id: idLine, enabled: false },
      null
    ).then((res) => {
      reload();
      successAlert(getKeyword("NOTIFICATION.WELL_DONE"));
    });
  };

  return (
    <>
      <div className="mt--6"  >
        <div className="body-content mgb-30">
          <div className="col">
            <div className="card-body">
              <div xs="12">
                <p className="d-flex text_dark"> <span className="font-weight-bold w-20 text_dark">{getKeyword("COMMON.CODE")}: </span>{getValueManager(table, "code")}</p>
                <p className="d-flex text_dark"> <span className="font-weight-bold w-20 text_dark"> {getKeyword("COMMON.DESIGNATION")}:</span>{getValueManager(table, "designation")}</p>
                <p className="d-flex text_dark"> <span className="font-weight-bold w-20 text_dark">{getKeyword("COMMON.DESCRIPTION")}: </span> {getValueManager(table, "description")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="card-block">
          <div className="card-header">
            <div>
              <h3 className="mb-0">{getKeyword("TABLE.TECHNICAL_TABLE")}</h3>
            </div>
            <div>
              {/* <button
                className="btn-1"
                id="tooltip969372949"
                onClick={(e) => toggleModal("formModal")}
                size="lg"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">{getKeyword("STD.NEW")}</span>
              </button> */}
              {modalState["formModal"] ? (
                <TableTechniqueLineForm
                  modalName="formModal"
                  toggleModal={toggleModal}
                  showModal={modalState["formModal"]}
                  onAdd={onAdd}
                  form={form}
                  updateForm={(form) => setForm(form)}
                  onUpdate={onUpdate}
                ></TableTechniqueLineForm>
              ) : null}
            </div>
          </div>
          {isLoaded ? (
            <GlobalTable
              table={TABLE}
              data={data}
              count={data.length}
              pagination={pagination}
              paginate={paginate}
              update={updateItem}
              enable={enableItem}
              disable={disableItem}
            ></GlobalTable>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default TableTechniqueLine;
