import React, { useContext } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { dateDateToString, dateToString, dateToStringJs } from "../../utils/helpers";
import Logger from "../../utils/Logger";

import "./Inputs.css";

export const DateBetweenInput = (props) => {
  const sharedContext = useContext(SharedContext);
  const { getKeyword } = sharedContext;

  const onChange = (event) => {
    if (event.target.value && props.valueTo && !validate(event.target.value, props.valueTo)) {
      return;
    }
    if (props.onChange) {
      props.onChange(event.target.value ? event.target.value + " 00:00:00" : null, props.nameFrom);
    }
  };

  const onChangeTo = (event) => {
    if (props.valueFrom && event.target.value && !validate(props.valueFrom, event.target.value)) {
      return;
    }
    if (props.onChange) {
      props.onChange(event.target.value ? event.target.value + " 00:00:00" : null, props.nameTo);
    }
  };

  const validate = (fromStr, toStr) => {
    const from = new Date(fromStr);
    const to = new Date(toStr);
    if (from && to && from.getTime() > to.getTime()) {
      Logger("La date de début doit être inférieure à la date de fin");
      return false;
    }
    return true;
  }

  return (
    <div className="TextInput DateBetween row">
      <div className="col-6">
        {!props.labelFrom ? null : (
          <label className="Ilabel">
            {getKeyword(props.labelFrom)}&nbsp;
            {props.optText ? <span>(Optionnel)</span> : ""}
            <span className="requiredLb">{props.requiredFrom ? " *" : ""}</span>
          </label>
        )}
        <div className="input">
          <input
            type="date"
            value={props.valueFrom ? dateToStringJs(props.valueFrom) : ""}
            onChange={onChange}
            placeholder={
              props.placeHolderFrom
                ? getKeyword(props.placeHolderFrom)
                : getKeyword(props.labelFrom)
            }
          ></input>
        </div>
      </div>
      <div className="col-6">
        {!props.labelTo ? null : (
          <label className="Ilabel">
            {getKeyword(props.labelTo)}&nbsp;
            {props.optText ? <span>(Optionnel)</span> : ""}
            <span className="requiredLb">{props.requiredTo ? " *" : ""}</span>
          </label>
        )}
        <div className="input">
          <input
            type="date"
            value={props.valueTo ? dateToStringJs(props.valueTo) : ""}
            onChange={onChangeTo}
            placeholder={
              props.placeHolderTo
                ? getKeyword(props.placeHolderTo)
                : getKeyword(props.labelTo)
            }
          ></input>
        </div>
      </div>
    </div>
  );
};

export default DateBetweenInput;
