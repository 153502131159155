import { getArrayFromVal, isArrayOfOrderBigger } from "../../../core/manager/ManagerUtils";

export const transformFormFromDbToJs = (backendItem) => {
    const cards = backendItem.cards;
    const blocks = backendItem.blocks;
    const newForm = [{
        title: "Page 1", items: [
        ]
    }];
    let level = 0;
    let foundOnLevel = true;
    let mapOfItem = {};
    // Reorder components
    while (foundOnLevel && level < 10) {
        const cardsOnLevel = cards.filter(f => f.index.length == level + 1);
        const blocksOnLevel = blocks.filter(f => f.index.length == level + 1);
        if (blocksOnLevel.length != 0 || cardsOnLevel.length != 0) {
            blocksOnLevel.forEach(block => {
                const newItem = { ...block, items: [] }
                mapOfItem[block.index.reduce((a, b) => a + "_" + b, "")] = newItem;
                // put the block in correct place
                if (level == 0) {
                    newForm[0].items.push(newItem);
                } else {
                    const mapOfItemV = mapOfItem[block.index.filter((f, i) => i != block.index.length - 1).reduce((a, b) => a + "_" + b, "")];
                    if (mapOfItemV && mapOfItemV.items) {
                        mapOfItemV.items.push(newItem);
                    }
                }
            })
            cardsOnLevel.forEach(card => {
                const newItem = { ...card }
                // put the block in correct place
                if (level == 0) {
                    newForm[0].items.push(newItem);
                } else {
                    const mapOfItemV = mapOfItem[card.index.filter((f, i) => i != card.index.length - 1).reduce((a, b) => a + "_" + b, "")];
                    if (mapOfItemV && mapOfItemV.items) {
                        mapOfItemV.items.push(newItem);
                    }
                }
            })
        } else {
            foundOnLevel = false;
        }
        level++;
    }
    Object.keys(mapOfItem).forEach(k => {
        if (mapOfItem[k].items) {
            mapOfItem[k].items = mapOfItem[k].items.sort((a, b) => isArrayOfOrderBigger(a.index, b.index) ? 1 : -1)
        }
    })
    newForm[0].items = newForm[0].items.sort((a, b) => { return isArrayOfOrderBigger(a.index, b.index) ? 1 : -1; });
    return newForm;
}