import { getValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { Modal } from "antd";
import QSQuestionCreatorPreview from "../preview/FormPreview";
import { transformFormFromDbToJs } from "../creator/helpers";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";



const QSFeedbackPreview = (props) => {
    const { GetResource, getKeyword } = useContext(SharedContext);
    const id = props.preview.id;
    const feedbackId = props.preview.feedbackId;
    const [formData, setFormData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);

    const [preview, setPreview] = useState(null);

    const [form, setForm] = useState({});
    const [formPreview, setFormPreview] = useState({});


    useEffect(() => {
        setIsLoading(true);

        GetResource(
            "CATALOG_MANAGEMENT",
            "GET_ID",
            { id: id },
            null
        ).then((res) => {
            setFormData(res.payload);
            const newForm = transformFormFromDbToJs(res.payload.form);
            setPreview(JSON.parse(JSON.stringify(newForm)));
            setIsLoading(false);
        }, err => {

        });

        GetResource(
            "FEEDBACK_MANAGEMENT",
            "GET_ID",
            { id: feedbackId },
            null
        ).then((res) => {
            setForm({ name: res.payload.name })
            setFormPreview({ ...res.payload.responses, ...res.payload.responsesTable, ...res.payload.responsesArray, ...res.payload.responsesTableArray })
        }, err => {

        });
    }, []);

    const handlePdf = () => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setTimeout(() => {
            }, 100);
            const divToPrint = document.querySelector('#print');
            html2canvas(divToPrint).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 195;
                const pageHeight = 290;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                const doc = new jsPDF('pt', 'mm');
                let position = 0;
                doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                doc.save('rapport.pdf');
            });
        }, 2000);
    };


    return (
        <div>
            <Modal
                className="modal-dialog-centered"
                size="lg"
                style={{ maxWidth: "1000px", width: "100%" }}
                open={props.showModal && !isLoading}
                width={"1000px"}
                onCancel={() => props.toggleModal(props.modalName)}
            >
                <div className="modal-body p-0">
                    <div className="bg-secondary  border-0 mb-0">
                        {/* <div className="bg-menu">
                            <div className="text-center">
                                <p className="font-weight-bold text-white mb-0"></p>
                            </div>
                        </div> */}
                        <div className="">
                            <div className="row">
                                <div className="col-md-12 qc-preview" id="print">
                                    <h1 className="text-center">{form && form.name}</h1>
                                    {props.isLoading || !preview || !preview[page] ? null :
                                        <QSQuestionCreatorPreview
                                            version={0}
                                            items={preview[page].items}
                                            root={true}
                                            indexes={[]}
                                            parentIndex=""
                                            onChange={(v, n) => { }}
                                            onChangeImages={(v, n) => { }}
                                            form={formPreview}
                                            preview={true}
                                        >
                                        </QSQuestionCreatorPreview>
                                    }
                                </div>
                            </div>
                            <div className="text-center d-flex justify-content-between">
                                <button
                                    className="btn-1"
                                    color="white"
                                    type="button"
                                    onClick={(e) => props.toggleModal(props.modalName)}
                                >
                                    {getKeyword("STD_VERB.CLOSE")}
                                </button>
                                <button className="btn-2" onClick={handlePdf}>
                                    PDF
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    );
};
export default QSFeedbackPreview;
