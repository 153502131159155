import TextInput from "../../../core/common/inputs/TextInput";
import { getJson, getJsonArray, getValueManager, getValueManagerArray, parseJsonArray, parseJsonObject } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import CheckboxInput from "../../../core/common/inputs/CheckboxInput";

import { Modal } from "antd";
import ConditionsInput from "../../../core/common/inputs/ConditionsInput";
import RegexInput from "../../../core/common/inputs/RegexInput";
import AreaInput from "../../../core/common/inputs/AreaInput";
import { userIsAdmin } from "../../../core/utils/security";
import QSQuestionCreatorCardSettingLines from "./CardSettingLines";

const QSQuestionCreatorCardSetting = (props) => {
  const { GetResource, getKeyword, profile } = useContext(SharedContext);
  const [form, setForm] = useState(JSON.parse(JSON.stringify(props.item.item)));
  const oldName = props.item.item.name;
  const id = getValueManager(form, "id", null);
  const [isLoading, setIsLoading] = useState(false);

  const notificationAlertRef = React.useRef(null);
  const notify = (type) => {
    // let options = {
    //   place: "tc",
    //   message: (
    //     <div className="alert-text">
    //       <span className="alert-title" data-notify="title">
    //         &nbsp;
    //         {replaceIndex(getKeyword("STD_ERROR.FIELD_EXISTS"), ["0"], [getKeyword("COMMON.CODE")])}
    //       </span>
    //     </div>
    //   ),
    //   type: type,
    //   icon: "ni ni-bell-55",
    //   autoDismiss: 7,
    // };
    // notificationAlertRef.current.notificationAlert(options);
  };

  useEffect(() => { }, []);

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    setForm({ ...f });
  };

  const updateTable = (event) => {
    props.updateSettings(form);
    props.toggleModal(props.modalName, null);
  };

  const formValid = getValueManager(form, "name", null) &&
    (getValueManager(form, "name", null) == oldName || !props.names.find(n => n == getValueManager(form, "name", null)))
    ;

  return (
    <div>
      {/* <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div> */}
      <Modal
        className="modal-dialog-centered"
        size="lg"
        // style={{ maxWidth: "1000px", width: "100%" }}
        open={props.showModal && !isLoading}
        width={"95%"}
        maxWidth={"95%"}
        onCancel={() => props.toggleModal(props.modalName)}
      >
        <div className="modal-body p-0">
          <div className="bg-secondary  border-0 mb-0">
            <div className="bg-menu">
              <div className="text-center">
                <p className="font-weight-bold text-white mb-0">Paramètres</p>
              </div>
            </div>
            <div className="">
              <form role="form">
                <div className="row">
                  <div className="col-md-12">
                    <RegexInput
                      label={"Nom dans la collecte"}
                      transform={val => val.toLowerCase()}
                      regex={/[^0-9a-z_-]/gmi}
                      deescription={"les caractères autorisés sont: a-z, 0-9, _ et -"}
                      name="name"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "name", null)}
                      required={true}
                      error={getValueManager(form, "name", null) == oldName || !props.names.find(n => n == getValueManager(form, "name", null)) ? null : "le nom de collecte existe déjà"}
                    ></RegexInput>
                  </div>
                  {getValueManager(props, "item.item.question.type") == "QUESTION_TYPE_TABLE" ?
                    <div className="col-12">
                      <QSQuestionCreatorCardSettingLines
                        label="Nom des lignes dans la collect"
                        name="secondNames"
                        onChange={(v, n) => onChange(parseJsonObject(v), n)}
                        value={getJson(getValueManager(
                          form,
                          "secondNames",
                          null
                        ))}
                        lines={getValueManagerArray(props, "item.item.question.lines")}
                      ></QSQuestionCreatorCardSettingLines>
                    </div>
                    : null}
                  <div className="col-md-12 mgb-20">
                    <CheckboxInput
                      label="le champ est-il obligatoire?"
                      name="required"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "required", null)}
                      required={true}
                    ></CheckboxInput>
                  </div>
                </div>
                {/* {userIsAdmin(profile) ?
                  <div className="col-md-12 mgb-20">
                    <CheckboxInput
                      label="Requis une validation ?"
                      name="hasValidation"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "hasValidation", null) || false}
                    ></CheckboxInput>
                  </div> : null} */}
                {/* {form.hasValidation && (
                  <div>
                    <div className="col-md-12 mgb-20">

                      <ConditionsInput
                        title="Critères de validation de la réponse:"
                        name="validation"
                        questions={props.names}
                        onChange={(v, n) => onChange(parseJsonArray(v), n)}
                        value={getJsonArray(getValueManager(form, "validation", null))}
                      />
                    </div>
                    <div className="col-md-12">
                      <TextInput
                        label={"Message d'erreur?"}
                        name="error"
                        onChange={(v, n) => onChange(v, n)}
                        value={getValueManager(form, "error", null)}
                        required={true}
                      ></TextInput>
                    </div>
                  </div>
                )} */}
                <div className="col-md-12 mgb-20">
                  <CheckboxInput
                    label="Visibilité conditionné "
                    name="conditionalVisibility"
                    onChange={(v, n) => onChange(v, n)}
                    value={getValueManager(form, "conditionalVisibility", null) || false}
                  ></CheckboxInput>
                </div>
                {form.conditionalVisibility && (
                  <div className="col-md-12 mgb-20">

                    <ConditionsInput
                      title="Conditions de visibilité de la question :"
                      questions={props.names}
                      name="visibility"
                      onChange={(v, n) => onChange(parseJsonArray(v), n)}
                      value={getJsonArray(getValueManager(form, "visibility", null))}
                    />
                  </div>
                )}

                {userIsAdmin(profile) ?
                  <div className="col-md-12">
                    <AreaInput
                      label={"Filters?"}
                      name="filter"
                      onChange={(v, n) => onChange(v, n)}
                      value={getValueManager(form, "filter", null)}
                      required={true}
                    ></AreaInput>
                  </div> : null}

                <div className="text-center d-flex justify-content-between">
                  <button
                    className="my-4"
                    type="button"
                    onClick={(e) => props.toggleModal(props.modalName)}
                  >
                    {getKeyword("STD_VERB.CANCEL")}
                  </button>
                  <button
                    className="btn-1"
                    type="button"
                    disabled={!formValid}
                    onClick={(e) => updateTable()}
                  >
                    {getKeyword("STD_VERB.EDIT")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default QSQuestionCreatorCardSetting;
