import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Logger from "../../core/utils/Logger";
import { URL_BASE } from "../../config";
import { HOMEPAGE } from "../../config";
import queryString from "query-string";
import SharedContext from "../../contexts/shared/sharedContext";
import { SETTING_SUCCESS } from "../../contexts/shared/types";
import { validatePassword } from "../../core/utils/helpers";
import AuthContext from "../../contexts/auth/authContext";
import PasswordInput from "../../core/common/inputs/PasswordInput";

const ExpiredPasswordConfirm = (props) => {
  const history = useHistory();
  const { GlobalPost, GetResource, settingData, getKeyword } = useContext(SharedContext);
  const authContext = useContext(AuthContext);
  const { isAuth, login } = authContext;
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = React.useState(false);
  const [searchParams] = useState(
    queryString.parse(props.location.search, { ignoreQueryPrefix: true })
  );
  const [errors, setErrors] = useState({
    oldPassword: null,
    confirmPassword: null,
    password: null,
    response: null,
    forceValidation: false,
  });
  const [formData, setFormData] = useState({
    oldPassword: "",
    confirmPassword: "",
    password: "",
  });
  useEffect(() => {
    if (isAuth) {
      history.push(URL_BASE + HOMEPAGE);
      Logger("LOGGEDIN_SUCCESS", true, "success");
    } else {
      GetResource(
        "TABLE_TECHNIQUE",
        "GET_TABLE_BY_CODE",
        { value: "PWD" },
        SETTING_SUCCESS
      ).then((res) => { });
    }


  }, [history, isAuth]);

  const { password, confirmPassword, oldPassword } = formData;

  const updateFormData = (value, name) => {
    const newForm = {
      ...formData,
      [name]: value,
    };
    setFormData(newForm);
    // if (errors.forceValidation) {
      validate(newForm, false);
    // }
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();

    if (!validate(formData, true)) {
      return;
    }

    setIsLoading(true);

    GlobalPost(
      { ...formData, username: searchParams.username },
      "/auth/expired/reset",
      null
    )
      .then(() => setIsLoading(false))
      .then(() => {
        setDone(true);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 403 && err.response.data.message == "USER_DISABLED") {
            setErrors({
              forceValidation: errors.forceValidation,
              response: getKeyword("LOGIN.DEASACTIVATED_USER"),
            });
          } else if ((err.response.status == 403 && err.response.data.message == "USER_BLOCKED") || (err.response.data && err.response.data.detail == "USER_BLOCKED")) {
            setErrors({
              forceValidation: errors.forceValidation,
              response: getKeyword("LOGIN.PASSWORD_USED"),
            });
          } else if ((err.response.status == 403 && err.response.data.message == "PASSWORD_ALREADY_USED") || (err.response.data && err.response.data.detail == "PASSWORD_ALREADY_USED")) {
            setErrors({
              forceValidation: errors.forceValidation,
              response: getKeyword("LOGIN.PASSWORD_USED"),
            });
          } else {
            setErrors({
              forceValidation: errors.forceValidation,
              response: getKeyword("STD_ERROR.PASSWORD_INCORRECT"),
            });
          }
        } else {
          setErrors({
            forceValidation: errors.forceValidation,
            response: getKeyword("STD_ERROR.PASSWORD_INCORRECT"),
          });
        }
        setIsLoading(false);
      });
  };

  const validate = (newForm, forceValidation) => {
    let errorsVar = null;
    if (newForm.confirmPassword === "" || newForm.password === "" || newForm.oldPassword === "") {
      errorsVar = {
        forceValidation: forceValidation ? true : errors.forceValidation,
        confirmPassword: newForm.confirmPassword
          ? null
          : "Confirmation de mot de passe requis",
        password: newForm.password ? null : "Mot de passe requis",
        oldPassword: newForm.oldPassword ? null : "Ancien mot de passe requis",
      };
    }

    if (
      settingData &&
      settingData["PWD"]
    ) {
      var resV = validatePassword(settingData["PWD"], newForm.password);
      if (resV && resV.error) {
        var html = <ul>
          {resV.items.map(item => (
            <li style={{ color: item.valid ? "green" : "red" }}>{item.msg}</li>
          ))}
        </ul>
        if (!errorsVar) {
          errorsVar = {};
        }
        errorsVar = {
          ...errorsVar,
          forceValidation: forceValidation ? true : errors.forceValidation,
          password:
            html,
        };
      }

    }

    if (newForm.confirmPassword != newForm.password) {
      if (!errorsVar) {
        errorsVar = {};
      }
      errorsVar = {
        ...errorsVar,
        forceValidation: forceValidation ? true : errors.forceValidation,
        confirmPassword: "Les deux mot de passe ne sont pas identiques",
      };
    }
    if (errorsVar) {
      setErrors(errorsVar);
      return false;
    }
    setErrors({
      forceValidation: forceValidation ? true : errors.forceValidation,
    });
    return true;
  };

  return (
    <>
      <div className="mt--8 pb-5 mb-3 form-login">
        {done ? (
          <div className="text-center">
            <div className="card p-3"><h2> <i className="fas fa-check mr-3"></i>Le mot de passe a été changé avec succès</h2></div>
            <a className=" my-4 rounded-pill font-20 btn btn-danger " href="/auth/login"> Retour à la connexion </a>
          </div>
        ) : (
          <div className="bg-image">
            <div className="">
              <div className="bg-secondary border-0 mb-0">
                <div className="px-lg-5 py-lg-5">
                  <div className=" text-muted mb-4">
                    <h2 className="font-17">{getKeyword("LOGIN.EXPIRED_MESSAGE")}</h2>
                  </div>
                  <form role="form" onSubmit={mySubmitHandler}>
                    <PasswordInput
                      label={getKeyword("PROFILE.OLD_PASSOWRD")}
                      name="oldPassword"
                      onChange={updateFormData}
                      value={oldPassword}
                      error={errors.oldPassword}
                    />
                    <PasswordInput
                      label="Nouveau mot de passe"
                      name="password"
                      onChange={updateFormData}
                      value={password}
                      error={errors.password}
                    />
                    <PasswordInput
                      label="Confirmer le nouveau mot de passe"
                      name="confirmPassword"
                      onChange={updateFormData}
                      value={confirmPassword}
                      error={errors.confirmPassword}
                    />
                    {!errors.response ? null : (
                      <p className="text-danger mb-0">{errors.response}</p>
                    )}
                    <div className="row justify-content-between align-items-center">
                      <div className="">
                        <a  href="/auth/login">
                          <small className=" text_dark border-bottom border-dark">
                            Retour à la connexion
                          </small>
                        </a>
                      </div>
                      <div className="">
                        <button
                          className="my-4 rounded-pill font-20"
                          color="danger"
                          type="submit"
                        >
                          Réinitialiser
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row" >
                <div className="text-right col-6"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>

  );
};

export default ExpiredPasswordConfirm;
