import FullInpurProvider from "../../../core/common/inputs/FullInputProvider";
import { getJsonArray, getValueManagerArray } from "../../../core/manager/ManagerUtils";
import { getValueManager, getValueManagerNumber, getValueManagerNumberFloat } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import { useDrag } from 'react-dnd'


const QSQuestionCreatorCardPreview = (props) => {
    return (
        <div
            className="qc-card-drag-preview"
        >
            <div className="elements">

                <FullInpurProvider label={props.question.name} form={props.form} name={props.item.name} onChange={props.onChange}
                    other={props.question.other} multiple={props.question.multiple} item={props.item} showError={props.showError}
                    api={{ apiLink: props.question.apiLink, apiMethod: props.question.apiMethod, apiBody: props.question.apiBody, apiLabel: props.question.apiLabel, apiSelect: props.question.apiSelect }}
                    onChangeImages={props.onChangeImages} type={props.question.type} required={props.item.required} description={props.question.description}
                    code={getValueManager(props.question, "response.id")} lines={getValueManagerArray(props.question, "lines")} preview={props.preview}
                    ></FullInpurProvider>
            </div>
        </div>
    )
}

export default QSQuestionCreatorCardPreview;