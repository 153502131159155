import { getValueManager, getValueManagerArray, updateObjectFromImage } from "../../../core/manager/ManagerUtils";
import { updateValueManager } from "../../../core/manager/ManagerUtils";
import React, { useContext, useEffect, useState } from "react";
import SharedContext from "../../../contexts/shared/sharedContext";
import { Modal } from "antd";
import FileInput from "../../../core/common/inputs/FileInput";
import Logger from "../../../core/utils/Logger";
import {toast} from "react-toastify";

const AffiliateImport = (props) => {
  const { GetResource, getKeyword, ImageSaver } = useContext(SharedContext);

  const [form, setForm] = useState({});
  const [images, setImages] = useState({});
  const [isLoading, setIsLoading] = useState({
    loading: false,
    size: 0,
    count: 0,
  });


  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    setForm({ ...f });
  };

  const onChangeImages = (
    files,
    name,
    multiple,
    indexes,
    usedFor = "TEMP"
  ) => {
    images[name] = { files, name, multiple, indexes, usedFor };
    setImages({ ...images });
  };

  const uploadImages = (callback) => {
    ImageSaver(
      (params, images) => {
        updateObjectFromImage(form, images);
        callback();
      },
      (params, status) => setIsLoading(status),
      {},
      images,
      form
    );
  };

  const updateTable = (event) => {
    uploadImages(() => {
      GetResource("AFFILIATE_MANAGEMENT", "IMPORT", form, null).then((res) => {
        if (res.payload && res.payload.hasError) {
          toast.warning(res.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false
          });
        }
        props.toggleModal(props.modalName);
        props.onImport();
      }, (err) => {
        console.log(err);
        toast.error(err.data.detail, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 10000
        });
        // Logger(err.data.detail, null, 'error');
      });
    });
  };

  const formValid = getValueManagerArray(images, "file.files").length > 0;

  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        style={{ maxWidth: "1000px", width: "100%" }}
        open={props.showModal}
        width={"1000px"}
        onCancel={() => props.toggleModal(props.modalName)}
      >
        <div className="modal-body p-0">
          <div className="bg-secondary  border-0 mb-0">
            <div className="bg-menu">
              <div className="text-center">
                <p className="font-weight-bold text-white mb-0">Filiale</p>
              </div>
            </div>
            <div className="">
              <div className="row">
                <div className="col-md-12">
                  <FileInput
                    label="Excel"
                    name="file"
                    onChange={(files) =>
                      onChangeImages(files, "file", false)
                    }
                    onChangeOld={(v, n) => onChange(v, n)}
                    value={getValueManager(form, "file", null)}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  ></FileInput>
                </div>
              </div>
              <div className="text-center d-flex justify-content-around">
                <button
                  className="my-4"
                  color="white"
                  type="button"
                  onClick={(e) => props.toggleModal(props.modalName)}
                >
                  Annuler
                </button>
                <button
                  className="btn-1"
                  color="danger"
                  type="button"
                  disabled={!formValid}
                  onClick={(e) => updateTable(e)}
                >
                  Importer
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default AffiliateImport;
