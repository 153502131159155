const ReferentielTable = {
  AFFILIATE_DOCUMENT_TYPE: "AFFILIATE_DOCUMENT_TYPE",
  AFFILIATE_CONTACT_TYPE: "AFFILIATE_CONTACT_TYPE",

  AFFILIATE_FORM_COLLEGE: "AFFILIATE_FORM_COLLEGE",
  AFFILIATE_FORM_TYPE: "HIST_TYPE_AFFILE",
  AFFILIATE_FORM_LEGAL_STATUS: "AFFILIATE_FORM_LEGAL_STATUS",
  AFFILIATE_FORM_SOCIAL_REASON: "AFFILIATE_FORM_SOCIAL_REASON",

  AFFILIATE_ESUS_STATUS: "AFFILIATE_ESUS_STATUS",

  USER_TYPE: "USER_TYPE",

  PRODUCT_FORM_NATURE_JURIDIQUE: "PRODUCT_FORM_NATURE_JURIDIQUE",
  PRODUCT_FORM_SOUSCRIPTEUR: "PRODUCT_FORM_SOUSCRIPTEUR",
  PRODUCT_FORM_CANAL: "PRODUCT_FORM_CANAL",
  PRODUCT_FORM_FISCALITE: "PRODUCT_FORM_FISCALITE",
  PRODUCT_FORM_MECANISME: "PRODUCT_FORM_MECANISME",
  PRODUCT_FORM_DOMAINE: "PRODUCT_FORM_DOMAINE",
  PRODUCT_FORM_SUPPORT: "PRODUCT_FORM_SUPPORT",
  PRODUCT_FORM_TYPE_FINANCEMENT: "PRODUCT_FORM_TYPE_FINANCEMENT",
  PRODUCT_FORM_PERFORMANCE_FINANCIERE: "PRODUCT_FORM_PERFORMANCE_FINANCIERE",
  PRODUCT_FORM_TYPE_FONDS: "PRODUCT_FORM_TYPE_FONDS",
  PRODUCT_FORM_FONDS: "PRODUCT_FORM_FONDS",
  PRODUCT_FORM_MODE_CLASSIFICATION_AMF: "PRODUCT_FORM_MODE_CLASSIFICATION_AMF",
  PRODUCT_FORM_MODE_INTERVENTION: "PRODUCT_FORM_MODE_INTERVENTION",
  PRODUCT_FORM_PRINCIPALE_ODD: "PRODUCT_FORM_PRINCIPALE_ODD",
  PRODUCT_FORM_STATUS_LABELLISATION_PRODUCT: "PRODUCT_FORM_STATUS_LABELLISATION_PRODUCT",
  PRODUCT_STATUT: "PRODUCT_STATUT", PRODUCT_LISTE_UTILITES_SOCIALES: "PRODUCT_LISTE_UTILITES_SOCIALES",
  QUESTION_TYPE: "QUESTION_TYPE",

};

export default ReferentielTable;
