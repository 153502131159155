import React, { useContext, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { CHART_BAR_MULTIPLE } from "../../variables/charts/charts";
import { CHART_BAR_MULTIPLE1 } from "../../variables/charts/charts1";

import AreaInput from "../../core/common/inputs/AreaInput";
import FileInput from "../../core/common/inputs/FileInput";
import {
  getValueManager,
  replaceIndex,
  updateObjectFromImage,
  updateValueManager,
} from "../../core/manager/ManagerUtils";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import "./Report.css";

import Logger, { LoggerSuccess } from "../../core/utils/Logger";
import SharedContext from "../../contexts/shared/sharedContext";

import {
  // mostUsedStartups,
  formatDataForBarChart1,
  formatDataForBarChart2,
  formatDataForBarChart3,
  formatDataForBarChart4,
  formatDataForTable,
  formatDataForPieChart1,
  formatDataForPieChart2,
  getMostUsedStartups,
  // data,
} from "./data";
import ImagePreviewProvider from "../../core/common/outputs/ImagePreviewProvider";
import {CHART_BAR_MULTIPLE3} from "../../variables/charts/charts3";


const currentYear = new Date().getFullYear();

export const RPReports = (props) => {
  const { GetResource, getKeyword, ImageSaver } = useContext(SharedContext);

  const [version, setVersion] = useState(0);

  const [preview, setPreview] = useState(false);
  const [form, setForm] = useState({
    logo: null,
    text0: "",
    text1_1: "",
    text1_2_1: "",
    text1_2_2: "",
    text1_2_3: "",
  });
  const [isLoading, setIsLoading] = useState({
    loading: false,
    size: 0,
    count: 0,
  });

  const [data, setData] = useState([]);
  const [goals, setGoals] = useState([]);
  const [activities, setActivities] = useState({});
  const [affiliates, setAffiliates] = useState({});

  const onChange = (v, n, indexes = null) => {
    const f = updateValueManager(form, n, v, indexes);
    setForm({ ...f });
  };

  const setAlert = (message) => {
    LoggerSuccess(message);
  };

  useEffect(() => {
    GetResource("REPORT_MANAGEMENT", "GET", form, null).then((res) => {
      setForm({ ...form, ...res.payload });
    });
    GetResource("FEEDBACK_MANAGEMENT", "REPORT", form, null).then((res) => {
      setData(res.payload.reports);
      setGoals(res.payload.goals);
      setActivities(res.payload.startups);
      setAffiliates(res.payload.affiliates);
    });
  }, []);

  const saveData = (event) => {
    uploadImages(() => {
      GetResource("REPORT_MANAGEMENT", "UPDATE", form, null)
        .then((res) => {
          setAlert(getKeyword("NOTIFICATION.WELL_DONE"));
          setVersion(version + 1);
        })
        .catch((err) => { });
    });
  };

  const uploadImages = (callback) => {
    ImageSaver(
      (params, images) => {
        updateObjectFromImage(form, images);
        callback();
      },
      (params, status) => setIsLoading(status),
      {},
      images,
      form
    );
  };

  const [images, setImages] = useState({});

  const onChangeImages = (
    files,
    name,
    multiple,
    indexes,
    usedFor = "REPORT"
  ) => {
    images[name] = { files, name, multiple, indexes, usedFor };
    setImages({ ...images });
  };

  const handlePdf = () => {
    const oldPreview = preview;
    setPreview(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      setTimeout(() => {
      }, 100);
      const divToPrint = document.querySelector('#report');
      html2canvas(divToPrint).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 195;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const doc = new jsPDF('pt', 'mm');
        let position = 0;
        doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save('rapport.pdf');
        setPreview(oldPreview);
      });
    }, 2000);
  };

  // const handlePdf2 = () => {
  //   const oldPreview = preview;
  //   setPreview(true);
  //   setTimeout(() => {
  //     const input = document.getElementById("report");

  //     html2canvas(input).then((canvas) => {

  //       var HTML_Width = canvas.width;
  //       var HTML_Height = canvas.height;
  //       var top_left_margin = 15;
  //       var PDF_Width = HTML_Width + (top_left_margin * 2);
  //       var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
  //       var canvas_image_width = HTML_Width;
  //       var canvas_image_height = HTML_Height;

  //       var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

  //       // const imgWidth = 208;
  //       // const imgWidth = canvas.width;
  //       // const pageHeight = 1000;
  //       // // const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       // const imgHeight = canvas.height ;
  //       // let heightLeft = imgHeight;
  //       // let position = 0;
  //       // heightLeft -= pageHeight;
  //       // const doc = new jsPDF("p", "px");
  //       // doc.addImage(
  //       //   canvas,
  //       //   "PNG",
  //       //   0,
  //       //   position,
  //       //   imgWidth,
  //       //   imgHeight,
  //       //   "",
  //       //   "FAST"
  //       // );
  //       // console.log("input.height",canvas.height, canvas.width, position, imgWidth, imgHeight, heightLeft);
  //       // while (heightLeft >= 0) {
  //       //   position = heightLeft - imgHeight;
  //       //   doc.addPage();
  //       //   doc.addImage(
  //       //     canvas,
  //       //     "PNG",
  //       //     0,
  //       //     position,
  //       //     imgWidth,
  //       //     imgHeight,
  //       //     "",
  //       //     "FAST"
  //       //   );
  //       //   heightLeft -= pageHeight;
  //       //   console.log("input.height",canvas.height, canvas.width, position, imgWidth, imgHeight, heightLeft);
  //       // }
  //       canvas.getContext('2d');

  //       console.log(canvas.height + "  " + canvas.width);

  //       var imgData = canvas.toDataURL("image/jpeg", 1.0);
  //       var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
  //       pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


  //       for (var i = 1; i <= totalPDFPages; i++) {
  //         pdf.addPage(PDF_Width, PDF_Height);
  //         pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
  //       }
  //       pdf.save("Downld.pdf");
  //     });
  //     // setPreview(oldPreview);
  //   }, 2000);
  // };

  const optionPie = (data) => (

      {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "0%",
      left: "center",
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: true,
          position: 'inside',
          formatter: '{c}'
          //   position: 'center'
        },
        emphasis: {
          label: {
            show: false,
            fontSize: "40",
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ],
  });


  // const optionPie1 (data) => {
  //   title: {
  //     text: 'Referer of a Website',
  //     subtext: 'Fake Data',
  //     left: 'center'
  //   },
  //   tooltip: {
  //     trigger: 'item'
  //   },
  //   legend: {
  //     orient: 'vertical',
  //     left: 'left'
  //   },
  //   series: [
  //     {
  //       name: 'Access From',
  //       type: 'pie',
  //       radius: '50%',
  //       data: [
  //         { value: 1048, name: 'Search Engine' },
  //         { value: 735, name: 'Direct' },
  //         { value: 580, name: 'Email' },
  //         { value: 484, name: 'Union Ads' },
  //         { value: 300, name: 'Video Ads' }
  //       ],
  //       emphasis: {
  //         itemStyle: {
  //           shadowBlur: 10,
  //           shadowOffsetX: 0,
  //           shadowColor: 'rgba(0, 0, 0, 0.5)'
  //         }
  //       }
  //     }
  //   ]
  // };

  console.log("PEDRO", CHART_BAR_MULTIPLE(formatDataForBarChart4(data)));

  return (
    <div>
      <div className="justify-content-between">
        <button className="btn-1" onClick={() => setPreview(!preview)}>
          {preview ? "Modifier" : "Prévisualiser"}
        </button>
        <button className="btn-2" onClick={saveData}>
          Sauvegarder
        </button>
        <button className="btn-2" onClick={handlePdf}>
          PDF
        </button>
      </div>
      <div id="report">
        <div role="form">
          {preview ? <div className="justify-content-center">
            {getValueManager(form, "logo", null) ? <ImagePreviewProvider className="size-report" file={getValueManager(form, "logo", null)}></ImagePreviewProvider> : null}
          </div> :
            <FileInput
              label="LOGO"
              name="logo"
              onChange={(files) => onChangeImages(files, "logo", false)}
              onChangeOld={(v, n) => onChange(v, n)}
              value={getValueManager(form, "logo", null)}
              isImage={true}
              preview={preview}
              version={version}
            ></FileInput>}
          <p className="report-comment">Rappel Contexte global</p>
          <div className="mgb-20">
            <AreaInput
              placeHolder="Xxxxxxxxxxxxxxxx texte xxxxxxxxxxxxxxxxxxxxx"
              name="text0"
              onChange={(v, n) => onChange(v, n)}
              value={getValueManager(form, "text0", null)}
              required={true}
              preview={preview}
            ></AreaInput>
          </div>
        </div>
        <div>
          <p className="report-title">I. Titre</p>
          <p className="report-subtitle">I. 1 - Introduction </p>
          <AreaInput
            placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
            name="text1_1"
            onChange={(v, n) => onChange(v, n)}
            value={getValueManager(form, "text1_1", null)}
            required={true}
            preview={preview}
          ></AreaInput>

          <div>
            <p className="report-subtitle">I. 2 - Les graphiques </p>
            <div>
              <ReactECharts option={CHART_BAR_MULTIPLE1(formatDataForBarChart4(data))} />
              <p className="report-comment">Commentaire</p>
              <div className="mgb-20">
                <AreaInput
                  placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
                  name="text1_2_1"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "text1_2_1", null)}
                  required={true}
                  preview={preview}
                ></AreaInput>
              </div>
            </div>
            <div>
              <table className="table-table">
                <thead>
                  <tr>
                    <td></td>
                    <td>Objectifs {currentYear}</td>
                    <td>Réalisations {currentYear}</td>
                    <td>Entdées En Relation</td>
                    <td>Taux de Transformation</td>
                  </tr>
                </thead>
                <tbody>
                  {formatDataForTable(data, currentYear, goals, affiliates).map((d) => (
                    <tr>
                      {d.map((dd) => (
                        <td>{dd}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <p style={{ textAlign: "center" }}>
                (1) : Taux de Transformation = Nombre de Contrats ou BC Signés /
                Nombre de Mises en Relation
              </p>
              <p className="report-comment">Commentaire</p>
              <div className="mgb-20">
                <AreaInput
                  placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
                  name="text1_2_2"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "text1_2_2", null)}
                  required={true}
                  preview={preview}
                ></AreaInput>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-6">
                  <ReactECharts option={CHART_BAR_MULTIPLE3(formatDataForBarChart3(data, true))} />
                </div>
                <div className="col-6">
                  <ReactECharts option={optionPie(formatDataForPieChart1(data, currentYear))} />
                  {/*<ReactECharts option={optionPie()} />*/}
                </div>
              </div>
              <p className="report-comment">Commentaire</p>
              <div className="mgb-20">
                <AreaInput
                  placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
                  name="text1_2_3"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "text1_2_3", null)}
                  required={true}
                  preview={preview}
                ></AreaInput>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p className="report-title">II. PERIODE {currentYear - 2} - {currentYear} </p>

          <div>
            <p className="report-subtitle">II. 1 - Entrées en Relation (nb)</p>
            <div>
              <p className="report-comment">Commentaire</p>
              <div className="mgb-20">
                <AreaInput
                  placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
                  name="text2_2_1"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "text2_2_1", null)}
                  required={true}
                  preview={preview}
                ></AreaInput>
              </div>
              <ReactECharts option={CHART_BAR_MULTIPLE(formatDataForBarChart2(data, 'signature', false))} />
            </div>
            <div>
              <p className="report-comment">Commentaire</p>
              <div className="mgb-20">
                <AreaInput
                  placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
                  name="text2_2_2"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "text2_2_2", null)}
                  required={true}
                  preview={preview}
                ></AreaInput>
              </div>
              <ReactECharts option={CHART_BAR_MULTIPLE(formatDataForBarChart1(data, 'signature', false, affiliates))} />
            </div>
          </div>

          <div>
            <p className="report-subtitle">
              II. 2 - Volume d'Affaires (xxx MDH)&nbsp;
            </p>
            <div>
              <p className="report-comment">Commentaire</p>
              <div className="mgb-20">
                <AreaInput
                  placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
                  name="text2_2_1"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "text2_2_1", null)}
                  required={true}
                  preview={preview}
                ></AreaInput>
              </div>
              <ReactECharts option={CHART_BAR_MULTIPLE(formatDataForBarChart2(data, "montant"))} />
            </div>
            <div>
              <p className="report-comment">Commentaire</p>
              <div className="mgb-20">
                <AreaInput
                  placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
                  name="text2_2_2"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "text2_2_2", null)}
                  required={true}
                  preview={preview}
                ></AreaInput>
              </div>
              <ReactECharts option={CHART_BAR_MULTIPLE(formatDataForBarChart1(data, "montant"))} />
            </div>
          </div>

          <div>
            <p className="report-subtitle">II. 3 - Contrats Signés (xxx)</p>
            <div>
              <p className="report-comment">Commentaire</p>
              <div className="mgb-20">
                <AreaInput
                  placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
                  name="text2_3_1"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "text2_3_1", null)}
                  required={true}
                  preview={preview}
                ></AreaInput>
              </div>
              <ReactECharts option={CHART_BAR_MULTIPLE(formatDataForBarChart2(data, "signature", true))} />
            </div>
            <div>
              <p className="report-comment">Commentaire</p>
              <div className="mgb-20">
                <AreaInput
                  placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
                  name="text2_3_2"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "text2_3_2", null)}
                  required={true}
                  preview={preview}
                ></AreaInput>
              </div>
              <ReactECharts option={CHART_BAR_MULTIPLE(formatDataForBarChart1(data, 'signature', true))} />
            </div>
          </div>
        </div>

        <div>
          <p className="report-title">III. PROFIL DES STARTUPS :</p>
          <p className="report-subtitle">Les Startups les plus sollicitées :</p>
          <AreaInput
            placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
            name="text3_1"
            onChange={(v, n) => onChange(v, n)}
            value={getValueManager(form, "text3_1", null)}
            required={true}
            preview={preview}
          ></AreaInput>

          <div className="row">
            {getMostUsedStartups(data, activities).map((mostUsedStartup) => (
              <>
                <div className="col-4  mgb-15">
                  Nb contrats : {mostUsedStartup.count +1}
                </div>
                <div className="col-8 mgb-15">
                  {mostUsedStartup.startups.map(({ name, activity }) => (
                    <p className=" mgb-0">
                      {name} ({activity})
                    </p>
                  ))}
                </div>
              </>
            ))}
          </div>

          <div>
            <p className="report-subtitle">
              Taux de Satisfaction des filiales :&nbsp;
            </p>
            <div>
              <p className="report-comment">Commentaire</p>
              <div className="mgb-20">
                <AreaInput
                  placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
                  name="text3_2"
                  onChange={(v, n) => onChange(v, n)}
                  value={getValueManager(form, "text3_2", null)}
                  required={true}
                  preview={preview}
                ></AreaInput>
              </div>
              <ReactECharts option={optionPie(formatDataForPieChart2(data, currentYear))} />
            </div>
          </div>
        </div>
        <p className="report-title">Annexe :</p>
        {preview ? <div className="justify-content-center">
          {getValueManager(form, "logo2", null) ? <ImagePreviewProvider className="size-report" file={getValueManager(form, "logo2", null)}></ImagePreviewProvider> : null}
        </div> :
          <FileInput
            label="Image"
            name="logo2"
            onChange={(files) => onChangeImages(files, "logo2", false)}
            onChangeOld={(v, n) => onChange(v, n)}
            value={getValueManager(form, "logo2", null)}
            isImage={true}
            preview={preview}
            version={version}
          ></FileInput>}
        <AreaInput
          placeHolder="xxxxxxxxx Texte à Saisir xxxxxxxxxxxx "
          name="suptext"
          onChange={(v, n) => onChange(v, n)}
          value={getValueManager(form, "suptext", null)}
          required={true}
          preview={preview}
        ></AreaInput>
      </div>
    </div>
  );
};

export default RPReports;
